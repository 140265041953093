import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import { FileUpload } from "primereact/fileupload";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Calendar } from "primereact/calendar";
import { connect } from "react-redux";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import { Toast } from "primereact/toast";
import { loyalityschemeActions } from "../../actions/loyalityschemeAction";
import { productMasterActions } from "../../actions";
import { userMasterActions } from "../../actions";
import { roleMasterActions } from "../../actions";
import tableRequest from "../../models/tableRequest";
// import { userTypeMasterActions } from '../../actions';
import Switch from "@material-ui/core/Switch";

class loyalityscheme extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Availableseries = this.Availableseries.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.onHidee = this.onHidee.bind(this);
    this.openDialogue = this.openDialogue.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getProductList = this.getProductList.bind(this);
    this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
    this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.getRoleList = this.getRoleList.bind(this);
    //this.getRoleDDL = this.getRoleDDL.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onPage1 = this.onPage1.bind(this);
    this.onSort1 = this.onSort1.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.submitseries = this.submitseries.bind(this);
    this.onChangeDDL = this.onChangeDDL.bind(this);
    // const UserTypeData = [
    //     { value: 1, label: 'Web' },
    //     { value: 2, label: 'Mobile' }
    // ]
    const SchemeCategoryData = [
      { value: 1, label: "Paytm" },
      { value: 2, label: "Loyality Points" },
    ];

    this.state = {
      selectedProduct: "",
      productId: "",
      IsAdd: true,
      IsEdit: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      first1: 0,
      rows1: 5,
      totalRecords1: 0,
      globalFilter: null,
      sortOrder: "",
      buttonLabel: "Submit",
      switchAction: false,
      formHeading: "Add Loyality Master",
      UserId: 0,
      RoleId: 0,
      //UserTypeId: '',
      Mappseriesdata: [],
      selectedProduct: null,
      // UserTypeData: [
      //     { value: 1, label: 'Web' },
      //     { value: 2, label: 'Mobile' }
      // ],
      SchemeCategoryData: [
        { value: 1, label: "Paytm" },
        { value: 2, label: "Loyality Points" },
      ],
      productId: "",
      SchemeName: "",
      Schemedescription: "",
      SchemeCategory: "",
      //UserTypeId: '',
      product: "",
      Qty: "",
      StartDate: new Date(),
      EndDate: new Date(),
      FromNo: "",
      ToNo: "",
      Status: "",
      loyalitypnt: "",
      IsActive: true,
      CreatedDate: null,
      UpdatedDate: null,
      isEditing: false,
      formError: {
        productId: "",
        SchemeName: "",
        Schemedescription: "",
        SchemeCategory: "",
        RoleId: "",
        StartDate: "",
        EndDate: "",
        FromNo: "",
        //UserTypeId: '',
        //product: '',
        Qty: "",
        loyalitypnt: "",
      },
    };
  }
  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    var date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 7);
    this.tblResponse.FromDate = date.toLocaleDateString();
    this.tblResponse.ToDate = new Date().toLocaleDateString();
    this.setState({
      StartDate: new Date(),
      EndDate: date,
    });

    this.setState({
      Mappseriesdata: this.props.Mappseries,
    });

    this.props.fetchApi(this.tblResponse);

    const userId = "2";
    this.props.getRoleDDL(userId);
    this.props.getProductDDL();
  }

  componentDidUpdate(prevProps) {
    if (this.props.SchememappingData) {
      if (prevProps.SchememappingData !== this.props.SchememappingData) {
        // this.props.myProp has a different value
        if (this.props.SchememappingData) {
          var e = this.props.SchememappingData;
          if (e[0]) {
            this.setState({
              totalRecords: e[0].TotalRows,
            });
          }
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }

    if (this.props.Mappseriesdata) {
      if (prevProps.Mappseriesdata !== this.props.Mappseriesdata) {
        // this.props.myProp has a different value
        if (this.props.Mappseriesdata) {
          var e = this.props.Mappseriesdata;
          this.setState({
            totalRecords1: e[0].this.props.TotalRows1,
          });
        }
      }
    } else {
      if (this.state.totalRecords1 !== 0)
        this.setState({
          totalRecords1: 0,
          first1: 0,
        });
    }
  }

  switchToggleConfirm = (e) => {
    this.props.inActiveRow(this.state.SchemeId, this.state.switchAction);
    setTimeout(() => {
      this.props.fetchApi(this.tblResponse);
    }, 2000);
  };

  Availableseries(event) {
    // this.setState({
    //     RoleId: this.state.RoleId ,
    //     product : event.target.value,
    //     productData: this.props.productData
    // });
    const data = {
      RoleId: this.state.RoleId,
      ProductId: this.state.product,
    };
    setTimeout(() => {
      this.props.GetAvailableseries(data);
    }, 500);
    this.openDialogue();
  }

  onChangeDDL(e) {
    this.setState({ RoleId: e.target.value });
    this.getFromNo(e.target.value, "r");
  }

  getFromNo(value, name) {
    var data = {
      RoleId: value,
    };
    this.props.GetAvailableseries(data);
    setTimeout(() => {
      this.setState({ FromNo: this.props.FromNo });
    }, 800);
  }

  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "IsActive":
        value = event.target.checked;
        break;
      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  getRoleList(event) {
    this.setState({ UserTypeId: event.target.value });
    this.props.getRoleDDL(event.target.value);
  }

  UpdatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>;
    } else {
    }
  }

  CreatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>;
    }
  }
  getProductList(event) {
    this.props.getProductDDL();
  }

  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "SchemeName":
        if (value.length < 1) {
          IsValid = false;
          errors.SchemeName = "Please enter Scheme name.";
        } else errors.SchemeName = "";
        break;
      case "Schemedescription":
        if (value.length < 1) {
          IsValid = false;
          errors.Schemedescription = "Please enter Scheme description";
        } else errors.Schemedescription = "";
        break;
      case "SchemeCategory":
        if (value.length < 1) {
          IsValid = false;
          errors.SchemeCategory = "Please enter Scheme Category.";
        } else errors.SchemeCategory = "";
        break;
      case "RoleId":
        if (!value) {
          IsValid = false;
          errors.RoleId = "Please select user role.";
        } else errors.RoleId = "";
        break;
      // case 'UserTypeId':
      //     if (!value) {
      //         IsValid = false;
      //         errors.UserTypeId = "Please select User Type.";
      //     } else errors.UserTypeId = ''
      //     break;
      case "FromNo":
        if (value.length < 1) {
          IsValid = false;
          errors.FromNo = "Please enter From No.";
        } else {
          if (!value.toString().match(/^[0-9]+$/.exec(value))) {
            IsValid = false;
            errors.Qty = "Please enter only number.";
            this.setState({ Qty: 0 });
          } else {
            errors.ToNo = null;
            errors.Qty = null;
            errors.FromNo = null;
            const fromNumber =
              value.toString().trim() === ""
                ? 0
                : parseInt(value.toString().trim());
            const qty =
              this.state.Qty.toString().trim() === ""
                ? 0
                : parseInt(this.state.Qty.toString().trim());
            if (fromNumber >= 1 && qty >= 1) {
              const toNumber = qty + fromNumber - 1;
              if (toNumber >= 0) {
                this.setState({ ToNo: toNumber });
              } else {
                IsValid = false;
                errors.Qty =
                  "To number should be greater than or equal to  from number.";
                this.setState({ ToNo: 0 });
              }
            } else {
              // IsValid = false;
              // errors.ToNo = "To number should be greater than from number.";
              this.setState({ ToNo: 0 });
            }
          }
        }
        break;
      //   case "product":
      //     if (value.length < 1) {
      //       IsValid = false;
      //       errors.product = "Please select Product.";
      //     } else errors.product = "";
      //     break;
      case "Qty":
        if (!value === value) {
          IsValid = false;
        } else {
          if (value < 1) {
            IsValid = false;
            errors.Qty = "Please enter your quantity.";
          } else {
            if (!value.toString().match(/^[0-9]+$/.exec(value))) {
              IsValid = false;
              errors.Qty = "Please enter only number.";
              this.setState({ Qty: 0 });
            } else {
              errors.ToNo = null;
              errors.Qty = null;
              errors.FromNo = null;
              const qty =
                value.toString().trim() === ""
                  ? 0
                  : parseInt(value.toString().trim());
              const fromNumber =
                this.state.FromNo.toString().trim() === ""
                  ? 0
                  : parseInt(this.state.FromNo.toString().trim());
              if (fromNumber >= 1 && qty >= 1) {
                const toNumber = qty + fromNumber - 1;
                if (toNumber >= 0) {
                  this.setState({ ToNo: toNumber });
                } else {
                  IsValid = false;
                  errors.Qty =
                    "To number should be greater than or equal to  from number.";
                  this.setState({ ToNo: 0 });
                }
              } else {
                // IsValid = false;
                // errors.ToNo = "To number should be greater than from number.";
                this.setState({ ToNo: 0 });
              }
            }
          }
        }
        break;
      case "loyalitypnt":
        value = value === "" ? 0 : value;
        if (value === null || parseInt(value) < 1) {
          IsValid = false;
          errors.loyalitypnt = "Loyalty points must be greater than 0";
        } else errors.loyalitypnt = null;
        break;
      case "StartDate":
        if (value === null || !(value instanceof Date)) {
          this.formIsValid = false;
          errors.StartDate = "Please select start date.";
          // this.refs.Messchild.showError("Please select from date.");
          //this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select start date.', life: 3000 });

          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          errors.StartDate = "Please enter only date.";
          //this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
          // this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (value instanceof Date && this.state.ToDate instanceof Date) {
          const startDate = value;
          const endDate = this.state.EndDate;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            errors.StartDate = "Start date must be less than End date.";
            //this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Start date must be less than End date.', life: 3000 });
            // this.refs.Messchild.showError("From date must be less than to date.");
            return;
          } else errors.StartDate = null;
        }

        break;
      case "EndDate":
        if (value === null || !(value instanceof Date)) {
          this.formIsValid = false;
          errors.EndDate = "Please select to date.";
          //this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select to date.', life: 3000 });
          // this.refs.Messchild.showError("Please select to date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          errors.EndDate = "Please enter only date.";
          //this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
          // this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (
          value instanceof Date &&
          this.state.StartDate instanceof Date
        ) {
          const startDate = this.state.StartDate;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            errors.EndDate = "End date must be greater than Start date.";
            //this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'To date must be greater than from date.', life: 3000 });
            // this.refs.Messchild.showError("To date must be greater than from date.");
            return;
          } else errors.EndDate = null;
        }

        break;
      default:
        break;
    }

    return IsValid;
  }

  getDropValueOfRole() {
    let roleName = "";
    this.props.UserRoleData.filter((x) => {
      if (x.value === this.state.RoleId.toString()) {
        roleName = x.label;
      }
    });

    return roleName;
  }

  // getDropValueOfRole() {
  //     let roleName = '';
  //     this.props.UserRoleData.filter(x => {
  //         if (x.value === this.state.RoleId.toString()) {
  //             roleName = x.label;
  //         }
  //     });

  //     return roleName;
  // }

  // getDropValueOfUserType() {
  //     let usertype = '';
  //     this.state.UserTypeData.filter(x => {
  //         if (x.value === this.state.UserTypeId) {
  //             usertype = x.label;
  //         }
  //     });

  //     return usertype;
  // }

  getDropValueOfSchemeCategory() {
    let SchemeCategoryId = "";
    this.state.SchemeCategoryData.filter((x) => {
      if (x.value === this.state.SchemeCategory) {
        SchemeCategoryId = x.label;
      }
    });

    return SchemeCategoryId;
  }

  submitseries(event) {
    event.preventDefault();

    this.props.onHideShoww(false);
  }

  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      const ddlValuRole = this.getDropValueOfRole();
      //const ddlValuRole = this.getDropValueOfRole();
      //const ddlValuUserType = this.getDropValueOfUserType();
      const ddlValuSchemeCategory = this.getDropValueOfSchemeCategory();
      const formData = {
        SchemeId: this.state.SchemeId,
        productId: this.state.productId,
        SchemeName: this.state.SchemeName,
        Schemedescription: this.state.Schemedescription,
        SchemeCategory: this.state.SchemeCategory,
        RoleId: this.state.RoleId,
        //UserTypeId: this.state.UserTypeId,
        product: this.state.product,
        Qty: this.state.Qty,
        FromNo: this.state.FromNo,
        ToNo: this.state.ToNo,
        StartDate: this.state.StartDate.toLocaleDateString(),
        EndDate: this.state.EndDate.toLocaleDateString(),
        Status: this.state.Status,
        loyalitypnt: this.state.loyalitypnt,
        IsActive: this.state.IsActive,
        CreatedDate:
          this.state.UserId > 0
            ? this.state.CreatedDate
            : this.getCurrentDateMMDDYYY(),
        UpdatedDate:
          this.state.UserId > 0 ? this.getCurrentDateMMDDYYY() : null,
        RoleName: ddlValuRole != null ? ddlValuRole : "",
        TotalRows: this.state.totalRecords,
      };
      if (formData.SchemeId > 0) {
        this.props.updateScheme(formData);
        event.preventDefault();
        setTimeout(() => {
          this.props.fetchApi(this.tblResponse);
        }, 250);
      } else {
        if (this.props.SchememappingData !== null) {
        }
        this.props.createScheme(formData);
        event.preventDefault();
        setTimeout(() => {
          this.props.fetchApi(this.tblResponse);
        }, 250);
      }

      if (!this.props.showDialog) {
        this.resetForm();
      }
    }
  }

  // getRoleDDL(event) {
  //     this.props.getRoleDDL();
  // }
  resetForm() {
    this.setState({
      productId: "",
      SchemeId: 0,
      SchemeName: "",
      Schemedescription: "",
      SchemeCategory: "",
      //UserTypeId: '',
      RoleId: "",
      product: "",
      Qty: "",
      StartDate: "",
      EndDate: "",
      FromNo: "",
      ToNo: "",
      Status: "",
      loyalitypnt: "",
      IsActive: true,
      formError: {
        productId: "",
        SchemeName: "",
        Schemedescription: "",
        SchemeCategory: "",
        FromNo: "",
        ToNo: "",
        StartDate: "",
        EndDate: "",
        Status: "",
        //UserTypeId: '',
        product: "",
        Qty: "",
        loyalitypnt: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  getLazyLoadingValues1(event) {
    this.setState({ rows1: event.rows, first1: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  onPage1(event) {
    //this.props.loadingData(true);
    //imitate delay of a backend call
    // setTimeout(() => {
    //     this.getLazyLoadingValues1(event);
    //     this.props.GetAvailableseries(this.tblResponse);
    // }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  onSort1(event) {
    //this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    //this.props.GetAvailableseries(this.tblResponse);
  }

  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }
  openDialog() {
    this.resetForm();
    this.props.onHideShow(true);
    this.setState({
      buttonLabel: "Submit",
      formHeading: "Add Scheme",
      isEditing: false,
    });
  }
  onHidee() {
    this.props.onHideShoww(false);
  }

  openDialogue() {
    this.props.onHideShoww(true);
    this.setState({ buttonLabel: "Submit", formHeadingg: "Available Series" });
    //this.props.GetAvailableseries(this.tblResponse);
  }
  onRowSelect(event) {
    //this.toast.show({ severity: 'info', summary: 'Series Selected', detail: `FromNo: ${event.data.FromNo}`, detail: `ToNo: ${event.data.ToNo}`, life: 3000 });
    this.setState({
      FromNo: event.data.FromNo,
      ToNo: event.data.ToNo,
      Qty: event.data.ToNo - event.data.FromNo + 1,
    });
    this.onHidee();
  }
  statusBodyTemplate(rowData) {
    if (rowData) {
      return (
        <Checkbox
          disabled
          checked={rowData.IsActive}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      );
    }
  }
  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }
  actionBodyTemplate(rowData) {
    const editMode = (e) => {
      this.props.onHideShow(true);
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Scheme",
        SchemeId: rowData.SchemeId,
        SchemeName: rowData.SchemeName,
        Schemedescription: rowData.Schemedescription,
        SchemeCategory: rowData.SchemeCategory,
        //UserTypeId: rowData.UserTypeId,
        RoleId: rowData.RoleId,
        product: rowData.product,
        Qty: rowData.Qty,
        StartDate: new Date(rowData.StartDate),
        EndDate: new Date(rowData.EndDate),
        FromNo: rowData.FromNo,
        ToNo: rowData.ToNo,
        Status: rowData.Status,
        IsActive: rowData.IsActive,
        loyalitypnt: rowData.loyalitypnt,
        CreatedDate: rowData.CreatedDate,
        isEditing: true,
      });
    };
    const switchHandleChange = (e) => {
      this.setState({
        switchAction: !rowData.IsActive,
        SchemeId: rowData.SchemeId,
      });
      this.props.onHideShowIsActive(true);
    };

    if (rowData) {
      return (
        <>
          {this.state.IsEdit === true ? (
            <div>
              <Switch
                checked={rowData.IsActive}
                color="primary"
                name={rowData.SchemeId}
                onChange={switchHandleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Button icon="pi pi-pencil" onClick={editMode} />
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  render() {
    const renderFooter = () => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={() => this.switchToggleConfirm()}
            autoFocus
          />
        </div>
      );
    };
    const header = (
      <div className="table-header row">
        <div className="col-md-6">
          <h4>Loyalty Scheme Master</h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>

          {this.state.IsAdd === true ? (
            <Button
              className="mr-2"
              label="Add Scheme"
              icon="pi pi-plus-circle"
              onClick={this.openDialog}
            />
          ) : (
            <Button
              className="mr-2"
              label="Add Scheme"
              icon="pi pi-plus-circle"
              style={{ display: "none" }}
              onClick={this.openDialog}
            />
          )}
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />

        <div className="card mb-5">
          <DataTable
            className="mb-2"
            value={this.props.SchememappingData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column style={{ width: "13%" }} field="SchemeName" header="SchemeName" sortable></Column>
            <Column
              field="Schemedescription"
              header="Scheme Description"
              sortable
              style={{ width: "16%" }}
            ></Column>
            <Column
              field="SchemeCategoryName"
              style={{ width: "14%" }}
              header="Scheme Category"
              sortable
            ></Column>
            <Column field="RoleName" style={{ width: "12%" }} header="Applied To" sortable></Column>
            <Column field="StartDate" style={{ width: "12%" }} header="Start Date" sortable></Column>
            <Column field="EndDate" style={{ width: "12%" }} header="End Date" sortable></Column>
            <Column field="Status" style={{ width: "12%" }} header="Status" sortable></Column>
            {this.state.IsEdit === true ? (
              <Column
                field="Action"
                header="Action"
                style={{ width: "10%" }}
                body={this.actionBodyTemplate}
              ></Column>
            ) : (
              <Column
                field="Action"
                header="Action"
                style={{ width: "10%" }}
              ></Column>
            )}
          </DataTable>
        </div>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          {/* <form onSubmit={this.submitForm} autocomplete="off"> */}
          <div className="row">
            <div className="col-md-6 pt-2">
              <TextField
                disabled={this.state.isEditing}
                onChange={this.handleChange}
                name="SchemeName"
                autoComplete="false"
                value={this.state.SchemeName}
                size="small"
                variant="outlined"
                className="fullwidth"
                label="Scheme Name*"
              />
              {this.state.formError.SchemeName !== "" ? (
                <div className="error">{this.state.formError.SchemeName}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 pt-2">
              <TextField
                onChange={this.handleChange}
                name="Schemedescription"
                autoComplete="false"
                value={this.state.Schemedescription}
                size="small"
                variant="outlined"
                className="fullwidth"
                label="Scheme Description*"
              />
              {this.state.formError.Schemedescription !== "" ? (
                <div className="error">
                  {this.state.formError.Schemedescription}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-6 pt-3">
              <FormControl
                variant="outlined"
                size="small"
                className="formControl col-md-12"
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Scheme Category*
                </InputLabel>
                <Select
                  disabled={this.state.isEditing}
                  onChange={this.handleChange}
                  name="SchemeCategory"
                  value={this.state.SchemeCategory}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Select Scheme Category"
                >
                  <MenuItem>
                    <em>None</em>
                  </MenuItem>
                  {this.state.SchemeCategoryData.map((key) => (
                    <MenuItem value={key.value}>{key.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {this.state.formError.SchemeCategory !== "" ? (
                <div className="error">
                  {this.state.formError.SchemeCategory}
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <div className="col-md-6 pt-3">
                                <FormControl variant="outlined" size="small" className="formControl col-md-12">
                                    <InputLabel id="demo-simple-select-outlined-label">Select User Type*</InputLabel>
                                    <Select onChange={this.handleChange} name="UserTypeId" value={this.state.UserTypeId} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select User Type">
                                        <MenuItem>
                                            <em>None</em>
                                        </MenuItem>
                                        {this.state.UserTypeData.map(key => (
                                            <MenuItem value={key.value}>{key.label}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                                {this.state.formError.UserTypeId !== '' ?
                                    <div className='error'>{this.state.formError.UserTypeId}</div> : ''}
                            </div> */}
            <div className="col-md-6 pt-3">
              <FormControl
                variant="outlined"
                size="small"
                className="formControl col-md-12"
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select User Role*
                </InputLabel>
                <Select
                  disabled={this.state.isEditing}
                  onChange={this.onChangeDDL}
                  name="RoleId"
                  value={this.state.RoleId}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Select User Role"
                >
                  <MenuItem>
                    <em>None</em>
                  </MenuItem>

                  {/* <MenuItem value={2025}>
                  Mechanic
                  </MenuItem> */}

                  {this.props.UserRoleData == (undefined || null)
                    ? null
                    : this.props.UserRoleData.map((key) => {
                        if (key.label != "Dealer") {
                          return (
                            <MenuItem value={key.value}>{key.label}</MenuItem>
                          );
                        }
                      })}
                </Select>
              </FormControl>
              {this.state.formError.RoleId !== "" ? (
                <div className="error">{this.state.formError.RoleId}</div>
              ) : (
                ""
              )}
            </div>

            {/* <div className="col-md-6 pt-3">
                                <FormControl variant="outlined" size="small" className="formControl col-md-12">
                                    <InputLabel id="demo-simple-select-outlined-label">Select Product*</InputLabel>
                                    <Select disabled={this.state.isEditing} onChange={this.onChangeDDL} name="product" value={this.state.product} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select User Role">
                                        <MenuItem>
                                            <em>None</em>
                                        </MenuItem>

                                        {this.props.productData == (undefined || null) ? null : this.props.productData.map(key => (
                                            <MenuItem value={key.value}>{key.label}</MenuItem>
                                        )
                                        )}

                                    </Select>
                                </FormControl>
                                {this.state.formError.product !== '' ?
                                    <div className='error'>{this.state.formError.product}</div> : ''}
                            </div> */}
            <div className="col-md-6 pt-2 flexContainer">
              <TextField
                disabled={this.state.isEditing}
                onChange={this.handleChange}
                value={this.state.FromNo}
                name="FromNo"
                size="small"
                variant="outlined"
                className="inputField"
                label="From No*"
              />
              {this.state.IsAdd === true ? (
                <Button
                  hidden={this.state.isEditing}
                  disabled={this.state.RoleId > 0 ? false : true}
                  icon="pi pi-plus"
                  onClick={this.Availableseries}
                />
              ) : (
                ""
              )}
              {this.state.formError.FromNo !== "" ? (
                <div className="error">{this.state.formError.FromNo}</div>
              ) : (
                ""
              )}
              <Dialog
                header={"Available Series"}
                modal
                dismissableMask={false}
                visible={this.props.showDialogue}
                style={{ width: "50vw" }}
                onHide={this.onHidee}
              >
                <div className="datatable-selection-demo">
                  <div className="card">
                    <Toast ref={(el) => (this.toast = el)} />

                    {this.props.Mappseries ? (
                      <DataTable
                        value={this.props.Mappseries}
                        scrollable
                        responsive={true}
                        selectionMode="checkbox"
                        selection={this.props.selectedProduct}
                        onSelectionChange={(e) =>
                          this.setState({ selectedProduct: e.value })
                        }
                        dataKey="id"
                        responsiveLayout="scroll"
                        onRowSelect={this.onRowSelect}
                      >
                        <Column
                          selectionMode="single"
                          headerStyle={{ width: "6em" }}
                        ></Column>
                        <Column
                          field="FromNo"
                          header="From No"
                          sortable
                        ></Column>
                        <Column field="ToNo" header="To No" sortable></Column>
                        {/* <Column field="Qty" header="Quantity" sortable></Column> */}
                      </DataTable>
                    ) : (
                      <h4 className="text-center"> No Data Available </h4>
                    )}

                    {/* <div className="col-md-12 mt-4 text-center">

                                                <Button label={this.state.buttonLabel} onClick={this.submitseries} icon="pi pi-check" />
                                            </div> */}
                  </div>
                </div>
              </Dialog>
            </div>

            <div className="col-md-6 pt-2 mt-2">
              <TextField
                onChange={this.handleChange}
                disabled
                value={this.state.ToNo}
                name="ToNo"
                size="small"
                variant="outlined"
                className="fullwidth"
                label="To No*"
              />
            </div>
            <div className="col-md-6 pt-3">
              <TextField
                disabled={this.state.isEditing}
                onChange={this.handleChange}
                value={this.state.Qty}
                name="Qty"
                size="small"
                variant="outlined"
                className="fullwidth"
                label="Qty*"
              />
              {this.state.formError.Qty !== "" ? (
                <div className="error">{this.state.formError.Qty}</div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-6 pt-3">
              <TextField
                disabled={this.state.isEditing}
                id="standard-basic"
                size="small"
                onChange={this.handleChange}
                variant="outlined"
                name="loyalitypnt"
                value={this.state.loyalitypnt}
                className="fullwidth"
                label="Loyality Points*"
              />
              {this.state.formError.loyalitypnt !== "" ? (
                <div className="error">{this.state.formError.loyalitypnt}</div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-6 pt-3">
              <span className="ml-2">Start Date: </span>
              <Calendar
                disabled={this.state.isEditing}
                placeholder="Start Date"
                showIcon={true}
                name="StartDate"
                value={this.state.StartDate}
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
              ></Calendar>
              {this.state.formError.StartDate !== "" ? (
                <div className="error">{this.state.formError.StartDate}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 pt-3">
              <span className="ml-2">End Date: </span>
              <Calendar
                placeholder="End Date"
                showIcon={true}
                name="EndDate"
                value={this.state.EndDate}
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
              ></Calendar>
              {this.state.formError.EndDate !== "" ? (
                <div className="error">{this.state.formError.EndDate}</div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-6 pt-3">
              <Checkbox
                name="IsActive"
                checked={this.state.IsActive}
                onClick={this.handleChange}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />{" "}
              <label>Is Active</label>
            </div>

            {/* <div className="col-md-12 mt-3 mb-3 text-center">
                                {this.state.IsEdit === true ?
                                    <Button label={this.state.buttonLabel} icon="pi pi-check" />
                                    : ""}
                            </div> */}
            <div className="col-md-12 mt-4 text-center">
              <div className="mb-2">Please Click Below Button To Submit*</div>

              <Button
                label={this.state.buttonLabel}
                onClick={this.submitForm}
                icon="pi pi-check"
              />
            </div>

            <div className="col-md-12">
              Note: Fields marked as (*) are required
            </div>
          </div>
          {/* </form> */}
        </Dialog>
        <Dialog
          header="Confirmation"
          visible={this.props.showIsActiveDialog}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    SchememappingData: state.loyalityscheme.items,
    UserRoleData: state.role.rollddl,
    FromNo:
      state.loyalityscheme.Mappseries != null
        ? state.loyalityscheme.Mappseries[0].FromNo
        : "",
    //UserRoleData: state.role.rollddl,
    Mappseries: state.loyalityscheme.Mappseries,
    showDialogue: state.loyalityscheme.showDialogue,
    productData: state.product.prdctddl,
    showDialog: state.role.showDialog,
    loading: state.company.loding,
    showIsActiveDialog: state.loyalityscheme.showIsActiveDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(loyalityschemeActions.getAll(obj));
    },
    getProductDDL: () => {
      dispatch(productMasterActions.getPrdctDDL());
    },
    GetAvailableseries: (data) => {
      dispatch(loyalityschemeActions.GetAvailableseries(data));
    },
    createScheme: (user) => dispatch(loyalityschemeActions.createScheme(user)),
    updateScheme: (user) => dispatch(loyalityschemeActions.updateScheme(user)),
    inActiveRow: (SchemeId, isActive) =>
      dispatch(loyalityschemeActions.inActiveRow(SchemeId, isActive)),
    onHideShow: (isbool) => dispatch(loyalityschemeActions.onHideShow(isbool)),
    onHideShoww: (isbool) =>
      dispatch(loyalityschemeActions.onHideShoww(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(loyalityschemeActions.onHideShowIsActive(isbool)),
    getRoleDDL: (data) => dispatch(userMasterActions.getRoleDDL(data)),
    loadingData: (isLoding) =>
      dispatch(loyalityschemeActions.loadingData(isLoding)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(loyalityscheme);
