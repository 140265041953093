import { DispatchConstants } from "../constants/DispatchConstants";
export function Dispatch(state = {}, action) {
  switch (action.type) {
    case DispatchConstants.LOADING_REQUEST:
      return {
        ...state,
        loding: action.isbool,
      };
    case DispatchConstants.DIALOG_REQUEST:
      return {
        ...state,
        showDialog: action.isbool,
      };
    //case DispatchConstants.DIALOGUE_REQUEST:
    //    return {
    //        ...state,
    //        showDialogue: action.isbool
    //    };
    case DispatchConstants.DIALOG_ISACTIVE_REQUEST:
      return {
        ...state,
        showIsActiveDialog: action.isbool,
      };
    //case DispatchConstants.UPDATE_PAGE_STORE:
    //    return {
    //        ...state,
    //        pages: action.pageList
    //    };
    case DispatchConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DispatchConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };

    case DispatchConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case DispatchConstants.GETALL_AVAILABLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DispatchConstants.GETALL_AVAILABLE_SUCCESS:
      return {
        ...state,
        Mappseries: action.datas,
      };
    case DispatchConstants.GETALL_AVAILABLE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case DispatchConstants.SO_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DispatchConstants.SO_DETAILS_SUCCESS:
      return {
        ...state,
        SODetails: action.data,
        dealerId: action.data.ResponseData.DealerId,
      };
    case DispatchConstants.SO_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case DispatchConstants.CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case DispatchConstants.CREATE_SUCCESS:
      return {
        ...state,
        Dispatch:
          state.items == null ? [action.data] : [action.data, ...state.items],
        isDownload: state.isDownload == null ? 1 : state.isDownload + 1,
      };
    case DispatchConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case DispatchConstants.UPDATE_REQUEST:
      return {
        ...state,
        items: state.items.map((item) =>
          item.DispatchId === action.data.DispatchId
            ? { ...item, updating: true }
            : item
        ),
      };
    case DispatchConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.DispatchId === action.data.DispatchId ? action.data : item
        ),
      };
    case DispatchConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case DispatchConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DispatchConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.DispatchId === action.data
            ? {
                CompanyId: item.CompanyId,
                DispatchName: item.DispatchName,
                //roleId: item.roleId,
                //RoleName: item.roleName,
                //RoleDesc: item.RoleDesc,
                isActive: item.isActive,
                //CreatedDate: item.CreatedDate
              }
            : item
        ),
      };
    case DispatchConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case DispatchConstants.GETDispatchDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DispatchConstants.GETDispatchDDL_SUCCESS:
      return {
        ...state,
        Dispatchddl: action.datas,
      };
    case DispatchConstants.GETDispatchDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    //case DispatchConstants.GETALL_FAILURE:
    //    return {
    //        ...state,
    //        error: action.error
    //    };
    //
    //    case DispatchConstants.GETPRDCTDDL_REQUEST:
    //    return {
    //        ...state,
    //        loading: true
    //    };
    //case DispatchConstants.GETPRDCTDDL_SUCCESS:
    //    return {
    //        ...state,
    //        prdctddl: action.datas
    //    };
    //case DispatchConstants.GETPRDCTDDL_FAILURE:
    //    return {
    //        ...state,
    //        error: action.error
    //    };
    // case DispatchConstants.GET_PAGES_REQUEST:
    //     return {
    //         ...state,
    //         getpages: true
    //     };
    // case DispatchConstants.GET_PAGES_SUCCESS:
    //     return {
    //         ...state,
    //         pages: action.pages
    //     };
    // case DispatchConstants.GET_PAGES_FAILURE:
    //     return {
    //         ...state,
    //         error: action.error
    //     };
    case DispatchConstants.ISACTIVE_REQUEST:
      return {
        ...state,
        activating: true,
      };
    case DispatchConstants.ISACTIVE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.DispatchId === action.data.DispatchId
            ? {
                CompanyId: item.CompanyId,
                DispatchName: item.DispatchName,
                //RoleId: item.RoleId,
                //RoleName: item.RoleName,
                //RoleDesc: item.RoleDesc,
                TotalRows: item.TotalRows,
                IsActive: action.data.IsActive,
                CreatedDate: item.CreatedDate,
              }
            : item
        ),
      };
    case DispatchConstants.ISACTIVE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case DispatchConstants.Warning:
      return {
        ...state,
        warning: action.isbool,
      };
    case DispatchConstants.Alreadypacked:
      return {
        ...state,
        alreadyPacked: action.isbool,
      };
    default:
      return state;
  }
}
