import { companyService } from '../services';
import { companyConstants } from '../constants';
import { alertActions } from '.';

export const companyActions = {
    getAll,
    createCompany,
    updateCompany,
    inActiveRow,
    getcmpnyddl,
    onHideShow,
    loadingData,
    onHideShowIsActive
};

function getAll(data) {
    return dispatch => {
        dispatch(request());

        companyService.fetchCompanyData(data).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: companyConstants.GETALL_REQUEST } }
    function success(datas) { return { type: companyConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: companyConstants.GETALL_FAILURE, error } }
}

function createCompany(data) {
    return dispatch => {
        dispatch(request(data));

        companyService.createCompany(data).then(
            response => {
                //history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(onHideShow(false));
                        if (response.ResponseData) {
                            dispatch(success(response.ResponseData));
                        }
                        dispatch(alertActions.success(response.ResponseMessage))
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: companyConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: companyConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: companyConstants.CREATE_FAILURE, error } }
};

function updateCompany(data) {
    return dispatch => {
        dispatch(request(data));

        companyService.updateCompany(data).then(
            response => {
                //history.push('/');                
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(onHideShow(false));
                        dispatch(success(data));
                        dispatch(alertActions.success(response.ResponseMessage))
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: companyConstants.UPDATE_REQUEST, data } }
    function success(data) { return { type: companyConstants.UPDATE_SUCCESS, data } }
    function failure(error) { return { type: companyConstants.UPDATE_FAILURE, error } }
};


function inActiveRow(companyId, isActive) {
    var data = {
        CompanyId: companyId,
        IsActive: isActive
    }
    return dispatch => {
        dispatch(request());

        companyService.inActiveRow(companyId, isActive).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(data));
                        dispatch(onHideShowIsActive(false));
                        dispatch(alertActions.success(response.ResponseMessage))
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };
    function request() { return { type: companyConstants.ISACTIVE_REQUEST } }
    function success(data) { return { type: companyConstants.ISACTIVE_SUCCESS, data } }
    function failure(error) { return { type: companyConstants.ISACTIVE_FAILURE, error } }
};

function getcmpnyddl() {
    return dispatch => {
        dispatch(request());

        companyService.FetchCompanyDDL().then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: companyConstants.GETCMPNYDDL_REQUEST } }
    function success(datas) { return { type: companyConstants.GETCMPNYDDL_SUCCESS, datas } }
    function failure(error) { return { type: companyConstants.GETCMPNYDDL_FAILURE, error } }
};

function onHideShow(isbool) {
    return {
        type: companyConstants.DIALOG_REQUEST, isbool
    }
}

function loadingData(isbool) {
    return {
        type: companyConstants.LOADING_REQUEST, isbool
    }
}

function onHideShowIsActive(isbool) {
    return {
        type: companyConstants.DIALOG_ISACTIVE_REQUEST, isbool
    }
}




