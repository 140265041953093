import { RedemptionLogRptService }  from '../services';
import  { RedemptionLogRptConstants }  from '../constants';
import { alertActions } from '.';

export const RedemptionLogRptActions = {
    getAll,
    loadingData,
    exportData
};

function getAll(obj)
{
    return dispatch =>{
        dispatch(request());

        RedemptionLogRptService.fetchRedemptionLogData(obj).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))

        },
        error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        }
        
        );
    };
    function request() { return { type : RedemptionLogRptConstants.GETALL_REQUEST } }       //Changes
    function success(datas) { return { type: RedemptionLogRptConstants.GETALL_SUCCESS, datas } }
    function successExport(datas) { return { type: RedemptionLogRptConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: RedemptionLogRptConstants.GETALL_FAILURE, error } }
}

function exportData(obj) {
    return dispatch => {
        dispatch(request());
        RedemptionLogRptService.fetchRedemptionLogData(obj).then(response => {
            dispatch(loadingData(false));
            var data = response.ResponseData;
            if (data != null) {
                data.forEach(function (v) { delete v.TotalRows; delete v.CreatedBy; 
                    delete v.DocURL; delete v.RedemptionLogId; delete v.ProductID
                });
            }
            dispatch(success(data))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };
    function request() { return { type: RedemptionLogRptConstants.GETEXPORT_REQUEST } }
    function success(datas) { return { type: RedemptionLogRptConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: RedemptionLogRptConstants.GETEXPORT_FAILURE, error } }
}

function loadingData(isbool) {
    return {
        type: RedemptionLogRptConstants.LOADING_REQUEST, isbool
    }
}

