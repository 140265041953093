import QRCode from "qrcode";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

/// TO print Dispatch Slip
export default class PrintDispatchService {
  genQR(text) {
    let qr = "";
    QRCode.toDataURL(text, function (err, url) {
      qr = url;
    });
    return qr;
  }

  Printpdf(dispatchData) {
    //   const cmp = dispatchData.Cmp;
    let qrdata = this.genQR(dispatchData.CartonId);
    const company = dispatchData.company;
    //s company.CompanyLogo =this.Logo;
    var doc = {
      pageMargins: [20, 30, 20, 30],
      layout: "",
      pageSize: "A4",

      footer: function (currentPage, pageCount) {
        return {
          stack: [
            //{ text: '', margin: [40, 0, 0, 0] },
            {
              columns: [
                {
                  text: currentPage.toString() + " of " + pageCount,
                  alignment: "center",
                },
                //{ text: new Date(), alignment: 'right', margin: [0, 0, 10, 0] }
              ],
            },
          ],
        };
      },
      content: [
        {
          text: "Dispatch Note",
          alignment: "center",
          fontSize: 18,
          bold: true,
          margin: 5,
        },
        {
          table: {
            widths: ["35%", "45%", "20%"],
            body: [
              [
                {
                  margin: [10, 10, 0, 0],
                  width: 90,
                  height: 75,
                  alignment: "center",
                  //rowSpan: 2,
                  image: "data:image/jpeg;base64," + company.CompanyLogo + "",
                }, //fontSize: 16, bold: true,margin:[0,20,0,0]
                {
                  //rowSpan: 2,
                  text: [
                    //this.selectedddl.value.Name
                    // { text: 'Krishi Rasayan' + '\n' + 'Address', fontSize: 12, bold: true, margin: [0, 100, 0, 0], alignment: 'center' },
                    {
                      text: "\n" + company.CompanyName + "\n" + company.Address,
                      fontSize: 12,
                      bold: true,
                      margin: [20, 70, 0, 0],
                      alignment: "center",
                    },
                    //{ text: '\n' + company.City + ' - ' + company.Pincode, fontSize: 10, alignment: 'center' },
                    {
                      text: "\nTel :- " + company.Mobile,
                      italics: true,
                      fontSize: 10,
                      alignment: "center",
                    },
                    {
                      text:
                        "\nDispatch Date : " +
                        new Date().toLocaleDateString() +
                        "\n" +
                        "Dispatch No : " +
                        dispatchData.CartonId +
                        "\n",
                      fontSize: 10,
                      alignment: "center",
                    },
                    // { text: 'Krishi Rasayan Exports Pvt. Ltd.' + '\n 1115, Hemkunt Modi Tower, 98, Nehru Place', fontSize: 12, bold: true, margin: [0, 100, 0, 0], alignment: 'center' },
                    // { text: '\n' + "New Delhi 110019" + '\n', fontSize: 10, alignment: 'center' },
                    // { text: 'Tel :- 011 3088 5555', italics: true, fontSize: 10, alignment: 'center' },
                  ],
                },
                {
                  margin: [20, 10, 5, 5],
                  qr: dispatchData.CartonId,
                  fit: "80",
                },
              ],

              [
                //fontSize: 16, bold: true,margin:[0,20,0,0]
                {
                  colSpan: 3,
                  text: [
                    //this.selectedddl.value.Name
                    {
                      bold: true,
                      text:
                        "Dealer Code : " +
                        dispatchData.userDetail.DealerCode +
                        // "\nShipping Address : " +
                        // dispatchData.userDetail.ship_Address1 +
                        // ", " +
                        // "\nShipping Address : " +
                        // dispatchData.userDetail.ship_Address1 +
                        // ", " +
                        // dispatchData.userDetail.ship_CityName +
                        // ", " +
                        // dispatchData.userDetail.ship_StateName +
                        // ", " +
                        // dispatchData.userDetail.ship_CountryName +
                        // ", " +
                        // dispatchData.userDetail.ship_Pincode +
                        // "\nBilling Address : " +
                        // dispatchData.userDetail.bill_Address1 +
                        // ", " +
                        // dispatchData.userDetail.bill_CityName +
                        // ", " +
                        // dispatchData.userDetail.bill_StateName +
                        // ", " +
                        // dispatchData.userDetail.bill_CountryName +
                        // ", " +
                        // dispatchData.userDetail.bill_Pincode +
                        " ",
                      fontSize: 10,
                    },
                  ],
                },
                "",
                "",
              ],
            ],
          },

          layout: "",
        },

        {
          //for dispatched data
          margin: -1,
          table: {
            widths: ["25%", "30%", "20%", "25%"],
            body: this.getDispatchedData(dispatchData),
          },
        },
        {
          //for special instruction
          table: {
            heights: [10, 90],
            margin: 5,
            widths: ["*"],
            body: [["Other Comments or Special Instructions"], [""]],
          },
        },
      ],
      styles: {
        filledHeader: {
          bold: true,
          fontSize: 14,
          color: "white",
          fillColor: "black",
          alignment: "center",
        },
      },
    };

    pdfMake.createPdf(doc).download(dispatchData.CartonId + ".pdf");
    //this.addDispatch.reset();
  }
  //to get Dispatched data
  getDispatchedData(data) {
    let d = [];

    d.push(["Carton No", "Product Specification", " Units", "Amount"]);

    data.cartonList.forEach((childObj) => {
      if (childObj.CartonId.length > 0)
        d.push([
          childObj.CartonId,
          childObj.ProductName,
          childObj.Qty,
          childObj.Amount,
        ]);
      // else if (childObj.CartonId.length > 0)
      //     d.push([childObj.CartonId, childObj.TotalQty]);
    });
    var cartonIds = data.cartonList.map((row) => row.CartonId);
    const uniqueCartonIds = [...new Set(cartonIds)];
    var CartonCount = uniqueCartonIds.length;
    d.push([
      { alignment: "left", text: "Total Cartons: " + CartonCount },
      {},
      { alignment: "left", text: "Total Quantity: " + data.Qty },
      { alignment: "left", text: "Total Amount: " + data.TotalAmount },
    ]);
    return d;
  }
}
