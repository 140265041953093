import { SliderProMsgConstants } from "../constants";

export function SliderProMsg(state = {}, action) {
  switch (action.type) {
    case SliderProMsgConstants.UPLOAD_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case SliderProMsgConstants.UPLOAD_SUCCESS:
      return {
        ...state,
        items: action.data,
      };
    case SliderProMsgConstants.UPLOAD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case SliderProMsgConstants.GETALLS_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case SliderProMsgConstants.GETALLS_SUCCESS:
      return {
        ...state,
        items: action.data,
      };
    case SliderProMsgConstants.GETALLS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case SliderProMsgConstants.GETALL_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case SliderProMsgConstants.GETALL_SUCCESS:
      return {
        ...state,
        item: action.data,
      };
    case SliderProMsgConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    //delete

    case SliderProMsgConstants.DELETE_REQUEST:
      return {
        ...state,
        creating: true,
      };

    case SliderProMsgConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: action.data,
      };

    case SliderProMsgConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
