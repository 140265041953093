export const productConfigurationConstants = {
    CREATE_REQUEST: 'CONFIGURATION_CREATE_REQUEST',
    CREATE_SUCCESS: 'CONFIGURATION_CREATE_SUCCESS',
    CREATE_FAILURE: 'CONFIGURATION_CREATE_FAILURE',

    UPDATE_REQUEST: 'CONFIGURATION_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CONFIGURATION_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CONFIGURATION_UPDATE_FAILURE',

    GETALL_REQUEST: 'CONFIGURATION_GETALL_REQUEST',
    GETALL_SUCCESS: 'CONFIGURATION_GETALL_SUCCESS',
    GETALL_FAILURE: 'CONFIGURATION_GETALL_FAILURE',   

    GETCONFIGDDL_REQUEST: 'CONFIGURATION_GETCMPNYDDL_REQUEST',
    GETCONFIGDDL_SUCCESS: 'CONFIGURATION_GETCONFIGDDL_SUCCESS',
    GETCONFIGDDL_FAILURE: 'CONFIGURATION_GETCONFIGDDL_FAILURE',
    
    RESET_REQUEST: 'RESET_REQUEST',

};