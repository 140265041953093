import { OrderBookingService } from "../services";
import { OrderBookingConstants } from "../constants";
import { alertActions } from ".";

export const OrderBookingActions = {
  getAll,
  CreateOrder,
  updateOrderBooking,
  inActiveRow,
  onHideShow,
  loadingData,
  onHideShowIsActive,
  getDealerDDL,
  GetSearchData,
  GetProductsData,
  ReadRow,
  Download,
  UploadSODetails,
  GetSOInsertLog,
};

function getAll(data) {
  return (dispatch) => {
    dispatch(request());

    OrderBookingService.fetchOrderBookingData(data).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: OrderBookingConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: OrderBookingConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: OrderBookingConstants.GETALL_FAILURE, error };
  }
}

function CreateOrder(data) {
  return (dispatch) => {
    dispatch(request(data));

    OrderBookingService.CreateOrder(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(onHideShow(false));
            if (response.ResponseData) {
              dispatch(success(response.ResponseData));
            }
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: OrderBookingConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: OrderBookingConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: OrderBookingConstants.CREATE_FAILURE, error };
  }
}

function updateOrderBooking(data) {
  return (dispatch) => {
    dispatch(request(data));

    OrderBookingService.updateOrderBooking(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(onHideShow(false));
            dispatch(success(data));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: OrderBookingConstants.UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: OrderBookingConstants.UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: OrderBookingConstants.UPDATE_FAILURE, error };
  }
}

function inActiveRow(orderBookingno, isActive) {
  var data = {
    OrderBookingNo: orderBookingno,
    IsActive: isActive,
  };
  return (dispatch) => {
    dispatch(request());

    OrderBookingService.inActiveRow(orderBookingno, isActive).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: OrderBookingConstants.ISACTIVE_REQUEST, data };
  }
  function success(data) {
    return { type: OrderBookingConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: OrderBookingConstants.ISACTIVE_FAILURE, error };
  }
}

function ReadRow(orderBookingno, IsShow, tblResponse) {
  return (dispatch) => {
    dispatch(request());

    OrderBookingService.ReadRow(orderBookingno, IsShow).then(
      (response) => {
        dispatch(getAll(tblResponse));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: OrderBookingConstants.ISACTIVE_REQUEST, data };
  }
  function success(data) {
    return { type: OrderBookingConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: OrderBookingConstants.ISACTIVE_FAILURE, error };
  }
}

function GetSOInsertLog(obj) {
  return (dispatch) => {
    dispatch(request());
    OrderBookingService.GetSOInsertLog(obj).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: OrderBookingConstants.GET_SO_LOG_REQUEST, data };
  }
  function success(datas) {
    return { type: OrderBookingConstants.GET_SO_LOG_SUCCESS, datas };
  }
  function failure(error) {
    return { type: OrderBookingConstants.GET_SO_LOG_FAILURE, error };
  }
}

function Download(Id, OBN, tblResponse) {
  return (dispatch) => {
    dispatch(request());

    OrderBookingService.Download(Id).then(
      (response) => {
        dispatch(success(response.ResponseData));
        dispatch(ReadRow(OBN, false, tblResponse));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: OrderBookingConstants.GETORDERDTL_REQUEST, data };
  }
  function success(data) {
    return { type: OrderBookingConstants.GETORDERDTL_SUCCESS, data };
  }
  function failure(error) {
    return { type: OrderBookingConstants.GETORDERDTL_FAILURE, error };
  }
}
function getDealerDDL() {
  return (dispatch) => {
    dispatch(request());
    OrderBookingService.getDealerDDL().then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: OrderBookingConstants.GETDEALERDDL_REQUEST };
  }
  function success(datas) {
    return { type: OrderBookingConstants.GETDEALERDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: OrderBookingConstants.GETDEALERDDL_FAILURE, error };
  }
}

function GetSearchData(searchval, cateId, pageSize) {
  return (dispatch) => {
    dispatch(request());
    OrderBookingService.GetSearchData(searchval, cateId, pageSize).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: OrderBookingConstants.GETSEARCH_REQUEST };
  }
  function success(datas) {
    return { type: OrderBookingConstants.GETSEARCH_SUCCESS, datas };
  }
  function failure(error) {
    return { type: OrderBookingConstants.GETSEARCH_FAILURE, error };
  }
}

function UploadSODetails(formData, tblResponse) {
  return (dispatch) => {
    dispatch(request());
    OrderBookingService.UploadSODetails(formData).then(
      (response) => {
        dispatch(success(response));
        if (response.ResponseCode == "200") {
          dispatch(alertActions.success(response.ResponseMessage.toString()));
          dispatch(GetSOInsertLog(tblResponse));
        } else
          dispatch(alertActions.error(response.ResponseMessage.toString()));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: OrderBookingConstants.UPLOAD_SO_REQUEST };
  }
  function success(datas) {
    return { type: OrderBookingConstants.UPLOAD_SO_SUCCESS, datas };
  }
  function failure(error) {
    return { type: OrderBookingConstants.UPLOAD_SO_FAILURE, error };
  }
}

function GetProductsData(ids) {
  return (dispatch) => {
    dispatch(request());
    OrderBookingService.GetProductsData(ids).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: OrderBookingConstants.GETPRODUCTS_REQUEST };
  }
  function success(datas) {
    return { type: OrderBookingConstants.GETPRODUCTS_SUCCESS, datas };
  }
  function failure(error) {
    return { type: OrderBookingConstants.GETPRODUCTS_FAILURE, error };
  }
}

function onHideShow(isbool) {
  return {
    type: OrderBookingConstants.DIALOG_REQUEST,
    isbool,
  };
}

function loadingData(isbool) {
  return {
    type: OrderBookingConstants.LOADING_REQUEST,
    isbool,
  };
}

function onHideShowIsActive(isbool) {
  return {
    type: OrderBookingConstants.DIALOG_ISACTIVE_REQUEST,
    isbool,
  };
}
