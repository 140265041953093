export const loyalityschemeConstants = {
    CREATE_REQUEST: 'LOYALITYSCHEME_CREATE_REQUEST',
    CREATE_SUCCESS: 'LOYALITYSCHEME_CREATE_SUCCESS',
    CREATE_FAILURE: 'LOYALITYSCHEME_CREATE_FAILURE',

    UPDATE_REQUEST: 'LOYALITYSCHEME_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'LOYALITYSCHEME_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'LOYALITYSCHEME_UPDATE_FAILURE',

    GETALL_REQUEST: 'LOYALITYSCHEME_GETALL_REQUEST',
    GETALL_SUCCESS: 'LOYALITYSCHEME_GETALL_SUCCESS',
    GETALL_FAILURE: 'LOYALITYSCHEME_GETALL_FAILURE',

    GETPRDCTDDL_REQUEST: 'LOYALITYSCHEME_GETPRDCTDDL_REQUEST',
    GETPRDCTDDL_SUCCESS: 'LOYALITYSCHEME_GETPRDCTDDL_SUCCESS',
    GETPRDCTDDL_FAILURE: 'LOYALITYSCHEME_GETPRDCTDDL_FAILURE',

    GETALL_AVAILABLE_REQUEST: 'LOYALITYSCHEME_GETALL_AVAILABLE_REQUEST',
    GETALL_AVAILABLE_SUCCESS: 'LOYALITYSCHEME_GETALL_AVAILABLE_SUCCESS',
    GETALL_AVAILABLE_FAILURE: 'LOYALITYSCHEME_GETALL_AVAILABLE_FAILURE',

    DELETE_REQUEST: 'LOYALITYSCHEME_DELETE_REQUEST',
    DELETE_SUCCESS: 'LOYALITYSCHEME_DELETE_SUCCESS',
    DELETE_FAILURE: 'LOYALITYSCHEME_DELETE_FAILURE',
    
    ISACTIVE_REQUEST: 'LOYALITYSCHEME_ISACTIVE_REQUEST',
    ISACTIVE_SUCCESS: 'LOYALITYSCHEME_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE: 'LOYALITYSCHEME_ISACTIVE_FAILURE',
    
    DIALOG_REQUEST:'DIALOG_REQUEST',

    DIALOGUE_REQUEST:'DIALOGUE_REQUEST',
    
    LOADING_REQUEST:'LOADING_REQUEST',

    DIALOG_ISACTIVE_REQUEST:'DIALOG_ISACTIVE_REQUEST'
};