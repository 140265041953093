import { authHeader, handleResponse } from '../helpers';
export const userService = {
    createUser,
    fetchRoleDLL,
    updateUser,
    fetchUserData,
    inActiveRow,
    fetchUserApprovalData,
    approveUser,
    rejectUser,
    fetchUserDataExport
};

function createUser(data) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/CreateUser`, requestOptions)
        .then(handleResponse);
}

function fetchRoleDLL(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetRoleDLL/${userId}`, requestOptions)
        .then(handleResponse);
}

function updateUser(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/UpdateUser`, requestOptions)
        .then(handleResponse);
}

function fetchUserData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetUsers`, requestOptions)
        .then(handleResponse);
}

function fetchUserDataExport(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetUserForExport`, requestOptions)
        .then(handleResponse);
}

function inActiveRow(userId, isActive) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/InActiveRow/${userId}/${isActive}`, requestOptions)
        .then(handleResponse);
}


function fetchUserApprovalData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetUsersForApproval`, requestOptions)
        .then(handleResponse);
}


function approveUser(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/ApproveUser/${userId}`, requestOptions)
        .then(handleResponse);
}

function rejectUser(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/RejectUser/${userId}`, requestOptions)
        .then(handleResponse);
}

