import { userConstants, dealerConstants } from "../constants";

export function user(state = {}, action) {
  switch (action.type) {
    case userConstants.LOADING_REQUEST:
      return {
        ...state,
        loding: action.isbool,
      };

    //start City
    case dealerConstants.GETCITYDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dealerConstants.GETCITYDDL_SUCCESS:
      return {
        ...state,
        cityddl: action.datas,
      };
    case dealerConstants.GETCITYDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    //end City
    case userConstants.DIALOG_REQUEST:
      return {
        ...state,
        showDialog: action.isbool,
      };
    case userConstants.DIALOG_ISACTIVE_REQUEST:
      return {
        ...state,
        showIsActiveDialog: action.isbool,
      };
    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };
    case userConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case userConstants.CREATE_SUCCESS:
      return {
        ...state,
        items:
          state.items == null ? [action.data] : [action.data, ...state.items],
      };
    case userConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        items: state.items.map((item) =>
          item.UserId == action.data.UserId ? { ...item, updating: true } : item
        ),
      };
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.UserId === action.data.UserId ? action.data : item
        ),
      };
    case userConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case userConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.UserId === action.data
            ? {
                UserId: item.userId,
                UserName: item.UserName,
                Email: item.Email,
                MobileNo: item.MobileNo,
                CompanyId: item.CompanyId,
                CompanyName: item.CompanyName,
                RoleId: item.RoleId,
                RoleName: item.RoleName,
                IsActive: item.isActive,
                Password: item.Password,
                ConfirmPassword: item.Password,
                CreatedDate: item.CreatedDate,
              }
            : item
        ),
      };

    case userConstants.GETROLEDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GETROLEDDL_SUCCESS:
      return {
        ...state,
        rollddl: action.datas,
      };
    case userConstants.GETROLEDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case userConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.ISACTIVE_REQUEST:
      return {
        ...state,
        activating: true,
      };
    case userConstants.ISACTIVE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.UserId === action.data.UserId
            ? {
                UserId: item.UserId,
                UserName: item.UserName,
                Email: item.Email,
                MobileNo: item.MobileNo,
                CompanyId: item.CompanyId,
                CompanyName: item.CompanyName,
                RoleId: item.RoleId,
                RoleName: item.RoleName,
                IsActive: action.data.IsActive,
                TotalRows: item.TotalRows,
                Password: item.Password,
                ConfirmPassword: item.Password,
                CreatedDate: item.CreatedDate,
              }
            : item
        ),
      };
    case userConstants.ISACTIVE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.GETEXPORT_REQUEST:
      return {
        ...state,
        exporting: true,
      };
    case userConstants.GETEXPORT_SUCCESS:
      return {
        ...state,
        exportitems: action.datas,
      };
    case userConstants.GETEXPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
