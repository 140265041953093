import { authHeader, handleResponse } from '../helpers';
export const TertiaryPackingService = {  
    ControllerName: 'TertiaryPacking',
    getProductByQrScan,
    fetchCartonData,
    saveTertiaryPacking,
    getTertiaryPacking,
    GetTertiaryPackingScan
};

// Get Product Details by Qr code Scanning
function getProductByQrScan(data) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetProductByQrScan`, requestOptions)
        .then(handleResponse);
}

function fetchCartonData(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        //body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}TertiaryPacking/GetCartonDDL`, requestOptions)
        .then(handleResponse);
}


// save seconday packing data
function saveTertiaryPacking(data) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}TertiaryPacking/Save`, requestOptions)
        .then(handleResponse);
}

// Get Secondary packing Details 
function getTertiaryPacking(data) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}TertiaryPacking/GetTertiaryPacking`, requestOptions)
        .then(handleResponse);
}

function GetTertiaryPackingScan(sid, checkItemDate) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        //body: JSON.stringify(sid, checkItemDate, scanList)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Dispatch/GetTertiaryPackingScan/${sid}/${checkItemDate}`, requestOptions)
        .then(handleResponse);
    }


