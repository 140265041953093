import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import TextField from '@material-ui/core/TextField';
import { FileUpload } from 'primereact/fileupload';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import { categoryActions } from '../../actions';
import tableRequest from '../../models/tableRequest';
// import { userTypeMasterActions } from '../../actions';
import Switch from '@material-ui/core/Switch';

class categoryMaster extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
        this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this)
        this.resetForm = this.resetForm.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this)
        this.photoBodyTemplate = this.photoBodyTemplate.bind(this);
        this.setImagePath = this.setImagePath.bind(this);



        this.state = {
            IsAdd: true,
            IsEdit: true,
            first: 0,
            rows: 5,
            sliderImage: '',
            totalRecords: 0,
            globalFilter: null,
            sortOrder: '',
            buttonLabel: 'Submit',
            switchAction: false,
            formHeading: 'Add Category Master',
            CategoryId: 0,
            CategoryName: '',
            IsActive: true,
            CreatedDate: null,
            UpdatedDate: null,
            formError: {
                CategoryName: '',
                sliderImage: [],
            }

        };
    }

    uploadFiles(e) {
        e.preventDefault()
        //console.log(this.state.file)
    }

    componentDidMount() {
        const userPages = sessionStorage.getItem('assignedPermissionsForUser')
        if (userPages) {
            const assignedPermissions = JSON.parse(userPages);
            const Curenturl = window.location.pathname;
            const nNumber = Curenturl.lastIndexOf('/');
            const pageName = Curenturl.substring(nNumber + 1);

            const obj = assignedPermissions.assigned.filter((x) => {
                return x.ActionName === pageName
            });

            if (obj !== null && obj.length > 0) {
                if (!obj[0].IsEdit) {
                    this.setState({
                        IsEdit: false
                    });
                }
                if (!obj[0].IsAdd) {
                    this.setState({
                        IsAdd: false
                    });
                } else {
                    this.setState({
                        IsAdd: true
                    });
                }
            }
        }

        this.props.fetchApi(this.tblResponse);

        // const userId = JSON.parse(sessionStorage.getItem("UserData"));
        // this.props.getRoleDDL(userId);
    }
    photoBodyTemplate(e) {
        if (e.SliderImage) {
            return <img src={e.SliderImage} width="50px" />
        }

    }
    componentDidUpdate(prevProps) {
        if (this.props.CategoryData) {
            if (prevProps.CategoryData !== this.props.CategoryData) {
                // this.props.myProp has a different value
                if (this.props.CategoryData) {
                    var e = this.props.CategoryData;
                    this.setState({
                        totalRecords: e[0].TotalRows
                    });
                }
            }
        } else {
            if (this.state.totalRecords !== 0)
                this.setState({
                    totalRecords: 0,
                    first: 0
                });
        }
    }

    switchToggleConfirm = (e) => {
        this.props.inActiveRow(this.state.CategoryId, this.state.switchAction);
    }

    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        switch (name) {
            case 'IsActive':
                value = event.target.checked;
                break;
            default:
                break;
        }
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }

    UpdatedDateTemplate(rowData) {
        if (rowData.UpdatedDate !== null) {
            return (
                <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>
            )
        }
    }

    CreatedDateTemplate(rowData) {
        if (rowData.UpdatedDate !== null) {

            return (
                <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>
            )
        }
    }
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        switch (name) {
            case 'CategoryName':
                if (value.length < 1) {
                    IsValid = false;
                    errors.CategoryName = "Please enter category name.";
                } else errors.CategoryName = ''
                break;
            default:
                break;
        }

        return IsValid;
    }

    submitForm(event) {
        event.preventDefault();
        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
            return isfrmvalid;
        });

        if (isfrmvalid) {
            ;
            const formData = {
                CategoryId: this.state.CategoryId,
                CategoryName: this.state.CategoryName,
                IsActive: this.state.IsActive,
                CreatedDate: this.state.CategoryId > 0 ? this.state.CreatedDate : this.getCurrentDateMMDDYYY(),
                UpdatedDate: this.state.CategoryId > 0 ? this.getCurrentDateMMDDYYY() : null,
                TotalRows: this.state.totalRecords,
                FilePath: this.state.sliderImage
            }

            if (formData.CategoryId > 0) {
                this.props.updateCategory(formData);
            }
            else {
                this.props.createCategory(formData);
            }

            if (!this.props.showDialog) {
                this.resetForm();
            }
        }
    }

    // getRoleDDL(event) {
    //     this.props.getRoleDDL();
    // }
    resetForm() {
        this.setState({
            CategoryId: 0,
            CategoryName: '',
            IsActive: true,
            formError: {
                CategoryName: ''
            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }

    //to lazy load
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    onPage(event) {
        this.props.loadingData(true);

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }

    //to search
    searchFilter(e) {
        this.props.loadingData(true);
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
    }

    //to sort
    onSort(event) {
        this.props.loadingData(true);
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDate" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
    }

    onHide() {
        this.props.onHideShow(false);
        this.resetForm();
    }
    openDialog() {
        this.props.onHideShow(true);
        this.setState({ buttonLabel: 'Submit', formHeading: 'Add Category' })
    }
    statusBodyTemplate(rowData) {

        if (rowData) {
            return (
                <Checkbox
                    disabled
                    checked={rowData.IsActive}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            )
        }
    }
    getCurrentDateMMDDYYY() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
    actionBodyTemplate(rowData) {
        const editMode = (e) => {
            ;
            this.props.onHideShow(true);
            this.setState({
                buttonLabel: 'Update',
                formHeading: 'Update Category',
                CategoryId: rowData.CategoryId == null ? 0 : rowData.CategoryId,
                CategoryName: rowData.CategoryName,
                IsActive: rowData.IsActive,
                CreatedDate: rowData.CreatedDate,
                sliderImage: rowData.FilePath
            });
        }
        const switchHandleChange = (e) => {
            this.setState({
                switchAction: !rowData.IsActive,
                CategoryId: rowData.CategoryId
            });
            this.props.onHideShowIsActive(true);
        }

        if (rowData) {
            return (
                <>
                    {this.state.IsEdit === true ?
                        <div>
                            <Switch checked={rowData.IsActive} color="primary" name={rowData.CategoryId}
                                onChange={switchHandleChange} inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <Button icon="pi pi-pencil" onClick={editMode} />
                        </div>
                        : ""}
                </>
            )
        }
    }
    // set Slider Image Path
    setImagePath(event) {
        let errors = this.state.formError;
        errors.sliderImage = "";
        //errors.promotionalImage="";
        if (event.xhr) {
            if (event.xhr.response) {
                const response = JSON.parse(event.xhr.response);
                if (response) {
                    if (response.ResponseCode === "200") {
                        this.setState({
                            sliderImage: response.Source,
                            //promotionalImage: response.Source
                        });
                    } else if (response.ResponseCode === "400") {
                        errors.sliderImage = "Only image format is accepted.";
                        this.setState({
                            sliderImage: [],
                            //promotionalImage:[],
                        });
                    } else {
                        // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
                    }
                } else {
                    //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
                }
            } else {
                //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
            }
        } else {
            //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
    }
    render() {
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => this.props.onHideShowIsActive(false)} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus />
                </div>
            );
        }
        const header = (
            <div className="table-header row">
                <div className="col-md-6">
                    <h4>Category Master</h4>
                </div>
                <div className="col-md-6 text-right">
                    <span className="p-input-icon-left mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => this.searchFilter(e)} placeholder="Global Search" />
                    </span>

                    {this.state.IsAdd === true ?
                        <Button className="mr-2" label="Add Category" icon="pi pi-plus-circle" onClick={this.openDialog} />
                        : <Button className="mr-2" label="Add Category" icon="pi pi-plus-circle" style={{ display: 'none' }} onClick={this.openDialog} />
                    }

                </div>

            </div>
        );

        return (
            <div className="datatable-filter-demo">
                <ToastContainer />

                <div className="card">
                    <DataTable
                        value={this.props.CategoryData}
                        scrollable responsive={true}
                        globalFilter={this.state.globalFilter} header={header}
                        emptyMessage="No data found." sortMode="multiple" paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[5, 10, 20]}
                        totalRecords={parseInt(this.state.totalRecords)}
                        lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort} loading={this.props.loading} ref={(el) => { this.dt = el; }}
                    >
                        <Column field="CategoryName" header="Category Name" sortable></Column>
                        {/* <Column field="CreatedDate" header="Created Date" sortable></Column> */}
                        {this.state.IsEdit === true ?
                            <Column field="Action" header="Action" style={{ width: '130px' }} body={this.actionBodyTemplate}></Column>
                            : <Column field="Action" header="Action" style={{ width: '130px', display: 'none' }}></Column>}
                    </DataTable>
                </div>
                <Dialog header={this.state.formHeading} modal dismissableMask={false} visible={this.props.showDialog} className="popup" onHide={this.onHide}>
                    <form onSubmit={this.submitForm} autocomplete="off">
                        <div className="row">

                            <div className="col-md-4 pt-2">
                                <TextField onChange={this.handleChange} name="CategoryName" autoComplete="false" value={this.state.CategoryName} size="small" variant="outlined" className="fullwidth" label="Category Name*" />
                                {this.state.formError.CategoryName !== '' ?
                                    <div className='error'>{this.state.formError.CategoryName}</div> : ''}
                            </div>
                            <div class="fileslider col-md-4 col-xl-4">
                                <FileUpload
                                    name="sliderImage"
                                    mode="basic"
                                    url={process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"}
                                    auto={true}
                                    onUpload={this.setImagePath}
                                    onChange={this.fileSelectedHandler}
                                    onError={this.onErrorImage}
                                    multiple accept={this.state.SliderUploadType}
                                />


                                {/* <button type="button" className="btn btn-warning btn-block" onClick={this.submitForm}>Upload</button> */}
                            </div>
                            <div className="fileslider col-md-4 col-xl-4">
                                <TextField id="standard-basic" size="small" onChange={this.handleChange} value={this.state.sliderImage} name="shareurl" variant="outlined" className="width" label="File Url : " />
                                <div className="blockimgprive">
                                    {this.state.sliderImage ? (
                                        <div className="p-fileupload-content mt-3 p-1">
                                            <div>
                                                <img
                                                    alt=" "
                                                    role="presentation"
                                                    src={this.state.sliderImage}
                                                    width="80"
                                                    height="60"
                                                />
                                            </div>
                                        </div>
                                    ) : null}

                                    {this.state.formError.sliderImage !== null ? (
                                        <div className="error">
                                            {this.state.formError.sliderImage}
                                        </div>
                                    ) : null}

                                </div>

                            </div>


                        </div>

                        <div className='row'>
                            <div className="col-md-4 pt-3">
                                <Checkbox
                                    name="IsActive"
                                    checked={this.state.IsActive}
                                    onClick={this.handleChange}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                /> <label>Is Active</label>
                            </div>
                            <div className="col-md-4 mt-3 mb-3 text-center">
                                {this.state.IsEdit === true ?
                                    <Button label={this.state.buttonLabel} icon="pi pi-check" />

                                    : ""}
                            </div>
                            <div className="col-md-12">
                                Note: Fields marked as (*) are required
                            </div>
                        </div>
                    </form>
                </Dialog>
                <Dialog header="Confirmation" visible={this.props.showIsActiveDialog} modal style={{ width: '350px' }}
                    footer={renderFooter('displayConfirmation')} onHide={() => this.props.onHideShowIsActive(false)}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3 confirm-icon" style={{ fontSize: '2rem' }} />
                        <span>Are you sure you want to {this.state.switchAction == true ? "Active" : "InActive"}?</span>
                    </div>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        CategoryData: state.category.items,
        showDialog: state.category.showDialog,
        loading: state.category.loding,
        showIsActiveDialog: state.category.showIsActiveDialog
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (obj) => {
            dispatch(categoryActions.getAll(obj))
        },
        createCategory: category => dispatch(categoryActions.createCatagory(category)),
        updateCategory: category => dispatch(categoryActions.updateCategory(category)),
        inActiveRow: (category, isActive) => dispatch(categoryActions.inActiveRow(category, isActive)),
        onHideShow: isbool => dispatch(categoryActions.onHideShow(isbool)),
        onHideShowIsActive: isbool => dispatch(categoryActions.onHideShowIsActive(isbool)),
        loadingData: (isLoding) => dispatch(categoryActions.loadingData(isLoding))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(categoryMaster)