import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TextField from "@material-ui/core/TextField";
import { Calendar } from "primereact/calendar";
import { alertActions, OrderBookingRptActions } from "../../actions";
import { connect } from "react-redux";
import tableRequest from "../../models/tableRequest.js";
import { Toast } from "primereact/toast";
import { CSVLink } from "react-csv";

class OrderBookingRpt extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.tblResponse = new tableRequest();
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.FilterData = this.FilterData.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.List = this.List.bind(this);
    this.state = {
      loading: false,
      FromDate: new Date(),
      ToDate: new Date(),
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      visible: false,
      sortOrder: "",
      datalist: [],
      Download: false,
    };
  }

  componentDidMount() {
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() - 7);
    this.tblResponse.FromDate = date.toLocaleDateString();
    this.tblResponse.ToDate = new Date().toLocaleDateString();
    this.setState({
      FromDate: date,
      ToDate: new Date(),
    });
    this.props.fetchApi(this.tblResponse);

    // this.props.exportData(this.tblResponse);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.OrderBooikngList !== this.props.OrderBooikngList) {
      // this.props.myProp has a different value
      if (this.props.OrderBooikngList) {
        var e = this.props.OrderBooikngList;
        this.setState({
          totalRecords: e[0].TotalRows,
        });
      }
    }

    if (
      this.state.Download &&
      this.props.IsDownload >= 1 &&
      prevProps.IsDownload != this.props.IsDownload
    ) {
      setTimeout(() => {
        this.csvLink.current.link.click();
      }, 3000);
      this.setState({
        Download: false,
      });
    }
  }

  GetOrderBooking() {
    let ToDt = new Date();
    let FrmDt = new Date();
    FrmDt.setDate(ToDt.getDate() - 7);

    const myFmDt = this.getCurrentDateYYYYMMDD(FrmDt);
    const myToDt = this.getCurrentDateYYYYMMDD(ToDt);

    this.tblResponse.FromDate = myFmDt;
    this.tblResponse.ToDate = myToDt;
    this.props.fetchApi(this.tblResponse);
  }

  FilterData() {
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;

    this.props.fetchApi(this.tblResponse);
  }

  getCurrentDateYYYYMMDD(dt) {
    var dd = String(dt.getDate()).padStart(2, "0");
    var mm = String(dt.getMonth() + 1).padStart(2, "0");
    var yyyy = dt.getFullYear();

    dt = yyyy + "/" + mm + "/" + dd;
    return dt;
  }

  handleChange(event) {
    const { name, value } = event.target;
    switch (name) {
      case "FromDate":
        if (value === null || !(value instanceof Date)) {
          this.formIsValid = false;
          // this.refs.Messchild.showError("Please select from date.");
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please select from date.",
            life: 3000,
          });
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please enter only date.",
            life: 3000,
          });
          // this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (value instanceof Date && this.state.ToDate instanceof Date) {
          const startDate = value;
          const endDate = this.state.ToDate;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.toast.show({
              severity: "error",
              summary: "Error Message",
              detail: "From date must be less than to date.",
              life: 3000,
            });
            // this.refs.Messchild.showError("From date must be less than to date.");
            return;
          }
        }

        break;
      case "ToDate":
        if (value === null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please select to date.",
            life: 3000,
          });
          // this.refs.Messchild.showError("Please select to date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please enter only date.",
            life: 3000,
          });
          // this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (
          value instanceof Date &&
          this.state.FromDate instanceof Date
        ) {
          const startDate = this.state.FromDate;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.toast.show({
              severity: "error",
              summary: "Error Message",
              detail: "To date must be greater than from date.",
              life: 3000,
            });
            // this.refs.Messchild.showError("To date must be greater than from date.");
            return;
          }
        }

        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  }
  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  List() {
    var obj = {
      FromDate: this.state.FromDate,
      ToDate: this.state.ToDate,
      PageSize: -1,
      SearchValue: "",
      SortColumn: "",
      SortOrder: "desc",
      First: 1,
    };
    this.props.exportData(obj);
    this.setState({
      Download: true,
    });
  }

  //to search
  searchFilter(e) {
    // this.setState({
    //     loading: true
    // });
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  render() {
    return (
      <div className="datatable-filter-demo">
        <Toast ref={(el) => (this.toast = el)} />
        <div className="row mb-3">
          <div className="col-md-2">
            <h4>Order Booking Report</h4>
          </div>
          <div className="col-md-10">
            <div className="row text-right">
              <div className="col-md-3">
                <Calendar
                  placeholder="From Date"
                  name="FromDate"
                  showIcon="true"
                  value={this.state.FromDate}
                  onChange={this.handleChange}
                ></Calendar>
              </div>
              <div className="col-md-3">
                <Calendar
                  placeholder="To Date"
                  name="ToDate"
                  showIcon="true"
                  value={this.state.ToDate}
                  onChange={this.handleChange}
                ></Calendar>
              </div>
              <div className="col-md-3 text-center ">
                <button
                  type="button"
                  onClick={this.FilterData}
                  className="btn btn-primary p-2 pl-2 pr-2 buttone"
                >
                  Submit
                </button>
                {/* &nbsp;
                                <CSVLink data={this.props.List} headers={this.state.headers} filename={"Order Booking Report.csv"}
                                    target="_blank">

                                    <button onClick={this.List} className="btn btn-success p-2 pr-2 pl-2 buttone">Export</button></CSVLink> */}
                &nbsp;
                <CSVLink
                  data={this.props.List}
                  headers={this.state.headers}
                  filename={"Order Booking Report.csv"}
                  // className="btn btn-primary ml-2"
                  target="_blank"
                  ref={this.csvLink}
                />
                <button
                  onClick={this.List}
                  className="btn btn-success p-2 pr-2 pl-2 buttone"
                >
                  Export
                </button>
              </div>
              <div className="col-md-3">
                <TextField
                  id="standard-basic"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Search"
                  onInput={this.searchFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <DataTable
            value={this.props.OrderBooikngList} //chngs
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            emptyMessage="No Data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            

            <Column field="OrderBookingNo" header="Order Booking No" sortable></Column>
            <Column field="UserName" header="User Name" sortable></Column>
            <Column field="OrderDate" header="Order Date" sortable></Column>
            <Column field="ProductsInOrder" header="Products In Order" sortable></Column>
            <Column field="TotalQtyOfProducts" header="Total Qty" sortable></Column>
            <Column field="Remark" header="Remark" sortable></Column>
            {/* <Column field="ProductDescription" header="Product Description" sortable></Column> */}
          </DataTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  ////console.log('state.OrderBookingRpt.items', state.OrderBookingRpt.items);
  return {
    OrderBooikngList: state.OrderBookingRpt.items,
    List:
      state.OrderBookingRpt.exportitems == undefined
        ? []
        : state.OrderBookingRpt.exportitems,
    loading: state.verification.loding,
    IsDownload: state.OrderBookingRpt.isDownload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(OrderBookingRptActions.getAll(obj));
    },
    loadingData: (isLoding) =>
      dispatch(OrderBookingRptActions.loadingData(isLoding)),
    exportData: (obj) => dispatch(OrderBookingRptActions.exportData(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderBookingRpt);
