import React, { Component } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { connect } from "react-redux";
import { TertiaryPackingActions } from "../../actions/TertiaryPackingActions.js";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import tableRequest from "../../models/tableRequest.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BreadCrumb } from "primereact/breadcrumb";
import FormControl from "@material-ui/core/FormControl";
// import { ConfirmationModal } from '../../template/ConfirmationModal';
import $ from "jquery";
import MessageComponent from "../../templates/Message";
// import PrintSecondaryPackagingService from '../../services/PrintSecondaryPackagingService';
// import Loaders from '../../template/Loaders';
import alreadyPacked from "../../assets/audio/AlreadyScanned.mp3";
import invalidScan from "../../assets/audio/InvalidScan.mp3";

class TertiaryPacking extends Component {
  constructor(props) {
    super(props);
    this.autoref = React.createRef();
    // this.UserService = new UserService();
    this.handleChange.bind(this);
    this.getCartonList = this.getCartonList.bind(this);
    this.alreadyPacked = new Audio(alreadyPacked);
    this.invalidScan = new Audio(invalidScan);
    // this.CartonMasterService = new CartonMasterService();
    // this.MappingService = new MappingService();
    // this.TertiaryPackingService = new TertiaryPackingService();
    // this.DispatchService = new DispatchService();
    // this.PrintSecondaryPackagingService = new PrintSecondaryPackagingService();
    this.tblResponse = new tableRequest();
    this.state = {
      CartonName: "",
      reason: "",
      Tvisible: false,
      remark: "",
      older: false,
      globalFilter: null,
      visible: false,
      isold: false,
      CartonSize: "",
      olderstockVisible: false,

      olderdata: [],
      sortIcon: false,
      Loader1: true,
      Loader2: false,
      isSelected: false,
      preVal: "",
      rvisible: false,
      sortOrder: "1",
      SelectedCartonDetails: [],
      formError: {},
      CartonDDL: [],
      CartonId: "",
      buttonLabel: "Submit",
      sales: [],
      scanItems: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      IsDisabledCartonDDL: false,
      scanTxt: "", //KREP-55 defect
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitFormFinal = this.submitFormFinal.bind(this);

    this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    //  this.openDialog = this.openDialog.bind(this);
    this.onSort = this.onSort.bind(this);

    this.ScanItem = this.ScanItem.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    // setTimeout(() => {
    //     //this.GetTertiaryPacking();
    // }, 100);

    // $('.p-column-title').on('click', function () {
    //     $(this).next().removeClass('pi-sort').toggleClass('pi-sort-up').addClass('pi-sort-down');
    // });

    //this.GetActiveCartonDDL();
    this.props.CartonData(this.tblResponse);
    //this.props.GetTertiaryPackingScan(this.tblResponse);
    this.props.getTertiaryPacking(this.tblResponse);
  }

  componentDidUpdate() {
    if (this.props.alreadyPackedAudio) {
      this.alreadyPacked.play();
      this.props.alreadyPacked(false);
    } else if (this.props.warningAudio) {
      this.invalidScan.play();
      this.props.warning(false);
    }
  }

  // PAGE EVENT CALL
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.getTertiaryPacking(this.tblResponse);
      //this.GetTertiaryPackingg();
    }, 200);
  }

  // set lazy loading values
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // get users data list from api
  GetTertiaryPackingg() {
    //this.tblResponse.SortColumn = 'TertiaryCartonId';
    this.props.getTertiaryPacking(this.tblResponse);
    //this.TertiaryPackingService.GetTertiaryPacking(this.tblResponse).then(data => {

    this.setState({ Loader2: true });
    const resdata = this.props.TertiaryPackdata;
    //if responsedata is not null
    if (
      resdata !== null &&
      resdata.ResponseData !== null &&
      resdata.ResponseData.length > 0
    ) {
      this.datasource = resdata.ResponseData;
      this.setState({
        totalRecords: resdata.ResponseData[0].TotalRows,
        first: this.tblResponse.First - 1,
        sales: this.datasource.slice(
          0,
          this.tblResponse.First - 1 + this.tblResponse.PageSize
        ),
        loading: false,
      });
    }
    //if responsedata is null
    else {
      this.datasource = null;
      this.setState({
        totalRecords: 0,
        first: 1,
        sales: null,
        loading: false,
      });
    }
    //});
  }

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    //const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "UserName":
        if (value.length < 1) {
          IsValid = false;
          errors.UserName = "Please enter your username.";
        } else errors.UserName = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  // reset form text value
  formReset() {
    this.setState({
      reason: "",
      CartonName: "",
      Tvisible: false,
      remark: "",
      older: false,
      globalFilter: null,
      visible: false,
      isold: false,
      SelectedCartonDetails: [],
      olderstockVisible: false,

      olderdata: [],
      sortIcon: false,
      Loader1: true,
      Loader2: false,
      isSelected: false,
      preVal: "",
      rvisible: false,
      sortOrder: "1",
      formError: {},
      CartonDDL: [],
      CartonId: "",
      buttonLabel: "Submit",
      sales: [],
      scanItems: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      IsDisabledCartonDDL: false,
      scanTxt: "", //KREP-55 defect
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // open Dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }

  submitForm(event) {
    if (this.state.scanItems.length == 0) {
      this.refs.Messchild.showWarn("Scan First");
      return;
    }
    // else if (this.state.SelectedCartonDetails[0].CartonSize != this.state.scanItems.length && this.state.reason.trim().length < 4) {

    //     // let reason = prompt("Carton is not fully Packed. \n Please Specify the Reason in min 5 chars Max 500 Chars", "");

    //     // reason = reason != null ? reason.trim() : "";
    //     this.setState({ rvisible: true })
    // }
    else {
      this.setState({
        Tvisible: true,
        CartonName: "",
      });
    }
  }

  // submit packing
  submitFormFinal(event) {
    if (this.state.older && this.state.remark == "") {
      this.setState({ isold: true });
      return;
    }

    //  if (event != undefined) { event.preventDefault(); }
    // if (this.state.CartonId.length == 0) {
    //     this.refs.Messchild.showWarn("Select Carton Size");
    //     this.setState({ scanTxt: "" });
    //     return;
    // }
    else if (this.state.scanItems.length == 0) {
      this.refs.Messchild.showWarn("Scan First");
      return;
    }

    // if (this.state.SelectedCartonDetails[0].CartonSize != this.state.scanItems.length && this.state.reason.trim().length < 4) {
    //     this.setState({ rvisible: true })
    //     return;
    // }
    else {
      this.setState({ Loader1: false });
      const formData = {
        //createdBy
        //cartonMasterId: this.state.CartonId,
        scanned: this.state.scanItems,

        CartonName: this.state.CartonName,

        reason: this.state.reason,
      };

      this.setState({ Tvisible: false });
      setTimeout(() => {
        // this.TertiaryPackingService.Save(formData).then(data => {

        //     this.setState({ Loader1: true });
        //     const resdata = data.data;
        //     //if ResponseCode is 200 success else error or warning message
        //     if (resdata !== null && resdata.ResponseCode === '200') {
        //         if ((resdata.ResponseData).CreatedDt == '410' && (resdata.ResponseData).ProductName == "Items Already Packed") {
        //             this.refs.Messchild.showError("Items Already Packed");
        //             this.formReset();
        //             this.GetActiveCartonDDL();
        //             this.GetTertiaryPackingg();
        //         }
        //         else {
        //             this.refs.Messchild.showSuccess(resdata.ResponseMessage);
        //             // this.PrintSecondaryPackagingService.formPreview1([resdata.ResponseData]);
        //             // this.setState({
        //             //     loading: true,
        //             //     visible: false
        //             // });
        //             // this.formReset();
        //             this.setState({
        //                 CartonName: '',
        //                 IsDisabledCartonDDL: false,
        //                 Tvisible: false
        //             });

        //             this.state.scanItems = [];
        //             this.setState({
        //                 scanItems: []
        //             });
        //             this.GetTertiaryPackingg();

        //             this.autoref.current.focus();
        //         }
        //     } else if (resdata.ResponseCode === '400') {
        //         this.refs.Messchild.showWarn(resdata.ResponseMessage);
        //     } else {

        //         this.setState({ CartonName: '' })
        //         this.refs.Messchild.showError(resdata.ResponseMessage);
        //     }
        // });
        //this.setState({ productLoading: true });
        this.props.saveTertiaryPacking(formData);
        this.setState({ reason: "" });
        this.formReset();
        this.autoref.current.focus();
        setTimeout(() => {
          this.props.getTertiaryPacking(this.tblResponse);
        }, 200);
      }, 200);
    }
  }

  getCartonList(event) {
    this.state.SelectedCartonDetails = this.props.CartonDDL.filter((carton) => {
      if (carton.value == event.target.value) {
        return carton;
      }
    });
    this.setState({ CartonId: this.state.SelectedCartonDetails[0].value });
  }

  // get active carton drop down list
  GetActiveCartonDDL() {
    this.CartonMasterService.GetActiveCartonDDL().then((data) => {
      const resdata = data.data;
      //if ResponseData is not null
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          CartonDDL: resdata.ResponseData,
        });
      }
    });
  }

  hideFunc() {
    this.setState({
      isold: false,
      olderstockVisible: false,
      // formHeading: 'Add User'
    });
  }

  // scan qr code
  ScanItem(e, flag) {
    let qr = e.target.value;
    const scanlist = this.state.scanItems;
    //this.props.GetTertiaryPackingScan(qr,flag,scanlist);
    let id = 1;
    if (e.key === "Enter" || !flag) {
      let qr = flag ? e.target.value : e;
      //let scan = this.props.TertiaryscanItems;
      //let Tscan = this.props.TertiaryPackdata;
      //let Cartondata = this.props.Cartondata;

      // if (this.state.CartonId.length == 0) {
      //     this.refs.Messchild.showWarn("Select Carton Size");
      //     this.setState({ scanTxt: '' })
      //     return;
      // } else
      if (!qr.includes("SID")) {
        this.refs.Messchild.showWarn("Invalid Item");
        this.props.warning(true);
        this.setState({ scanTxt: "" });
        return;
      }
      // else if (this.state.SelectedCartonDetails[0].CartonSize == this.state.scanItems.length) {
      //     this.refs.Messchild.showWarn("Carton Full");
      //     return;
      // }
      else if (qr.includes("SID")) {
        this.state.scanItems.forEach((element) => {
          if (element.CartonId == qr) {
            this.props.alreadyPacked(true);
            this.refs.Messchild.showWarn("Already Scanned..!");
            id = 2;
            return;
          }
        });
      }
      setTimeout(() => {
        if (id == 1) {
          //const fObj = {UID : qr}
          const scanlist = this.state.scanItems;
          this.props.GetTertiaryPackingScan(qr, flag);
          setTimeout(() => {
            const resdata = this.props.TertiaryscanItems;
            //if ResponseCode is 200 success else error or warning message
            if (resdata !== null && resdata.ResponseCode === "200") {
              let id = 1;
              // if (this.state.scanItems.length > 0) {
              //     let pid = this.state.scanItems[0].ProductId;
              //     (resdata.ResponseData).forEach(element => {
              //         if (element.ProductId != pid) {
              //             id = 2
              //         }
              //     });
              //     if (id == 2) {
              //         this.refs.Messchild.showWarn("only Similar Items Can be packed");
              //         return;
              //     }
              // }

              //disable
              setTimeout(() => {
                if (id == 2) {
                  return;
                } else {
                  var element = this.props.TertiaryscanItems;
                  resdata.ResponseData.forEach((element) => {
                    element.MFGDate =
                      element.MFGDate != null || undefined
                        ? element.MFGDate.substring(0, 10)
                        : element.MFGDate;
                    element.EXPDate =
                      element.EXPDate != null || undefined
                        ? element.EXPDate.substring(0, 10)
                        : element.EXPDate;
                    if (this.state.scanItems.length > 0) {
                      let checkseqNo = this.state.scanItems.filter(
                        (y) => y.Id == element.Id
                      );

                      if (checkseqNo.length > 0) {
                        this.props.alreadyPacked(true);
                        this.refs.Messchild.showWarn("Already Scanned");
                        return;
                      }
                    }

                    this.state.scanItems.push(element);
                    this.setState({
                      //IsDisabledCartonDDL: true,
                      scanItems: this.state.scanItems,
                      scanTxt: "",
                      radio: true,
                    });
                  });
                }
                // if (this.state.CartonId.CartonSize == this.state.scanItems.length) {
                //     this.submitForm(e);
                // }
              }, 200);
            } else if (resdata !== null && resdata.ResponseCode === "500") {
              if (resdata.length == 0) {
                this.ScanItem(qr, false);
                return;
              }
              let checkseqNo = [];
              resdata.ResponseData.forEach((element) => {
                if (this.state.scanItems.length > 0) {
                  let chk = this.state.scanItems.filter(
                    (y) => y.Id == element.Id
                  );
                  if (chk.length != 0) {
                    checkseqNo.push(chk);
                  }
                  if (checkseqNo.length == resdata.length) {
                    this.ScanItem(qr, false);
                  }
                }
              });

              if (
                checkseqNo == undefined ||
                checkseqNo.length != resdata.length
              ) {
                let id = 1;
                if (this.state.scanItems.length > 0) {
                  let pid = this.state.scanItems[0].ProductId;
                  resdata.forEach((element) => {
                    if (element.ProductId != pid) {
                      id = 2;
                    }
                  });
                  if (id == 2) {
                    this.refs.Messchild.showWarn(
                      "only Similar Items Can be packed"
                    );
                    return;
                  }
                }
                setTimeout(() => {
                  if (id == 2) {
                    return;
                  }
                }, 200);
              }
            } //// else of response code 5000 ends here
            else this.refs.Messchild.showWarn(resdata.ResponseMessage);
          }, 1000);
        } else if (id != 2) {
          this.refs.Messchild.showWarn("Invalid Scanned");
        }
      }, 200);
      this.setState({
        scanTxt: "",
      });
    }
  }

  // delete user data
  deleteScanned(CartonId) {
    let arrayfilter = this.state.scanItems.filter(
      (x) => x.CartonId != CartonId
    );
    this.setState({
      scanItems: arrayfilter,
    });
  }

  // seach table
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.props.getTertiaryPacking(this.tblResponse);
    //this.GetTertiaryPackingg();
  }

  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

  // disabled checkbox
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // Sort table
  onSort(event) {
    this.setState({
      sortOrder: this.state.sortOrder === 1 ? 2 : 1,
      sortIcon: !this.state.sortIcon,
    });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.props.getTertiaryPacking(this.tblResponse);
    //this.GetTertiaryPackingg();
    const clickData = document.getElementsByClassName(
      "p-sortable-column-icon pi pi-fw pi-sort"
    );
    ////console.log(event);
  }

  // edit form
  actionTemplate(rowData, column) {
    const deleteFunc = () => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteScanned(rowData.CartonId),
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    };

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-trash"
          onClick={deleteFunc}
          className="p-button-danger"
        ></Button>
      </span>
    );
  }

  // create html part
  render() {
    const items = [{ label: "Transaction" }, { label: "Tertiary Packing" }];
    const TabView = (
      <Dialog
        className="popup-modal py-3 text-center"
        style={{ width: "30%" }}
        header="Scan the Carton Slip For Tertiary Packing"
        visible={this.state.Tvisible}
        modal={true}
        onHide={() => {
          this.setState({ Tvisible: false });
        }}
      >
        <div className="py-3 text-center">
          {" "}
          <input
            type="text"
            value={this.state.CartonName}
            name="CartonName"
            onChange={(e) => {
              this.setState({ CartonName: e.target.value.toString().trim() });
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                //if (e.target.value.includes(sessionStorage.getItem('Prefix')) && e.target.value.includes('SID')) {
                if (e.target.value.includes("TID")) {
                  this.submitFormFinal(e);
                } else {
                  this.props.showWarn("Invalid Cartons");
                  this.setState({ CartonName: "" });
                }
              }
            }}
            autoFocus
          />
        </div>
      </Dialog>
    );
    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div className="row">
        <div className="col-md-3 text-left">
          <h4 className="leftspace">Tertiary Packing</h4>
        </div>
        <div className="col-md-2 mapping ">
          <label>Scan items:</label>
        </div>
        <div className="col-md-4 text-left">
          <div className="form-group pl-3" style={{ margin: "0rem" }}>
            <input
              ref={this.autoref}
              onChange={(e) => {
                this.handleChange(e);
              }}
              name="scanTxt"
              value={this.state.scanTxt}
              type="search"
              className="form-control ml-2"
              id="scan"
              onKeyDown={(e) => {
                this.ScanItem(e, true);
              }}
            />
          </div>
        </div>
        <div className="col-md-3 text-right">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );

    let header2 = (
      <div className="row">
        <div className="col-md-4 text-left">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <div className="card">
          <>
            <MessageComponent ref="Messchild" />
            {/* <ConfirmationModal parentCallback={this.callbackFunction} ref="child" /> */}
            {/* <div className="row head_data">
                            <div className="col-md-6 heading">
                                <h4 className="leftspace">Tertiary Packing</h4>
                            </div>
                            <div className="col-md-6 custom-breadcrumb">
                                <BreadCrumb model={items} home={home} />
                            </div>
                        </div> */}
            <div className="heading">
              {/* <Button label="Add Packing" icon="pi pi-plus-circle" onClick={this.openDialog} /> */}
            </div>
            {/* <Loaders loader={this.state.Loader1} classType="small" /> */}
            <DataTable
              responsive={true}
              header={header}
              emptyMessage={this.state.emptyMessage}
              value={this.state.scanItems}
              paginator={true}
              currentPageReportTemplate="Total Items Added: {totalRecords}, Showing {first} to {last}"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              sortMode="multiple"
              totalRecords={this.state.scanItems.length}
              rows={this.state.rows}
              globalFilter={this.state.globalFilter}
              className="tblAuto"
            >
              <Column field="CartonId" header="Carton Id" />
              <Column field="Qty" header="Total Qty" sortable="custom" />
              {/* <Column field="MFGDate" header="MFG Date" />
                            <Column field="EXPDate" header="EXP Date" /> */}
              <Column body={this.actionTemplate} header="Action" />
            </DataTable>

            <div className="col-md-12 text-center mt-4 mb-4">
              <button
                className="btn btn-primary mt-4"
                onClick={this.submitForm}
              >
                Submit
              </button>
            </div>

            <div className="col-md-12 p-0">
              {/* <Loaders loader={this.state.Loader2} classType="small" /> */}
              <DataTable
                responsive={true}
                header={header2}
                globalFilter={this.state.globalFilter}
                emptyMessage={this.state.emptyMessage}
                value={this.props.TertiaryPackdata}
                paginator={true}
                rows={this.state.rows}
                rowsPerPageOptions={[10, 50, 200, 500]}
                totalRecords={this.state.totalRecords}
                lazy={true}
                first={this.state.first}
                onPage={this.onPage}
                onSort={this.onSort}
                className="tblAuto"
              >
                <Column
                  field="TertiaryCartonId"
                  header="CartonId"
                  headerStyle={{ width: "250px" }}
                  sortable={true}
                />
                <Column
                  field="TotalSecondaryCarton"
                  header="Total Carton"
                  headerStyle={{ width: "250px" }}
                  sortable="custom"
                />
                <Column
                  field="CreatedDt"
                  header="Created Date"
                  headerStyle={{ width: "250px" }}
                  sortable={true}
                />
                {/* <Column field="CartonName" headerStyle={{ width: '250px' }} header="Carton Size" sortable={true} /> */}
                {/* <Column field="reason" headerStyle={{ width: '250px' }} header="Reason of Fractional" style={{ width: '200px' }} /> */}
              </DataTable>
              <br />
              <br />
              <br />
            </div>
            <Dialog
              className="popup-modal"
              style={{ width: "30%" }}
              header="Add approval remarks"
              visible={this.state.isold}
              modal={true}
              onHide={this.hideFunc}
            >
              <div className="row">
                <div className="col-md-12">
                  <label>
                    Remark<span>*</span>:
                  </label>
                  <input
                    type="text"
                    style={{ width: "95%", height: "30px" }}
                    value={this.state.remark}
                    onChange={this.handleChange}
                    className="form-control"
                    name="remark"
                  />
                </div>
              </div>
              <div className="col-md-12 text-center mt-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({ isold: false });
                    this.submitForm();
                  }}
                >
                  Submit
                </button>
              </div>
            </Dialog>
            <Dialog
              className="popup-modal"
              style={{ width: "60%" }}
              header="Older Stock List"
              visible={this.state.olderstockVisible}
              modal={true}
              onHide={this.hideFunc}
            >
              <DataTable
                responsive={true}
                emptyMessage={this.state.emptyMessage}
                value={this.state.olderdata}
                paginator={false}
                className="tblAuto"
              >
                <Column field="CartonId" header="Carton Id" />
                <Column field="Qty" header="Quantity" />
                <Column field="EXPDate" header="Expire Date" />
              </DataTable>
            </Dialog>
            <Dialog
              visible={this.state.rvisible}
              className="popup-modal"
              style={{ width: "30%" }}
              modal={true}
              header="Fractional Carton Remark"
              onHide={() => {
                this.setState({ rvisible: false });
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <h5>Define in min 5 char</h5>
                  <label>
                    Submit Remark<span>*</span>:
                  </label>
                  <input
                    type="text"
                    style={{ width: "95%", height: "30px" }}
                    name="reason"
                    value={this.state.reason}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-12 text-center mt-4">
                <button
                  type="button"
                  onClick={(e) => {
                    this.setState({ rvisible: false });
                    this.submitForm();
                  }}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </Dialog>
            {TabView}
          </>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    CartonDDL: state.TertiaryPacking.Cartondata,
    Cartondata: state.TertiaryPacking.Cartondata,
    TertiaryscanItems: state.TertiaryPacking.TertiaryscanItems,
    TertiaryPackdata: state.TertiaryPacking.secondaryItems,
    warningAudio: state.TertiaryPacking?.warning,
    alreadyPackedAudio: state.TertiaryPacking?.alreadyPacked,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    CartonData: (data) => dispatch(TertiaryPackingActions.CartonData(data)),
    GetTertiaryPackingScan: (qr, flag) =>
      dispatch(TertiaryPackingActions.GetTertiaryPackingScan(qr, flag)),
    getTertiaryPacking: (data) =>
      dispatch(TertiaryPackingActions.getTertiaryPacking(data)),
    saveTertiaryPacking: (formData) =>
      dispatch(TertiaryPackingActions.saveTertiaryPacking(formData)),
    warning: (bool) => dispatch(TertiaryPackingActions.warning(bool)),
    alreadyPacked: (bool) =>
      dispatch(TertiaryPackingActions.alreadyPacked(bool)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TertiaryPacking);
