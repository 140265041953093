import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MuiAlert from '@material-ui/lab/Alert';
import { Dialog } from 'primereact/dialog';
import { ToastContainer, toast } from 'react-toastify';
import { productMasterActions } from '../../actions';
import tableRequest from '../../models/tableRequest.js';

class MRPMaster extends Component {
    constructor(props){
        super(props);
        this.tblResponse = new tableRequest();
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.onHide = this.onHide.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.state = {
            IsEdit: true,
            IsAdd: true,
            first: 0,
            rows: 5,
            TotalRecords: 0,
            globalFilter: null,
            sortOrder: '',
            confirm: false,
            buttonLabel: 'Next',
            formHeading: 'Add MRP',
            ProductId:0,
            MRP:'',
            formError: {
                ProductId:0,
                MRP:''
            }
        };
    }
    componentDidMount() {
        const userPages = sessionStorage.getItem('assignedPermissionsForUser')
        if (userPages) {
            const assignedPermissions = JSON.parse(userPages);
            const Curenturl = window.location.pathname;
            const nNumber = Curenturl.lastIndexOf('/');
            const pageName = Curenturl.substring(nNumber + 1);

            const obj = assignedPermissions.assigned.filter((x) => {
                return x.ActionName === pageName
            });

            if (obj !== null && obj.length > 0) {
                if (!obj[0].IsEdit) {
                    this.setState({
                        IsEdit: false
                    });
                } else {
                    this.setState({
                        IsEdit: true
                    });
                }

                if (!obj[0].IsAdd) {
                    this.setState({
                        IsAdd: false
                    });
                } else {
                    this.setState({
                        IsAdd: true
                    });
                }
            }
        }
        this.props.fetchApi(this.tblResponse);        
        this.props.getPrdctDDL();
    }

    componentDidUpdate(prevProps) {
        if (this.props.MRPData) {
            if (prevProps.MRPData !== this.props.MRPData) {
                // this.props.myProp has a different value
                if (this.props.MRPData) {
                    var e = this.props.MRPData;
                    this.setState({
                        TotalRecords: e[0].TotalRecords
                    });
                }
            }
        } else {
            if (this.state.TotalRecords !== 0)
                this.setState({
                    TotalRecords: 0,
                    first: 0
                });
        }
    }

    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        switch (name) {
            case 'ProductId':
                if (value === 0) {
                    IsValid = false;
                    errors.ProductId = "Please select Product";
                } else errors.ProductId = null
                break;
                case 'MRP':
                    if (!value === value) {
                        IsValid = false;
                    }
                    else {
                        if (value < 1) {
                            IsValid = false;
                            errors.MRP = "Please enter MRP.";
                        }
                        else {
                            if (!value.toString().match(/^[0-9]+$/.exec(value))) {
                                IsValid = false;
                                errors.MRP = "Please enter only number.";
                                this.setState({ MRP: 0 })
                            }
                            else{
                                errors.MRP = "";
                            }
                        }
                    }
            default:
                break;
        }

        return IsValid;
    }
    resetForm() {
        this.setState({
            ProductId:0,
            MRP:'',
            formError: {
                ProductId:0,
                MRP:'',
            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }

    submitForm(event) {

        event.preventDefault();
        let isfrmvalid = true;
        let checkerror = this.state.formError;
        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
            return isfrmvalid;
        });
        if (isfrmvalid) { this.setState({ activeIndex: 1 }); }

        if (isfrmvalid) {
            const formData = {
                ProductId:this.state.ProductId,
                MRP:this.state.MRP
            }
            this.props.addMRP(formData);
            setTimeout(() => {
                this.props.fetchApi(this.tblResponse);
            }, 1000)
            if (!this.props.showDialog) {
                this.resetForm();
            }
        }
    }

    getDropValue() {
        let productId = '';
        this.props.ProductData.filter(x => {
            if (x.ProductId === this.state.ProductId) {
                productId = x.ProductId;
            }
        });
        return productId;
    }

    //to lazy load
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    onPage(event) {
        this.props.loadingData(true);

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }

    //to search
    searchFilter(e) {
        this.props.loadingData(true);
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
    }

    //to sort
    onSort(event) {
        this.props.loadingData(true);
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDate" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
    }


    onHide() {
        this.props.onHideShow(false);
        this.resetForm();
    }

    openDialog() {
        this.props.onHideShow(true);
        this.setState({ buttonLabel: 'Next', formHeading: 'Add MRP' })
    }

    render(){
        const header = (
            <div className="table-header row">
                <div className="col-md-6">
                    <h4>MRP Master</h4>
                </div>
                <div className="col-md-6 text-right">
                    <span className="p-input-icon-left mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => this.searchFilter(e)} placeholder="Global Search" />
                    </span>
                    {/* <Button className="mr-2" icon="pi pi-plus" label="Add" onClick={this.openDialog} /> */}
                    {this.state.IsAdd === true ?
                        <Button label="Add MRP" icon="pi pi-plus-circle" onClick={this.openDialog} />
                        : <Button label="Add MRP" icon="pi pi-plus-circle" style={{ display: 'none' }} onClick={this.openDialog} />}
                </div>
            </div>
        );

        return (
            <div className="datatable-filter-demo">
                <ToastContainer />

                <div className="card">
                    <DataTable value={this.props.MRPData}
                        scrollable responsive={true}
                        globalFilter={this.state.globalFilter} header={header}
                        emptyMessage="No data found." sortMode="multiple" paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[5, 10, 20]}
                        totalRecords={this.state.TotalRecords}
                        lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort} loading={this.props.loading} ref={(el) => { this.dt = el; }}>

                        <Column field="ProductName" header="Product Name" sortable></Column>
                        <Column field="MRP" header="MRP" style={{ width: '270px' }} sortable></Column>
                        <Column field="CreatedDate" header="Created Date" sortable></Column>
                    </DataTable>
                </div>
                <Dialog header={this.state.formHeading} modal dismissableMask={false} visible={this.props.showDialog} className="popup" onHide={this.onHide}>
                <div className="row">
                    <div className="col-md-6 pt-2">
                        <FormControl variant="outlined" size="small" className="formControl col-md-12">
                            <InputLabel id="demo-simple-select-outlined-label">Select Product*</InputLabel>
                            <Select onChange={this.handleChange} name="ProductId" value={this.state.ProductId} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Product">
                                <MenuItem>
                                    <em>None</em>
                                </MenuItem>
                                {
                                    (typeof (this.props.ProductData) == "undefined" || this.props.ProductData == null) === true ? null : this.props.ProductData?.map(key => (
                                        <MenuItem value={key.value}>{key.label}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        {this.state.formError.ProductId !== 0 ?
                        <div className='error'>{this.state.formError.ProductId}</div> : ''}
                    </div>
                    <div className="col-md-6 pt-2">
                        <TextField onChange={this.handleChange} value={this.state.MRP} name="MRP" size="small" variant="outlined" className="fullwidth" label="MRP*" />
                        {this.state.formError.MRP !== '' ?
                        <div className='error'>{this.state.formError.MRP}</div> : ''}
                    </div>
                    <div className="col-md-12 mt-4 text-center">
                        <div className="mb-2">Please Click Below Button To Submit*</div>
                        <Button label="Submit" onClick={this.submitForm} icon="pi pi-check" />
                    </div>
                </div>
                </Dialog>
            </div >

        )
    }
}

const mapStateToProps = (state) => {
    return {
        ProductData: (typeof (state.product.prdctddl) !== "undefined" && state.product.prdctddl !== null) === true ? state.product?.prdctddl : null,
        MRPData : state.product.MRPData,
        showDialog: state.product.showDialog,
        loading: state.product.loding,
        showIsActiveDialog: state.product.showIsActiveDialog
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (data) => {
            dispatch(productMasterActions.GetMRPs(data))
        },
        addMRP: (data) => {dispatch(productMasterActions.AddMRP(data))},
        getPrdctDDL: () => dispatch(productMasterActions.getPrdctDDL()),
        onHideShow: isbool => dispatch(productMasterActions.onHideShow(isbool)),
        onHideShowIsActive: isbool => dispatch(productMasterActions.onHideShowIsActive(isbool)),
        loadingData: (isLoding) => dispatch(productMasterActions.loadingData(isLoding))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MRPMaster)