import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { confirmAlert } from "react-confirm-alert"; // Import
import { connect } from "react-redux";
import { TertiaryPackingActions } from "../../actions/TertiaryPackingActions.js";
import PrintDispatchService from "../../services/PrintDispatchService";
import MessageComponent from "../../templates/Message";
import { productMasterActions } from "../../actions/productMasterActions.js";
import { DispatchActions } from "../../actions/DispatchActions.js";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import tableRequest from "../../models/tableRequest.js";
import { RedemptionApprovalActions } from "../../actions/RedemptionApprovalActions.js";
import $ from "jquery";
import { BreadCrumb } from "primereact/breadcrumb";
import FormControl from "@material-ui/core/FormControl";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { secondaryPackingActions } from "../../actions/secondaryPackingAction.js";
import alreadyPacked from "../../assets/audio/AlreadyScanned.mp3";
import invalidScan from "../../assets/audio/InvalidScan.mp3";

class Dispatch extends Component {
  constructor(props) {
    super(props);

    this.getUserList = this.getUserList.bind(this);
    this.PrintDispatchService = new PrintDispatchService();
    // this.DispatchService = new DispatchService();
    // this.PrintDispatchService = new PrintDispatchService();
    // this.ApiService = new ApiService();
    // this.WareHouseMaterService = new WareHouseMaterService();
    this.tblResponse = new tableRequest();
    this.alreadyPacked = new Audio(alreadyPacked);
    this.invalidScan = new Audio(invalidScan);
    this.state = {
      DealerId: "",
      DealerName: "",
      GstNo: "",
      OrderBookingNo: "",
      SONumber: "",
      SONo: "",
      scanTxt: "",
      UserId: "",
      warehouseVisible: true,
      visible: false,
      radio: false,
      isold: false,
      cartoonLoader: false,
      scanItemLoader: true,
      remarkVisible: false,
      olderstockVisible: false,
      eventBlock: false,
      sortIcon: false,
      sortOrder: "1",
      formError: {},
      UserDDL: [],
      DispatchDetails: [],
      SelectedDDL: [],
      wareHouseDDL: [],
      WareHouseFactoryDDL: [],
      SendTo: [],
      buttonLabel: "Submit",
      sales: [],
      scanItems: [],
      scanDisp: [],
      olderdata: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      bill_Address1: "",
      bill_Address2: "",
      bill_City: "",
      bill_Country: "",
      bill_Pincode: "",
      bill_State: "",
      ship_Address1: "",
      ship_Address2: "",
      ship_City: "",
      ship_Country: "",
      ship_Pincode: "",
      ship_State: "",
      IsOldProduct: false,
      Remark: "",
      globalFilter: null,
      needtoSaveDispatch: "",
    };
    this.hideFunc = this.hideFunc.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //this.sendToEvent = this.sendToEvent.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitRemark = this.submitRemark.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.onSort = this.onSort.bind(this);
    this.ScanItem = this.ScanItem.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetDispatch();
      //   this.GetWareHouse(0);
      const objtoprint = {
        CartonId: "DID-2020-21-00001",
        CreatedDt: "14/07/20",
        Qty: "2",
        userDetail: {
          UserId: 30,
          Token: null,
          RefreshToken: null,
          ReferenceID: "CE1215F5-44DE-46F0-B9B7-72E1D1B24172",
          Username: "ajaytestdealer",
          Password: null,
          FirstName: "new",
          LastName: "gupta",
          Email: null,
          MobileNo: "8181818181",
          UserType: 2,
          ProfileImage: null,
          DeviceType: null,
          IsActive: true,
          InActiveReason: null,
          CreatedBy: null,
          CreatedDt: "2020-07-10T13:57:11.46",
          UpdatedBy: null,
          UpdatedDt: null,
          Salt: null,
          CreatedDate: null,
          IsResetPassword: false,
          TotalRows: 0,
          userId: 0,
          Id: 0,
          ship_Country: "101",
          ship_State: "1",
          ship_City: "93",
          ship_CountryName: "India",
          ship_StateName: "Andaman & Nicobar Islands",
          ship_CityName: "Carnicobar",
          ship_Address1: "Add1",
          ship_Address2: "Add2",
          ship_Pincode: "1902929",
          bill_Country: "101",
          bill_State: "4",
          bill_City: "91",
          bill_CountryName: "India",
          bill_StateName: "Assam",
          bill_CityName: "Cachar",
          bill_Address1: "Add1",
          bill_Address2: "Add2",
          bill_Pincode: "1902929",
        },
        cartonList: [
          {
            ProductName: "KROSIN-AG",
            TertiaryCartonId: "TID-2020-21-00001",
            TotalSecondaryCarton: null,
            CartonId: null,
            TotalQty: "2",
            ProductUOM: "Pieces",
          },
          {
            ProductName: "Keetnashak",
            TertiaryCartonId: "TID-2020-21-00002",
            TotalSecondaryCarton: null,
            CartonId: null,
            TotalQty: "3",
            ProductUOM: "Pieces",
          },
        ],
      };
    }, 1000);

    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });

    this.props.UserData(this.tblResponse);

    //this.GetActiveUsers();
    //if user type is 1 state of visible will be set to true
    // if (this.ApiService.getUserType() == 1) {
    //   this.setState({
    //     visible: true,
    //   });
    // }
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.Download &&
      this.props.IsDownload >= 1 &&
      prevProps.IsDownload != this.props.IsDownload
    ) {
      const resdata = this.props.Dispatch;
      //if ResponseCode is 200 success message will be shown else will move to 'else statement'
      if (resdata && resdata !== null && resdata[0].ResponseCode === "200") {
        //console.log(resdata[0].ResponseData);
        //this.PrintDispatchService.Printpdf(resdata[0].ResponseData);
        this.state.scanItems = [];
        this.setState({
          scanItems: [],
          scanDisp: [],
          SendTo: [],
          //KREP-55
          remarkVisible: false,
          needtoSaveDispatch: "",
          radio: false,
          Download: false,
        });
        this.GetDispatch();
      }
      // else if (resdata && resdata.ResponseCode === "400") {
      //   this.refs.Messchild.showWarn(resdata.ResponseMessage);
      // } else {
      //   this.showError("Seems Scanned Items already Dispatched");
      //   this.formReset();
      //   this.GetDispatch();
      //   this.GetWareHouse(0);
      // }
    }
  }

  componentDidUpdate() {
    if (this.props.alreadyPackedAudio) {
      this.alreadyPacked.play();
      this.props.alreadyPacked(false);
    } else if (this.props.warningAudio) {
      this.invalidScan.play();
      this.props.warning(false);
    }
  }

  // PAGE EVENT CALL
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetDispatch();
    }, 250);
  }

  hideFunc() {
    this.setState({
      remarkVisible: false,
      olderstockVisible: false,
      // formHeading: 'Add User'
    });
  }

  // set lazy loading values
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // get users data list from api
  GetDispatch() {
    this.formReset();
    this.setState({ cartoonLoader: false, eventBlock: false, SONo: "" });
    this.tblResponse.SortColumn = "DispatchId";
    this.props.fetchApi(this.tblResponse);
  }

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;
    if (name == "SendTo") {
      this.setState({ scanTxt: "" });
    }
    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  getUserList(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;
    if (name == "SendTo") {
      this.setState({ scanTxt: "" });
    }
    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
    this.setState({ UserId: event.target.value });
    this.props.UserData(event.target.value);
    const userpnt = {
      UserId: event.target.value,
    };
  }

  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "UserName":
        if (value.length < 1) {
          IsValid = false;
          errors.UserName = "Please enter your username.";
        } else errors.UserName = "";
        break;
      case "SendTo":
        ////console.log(value);
        this.state.bill_Address1 = value.bill_Address1;
        this.state.bill_Address2 = value.bill_Address2;
        this.state.bill_Pincode = value.bill_Pincode;
        this.state.bill_Country = value.bill_CountryName;
        this.state.bill_State = value.bill_StateName;
        this.state.bill_City = value.bill_CityName;
        this.state.ship_Address1 = value.bill_Address1;
        this.state.ship_Address2 = value.bill_Address2;
        this.state.ship_Pincode = value.bill_Pincode;
        this.state.ship_Country = value.bill_CountryName;
        this.state.ship_State = value.bill_StateName;
        this.state.ship_City = value.bill_CityName;
        this.setState({
          eventBlock: true,
        });
        break;
      default:
        break;
    }
    return IsValid;
  }

  // reset form text value
  formReset() {
    this.setState({
      ReferenceID: "",
      UserName: "",
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      IsActive: true,
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // open Dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }

  // submit packing
  submitForm(event) {
    event.preventDefault();
    //if SO length is 0
    if (this.state.SONo.length == 0) {
      this.refs.Messchild.showWarn("Enter Sales Order Number");
      return;
    }
    //if scanItems length is 0
    else if (this.state.scanItems.length == 0) {
      this.refs.Messchild.showWarn("Scan First");
      return;
    } else {
      let formData = {
        DealerId: this.props.DealerId,
        SONo: this.state.SONumber,
        scanned: this.state.scanItems,
      };
      // if old stock is greater than 0
      if (this.state.isold) {
        //open Dialog to add remark
        this.setState({
          remarkVisible: true,
          needtoSaveDispatch: formData,
        });
      } else {
        this.SaveDispatch(formData);
      }
    }
  }

  submitRemark(event) {
    event.preventDefault();
    let formD = { ...this.state.needtoSaveDispatch };
    formD.Remark = this.state.Remark;
    this.SaveDispatch(formD);
  }

  SaveDispatch(formData) {
    this.setState({ scanItemLoader: false, Download: true });
    this.props.createDispatch(formData);
    setTimeout(() => {
      this.setState({
        scanItems: [],
        scanDisp: [],
        SendTo: [],
        //KREP-55
        remarkVisible: false,
        needtoSaveDispatch: "",
        radio: false,
        Download: false,
      });
      this.GetDispatch();
    }, 3000);
  }

  // get active carton drop down list
  // GetActiveUsers() {
  //   this.props.UserDDL.filter((data) => {
  //     const resdata = data.data;
  //     //if ResponseCode is 200 UserDDL state will be set to ResponseData
  //     if (resdata !== null && resdata.ResponseCode === "200") {
  //       const Dealer = (resdata.ResponseData).filter((x) => x.UserType == 1);
  //       this.setState({
  //         SelectedDDL: Dealer,
  //         warehouseVisible: true,
  //       });
  //       this.setState({
  //         UserDDL: resdata.ResponseData,
  //       });
  //     }
  //   });
  // }
  getDropValueOfUser() {
    let User = "";
    this.props.Userdataa.filter((x) => {
      if (x.value === this.props.Userdataa) {
        User = x.label;
      }
    });
    return User;
  }

  // scan qr code
  ScanItem(e) {
    if (e.key === "Enter") {
      let qr = e.target.value;
      qr = qr.toString().trim();
      if (
        this.state.SONo.length == 0 ||
        this.props.SODetails == (undefined || null)
      ) {
        this.refs.Messchild.showWarn("Enter Sales Order Number");
        return;
      } else if (qr.includes("TID") || qr.includes("SID")) {
        this.setCartonDetails(qr, true);
      } else {
        this.refs.Messchild.showWarn("Invalid Scan");
        this.props.warning(true);
      }
      this.setState({
        scanTxt: "",
      });
    }
  }

  // set tertiary details in grid

  setCartonDetails(qr, checkItemDate) {
    let id = 1;
    this.state.scanItems.forEach((element) => {
      if (element.CartonId == qr) {
        this.props.alreadyPacked(true);
        this.refs.Messchild.showWarn("Already Scanned..!");
        id = 2;
        return;
      }
    });

    // setTimeout(() => {
    if (id == 1) {
      this.props.GetTertiaryPackingScan(
        qr,
        checkItemDate,
        this.state.scanItems
      );
      //.then((data) => {
      setTimeout(() => {
        const resdata = this.props.TertiaryscanItems;
        let _scanList = [];
        let _scanDisp = [];
        //const resdata = data.data;
        //if ResponseCode is 200 else will move to 'else statement'
        if (resdata && resdata !== null && resdata.ResponseCode === "200") {
          //disable
          var ProdData = resdata.ResponseData.map((d) => {
            return {
              ProductId: d.ProductId,
              PQty: d.PQty,
            };
          });

          var contains = false;

          const result = ProdData.map(({ ProductId, PQty }) => ({
            contains: this.state.OrderInfos.some(
              (item) =>
                item.ProductId.toString() === ProductId.toString() &&
                item.RemainingQty >= PQty
            ),
          }));

          const containsExtraProducts = result.some(
            (item) => item.contains == false
          );

          if (!containsExtraProducts) {
            this.state.OrderInfos.forEach((val) => {
              ProdData.forEach((i) => {
                if (
                  i.ProductId.toString() == val.ProductId.toString() &&
                  val.RemainingQty >= i.PQty
                ) {
                  contains = true;
                  val.AddedQty = val.AddedQty + i.PQty;
                  val.RemainingQty = val.RemainingQty - i.PQty;
                }
              });
            });
          }

          if (contains && !containsExtraProducts) {
            resdata.ResponseData.forEach((element) => {
              element.MFGDate =
                element.MFGDate != null || undefined
                  ? element.MFGDate.substring(0, 10)
                  : element.MFGDate;
              element.EXPDate =
                element.EXPDate != null || undefined
                  ? element.EXPDate.substring(0, 10)
                  : element.EXPDate;

              if (this.state.scanItems.length > 0) {
                let check = this.state.scanItems.filter(
                  (y) =>
                    y.Id == element.Id &&
                    y.ProductId == element.ProductId &&
                    y.MFGDate == element.MFGDate &&
                    y.EXPDate == element.EXPDate
                );

                if (check.length > 0) {
                  this.refs.Messchild.showWarn("already Scanned");
                }
              }

              this.state.scanItems.push(element);
              //this.state.scanItems.push(element);
              if (this.state.scanDisp.length > 0) {
                let checkseqNo = this.state.scanDisp.filter(
                  (y) => y.CartonId == element.CartonId
                );

                if (checkseqNo.length > 0) {
                  return;
                }
              }

              this.state.scanDisp.push(element);
              //this.state.scanDisp.push(element);
            });
            // if (_scanList && _scanList.length > 0) {
            //   _scanList.forEach((scnItem) => {
            //     this.state.scanItems.push(scnItem);
            //   });
            // }

            // if (_scanDisp && _scanDisp.length > 0) {
            //   _scanDisp.forEach((scnItem) => {
            //     this.state.scanDisp.push(scnItem);
            //   });
            // }
            this.setState({
              scanItems: this.state.scanItems,
              scanTxt: "",
              radio: true,
              //scanDisp: _scanDisp
            });
          } else {
            if (containsExtraProducts) {
              this.props.warning(true);
              this.refs.Messchild.showWarn(
                "This Carton contains some extra products which are not part of this order"
              );
            } else if (!contains) {
              this.props.warning(true);
              this.refs.Messchild.showWarn(
                "This Carton contains some extra products which are not part of this order"
              );
            }
          }
        } else if (
          resdata &&
          resdata !== null &&
          resdata.ResponseCode === "500"
        ) {
          let checkseqNo = [];
          resdata.ResponseData.forEach((element) => {
            if (this.state.scanItems.length > 0) {
              let chk = this.state.scanItems.filter(
                (y) => y.CartonId == element.CartonId
              );
              if (chk.length != 0) {
                checkseqNo.push(chk);
              }
              if (checkseqNo.length == resdata.ResponseData.length) {
                this.setCartonDetails(qr, false);
                return;
              }
            }
          });
          /// will show list and input yes or no
          ///this.refs.Messchild.showWarn(resdata.ResponseMessage);
          // if no
          //resolved KREP-132,KREP-121(CR)
          if (
            checkseqNo == undefined ||
            checkseqNo.length != resdata.ResponseData.length
          ) {
            //   confirmAlert({
            //     title: resdata.ResponseMessage,
            //     message: 'Are you sure you want to continue scanning the latest stock.',
            //     buttons: [
            //       {
            //         label: 'Yes',
            //         onClick: () => {
            //           this.setCartonDetails(qr, false)
            //           this.setState({ isold: true })
            //         }
            //       },
            //       {
            //         label: 'No',
            //         onClick: () => {
            //           let olddata = [];
            //           (resdata.ResponseData).forEach(e1 => {
            //             let scanned
            //             (this.state.scanItems).forEach(e2 => {
            //               if (e1.CartonId == e2.CartonId) {
            //                 scanned = 1
            //               }
            //             });
            //             if (scanned == undefined) {
            //               e1.EXPDate = (e1.EXPDate != null || undefined) ? e1.EXPDate.substring(0, 10) : e1.EXPDate
            //              olddata.push(e1)
            //             }
            //           });
            //           this.setState({
            //             olderstockVisible: true,
            //             olderdata: olddata
            //           })
            //         }
            //       }
            //     ]
            //   });
          }
        } else {
          this.refs.Messchild.showWarn(resdata.ResponseMessage);
        }
      }, 1000);
      //});
    }
    // }, 50);
  }
  // delete user data
  deleteScanned(TertiaryCartonId) {
    var ProdData = this.state.scanItems.map((d) => {
      if (d.CartonId == TertiaryCartonId) {
        return {
          ProductId: d.ProductId,
          PQty: d.PQty,
        };
      }
    });

    this.state.OrderInfos.forEach((val) => {
      ProdData.forEach((i) => {
        if (
          i &&
          i.ProductId.toString() == val.ProductId.toString() &&
          val.RemainingQty >= i.PQty
        ) {
          val.AddedQty = val.AddedQty - i.PQty;
          val.RemainingQty = val.RemainingQty + i.PQty;
        }
      });
    });

    let arrayfilter = this.state.scanItems.filter(
      (x) => x.CartonId != TertiaryCartonId
    );

    this.setState({
      scanItems: arrayfilter,
    });
    let array = this.state.scanDisp.filter(
      (x) => x.CartonId != TertiaryCartonId
    );

    this.setState({
      scanDisp: array,
    });
  }

  GetWareHouse(compId) {
    this.WareHouseMaterService.GetWareHouseDDL(compId).then((data) => {
      const resdata = data.data;
      //if ResponseData is not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        const filteredWareHouse = resdata.ResponseData.filter(
          (x) => x.ID != this.ApiService.getWareHouseId()
        );

        this.setState({
          WareHouseFactoryDDL: filteredWareHouse,
        });
      }
    });
  }

  // seach table
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetDispatch();
  }

  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

  onValueChange(event) {
    const val = event.target.value;

    if (val == "Dealer") {
      const Dealer = this.state.UserDDL.filter((x) => x.UserType == 2);
      this.setState({
        SelectedDDL: Dealer,
        warehouseVisible: true,
      });
    }
    if (val == "Distributor") {
      const Distributor = this.state.UserDDL.filter((x) => x.UserType == 1);

      this.setState({
        SelectedDDL: Distributor,
        warehouseVisible: true,
      });
    }
    if (val == "WareHouse") {
      const wareHouse = this.state.WareHouseFactoryDDL.filter(
        (x) => x.IsFactory == 0
      );
      this.setState({
        wareHouseDDL: wareHouse,
      });

      this.setState({
        warehouseVisible: false,
      });
    }

    if (val == "Factory") {
      const fact = this.state.WareHouseFactoryDDL.filter(
        (x) => x.IsFactory == 1
      );
      this.setState({
        wareHouseDDL: fact,
        warehouseVisible: false,
      });
    }
  }

  //To fetch Sales Order Number Details
  fetchSODetails(e) {
    if (e.key === "Enter") {
      if (this.state.SONo.length == 0) {
        this.refs.Messchild.showWarn("Enter Sales Order Number");
        return;
      } else {
        this.props.fetchSODetails(e.target.value);
        setTimeout(() => {
          this.setState({
            DispatchDetails: this.props.SODetails,
          });
          let value = this.props.SODetails;
          if (value && value.ResponseData != null) {
            this.state.bill_Address1 = value.ResponseData.Bill_Address;
            this.state.bill_Pincode = value.ResponseData.Bill_Pincode;
            this.state.bill_Country = value.ResponseData.Bill_Country;
            this.state.bill_State = value.ResponseData.Bill_State;
            this.state.bill_City = value.ResponseData.Bill_City;
            this.state.ship_Address1 = value.ResponseData.Ship_Address;
            this.state.ship_Pincode = value.ResponseData.Ship_Pincode;
            this.state.ship_Country = value.ResponseData.Ship_Country;
            this.state.ship_State = value.ResponseData.Ship_State;
            this.state.ship_City = value.ResponseData.Ship_City;
            this.setState({
              DealerCode: value.ResponseData.DealerCode,
              DealerId: value.ResponseData.DealerId,
              DealerName: value.ResponseData.DealerName,
              GstNo: value.ResponseData.GstNo,
              OrderBookingNo: value.ResponseData.OrderBookingNo,
              SONumber: value.ResponseData.SONumber,
              eventBlock: true,
              OrderInfos: value.ResponseData.OrderInfos,
            });
          } else {
            //this.refs.Messchild.showWarn("Invalid Sales Order Number");
          }
        }, 2000);
      }
    }
  }

  // disabled checkbox
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // Sort table
  onSort(event) {
    this.setState({
      sortOrder: this.state.sortOrder === 1 ? 2 : 1,
      sortIcon: !this.state.sortIcon,
    });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetDispatch();
    const clickData = document.getElementsByClassName(
      "p-sortable-column-icon pi pi-fw pi-sort"
    );
    ////console.log(event);
  }

  // edit form
  actionTemplate(rowData, column) {
    const deleteFunc = () => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteScanned(rowData.CartonId),
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    };

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-trash"
          onClick={deleteFunc}
          className="p-button-danger"
        ></Button>
      </span>
    );
  }

  // create html part
  render() {
    const items = [{ label: "Transaction" }, { label: "Dispatch" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div className="row">
        <div className="col-md-8 text-left"></div>
        <div className="col-md-4 text-right">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );

    let header2 = (
      <div className="row">
        <div className="col-md-4 text-left">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <div className="card">
          <>
            <MessageComponent ref="Messchild" />
            {/* <ConfirmationModal parentCallback={this.callbackFunction} ref="child" /> */}
            <Card>
              <div className="row head_data">
                <div className="col-md-6 heading">
                  <h4 className="leftspace">Dispatch</h4>
                </div>
                {/* <div className="col-md-6 custom-breadcrumb">
                  <BreadCrumb model={items} home={home} />
                </div> */}
              </div>
              <div>
                <form className="form-inline dispatch-label row mb-3">
                  <div className="form-group col-md-6 pl-5">
                    <label>Sales Order No.:</label>
                    <input
                      style={{ width: "80%" }}
                      disabled={this.state.eventBlock}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      name="SONo"
                      value={this.state.SONo}
                      type="search"
                      className="form-control ml-2"
                      id="SONo"
                      onKeyDown={(e) => {
                        this.fetchSODetails(e);
                      }}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label>Scan Item(s):</label>
                    <input
                      style={{ width: "80%" }}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      name="scanTxt"
                      value={this.state.scanTxt}
                      type="search"
                      className="form-control ml-2"
                      id="scanTxt"
                      onKeyDown={(e) => {
                        this.ScanItem(e);
                      }}
                    />
                  </div>

                  {/* <div hidden={!this.state.warehouseVisible} className="form-group col-md-6" style={this.state.radio ? { pointerEvents: 'none' } : {}}>
                    <Dropdown style={this.state.radio ? { pointerEvents: 'none' } : {}} optionLabel="Username" onChange={this.handleChange} value={this.state.SendTo}
                      className="form-control" placeholder="Select User" name="SendTo" options={this.state.SelectedDDL} />
                    {this.state.formError.SendTo !== null ? (
                      <div className="error">{this.state.formError.SendTo}</div>
                    ) : null}
                  </div> */}
                  {/* <div className="col-md-6 pt-3">
                            <FormControl variant="outlined" size="small" className="demo-simple-select-outlined-label">
                                <Dropdown value={this.state.UserId} filter={true}
                                    filterPlaceholder="Search User" style={{ width: '340px' }}
                                    emptyFilterMessage="No Result Found"
                                    name="SendTo"
                                    options={this.props.Userdataa}
                                    onChange={this.getUserList}
                                    
                                    
                                    placeholder="Select User" />
                            </FormControl>
                            {this.state.formError.UserId !== '' ?
                                <div className='error'>{this.state.formError.UserId}</div> : ''}
                        </div> */}
                </form>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="address-block"
                      style={{
                        display: this.state.eventBlock ? "block" : "none",
                        padding: "20px",
                      }}
                    >
                      <h4>Sales Order Details</h4>
                      <div className="row dispatch-label">
                        <div className="col-md-4">
                          <label>
                            <strong>Dealer Code: </strong>
                          </label>
                          &nbsp;
                          {this.state.DealerCode}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <strong>Order Booking No.: </strong>
                          </label>
                          &nbsp;
                          {this.state.OrderBookingNo}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <strong>Sales Order No: </strong>
                          </label>
                          &nbsp;
                          {this.state.SONumber}
                        </div>
                        {/* <div className="col-md-6">
                          <label>
                            <strong>Dealer Name: </strong>
                          </label>
                          &nbsp;
                          {this.state.DealerName}
                        </div> */}
                        {/* <div className="col-md-6">
                          <label>
                            <strong>GST No.: </strong>
                          </label>
                          &nbsp;
                          {this.state.GstNo}
                        </div> */}
                      </div>
                      {/* <h4 className="mt-3">Billing Address</h4>
                      <div className="row dispatch-label">
                        <div className="col-md-4">
                          <label>
                            <strong>Address Line:</strong>
                          </label>
                          &nbsp;
                          {this.state.bill_Address1}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <strong>City : </strong>
                          </label>
                          &nbsp;
                          {this.state.bill_City}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <strong>State : </strong>
                          </label>
                          &nbsp;
                          {this.state.bill_State}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <strong>Country : </strong>
                          </label>
                          &nbsp;
                          {this.state.bill_Country}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <strong>Pincode : </strong>
                          </label>
                          &nbsp;
                          {this.state.bill_Pincode}
                        </div>
                      </div>
                      <h4 className="mt-3">Shipping Address</h4>
                      <div className="row dispatch-label mb-3">
                        <div className="col-md-4">
                          <label>
                            <strong>Address Line: </strong>
                          </label>
                          &nbsp;
                          {this.state.ship_Address1}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <strong>City : </strong>
                          </label>
                          &nbsp;
                          {this.state.ship_City}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <strong>State : </strong>
                          </label>
                          &nbsp;
                          {this.state.ship_State}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <strong>Country : </strong>
                          </label>
                          &nbsp;
                          {this.state.ship_Country}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <strong>Pincode : </strong>
                          </label>
                          &nbsp;
                          {this.state.ship_Pincode}
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div
                      className="address-block"
                      style={{
                        display: this.state.eventBlock ? "block" : "none",
                        padding: "20px",
                      }}
                    >
                      <h4>Order Info</h4>
                      <DataTable value={this.state.OrderInfos}>
                        <Column field="ProductName" header="Product Name" />
                        <Column
                          field="TotalQty"
                          header="Total Products' Qty in Order"
                        />
                        <Column
                          field="RemainingQty"
                          header="Remaining Qty For Dispatch"
                        />
                        <Column
                          field="AddedQty"
                          header="Qty Added in Dispatch"
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Loaders loader={this.state.scanItemLoader} /> */}
              <DataTable
                responsive={true}
                header={header}
                emptyMessage={this.state.emptyMessage}
                value={this.state.scanDisp}
                paginator={true}
                currentPageReportTemplate="Total Items Added: {totalRecords}, Showing {first} to {last}"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                sortMode="multiple"
                totalRecords={this.state.scanDisp.length}
                rowsPerPageOptions={[10, 50, 200, 500]}
                rows={this.state.rows}
                className="tblAuto"
              >
                <Column field="CartonId" header="Carton Id" />
                {/* <Column field="ProductName" header="Product Name" /> */}
                <Column field="Qty" header="Total Products' Qty in Carton" />
                {/* <Column field="MFGDate" header="MFG Date" />
                <Column field="EXPDate" header="EXP Date" /> */}
                <Column body={this.actionTemplate} header="Action" />
              </DataTable>

              <div className="text-center">
                <button
                  className="btn btn-primary my-4"
                  onClick={this.submitForm}
                >
                  Submit
                </button>
              </div>
            </Card>
            <Card className="mt-4">
              <div>
                {/* <Loaders loader={this.state.cartoonLoader} classType="small" /> */}
                <DataTable
                  responsive={true}
                  header={header2}
                  globalFilter={this.state.globalFilter}
                  emptyMessage={this.state.emptyMessage}
                  value={this.props.DispatchData}
                  sortMode="multiple"
                  paginator={true}
                  rows={this.state.rows}
                  rowsPerPageOptions={[10, 50, 200, 500]}
                  totalRecords={this.state.totalRecords}
                  lazy={true}
                  first={this.state.first}
                  onPage={this.onPage}
                  onSort={this.onSort}
                  className="tblAuto"
                >
                  <Column
                    field="DispatchId"
                    header="Dispatch Id"
                    sortable={true}
                  />

                  <Column
                    field="TotalCarton"
                    header="Total Carton"
                    sortable={true}
                  />
                  <Column
                    field="CreatedDt"
                    header="Created Date"
                    sortable={true}
                  />
                  <Column
                    field="userName"
                    header="Dispatched To"
                    sortable={true}
                  />
                </DataTable>
              </div>
            </Card>
            <Dialog
              className="popup-modal"
              style={{ width: "30%" }}
              header="Add approval remarks"
              visible={this.state.remarkVisible}
              modal={true}
              onHide={this.hideFunc}
            >
              <form onSubmit={this.submitRemark}>
                <div className="row">
                  <div className="col-md-12">
                    <label>
                      Remark<span>*</span>:
                    </label>
                    <input
                      type="text"
                      style={{ width: "95%", height: "30px" }}
                      value={this.state.Remark}
                      onChange={this.handleChange}
                      className="form-control"
                      name="Remark"
                    />
                  </div>
                </div>
                <div className="col-md-12 text-center mt-4">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </Dialog>
            <Dialog
              className="popup-modal"
              style={{ width: "60%" }}
              header="Older Stock List"
              visible={this.state.olderstockVisible}
              modal={true}
              onHide={this.hideFunc}
            >
              <DataTable
                responsive={true}
                emptyMessage={this.state.emptyMessage}
                value={this.state.olderdata}
                sortMode="multiple"
                paginator={true}
                rowsPerPageOptions={[10, 50, 200, 500]}
                className="tblAuto"
              >
                <Column field="CartonId" header="Carton Id" />
                <Column field="Qty" header="Quantity" />
                <Column field="EXPDate" header="Expire Date" />
              </DataTable>
            </Dialog>
          </>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //////console.log('state.Dispatch.SODetails', state.Dispatch.SODetails);
  return {
    TertiaryscanItems: state.TertiaryPacking.TertiaryscanItems,
    Userdataa: state.RedemptionApproval.Userdataa,
    SODetails: state.Dispatch.SODetails,
    DealerId: state.Dispatch.dealerId,
    Dispatch: state.Dispatch.Dispatch,
    DispatchData: state.Dispatch.items,
    IsDownload: state.Dispatch.isDownload,
    // ProductData: (typeof (state.product.prdctddl) !== "undefined" && state.product.prdctddl !== null) === true ? state.product?.prdctddl : null,
    // Issueseries: state.Dispatch.Issueseries,
    // MaxFromNo: state.Dispatch?.issueData?.ToNo == (null || undefined) ? 1 : (state.Dispatch?.issueData?.ToNo) + 1,
    // showDialog: state.Dispatch.showDialog,
    // showDialogue: state.Dispatch.showDialogue,
    // loading: state.company.loding
    warningAudio: state.secondaryPacking?.warning,
    alreadyPackedAudio: state.secondaryPacking?.alreadyPacked,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetTertiaryPackingScan: (qr, flag, scannedItem) =>
      dispatch(
        TertiaryPackingActions.GetTertiaryPackingScan(qr, flag, scannedItem)
      ),
    fetchApi: (obj) => {
      dispatch(DispatchActions.getAll(obj));
    },
    fetchSODetails: (SONo) => {
      dispatch(DispatchActions.fetchSODetails(SONo));
    },
    fetchProductApi: (obj) => {
      dispatch(productMasterActions.getProductName(obj));
    },
    getToNumber: () => {
      dispatch(DispatchActions.GetToNo());
    },
    UserData: (data) => dispatch(RedemptionApprovalActions.UserData(data)),
    createDispatch: (machine) =>
      dispatch(DispatchActions.CreateDispatch(machine)),
    updateDispatch: (machine) =>
      dispatch(DispatchActions.updateDispatch(machine)),
    onHideShow: (isbool) => dispatch(DispatchActions.onHideShow(isbool)),
    onHideShoww: (isbool) => dispatch(DispatchActions.onHideShoww(isbool)),
    getPrdctDDL: () => dispatch(productMasterActions.getPrdctDDL()),
    loadingData: (isLoding) => dispatch(DispatchActions.loadingData(isLoding)),
    warning: (bool) => dispatch(DispatchActions.warning(bool)),
    alreadyPacked: (bool) => dispatch(DispatchActions.alreadyPacked(bool)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dispatch);
