import { damageConstants } from '../constants';

export function damage(state = {}, action) {
    switch (action.type) {
        case damageConstants.DIALOG_REQUEST:
            return {
                ...state,
                showDialog: action.isbool
            };
            case damageConstants.LOADING_REQUEST:
                return {
                    ...state,
                    loding: action.isbool
                };
        case damageConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case damageConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case damageConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case damageConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case damageConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: action.data
            };
        case damageConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case damageConstants.UPDATE_REQUEST:
            return {
                ...state,
                items: state.items.map(item =>
                    item.MappingId == action.data.MappingId ? { ...item, updating: true } : item
                )
            };
        case damageConstants.UPDATE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.MappingId === action.data.MappingId ? action.data : item
                )
            };
        case damageConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case damageConstants.DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            };
        case damageConstants.DELETE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.damageId === action.data ? {
                        CreatedBy: item.CreatedBy,
                        CreatedDt: item.CreatedDt,
                        damageId: item.damageId,
                        productName: item.productName,
                        UpdatedBy: item.UpdatedBy,
                        UpdatedDt: item.UpdatedDt,
                        FromNo:item.FromNo,
                        ToNo:item.ToNo,
                        Qty:item.Qty
                    } : item
                )
            };
        case damageConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }
}
