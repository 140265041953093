import { CartonConstants } from "../constants/CartonConstants";

export function Carton(state = {}, action) {
    switch (action.type) {
        case CartonConstants.LOADING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };
        case CartonConstants.DIALOG_REQUEST:
            return {
                ...state,
                showDialog: action.isbool
            };
        //case CartonConstants.DIALOGUE_REQUEST:
        //    return {
        //        ...state,
        //        showDialogue: action.isbool
        //    };
        case CartonConstants.DIALOG_ISACTIVE_REQUEST:
            return {
                ...state,
                showIsActiveDialog: action.isbool
            };
        //case CartonConstants.UPDATE_PAGE_STORE:
        //    return {
        //        ...state,
        //        pages: action.pageList
        //    };
        case CartonConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CartonConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };

        case CartonConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case CartonConstants.GETALL_AVAILABLE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CartonConstants.GETALL_AVAILABLE_SUCCESS:
            return {
                ...state,
                Mappseries: action.datas,
               
            };
        case CartonConstants.GETALL_AVAILABLE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case CartonConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case CartonConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: state.items == null ? [action.data] : [action.data, ...state.items]
            };
        case CartonConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case CartonConstants.UPDATE_REQUEST:
            return {
                ...state,
                items: state.items.map(item =>
                    item.CartonId === action.data.CartonId ? { ...item, updating: true } : item
                )
            };
        case CartonConstants.UPDATE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.CartonId === action.data.CartonId ? action.data : item
                )
            };
        case CartonConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case CartonConstants.DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            };
        case CartonConstants.DELETE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.CartonId === action.data ? {
                        CompanyId: item.CompanyId,
                        CartonName: item.CartonName,
                        //roleId: item.roleId,
                        //RoleName: item.roleName,
                        //RoleDesc: item.RoleDesc,
                        isActive: item.isActive,
                        //CreatedDate: item.CreatedDate
                    } : item
                )
            };
        case CartonConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case CartonConstants.GETCARTONDDL_REQUEST:
           return {
               ...state,
               loading: true
           };
        case CartonConstants.GETCARTONDDL_SUCCESS:
           return {
               ...state,
               cartonddl: action.datas
           };
        case CartonConstants.GETCARTONDDL_FAILURE:
           return {
               ...state,
               error: action.error
           };
        //case CartonConstants.GETALL_FAILURE:
        //    return {
        //        ...state,
        //        error: action.error
        //    };
//
        //    case CartonConstants.GETPRDCTDDL_REQUEST:
        //    return {
        //        ...state,
        //        loading: true
        //    };
        //case CartonConstants.GETPRDCTDDL_SUCCESS:
        //    return {
        //        ...state,
        //        prdctddl: action.datas
        //    };
        //case CartonConstants.GETPRDCTDDL_FAILURE:
        //    return {
        //        ...state,
        //        error: action.error
        //    };
       // case CartonConstants.GET_PAGES_REQUEST:
       //     return {
       //         ...state,
       //         getpages: true
       //     };
       // case CartonConstants.GET_PAGES_SUCCESS:
       //     return {
       //         ...state,
       //         pages: action.pages
       //     };
       // case CartonConstants.GET_PAGES_FAILURE:
       //     return {
       //         ...state,
       //         error: action.error
       //     };
        case CartonConstants.ISACTIVE_REQUEST:
            return {
                ...state,
                activating: true
            };
        case CartonConstants.ISACTIVE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.CartonId === action.data.CartonId ? {
                        CompanyId: item.CompanyId,
                        CartonName: item.CartonName,
                        //RoleId: item.RoleId,
                        //RoleName: item.RoleName,
                        //RoleDesc: item.RoleDesc,
                        TotalRows: item.TotalRows,
                        IsActive: action.data.IsActive,
                        CreatedDate: item.CreatedDate
                    } : item
                )
            };
        case CartonConstants.ISACTIVE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }
}