export const SliderProMsgConstants = {
  UPLOAD_REQUEST: "FILES_UPLOAD_REQUEST",
  UPLOAD_SUCCESS: "FILES_UPLOAD_SUCCESS",
  UPLOAD_FAILURE: "FILES_UPLOAD_FAILURE",

  GETALL_REQUEST: "HOMESLIDER_GETALL_REQUEST",
  GETALL_SUCCESS: "HOMESLIDER_GETALL_SUCCESS",
  GETALL_FAILURE: "HOMESLIDER_GETALL_FAILURE",

  GETALLS_REQUEST: "ALLSLIDER_GETALL_REQUEST",
  GETALLS_SUCCESS: "ALLSLIDER_GETALL_SUCCESS",
  GETALLS_FAILURE: "ALLSLIDER_GETALL_FAILURE",

  DELETE_REQUEST: "HOMESLIDER_DELETE_REQUEST",
  DELETE_SUCCESS: "HOMESLIDER_DELETE_SUCCESS",
  DELETE_FAILURE: "HOMESLIDER_DELETE_FAILURE",
};
