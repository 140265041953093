export const demappingConstants = {
    CREATE_REQUEST: 'DEMAPPINGS_CREATE_REQUEST',
    CREATE_SUCCESS: 'DEMAPPINGS_CREATE_SUCCESS',
    CREATE_FAILURE: 'DEMAPPINGS_CREATE_FAILURE',

    UPDATE_REQUEST: 'DEMAPPINGS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'DEMAPPINGS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'DEMAPPINGS_UPDATE_FAILURE',

    GETALL_REQUEST: 'DEMAPPINGS_GETALL_REQUEST',
    GETALL_SUCCESS: 'DEMAPPINGS_GETALL_SUCCESS',
    GETALL_FAILURE: 'DEMAPPINGS_GETALL_FAILURE',

    DELETE_REQUEST: 'DEMAPPINGS_DELETE_REQUEST',
    DELETE_SUCCESS: 'DEMAPPINGS_DELETE_SUCCESS',
    DELETE_FAILURE: 'DEMAPPINGS_DELETE_FAILURE',
    
    DIALOG_REQUEST:'DIALOG_REQUEST',

    LOADING_REQUEST:'LOADING_REQUEST'
};