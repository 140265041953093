export const roleMasterConstants = {
    CREATE_REQUEST: 'ROLE_CREATE_REQUEST',
    CREATE_SUCCESS: 'ROLE_CREATE_SUCCESS',
    CREATE_FAILURE: 'ROLE_CREATE_FAILURE',

    UPDATE_REQUEST: 'ROLE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ROLE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ROLE_UPDATE_FAILURE',

    GETALL_REQUEST: 'ROLE_GETALL_REQUEST',
    GETALL_SUCCESS: 'ROLE_GETALL_SUCCESS',
    GETALL_FAILURE: 'ROLE_GETALL_FAILURE',

    DELETE_REQUEST: 'ROLE_DELETE_REQUEST',
    DELETE_SUCCESS: 'ROLE_DELETE_SUCCESS',
    DELETE_FAILURE: 'ROLE_DELETE_FAILURE',

    
    ISACTIVE_REQUEST: 'ROLE_ISACTIVE_REQUEST',
    ISACTIVE_SUCCESS: 'ROLE_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE: 'ROLE_ISACTIVE_FAILURE',

    DIALOG_REQUEST: 'DIALOG_REQUEST',

    GETROLEDDL_REQUEST: 'ROLE_DDL_REQUEST',
    GETROLEDDL_SUCCESS: 'ROLE_DDL_SUCCESS',
    GETROLEDDL_FAILURE: 'GETROLEDDL_FAILURE',

    GET_PAGES_REQUEST: 'GET_PAGES_REQUEST',
    GET_PAGES_SUCCESS: 'GET_PAGES_SUCCESS',
    GET_PAGES_FAILURE: 'GET_PAGES_FAILURE',

    UPDATE_PAGE_STORE:'UPDATE_PAGE_STORE',

    LOADING_REQUEST:'LOADING_REQUEST',

    DIALOG_ISACTIVE_REQUEST:'DIALOG_ISACTIVE_REQUEST'
};