import { secondaryPackingService } from "../services";
import { secondaryPackingConstants } from "../constants";
import { alertActions } from ".";

export const secondaryPackingActions = {
  getProductByQrScan,
  updateScannedList,
  saveSecondaryPacking,
  getSecondaryPacking,
  removeItem,
  alreadyPacked,
  warning,
};

function getProductByQrScan(data, scannedList) {
  return (dispatch) => {
    dispatch(request(data));

    secondaryPackingService.getProductByQrScan(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            const resdata = response.ResponseData;
            if (resdata.ProductID > 0) {
              let checkseqNo = scannedList?.filter(
                (y) => y.SeqNo == resdata.SeqNo
              );
              if (checkseqNo && checkseqNo.length > 0) {
                dispatch(warning(response.ResponseMessage));
                dispatch(alertActions.warning("already Scanned"));
                return;
              }
              // let IscheckSameProduct = scannedList?.filter(y => y.ProductID == resdata.ProductID)
              // if (IscheckSameProduct && IscheckSameProduct.length == 0) {
              //     dispatch(alertActions.warning("You can pack only same product"))
              //     return;
              // }

              dispatch(success(response.ResponseData));
            }
          } else if (response.ResponseCode === "400") {
            dispatch(warning(response.ResponseMessage));
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: secondaryPackingConstants.ProductByQrScan_REQUEST, data };
  }
  function success(data) {
    return { type: secondaryPackingConstants.ProductByQrScan_SUCCESS, data };
  }
  function warning(data) {
    return {
      type: secondaryPackingConstants.ProductByQrScan_Warning,
      data,
    };
  }
  function failure(error) {
    return { type: secondaryPackingConstants.ProductByQrScan_FAILURE, error };
  }
}

function updateScannedList(updatedList) {
  return {
    type: secondaryPackingConstants.UPDATE_SCANNED_REQUEST,
    updatedList,
  };
}

function saveSecondaryPacking(data) {
  return (dispatch) => {
    dispatch(request(data));

    secondaryPackingService.saveSecondaryPacking(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(alertActions.success(response.ResponseMessage));
            //var scanItems = [];
            //updateScannedList(scanItems);
            dispatch(success(response.ResponseData));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return {
      type: secondaryPackingConstants.SAVE_SECONDARY_PACKING_RESUEST,
      data,
    };
  }
  function success(data) {
    return {
      type: secondaryPackingConstants.SAVE_SECONDARY_PACKING_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: secondaryPackingConstants.SAVE_SECONDARY_PACKING_FAILURE,
      error,
    };
  }
}

function getSecondaryPacking(tblobj) {
  return (dispatch) => {
    dispatch(request());
    secondaryPackingService.getSecondaryPacking(tblobj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: secondaryPackingConstants.GETALL_REQUEST };
  }
  function success(data) {
    return { type: secondaryPackingConstants.GETALL_SUCCESS, data };
  }
  function failure(error) {
    return { type: secondaryPackingConstants.GETALL_FAILURE, error };
  }
}

function loadingData(isbool) {
  return {
    type: secondaryPackingConstants.LOADING_REQUEST,
    isbool,
  };
}

function alreadyPacked(isbool) {
  return {
    type: secondaryPackingConstants.Alreadypacked,
    isbool,
  };
}

function warning(isbool) {
  return {
    type: secondaryPackingConstants.Warning,
    isbool,
  };
}

function removeItem(data) {
  return (dispatch) => {
    dispatch(success(data));
  };
  function success(data) {
    return {
      type: secondaryPackingConstants.REMOVE_ITEM,
      data,
    };
  }
}
