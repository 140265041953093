import React, { Component, Fragment } from "react";
import { DataTable } from "primereact/datatable";
import ReactExport from "react-export-excel";
//import DataTable from "react-data-table-component";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Button as MButton } from "@material-ui/core";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import MuiAlert from "@material-ui/lab/Alert";
import { Dialog } from "primereact/dialog";
import { ToastContainer, toast } from "react-toastify";
import { categoryActions, productMasterActions } from "../../actions";
//import { categoryActions } from "../../actions";
import { OrderBookingActions } from "../../actions";
import { roleMasterActions } from "../../actions";
import { TabView, TabPanel } from "primereact/tabview";
import Switch from "@material-ui/core/Switch";
import Moment from "react-moment";
import tableRequest from "../../models/tableRequest.js";
import { product } from "../../reducers/productReducer";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { removeData } from "jquery";
import MessageComponent from "../../templates/Message";
import Multiselect from "multiselect-react-dropdown";
import { Dropdown } from "primereact/dropdown";
import { Container, Row, Label } from "reactstrap";
import { CSVLink } from "react-csv";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import { BorderAll } from "@material-ui/icons";

var bgColors = {
  Default: "#81b71a",
  Blue: "#00B1E1",
  Cyan: "#37BC9B",
  Green: "#8CC152",
  Red: "#E9573F",
  Yellow: "#F6BB42",
};
class OrderBooking extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.tblResponse = new tableRequest();
    this.LocRef = React.createRef();
    this.setQty = this.setQty.bind(this);
    this.setQtyInCart = this.setQtyInCart.bind(this);
    this.getQty = this.getQty.bind(this);
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.getDropValueOfDealer = this.getDropValueOfDealer.bind(this);
    this.noOfProducts = this.noOfProducts.bind(this);
    this.ProductSelect = this.ProductSelect.bind(this);
    this.onSelectState = this.onSelectState.bind(this);
    this.onRemoveState = this.onRemoveState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.onHide = this.onHide.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.getProductStuff = this.getProductStuff.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.getProductList = this.getProductList.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.CreatedDtTemplate = this.CreatedDtTemplate.bind(this);
    this.UpdatedDtTemplate = this.UpdatedDtTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.increaseValue = this.increaseValue.bind(this);
    this.increaseValueInCart = this.increaseValueInCart.bind(this);
    this.decreaseValue = this.decreaseValue.bind(this);
    this.decreaseValueInCart = this.decreaseValueInCart.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.state = {
      cartButtonText: "ADD TO CART",
      icon: "fa fa-cart-plus",
      isAdded: false,
      showCart: false,
      searchValue: "",
      IsDetails: false,
      IsEdit: true,
      IsAdd: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
      confirm: false,
      buttonLabel: "Create Order",
      switchAction: true,
      displayConfirmation: false,
      formHeading: "Create Order",
      productDetails: [],
      UserId: 0,
      DealerId: 0,
      MRP: "",
      OrderBookingNo: 0,
      product: "",
      productId: "",
      categoryId: 0,
      ProductName: "",
      Qty: 1,
      SelectedProducts: [],
      Remark: "",
      isActive: true,
      CreatedDate: null,
      UpdatedDate: null,
      UserType: 0,
      productDetailsError: [{ product: "", Qty: "", MRP: "" }],
      pageSize: 20,
      formError: {
        OrderBookingNo: 0,
        productId: "",
        categoryId: "",
        ProductName: "",
        DealerId: "",
        //Qty: "",
        Remark: "",
        MRP: "",
        //product: "",
      },
    };
  }
  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    const userDetails = sessionStorage.getItem("user");
    const DealerId = sessionStorage.getItem("UserData");
    const userdetailsObject = JSON.parse(userDetails);
    this.setState({
      UserType: userdetailsObject.UserType,
      DealerId: userdetailsObject.UserType > 1 ? DealerId : 0,
      UserId: userdetailsObject.UserId,
      UserType: userdetailsObject.UserType,
    });
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        } else {
          this.setState({
            IsEdit: true,
          });
        }

        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }
    this.tblResponse.DealerId = userdetailsObject.UserType > 1 ? DealerId : 0;
    this.props.fetchApi(this.tblResponse);
    this.props.getCategoryDDL();
    this.props.getDealerDDL();
    //this.props.getProductDDL();
    this.props.getSearchData(this.state.searchValue, 0, this.state.pageSize);
    //this.props.getSearchDataByCatId("");
  }

  componentDidUpdate(prevProps) {
    if (this.props.bookinglist) {
      if (prevProps.bookinglist !== this.props.bookinglist) {
        // this.props.myProp has a different value
        if (this.props.bookinglist) {
          var e = this.props.bookinglist;
          this.setState({
            totalRecords: parseInt(e[0].TotalRows),
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  switchToggleConfirm = (e) => {
    this.props.inActiveRow(this.state.OrderBookingNo, this.state.switchAction);
    this.tblResponse.DealerId =
      this.state.UserType > 1 ? this.state.DealerId : 0;
    setTimeout(() => {
      this.props.fetchApi(this.tblResponse);
    }, 1000);
  };
  // handle click event of the Remove button
  handleRemoveClick = (id) => {
    var list = [...this.state.productDetails];

    list = list.filter((item) => item.product != id);
    //list.splice(index, 1);
    this.setState({ productDetails: list });
  };

  // handle click event of the Add button
  handleAddClick = (productData) => {
    this.setState({
      productDetails: [
        ...this.state.productDetails,
        {
          product: productData.value,
          Qty: 1,
          MRP: productData.MRP,
          HPCode: productData.HPCode,
          OEM: productData.OEM,
          ProductName: productData.ProductName,
        },
      ],
      productDetailsError: [
        ...this.state.productDetailsError,
        { product: "", Qty: "", MRP: "" },
      ],
    });
  };

  handleChange(event, i) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "IsActive":
        value = event.target.checked;
        break;
      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value }, () => {
      if (name == "categoryId" || name == "searchValue") {
        this.props.getSearchData(
          this.state.searchValue,
          parseInt(this.state.categoryId),
          this.state.pageSize
        );
      }
    });
  }

  // handle input change
  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let repeat = false;
    if (name == "product") {
      this.state.productDetails.filter((product) => {
        if (product.product == value) repeat = true;
      });
    }
    if (repeat) {
      this.refs.Messchild.showWarn(
        "This Product is already Selected in this order, Please increase the Quantity if you want to add more Pieces of it"
      );
      return;
    }
    const list = [...this.state.productDetails];
    list[index][name] = value;
    let mrp = "";
    let OEM = "";
    let HPCode = "";
    if (name == "product") {
      this.props.productData.filter((product) => {
        if (product.value == value) {
          mrp = product.MRP;
          OEM = product.OEM;
          HPCode = product.HPCode;
        }
      });
      list[index]["MRP"] = mrp;
      list[index]["OEM"] = OEM;
      list[index]["HPCode"] = HPCode;
      this.state.MRP = mrp;
    }
    this.setState({ productDetails: list });
  };

  validateProductDetails(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "DealerId":
        if (!value) {
          IsValid = false;
          errors.DealerId = "Please select dealer.";
        } else errors.DealerId = "";
        break;
      default:
        break;
    }
    return IsValid;
  }

  handleMultipleProducts(event, i) {
    this.handleChange(event, i);
    this.handleInputChange(event, i);
  }

  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  UpdatedDtTemplate(rowData) {
    if (rowData.UpdatedDt !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDt}</Moment>;
    } else {
    }
  }

  CreatedDtTemplate(rowData) {
    return <Moment format="D MMM YYYY">{rowData.CreatedDt}</Moment>;
  }

  getProductList(event) {
    this.setState({ ProductId: event.target.value });
    this.props.getProductDDL(event.target.value);
  }

  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "product":
        if (!value) {
          IsValid = false;
          errors.product = "Please select Product.";
        } else errors.product = "";
        break;
      case "DealerId":
        if (!value) {
          IsValid = false;
          errors.DealerId = "Please select dealer.";
        } else errors.DealerId = "";
        break;
      case "Qty":
        value = value === "" ? 1 : value;
        if (value >= 1) {
          if (parseInt(value) < 1) {
            IsValid = false;
            errors.Qty = "Qty must be greater than 0";
          } else if (!value.toString().match(/^[0-9]+$/.exec(value))) {
            IsValid = false;
            errors.Qty = "Please enter only number.";
          } else {
            errors.Qty = null;
          }
        } else {
          IsValid = false;
          errors.Qty = "Please Enter Qty";
        }
        break;
      default:
        break;
    }
    return IsValid;
  }

  getDropValueOfRole() {
    let roleName = "";
    this.props.UserDealerData.filter((x) => {
      if (x.value === this.state.DealerId.toString()) {
        roleName = x.label;
      }
    });
    return roleName;
  }

  submitseries(event) {
    event.preventDefault();
    this.props.onHideShoww(false);
  }
  getDropValueOfDealer(id) {
    let dealer = "";
    this.props.UserDealerData.filter((x) => {
      if (x.value === id.toString()) {
        dealer = x;
      }
    });
    this.setState({
      Dealer: dealer,
    });
    return dealer;
  }

  submitForm(event) {
    this.setState({ showConfirmDialog: false });
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });
    if (this.state.SelectedProducts.length == 0) {
      isfrmvalid = false;
      this.refs.Messchild.showWarn("Please Add Products to Cart");
    } else {
      isfrmvalid = this.state.SelectedProducts.some((i) => i.Qty != 0);
      var sfrmvalid = this.state.SelectedProducts.some((i) => i.Qty != NaN);
      // if (!isfrmvalid || sfrmvalid) {
      //   isfrmvalid = false
      //   this.refs.Messchild.showWarn("Please Enter Product(s) Quantity");
      //   return;
      // }
    }
    if (
      this.state.OrderBookingNo != 0 &&
      this.state.SelectedProducts.length == this.state.tmpDetails.length
    ) {
      var count = 0;
      for (var i = 0; i < this.state.SelectedProducts.length; i++) {
        if (
          this.state.SelectedProducts[i].Qty != this.state.tmpDetails[i].Qty
        ) {
          count++;
        }
      }
      if (count == 0) {
        isfrmvalid = false;
        this.refs.Messchild.showWarn("No changes in Order, can't update");
        return;
      }
    }
    if (isfrmvalid) {
      const formData = {
        OrderBookingNo: this.state.OrderBookingNo,
        DealerId: this.state.DealerId,
        Remark: this.state.Remark,
        productDetails: this.state.SelectedProducts,
        IsActive: this.state.isActive,
        CreatedDate:
          this.state.OrderBookingNo > 0
            ? this.state.CreatedDate
            : this.getCurrentDateMMDDYYY(),
        CreatedBy: this.state.UserId,
      };
      if (formData.OrderBookingNo == 0) {
        this.props.CreateOrder(formData);
        event.preventDefault();
        //console.log(formData);
      } else {
        this.props.CreateOrder(formData);
        event.preventDefault();
        //console.log(formData);
      }
      if (!this.props.showDialog) {
        this.resetForm();
      }
      setTimeout(() => {
        this.tblResponse.DealerId =
          this.state.UserType > 1 ? this.state.DealerId : 0;
        this.props.fetchApi(this.tblResponse);
      }, 500);
    }
  }
  getDropValueOfCategory() {
    let categoryName = "";
    this.props.CategoryData.filter((x) => {
      if (x.value === this.state.categoryId.toString()) {
        categoryName = x.label;
      }
    });

    return categoryName;
  }

  resetForm() {
    this.setState({
      cartButtonText: "ADD TO CART",
      icon: "fa fa-cart-plus",
      isAdded: false,
      showCart: false,
      searchValue: "",
      OrderBookingNo: "",
      IsDetails: false,
      SelectedProducts: [],
      categoryId: 0,
      ProductId: "",
      product: "",
      DealerId: this.state.UserType > 1 ? this.state.DealerId : "",
      Qty: 1,
      MRP: "",
      productDetails: [],
      Remark: "",
      isActive: true,
      pageSize: 20,
      formError: {
        ProductId: "",
        product: "",
        categoryId: "",
        Qty: "",
        MRP: "",
        productDetails: [],
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
    this.props.getSearchData(
      this.state.searchValue,
      this.state.categoryId,
      this.state.pageSize
    );
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.DealerId = this.state.DealerId;
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.tblResponse.DealerId =
        this.state.UserType > 1 ? this.state.DealerId : 0;
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.tblResponse.DealerId =
      this.state.UserType > 1 ? this.state.DealerId : 0;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.tblResponse.DealerId =
      this.state.UserType > 1 ? this.state.DealerId : 0;
    this.props.fetchApi(this.tblResponse);
  }

  statusBodyTemplate(rowData) {
    if (rowData) {
      return (
        <Checkbox
          disabled
          checked={rowData.IsActive}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      );
    }
  }

  actionBodyTemplate(rowData) {
    const editModeUpdate = (e) => {
      this.onHide();
      this.props.getCategoryDDL();
      this.setState({
        tmpDetails: rowData.tmpDetails != null ? rowData.tmpDetails : [],
        showCart: true,
        buttonLabel: "Update Order",
        formHeading: "Order Update",
        OrderBookingNo: rowData.OrderBookingNo,
        categoryId: 0,
        DealerId: rowData.DealerId.toString(),
        Remark: rowData.Remark,
        SelectedProducts:
          rowData.productDetails != null ? rowData.productDetails : [],
        IsActive: rowData.IsActive,
        CreatedDate: rowData.CreatedDate,
      });
    };

    const reorderMode = (e) => {
      this.onHide();
      this.props.getCategoryDDL();
      this.props.onHideShow(true);
      this.setState({
        showCart: true,
        buttonLabel: "Create Order",
        formHeading: "Reorder",
        OrderBookingNo: 0,
        categoryId: 0,
        DealerId: rowData.DealerId.toString(),
        Remark: rowData.Remark,
        SelectedProducts:
          rowData.productDetails != null ? rowData.productDetails : [],
        IsActive: rowData.IsActive,
        CreatedDate: rowData.CreatedDate,
      });
    };

    const ShowRow = (e) => {
      if (!e.target.className.toString().includes("slash")) {
        this.props.readRow(rowData.OrderBookingNo, false, this.tblResponse);
      } else {
        this.props.readRow(rowData.OrderBookingNo, true, this.tblResponse);
      }
    };

    const Download = (e) => {
      this.setState({
        fileName:
          rowData.OrderBookingNo + "_" + new Date().toString().split("GMT")[0],
      });
      this.props.download(rowData.Id, rowData.OrderBookingNo, this.tblResponse);
      setTimeout(() => {
        this.csvLink.current.element.click();
      }, 3000);
    };

    const switchHandleChange = (e) => {
      this.setState({
        switchAction: !rowData.IsActive,
        OrderBookingNo: rowData.OrderBookingNo,
      });

      this.props.onHideShowIsActive(true);
    };
    if (rowData) {
      return (
        <>
          {this.state.IsEdit === true ? (
            <div onClick={(event) => event.stopPropagation()}>
              <Button
                style={{ backgroundColor: bgColors.Green }}
                onClick={reorderMode}
              >
                Reorder
              </Button>{" "}
              &nbsp;
              <Button
                hidden={
                  rowData.IsShow && this.state.UserType == 1 ? false : true
                }
                icon="pi pi-download"
                className="addMore"
                title="Download Order Details"
                onClick={Download}
              />
              <ExcelFile
                filename={this.state.fileName}
                element={<Button hidden ref={this.csvLink} />}
              >
                <ExcelSheet
                  data={this.props.OrderDetail}
                  name={this.state.fileName}
                >
                  <ExcelColumn
                    label="Order Booking No"
                    value="OrderBookingNo"
                  />
                  <ExcelColumn label="Dealer Name" value="DealerName" />
                  <ExcelColumn label="Mobile No" value="MobileNo" />
                  <ExcelColumn label="Gst No." value="GstNo" />
                  <ExcelColumn label="Billing Address" value="Bill_Address" />
                  <ExcelColumn label="Shiping Address" value="Ship_Address" />
                  <ExcelColumn label="Product Name" value="ProductName" />
                  <ExcelColumn label="HP Code" value="HPCode" />
                  <ExcelColumn label="Part No." value="PartNo" />
                  <ExcelColumn label="Quantity" value="Qty" />
                  <ExcelColumn label="Order Date" value="OrderDate" />
                </ExcelSheet>
              </ExcelFile>
              <CSVLink
                data={this.props.OrderDetail}
                headers={this.state.headers}
                filename={this.state.fileName}
                target="_blank"
                //ref={this.csvLink}
              />
              &nbsp;
              <Button
                hidden={rowData.IsShow ? false : true}
                icon="pi pi-eye"
                className="addMore"
                title="Mark as Read"
                onClick={ShowRow}
              />
              <Button
                hidden={!rowData.IsShow ? false : true}
                className="slashes addMore"
                title="Mark as Unread"
                icon="pi pi-eye-slash"
                onClick={ShowRow}
              />{" "}
              &nbsp;
              <Button
                hidden={rowData.isEdit ? false : true}
                style={{ backgroundColor: bgColors.Green }}
                className="addMore"
                title="Edit Order"
                icon="pi pi-pencil"
                onClick={editModeUpdate}
              />
              {/* <Button icon="pi pi-eye" onClick={editMode} /> */}
              {/* <Switch
                checked={rowData.IsActive}
                color="primary"
                name={rowData.OrderBookingNo}
                onChange={switchHandleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              /> */}
            </div>
          ) : (
            <div onClick={(event) => event.stopPropagation()}>
              <Button
                style={{ backgroundColor: bgColors.Green }}
                onClick={editModeUpdate}
              >
                Reorder
              </Button>{" "}
              &nbsp;
              <Button
                hidden={rowData.IsShow ? false : true}
                icon="pi pi-eye"
                onClick={ShowRow}
              />
              <Button
                hidden={!rowData.IsShow ? false : true}
                icon="pi pi-eye-slash"
                onClick={ShowRow}
              />
              {/* <Button icon="pi pi-eye" onClick={editMode} /> */}
            </div>
          )}
        </>
      );
    }
  }

  getOrderNo(rowData) {
    if (rowData) {
      return (
        <>
          {rowData.IsShow ? (
            <span>
              <i className="fa fa-sign-in" style={{ color: "#2d4050" }}></i>
              {rowData.OrderBookingNo}
            </span>
          ) : (
            <span>
              <i className="fa fa-sign-in" style={{ color: "#9ba9b480" }}></i>
              {rowData.OrderBookingNo}
            </span>
          )}
        </>
      );
    }
  }

  noOfProducts(rowData) {
    //let no = JSON.parse(rowData.productDetails);
    //return no.length;
    if (rowData.productDetails != null) return rowData.productDetails.length;
    else return 0;
  }

  getStatus(rowData) {
    var value = rowData.Status;
    if (value == 0) {
      return <span className="span-status pending">Pending</span>;
    } else if (value == 1) {
      return (
        <span className="span-status p-dispatch">Partially Dispatched</span>
      );
    } else if (value == 2) {
      return <span className="span-status dispatch">Dispatched</span>;
    }
  }

  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }
  openDialog() {
    this.props.onHideShow(true);
    this.setState({
      IsDetails: false,
      buttonLabel: "Create Order",
      formHeading: "Create Order",
    });
    this.resetForm();
  }

  getProductStuff(e) {
    this.props.getSearchData(
      e.target.value,
      this.state.categoryId,
      this.state.pageSize
    );
  }

  onSelectState(selectedList, selectedItem) {
    var repeat = this.state.productDetails.some(
      (i) => i.product == selectedItem.value
    );
    if (repeat) {
      this.refs.Messchild.showWarn(
        "This Product is already Selected in this order, Please increase the Quantity if you want to add more Pieces of it"
      );
      return;
    }
    this.ProductSelect(selectedItem);
    this.handleAddClick(selectedItem);
  }

  onRemoveState(selectedList, removedItem) {
    this.handleRemoveClick(removedItem.value);
    this.setState({
      SelectedProducts: this.state.SelectedProducts.filter(
        (item) => item.value != removedItem.value
      ),
    });
  }

  ProductSelect(id) {
    this.setState({
      SelectedProducts: [...this.state.SelectedProducts, id],
    });
  }

  increaseValue(i) {
    if (
      this.state.SelectedProducts.some(
        (row) => row.ProductId === this.props.SearchData[i].ProductId
      )
    ) {
    } else {
      var pushEle = this.props.SearchData[i];
      pushEle.Qty = 0;
      this.state.SelectedProducts.push(pushEle);
    }
    var index = this.state.SelectedProducts.findIndex(
      (x) => x.ProductId === this.props.SearchData[i].ProductId
    );
    var value = parseInt(this.state.SelectedProducts[index].Qty);
    value = isNaN(value) ? 0 : value;
    value++;
    const list = [...this.state.SelectedProducts];
    list[index]["Qty"] = parseInt(value);
    this.setState({
      SelectedProducts: list,
    });
  }

  increaseValueInCart(i) {
    var value = parseInt(this.state.SelectedProducts[i].Qty);
    value = isNaN(value) ? 0 : value;
    value++;
    const list = [...this.state.SelectedProducts];
    list[i]["Qty"] = parseInt(value);
    this.setState({
      SelectedProducts: list,
    });
  }

  decreaseValue(i) {
    if (
      this.state.SelectedProducts.some(
        (row) => row.ProductId === this.props.SearchData[i].ProductId
      )
    ) {
      var index = this.state.SelectedProducts.findIndex(
        (x) => x.ProductId === this.props.SearchData[i].ProductId
      );
      var value = parseInt(this.state.SelectedProducts[index].Qty);
      value = isNaN(value) ? 0 : value;
      value--;
      if (value <= 0) {
        this.state.SelectedProducts.splice(index, 1);
      } else {
        this.state.SelectedProducts[index]["Qty"] = parseInt(value);
      }
      const list = [...this.state.SelectedProducts];
      this.setState({
        SelectedProducts: list,
      });
    }
  }

  decreaseValueInCart(i) {
    if (this.state.SelectedProducts[i].Qty > 1) {
      var value = parseInt(this.state.SelectedProducts[i].Qty);
      value = isNaN(value) ? 0 : value;
      value--;
      const list = [...this.state.SelectedProducts];
      list[i]["Qty"] = parseInt(value);
      this.setState({
        SelectedProducts: list,
      });
    }
  }

  setQtyInCart(e, i) {
    //if (e.target.value != "") {
    const list = [...this.state.SelectedProducts];
    list[i]["Qty"] = parseInt(e.target.value);
    this.setState({
      SelectedProducts: list,
    });
    // } else {
    //   this.refs.Messchild.showWarn("Enter Quantity Greater than 0");
    // }
  }

  setQty(e, i) {
    //if (e.target.value != "") {
    if (
      this.state.SelectedProducts.some(
        (row) => row.ProductId === this.props.SearchData[i].ProductId
      )
    ) {
    } else {
      this.state.SelectedProducts.push(this.props.SearchData[i]);
    }
    var index = this.state.SelectedProducts.findIndex(
      (x) => x.ProductId === this.props.SearchData[i].ProductId
    );
    const list = [...this.state.SelectedProducts];
    list[index]["Qty"] = parseInt(e.target.value);
    this.setState({
      SelectedProducts: list,
    });
    // } else {
    //   this.refs.Messchild.showWarn("Enter Quantity Greater than 0");
    // }
  }

  getQty(i) {
    if (
      this.state.SelectedProducts.some(
        (row) => row.ProductId === this.props.SearchData[i].ProductId
      )
    ) {
      var index = this.state.SelectedProducts.findIndex(
        (x) => x.ProductId === this.props.SearchData[i].ProductId
      );
      return this.state.SelectedProducts[index].Qty;
    } else {
      return 0;
    }
  }

  addToCart() {
    this.setState({
      isAdded: true,
      cartButtonText: "ADDED",
    });
    var added = this.state.isAdded;
    const button = document.querySelector(".addtocart");
    button.style.background = "#fdbe33";
  }

  removeProduct(e, i) {
    this.state.SelectedProducts.splice(i, 1);
    const list = [...this.state.SelectedProducts];
    this.setState({
      SelectedProducts: list,
    });
  }

  render() {
    const renderFooter = (name) => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={this.switchToggleConfirm}
            autoFocus
          />
        </div>
      );
    };

    const viewDetails = (e) => {
      var rowData = e.data;
      this.tblResponse.DealerId =
        this.state.UserType > 1 ? this.state.DealerId : 0;
      //this.props.readRow(rowData.OrderBookingNo, false, this.tblResponse);
      this.setState({
        showCart: true,
        IsDetails: true,
        buttonLabel: "",
        formHeading: "Order Details",
        OrderBookingNo: rowData.OrderBookingNo,
        categoryId: 0,
        DealerId: rowData.DealerId.toString(),
        Remark: rowData.Remark,
        SelectedProducts:
          rowData.productDetails != null ? rowData.productDetails : [],
        IsActive: rowData.IsActive,
        CreatedDate: rowData.CreatedDate,
      });
    };

    const renderOrderFooter = (name) => {
      return (
        <div>
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={() => this.setState({ showConfirmDialog: false })}
          />

          <Button
            className="p-button-text"
            label="No"
            icon="pi pi-times"
            onClick={this.submitForm}
            autoFocus
          />
        </div>
      );
    };

    const rowClass = (data) => {
      var colortag;
      if (data.IsShow) colortag = createStyle("#9ba9b480");
      //createStyle("#2d4050");
      return { [colortag]: data.IsShow };
    };

    function createStyle(color) {
      var style = document.getElementsByTagName("style");
      var colortag = color.replace("#", "mycolor");
      //Assuming there is a style section in the head
      var pos = style[0].innerHTML.indexOf(colortag);
      if (pos < 0) {
        if (style[0]) {
          style[0].innerHTML +=
            "." +
            colortag +
            `
          {
            background-color: ${color}!important;
            font-weight: bold;
          }
      `;
        }
      }
      return colortag;
    }

    const header = (
      <div className="table-header row">
        <div className="col-md-6">
          <h4>Order Booking</h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>
          <Button
            className="mr-2"
            icon="pi pi-plus"
            label="Create Order"
            onClick={this.openDialog}
          />
          {/* {this.state.IsAdd === true ? (
            <Button
              label="Create Order"
              icon="pi pi-plus-circle"
              onClick={this.openDialog}
            />
          ) : (
            <Button
              label="Create Order"
              icon="pi pi-plus-circle"
              style={{ display: "none" }}
              onClick={this.openDialog}
            />
          )} */}
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />

        <div className="card">
          <MessageComponent ref="Messchild" />
          <DataTable
            value={this.props.bookinglist}
            scrollable
            onRowClick={viewDetails}
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            rowClassName={rowClass}
            className="dataTables_wrapper"
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column
              body={this.getOrderNo}
              header="Order Number"
              style={{ width: "25%", marginLeft: "-20px" }}
              className="text-center"
              sortable
            ></Column>
            <Column
              field="CreatedDate"
              className="text-center"
              style={{ width: "13%" }}
              header="Order Date"
              sortable
            ></Column>
            <Column
              field="DealerName"
              style={{ width: "17%" }}
              className="text-center"
              header="Dealer Name"
              sortable
            ></Column>
            <Column
              body={this.getStatus}
              className="text-center"
              style={{ width: "20%" }}
              header="Dispatch Status"
              sortable
            ></Column>
            {this.state.IsEdit === true ? (
              <Column
                field="Action"
                onClick={(event) => event.stopPropagation()}
                header="Action"
                style={{ width: "25%" }}
                body={this.actionBodyTemplate}
              ></Column>
            ) : (
              <Column
                field="Action"
                onClick={(event) => event.stopPropagation()}
                header="Action"
                style={{ width: "25%", display: "none" }}
              ></Column>
            )}
          </DataTable>
        </div>

        {/* CREATE ORDER DIALOG FORM STARTS */}

        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.props.showDialog}
          className="popup3"
          onHide={this.onHide}
          style={{ overflow: "hidden" }}
        >
          <div className="orderbooking">
            <div>
              <div
                className="row"
                style={{
                  marginRight: "2px",
                  marginBottom: "5px",
                  justifyContent: "right",
                }}
              >
                <Button
                  className="mr-2"
                  icon="pi pi-shopping-cart"
                  label="View Cart"
                  onClick={() => this.setState({ showCart: true })}
                />
              </div>

              <div className="row">
                {/* SELECT DEALER POPUP STARTS */}

                <div className="col-md-6 pt-3">
                  <FormControl
                    variant="outlined"
                    disabled={this.state.IsDetails}
                    size="small"
                    className="formControl col-md-12"
                  >
                    <Dropdown
                      disabled={
                        this.state.UserType > 1 || this.state.IsDetails
                          ? true
                          : false
                      }
                      filter
                      value={
                        this.state.DealerId == 0
                          ? this.state.Dealer
                          : this.state.DealerId
                      }
                      options={this.props.UserDealerData}
                      optionLabel="label"
                      optionValue="value"
                      onChange={this.handleChange}
                      name="DealerId"
                      className="form-control custom-select"
                      placeholder="Select Dealer*"
                    ></Dropdown>
                  </FormControl>
                  {this.state.formError.DealerId !== "" ? (
                    <div className="error">{this.state.formError.DealerId}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-3">
                  <TextField
                    disabled={this.state.IsDetails}
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="Remark"
                    value={this.state.Remark}
                    className="fullwidth"
                    label="Remarks"
                  />
                  {this.state.formError.Remark !== "" ? (
                    <div className="error">{this.state.formError.Remark}</div>
                  ) : (
                    ""
                  )}
                </div>

                {/* Product Catgery */}

                <div className="col-md-6 pt-3">
                  <FormControl
                    variant="outlined"
                    size="small"
                    className="formControl col-md-12"
                  >
                    <Label>
                      <b>Category:</b>
                    </Label>
                    <Dropdown
                      onChange={this.handleChange}
                      name="categoryId"
                      options={this.props.CategoryData}
                      optionLabel="label"
                      optionValue="value"
                      value={this.state.categoryId.toString()}
                      filter
                      className="form-control custom-select"
                      placeholder="Select Category*"
                    ></Dropdown>
                  </FormControl>
                </div>

                {/* IS ACTIVE TAG */}

                <div className="col-md-6 pt-5" hidden>
                  <Checkbox
                    disabled
                    checked={this.state.isActive}
                    onClick={this.handleChange}
                    color="primary"
                    name="isActive"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <label>Is Active</label>
                </div>
                <div className="col-md-6 pt-5">
                  <TextField
                    disable={this.state.IsDetails}
                    onChange={this.handleChange}
                    //onChange={this.getProductStuff}
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    className="fullwidth"
                    label="Search Product/OEM/HP Code"
                    name="searchValue"
                    value={this.state.searchValue}
                  />
                </div>
              </div>
            </div>
            <div className="bordere text-center">
              <fieldset>
                {/* <div className="row"> */}
                <div
                  style={{ height: "400px", overflowY: "auto", padding: "0" }}
                >
                  <div className="col-lg-12">
                    <Row xl={5} sm={1} className="rowD m-5">
                      {this.props.SearchData.map((x, i) => {
                        return (
                          <div>
                            <div>
                              <div
                                className="hover14"
                                style={{ marginTop: "-10px" }}
                              >
                                <div className="agile_top_brand_left_grid">
                                  <div className="agile_top_brand_left_grid1">
                                    <figure>
                                      <div className="snipcart-item block1">
                                        <div className="snipcart-thumb">
                                          <img
                                            height="120px"
                                            width="120px"
                                            src={x.ProductImage}
                                          />
                                          <h4>{x.ProductName}</h4>
                                          <hr></hr>
                                          {x.MRP > 0 ? <h5>₹{x.MRP}</h5> : ""}
                                          <h5>{x.OEM}</h5>
                                          <h5>{x.HPCode}</h5>
                                          <div className="text-center">
                                            <form
                                              onSubmit={(event) =>
                                                event.preventDefault()
                                              }
                                            >
                                              <div
                                                className="value-button"
                                                id="decrease"
                                                onClick={() =>
                                                  this.decreaseValue(i)
                                                }
                                                value="Decrease Value"
                                              >
                                                -
                                              </div>
                                              <input
                                                type="number"
                                                id="number"
                                                disabled={this.state.IsDetails}
                                                name="Qty"
                                                min="0"
                                                maxlength="5"
                                                onChange={(event) =>
                                                  this.setQty(event, i)
                                                }
                                                value={this.getQty(i)}
                                              />
                                              <div
                                                className="value-button"
                                                id="increase"
                                                onClick={() =>
                                                  this.increaseValue(i)
                                                }
                                                value="Increase Value"
                                              >
                                                +
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </figure>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Row>

                    <MButton
                      hidden={
                        this.props.SearchData &&
                        this.props.SearchData.length > 0
                          ? this.props.SearchData[0].TotalProducts >=
                            this.state.pageSize
                            ? false
                            : true
                          : true
                      }
                      onClick={() => {
                        this.props.getSearchData(
                          this.state.searchValue,
                          this.state.categoryId,
                          this.state.pageSize + 20
                        );
                        this.setState({
                          pageSize: this.state.pageSize + 20,
                        });
                      }}
                    >
                      + Load More...
                    </MButton>

                    {/* filterBy  Product cat id */}

                    {/* <div
                      hidden={this.state.IsDetails}
                      className="col-md-12 mt-5 mb-3 text-center"
                    >
                      {/* <div className="mb-2">
                      Please Click Below Button To Submit*
                    </div> */}
                    {/* {this.state.IsEdit === true ? (
                        // <Button
                        //   onClick={(e) => this.submitForm(e)}
                        //   label={this.state.buttonLabel}
                        //   icon="pi pi-check"
                        // />

                        <Button
                          onClick={() => this.setState({ showCart: true })}
                          //onClick={(e) => { if (window.confirm('Are you sure want to confirm this order?')) { this.submitForm(e) }; }}

                          label={"Go to Cart"}
                          icon="pi pi-check"
                        />
                      ) : (
                        <Button
                          label={this.state.buttonLabel}
                          icon="pi pi-check"
                          style={{ display: "none" }}
                        />
                      )}
                    </div> */}

                    <div
                      hidden={
                        this.props.SearchData &&
                        this.props.SearchData.length > 0
                          ? this.props.SearchData[0].TotalProducts >=
                            this.state.pageSize
                            ? false
                            : true
                          : true
                      }
                      className="col-md-12"
                    >
                      Click 'Load More' to view additional products (*).
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </Dialog>

        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.state.showCart}
          className="popup3"
          onHide={() => this.setState({ showCart: false })}
        >
          <div style={{ height: "100vh" }}>
            <div
              className="row"
              style={{
                marginRight: "2px",
                marginBottom: "5px",
                justifyContent: "right",
              }}
            >
              <Button
                className="mr-2"
                icon="pi pi-plus"
                label="Add More Products"
                hidden={this.state.IsDetails}
                onClick={(e) => {
                  if (this.state.formHeading == ("Order Update" || "Reorder")) {
                    this.props.onHideShow(true);
                  }
                  this.setState({ showCart: false });
                }}
              />
            </div>
            <div className="bordere text-center">
              <fieldset>
                <div className="row">
                  <div className="col-lg-12">
                    {/* <div className="col-lg-12">
                      <TextField
                        disable={this.state.IsDetails}
                        onChange={this.handleChange}
                        id="standard-basic"
                        size="small"
                        variant="outlined"
                        className="fullwidth"
                        label="Search Product/OEM/HP Code"
                        name="searchValue"
                        value={this.state.searchValue}
                      />
                    </div> */}

                    <Row xl={5} sm={1} className="rowD m-5">
                      {this.state.SelectedProducts.map((x, i) => {
                        return (
                          <div>
                            <div>
                              <div className="hover14">
                                <div className="agile_top_brand_left_grid">
                                  <div className="agile_top_brand_left_grid1">
                                    <figure>
                                      <div className="snipcart-item block1">
                                        <button
                                          hidden={this.state.IsDetails}
                                          type="button"
                                          style={{ float: "right" }}
                                          onClick={(e) =>
                                            this.removeProduct(e, i)
                                          }
                                          className="p-dialog-header-icon p-dialog-header-close p-link"
                                          aria-label="Close"
                                        >
                                          <span className="p-dialog-header-close-icon pi pi-times"></span>
                                        </button>
                                        <div className="snipcart-thumb">
                                          <img
                                            height="120px"
                                            width="120px"
                                            src={x.ProductImage}
                                          />
                                          <h4>{x.ProductName}</h4>
                                          <hr></hr>
                                          <h5>₹{x.MRP}</h5>
                                          <h5>{x.OEM}</h5>
                                          <h5>{x.HPCode}</h5>
                                          <div className="text-center">
                                            <form
                                              onSubmit={(event) =>
                                                event.preventDefault()
                                              }
                                            >
                                              <div
                                                className="value-button"
                                                id="decrease"
                                                style={
                                                  this.state.IsDetails
                                                    ? { pointerEvents: "none" }
                                                    : {}
                                                }
                                                onClick={() =>
                                                  this.decreaseValueInCart(i)
                                                }
                                                value="Decrease Value"
                                              >
                                                -
                                              </div>
                                              <input
                                                type="number"
                                                id="number"
                                                disabled={this.state.IsDetails}
                                                name="Qty"
                                                min="0"
                                                maxlength="5"
                                                onChange={(event) =>
                                                  this.setQtyInCart(event, i)
                                                }
                                                value={x.Qty}
                                                //value={this.state.productDetails[i].Qty}
                                              />
                                              <div
                                                className="value-button"
                                                id="increase"
                                                style={
                                                  this.state.IsDetails
                                                    ? { pointerEvents: "none" }
                                                    : {}
                                                }
                                                onClick={() =>
                                                  this.increaseValueInCart(i)
                                                }
                                                value="Increase Value"
                                              >
                                                +
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </figure>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Row>

                    {/* filterBy  Product cat id */}

                    <div
                      hidden={this.state.IsDetails}
                      className="col-md-12 mt-5 mb-3 text-center"
                    >
                      {this.state.IsEdit === true ? (
                        <Button
                          onClick={() =>
                            this.setState({ showConfirmDialog: true })
                          }
                          //onClick={(e) => { if (window.confirm('Are you sure want to confirm this order?')) { this.submitForm(e) }; }}
                          disabled={
                            this.state.SelectedProducts.length == 0
                              ? true
                              : false
                          }
                          label={this.state.buttonLabel}
                          icon="pi pi-check"
                        />
                      ) : (
                        <Button
                          label={this.state.buttonLabel}
                          icon="pi pi-check"
                          style={{ display: "none" }}
                        />
                      )}
                    </div>

                    <div hidden={this.state.IsDetails} className="col-md-12">
                      Note: Fields marked as (*) are required
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </Dialog>

        <Dialog
          header="Confirmation"
          visible={this.props.showIsActiveDialog}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog>
        {/* confirmmation dialog */}
        <Dialog
          header="Order Confirmation"
          visible={this.state.showConfirmDialog}
          modal
          style={{ width: "350px" }}
          footer={renderOrderFooter("displayConfirmation")}
          onHide={() => this.setState({ showConfirmDialog: false })}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />

            <span>
              You Want To Add More Item ?
              {/* {this.state.buttonLabel == true ? "Active" : "InActive"}? */}
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bookinglist: state.OrderBooking.items,
    productData: state.product.prdctddl,
    UserDealerData: state.OrderBooking.dealerddl,
    Mappseries: state.product.Mappseries,
    showDialog: state.product.showDialog,
    showDialog: state.role.showDialog,
    loading: state.product.loding,
    CategoryData: state.category.categoryddl,
    showIsActiveDialog: state.OrderBooking.showIsActiveDialog,
    SearchData:
      state.OrderBooking.searchData == (undefined || null)
        ? []
        : state.OrderBooking.searchData,
    orderProducts:
      state.OrderBooking.orderProducts == (undefined || null)
        ? []
        : state.OrderBooking.orderProducts,
    OrderDetail:
      state.OrderBooking.OrderDetail == (undefined || null)
        ? []
        : state.OrderBooking.OrderDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (data) => {
      dispatch(OrderBookingActions.getAll(data));
    },
    getProductDDL: () => {
      dispatch(productMasterActions.getPrdctDDL());
    },
    CreateOrder: (OrderBookingNo) =>
      dispatch(OrderBookingActions.CreateOrder(OrderBookingNo)),
    updateOrderBooking: (OrderBookingNo) =>
      dispatch(OrderBookingActions.updateOrderBooking(OrderBookingNo)),
    inActiveRow: (OrderBookingNo, isActive) =>
      dispatch(OrderBookingActions.inActiveRow(OrderBookingNo, isActive)),
    readRow: (OrderBookingNo, IsShow, tblResponse) =>
      dispatch(
        OrderBookingActions.ReadRow(OrderBookingNo, IsShow, tblResponse)
      ),
    download: (Id, OBN, tblResponse) =>
      dispatch(OrderBookingActions.Download(Id, OBN, tblResponse)),
    onHideShow: (isbool) => dispatch(OrderBookingActions.onHideShow(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(OrderBookingActions.onHideShowIsActive(isbool)),
    getDealerDDL: () => dispatch(OrderBookingActions.getDealerDDL()),
    getSearchData: (searchval, cateId, pageSize) =>
      dispatch(OrderBookingActions.GetSearchData(searchval, cateId, pageSize)),
    getProductsData: (ids) =>
      dispatch(OrderBookingActions.GetProductsData(ids)),
    loadingData: (isLoding) =>
      dispatch(OrderBookingActions.loadingData(isLoding)),
    getCategoryDDL: (data) => dispatch(categoryActions.getCategoryDDL(data, 1)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderBooking);
