import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className="row footer">
        <div className="col-md-8">
          <p style={{ marginBottom: "0px" }} className="Disclaimer">
            <b>Disclaimer: </b>
            O.E Part numbers are just reference purpose only and we do not imply
            that these parts are the products of any OEM
          </p>
        </div>
        <div className="col-md-4">
          <p style={{ marginBottom: "0px", textAlign: "end" }}>
            Copyright 2023 @ Hytech Earth Moving Engineers. | All Rights
            Reserved
          </p>
        </div>
        {/* <div className="col-md-6 text-right designedby">Design & Developed by <a target="_blank" href="https://www.holostik.com/">  Holostik India Limited</a></div> */}
      </div>
    );
  }
}
