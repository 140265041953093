import { productConfigurationService } from '../services'
import { productConfigurationConstants } from '../constants';
import { alertActions } from '.';

export const productConfigActions = {
    getAll,
    updateproductConfiguration,
    getconfigDDL,
    onHideShow,
    onreset
};

function getAll(productId) {

    return dispatch => {
        dispatch(request());

        productConfigurationService.fetchProductConfigData(productId).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: productConfigurationConstants.GETALL_REQUEST } }
    function success(datas) { return { type: productConfigurationConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: productConfigurationConstants.GETALL_FAILURE, error } }
}

function updateproductConfiguration(data) {
    return dispatch => {
        dispatch(request(data));

        productConfigurationService.updateProductConfig(data).then(
            response => {                
                //history.push('/');     

                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(data));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: productConfigurationConstants.UPDATE_REQUEST, data } }
    function success(data) { return { type: productConfigurationConstants.UPDATE_SUCCESS, data } }
    function failure(error) { return { type: productConfigurationConstants.UPDATE_FAILURE, error } }
};

function getconfigDDL(productid) {
    return dispatch => {
        dispatch(request());

        productConfigurationService.sp_getconfigDDL(productid).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };;

    function request() { return { type: productConfigurationConstants.GETCONFIGDDL_REQUEST } }
    function success(datas) { return { type: productConfigurationConstants.GETCONFIGDDL_SUCCESS, datas } }
    function failure(error) { return { type: productConfigurationConstants.GETCONFIGDDL_FAILURE, error } }
};

function onHideShow(isbool) {
    return {
        type: productConfigurationConstants.DIALOG_REQUEST, isbool
    }
}

function onreset(isbool) {
    return {
        type: productConfigurationConstants.RESET_REQUEST, isbool
    }
}

