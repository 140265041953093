import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import TextField from '@material-ui/core/TextField';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from '@material-ui/core';
import { Calendar } from 'primereact/calendar';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import { Toast } from 'primereact/toast';
import { CartonActions } from '../../actions/CartonActions';
import { productMasterActions } from '../../actions';
import { roleMasterActions } from '../../actions';
import tableRequest from '../../models/tableRequest';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel } from '@material-ui/core';
// import { userTypeMasterActions } from '../../actions';
import Switch from '@material-ui/core/Switch';
import $ from 'jquery';

class cartonMaster extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        //this.actionTemplate = this.actionTemplate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //this.submitForm = this.submitForm.bind(this);
        this.hideFunc = this.hideFunc.bind(this);
        //this.onPage = this.onPage.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.onSort = this.onSort.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
        //this.openDialog = this.openDialog.bind(this);
        this.formReset = this.formReset.bind(this);
        //this.onHidee = this.onHidee.bind(this);
        //this.openDialog = this.openDialog.bind(this);
        //this.getProductList = this.getProductList.bind(this);
        //this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
        //this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this)
        //this.getRoleList = this.getRoleList.bind(this);
        //this.getRoleDDL = this.getRoleDDL.bind(this);

        //this.onRowSelect = this.onRowSelect.bind(this);
        //this.submitseries = this.submitseries.bind(this);

        this.state = {
            visible: false,
            CartonId: '',
            updateLoader: false,
            sortIcon: false,
            CartonName: '',
            CartonSize: '',
            Description: '',
            IsActive: false,
            sortOrder: '',
            formError: {
                CartonName: '',
                CartonSize: '',
                Description: '',

            },

            formHeading: 'Add Carton',
            buttonLabel: 'Submit',
            sales: [],
            loading: true,
            first: 0,
            rows: 5,
            totalRecords: 0,
            globalFilter: null,
            emptyMessage: 'No Data found',

        }

    }
    //trigger api function on page call
    componentDidMount() {
        const userPages = sessionStorage.getItem('assignedPermissionsForUser')
        if (userPages) {
            const assignedPermissions = JSON.parse(userPages);
            const Curenturl = window.location.pathname;
            const nNumber = Curenturl.lastIndexOf('/');
            const pageName = Curenturl.substring(nNumber + 1);

            const obj = assignedPermissions.assigned.filter((x) => {
                return x.ActionName === pageName
            });

            if (obj !== null && obj.length > 0) {
                if (!obj[0].IsEdit) {
                    this.setState({
                        IsEdit: false
                    });
                } else {
                    this.setState({
                        IsEdit: true
                    });
                }

                if (!obj[0].IsAdd) {
                    this.setState({
                        IsAdd: false
                    });
                } else {
                    this.setState({
                        IsAdd: true
                    });
                }
            }
        }
        this.props.fetchApi(this.tblResponse);
    }

    componentDidUpdate(prevProps) {
        if (this.props.CartonData) {
            if (prevProps.CartonData !== this.props.CartonData) {
                // this.props.myProp has a different value
                if (this.props.CartonData) {
                    var e = this.props.CartonData;
                    this.setState({
                        totalRecords: e[0].TotalRows
                    });
                }
            }
        } else {
            if (this.state.totalRecords !== 0)
                this.setState({
                    totalRecords: 0,
                    first: 0
                });
        }

        //if (this.props.Mappseriesdata) {
        //    if (prevProps.Mappseriesdata !== this.props.Mappseriesdata) {
        //        // this.props.myProp has a different value
        //        if (this.props.Mappseriesdata) {
        //            var e = this.props.Mappseriesdata;
        //            this.setState({
        //                totalRecords1: e[0].this.props.TotalRows1
        //            });
        //        }
        //    }
        //} else {
        //    if (this.state.totalRecords1 !== 0)
        //        this.setState({
        //            totalRecords1: 0,
        //            first1: 0
        //        });
        //}
    }
    switchToggleConfirm = (e) => {
        this.setState({isActive : this.state.switchAction })
        this.submitForm(e)
    }

    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        switch (name) {
            case 'IsActive':
                value = event.target.checked;
                break;
            default:
                break;
        }
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }


    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    onPage(event) {
        this.props.loadingData(true);

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }


    //to search
    searchFilter(e) {
        this.props.loadingData(true);
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
    }

    //to sort
    onSort(event) {
        this.props.loadingData(true);
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDate" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
    }

    getCurrentDateMMDDYYY() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }

    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        //this switch case is used for each form field to validate
        switch (name) {
            case 'CartonName':
                if (value.length < 1) {
                    IsValid = false;
                    errors.CartonName = "Please enter your carton name.";
                } else errors.CartonName = ''
                break;
            case 'CartonSize':
                if (value < 1) {
                    IsValid = false;
                    errors.CartonSize = "Please enter your carton size quantity.";
                }
                else errors.CartonSize = ''
                break;
            case 'Description':
                if (value.length < 1) {
                    IsValid = false;
                    errors.Description = "Please enter your Description.";
                }
                else errors.Description = ''
                break;

            default:
                break;
        }

        return IsValid;
    }

    // reset form text value
    formReset() {
        this.setState({
            CartonId: 0,
            CartonName: '',
            CartonSize: '',
            Description: '',
            IsActive: true

        });
        let errors = this.state.formError;
        Object.entries(errors).map(([key, value]) => {
            errors[key] = '';
        });
    }

    // add text
    hideFunc() {
        this.setState({
            visible: false, buttonLabel: 'Submit',
            formHeading: 'Add Carton'
        })
        this.formReset();
    }

    // submit form 
    submitForm(event) {
        event.preventDefault();
        let isfrmvalid = true;
        let checkerror = this.state.formError;
        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);

            //when form validation is not valid
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
        });
        let checkbox = document.getElementsByName('IsActive');
        //when isfrmvalid is true
        if (isfrmvalid) {
            this.setState({ updateLoader: false });
            const formData = {
                CartonId: this.state.CartonId,
                CartonName: this.state.CartonName,
                CartonSize: this.state.CartonSize,
                Description: this.state.Description,
                IsActive: this.state.IsActive,
                CreatedDate: this.state.CartonId > 0 ? this.state.CreatedDate : this.getCurrentDateMMDDYYY(),
                UpdatedDate: this.state.CartonId > 0 ? this.getCurrentDateMMDDYYY() : null,
                TotalRows: this.state.totalRecords
            }

            if (formData.CartonId > 0) {
                this.props.updateCarton(formData);
                event.preventDefault();
            }
            else {
                this.props.createCarton(formData);
                event.preventDefault();
            }
            setTimeout(() => {
                this.props.fetchApi(this.tblResponse);
            }, 500);
            
            if (this.props.showDialog!=undefined &&  !this.props.showDialog) {
                this.formReset();
            }
            this.props.onHideShowIsActive(false);
        }
    }


    //return checkbox disabled
    activeTemplate(rowData, column) {
        return (
            <input type="checkbox" disabled checked={rowData.IsActive} />
        )
    }


    // Sorting Data
    onSort(event) {
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.sortField == "CreatedDate" ? "CreatedDate" : event.sortField;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.GetCarton();
        // const clickData = document.getElementsByClassName("p-sortable-column-icon pi pi-fw pi-sort");        
        // ////console.log(event);
    }

    onHide() {        
        this.props.onHideShow(false);
        this.formReset();
    }
    openDialog() {
        this.props.onHideShow(true);
        this.setState({ buttonLabel: 'Submit', formHeading: 'Add Carton' })
    }
    //onHidee() {
    //    this.props.onHideShoww(false);
    //}
    //
    //openDialogue() {
    //    this.props.onHideShoww(true);
    //    this.setState({ buttonLabel: 'Submit', formHeadingg: 'Available Series' })
    //    this.props.GetAvailableseries(this.tblResponse);
    //
    //}
    //onRowSelect(event) {
    //    //this.toast.show({ severity: 'info', summary: 'Series Selected', detail: `FromNo: ${event.data.FromNo}`, detail: `ToNo: ${event.data.ToNo}`, life: 3000 });
    //    this.setState({
    //        FromNo: event.data.FromNo,
    //        ToNo: event.data.ToNo,
    //        Qty: (event.data.ToNo - event.data.FromNo) + 1,
    //    })
    //
    //    this.onHidee();      
    //
    //}
    statusBodyTemplate(rowData) {

        if (rowData) {
            return (
                <Checkbox
                    disabled
                    checked={rowData.IsActive}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            )
        }
    }
    getCurrentDateMMDDYYY() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
    actionBodyTemplate(rowData) {        
        const editMode = (e) => {
            this.props.onHideShow(true);
            this.setState({
                buttonLabel: 'Update',
                formHeading: 'Update Carton',
                CartonId: rowData.CartonId,
                CartonName: rowData.CartonName,
                CartonSize: rowData.CartonSize,
                Description: rowData.Description,
                CartonCategory: rowData.CartonCategory,
                //UserTypeId: rowData.UserTypeId,
                //RoleId: rowData.RoleId,
                product: rowData.product,
                Qty: rowData.Qty,
                //StartDate: new Date(rowData.StartDate),
                //EndDate: new Date(rowData.EndDate),
                //FromNo: rowData.FromNo,
                //ToNo: rowData.ToNo,
                Status: rowData.Status,
                IsActive: rowData.IsActive,
                //loyalitypnt: rowData.loyalitypnt,
                CreatedDate: rowData.CreatedDate
            });
        }
        const switchHandleChange = (e) => {
            this.setState({
                CartonId: rowData.CartonId,
                CartonName: rowData.CartonName,
                CartonSize: rowData.CartonSize,
                Description: rowData.Description,
                CartonCategory: rowData.CartonCategory,
                product: rowData.product,
                Qty: rowData.Qty,
                Status: rowData.Status,
                IsActive: !rowData.IsActive,
                CreatedDate: rowData.CreatedDate
            });
            this.props.onHideShowIsActive(true);
        }

        if (rowData) {
            return (
                <>
                    {this.state.IsEdit === true ?
                        <div>
                            <Switch checked={rowData.IsActive} color="primary" name={rowData.CartonId}
                                onChange={switchHandleChange} inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <Button icon="pi pi-pencil" onClick={editMode} />
                        </div>
                        : ""}

                </>
            )
        }
    }


    // edit form 
    // actionTemplate(rowData, column) {
    //     const editMode = (e) => {
    //         this.setState({
    //             buttonLabel: 'Update',
    //             formHeading: 'Update Carton',
    //             visible: true,
    //             CartonId: rowData.CartonId,
    //             CartonName: rowData.CartonName,
    //             CartonSize: rowData.CartonSize,
    //             Description: rowData.Description,
    //             IsActive: rowData.IsActive
    //         });

    //     }



    //     return <span>
    //         <Button type="button" icon="pi pi-pencil" onClick={editMode} className="p-button-warning" style={{ marginRight: '3px' }}></Button>
    //         {/* <Button type="button" icon="pi pi-trash" onClick={deleteFunc} className="p-button-danger"></Button> */}
    //     </span>;
    //     }

    // create html part
    render() {
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => this.props.onHideShowIsActive(false)} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus />
                </div>
            );
        }
        const header = (
            <div className="table-header row">
                <div className="col-md-6">
                    <h4>Carton Master</h4>
                </div>
                <div className="col-md-6 text-right">
                    <span className="p-input-icon-left mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => this.searchFilter(e)} placeholder="Global Search" />
                    </span>

                    {this.state.IsAdd === true ?
                        <Button className="mr-2" label="Add Carton" icon="pi pi-plus-plus" onClick={this.openDialog} />
                        :
                        <Button className="mr-2" label="Add Carton" icon="pi pi-plus-plus" style={{ display: 'none' }} onClick={this.openDialog} />
                    }

                </div>
            </div>
        );

        return (
            <div className="datatable-filter-demo">
                <ToastContainer />

                <div className="card">
                    <DataTable responsive={true} header={header} globalFilter={this.state.globalFilter}
                        emptyMessage={this.state.emptyMessage} value={this.props.CartonData} sortMode="multiple" paginator={true}
                        rows={this.state.rows} rowsPerPageOptions={[5, 10, 20]}
                        totalRecords={this.state.totalRecords} lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort} className="tblAuto">

                        <Column field="CartonName" header="Carton Name" sortable={true} />
                        <Column field="CartonSize" header="Carton Size" sortable={true} />
                        <Column field="Description" header="Description" sortable={true} />
                        <Column field="CreatedDate" header="Created Date" sortable={true} />
                        <Column body={this.activeTemplate} header="Status" />
                        <Column body={this.actionBodyTemplate} header="Action" />
                    </DataTable>

                    {/*<Dialog header={this.state.formHeading} visible={this.state.visible} modal={true} onHide={this.hideFunc}>*/}
                    <Dialog header={this.state.formHeading} modal dismissableMask={true} visible={this.props.showDialog} className="popup" onHide={this.onHide}>
                        {/* <form onSubmit={this.submitForm}> */}
                        <div className="form-padding">
                            <div className="row">
                                <div className="col-md-4 text-right">
                                    <label>Carton Name<span>*</span>:</label>
                                </div>
                                <div className="col-md-8 mb-2">
                                    <input type="text" value={this.state.CartonName} onChange={this.handleChange} className="form-control" name="CartonName" noValidate />
                                    {this.state.formError.CartonName !== '' ?
                                        <div className='error'>{this.state.formError.CartonName}</div> : ''}
                                </div>
                                <div className="col-md-4 text-right">
                                    <label>Carton Size<span>*</span>:</label>
                                </div>
                                <div className="col-md-8 mb-2">
                                    <input type="number" value={this.state.CartonSize} onChange={this.handleChange} className="form-control" name="CartonSize" />
                                    {this.state.formError.CartonSize !== '' ?
                                        <div className='error'>{this.state.formError.CartonSize}</div> : ''}
                                </div>
                                <div className="col-md-4 text-right">
                                    <label>Description<span>*</span>:</label>
                                </div>
                                <div className="col-md-8 mb-2">
                                    <input type="text" value={this.state.Description} onChange={this.handleChange} className="form-control" name="Description" />
                                    {this.state.formError.Description !== '' ?
                                        <div className='error'>{this.state.formError.Description}</div> : ''}
                                </div>
                                <div className="col-md-4 text-right">
                                    <label>IsActive :</label>
                                </div>
                                <div className="col-md-8 mb-2">
                                    <input type="checkbox" checked={this.state.IsActive} className="ml-2" name="IsActive" onClick={this.handleChange} />
                                </div>
                                {/* <div className="col-md-12 text-center">
                                    <button type="submit" className="btn btn-primary">{this.state.buttonLabel}</button>
                                </div> */}
                                {/* <div className="col-md-12 text-left">
                                    <label>Note: Fields marked as (<span style={{ color: 'red' }}>*</span>) are required</label>
                                </div> */}
                                <div className="col-md-12 mt-4 text-center">

                                    <div className="mb-2">Please Click Below Button To Submit*</div>

                                    <Button label={this.state.buttonLabel} onClick={this.submitForm} icon="pi pi-check" />

                                </div>
                                <div className="col-md-12">

                                    Note: Fields marked as (*) are required

                                </div>

                            </div>
                        </div>
                        {/* </form> */}
                    </Dialog>

                    <Dialog header="Confirmation" visible={this.props.showIsActiveDialog} modal style={{ width: '350px' }}
                        footer={renderFooter('displayConfirmation')} onHide={() => this.props.onHideShowIsActive(false)}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3 confirm-icon" style={{ fontSize: '2rem' }} />
                            <span>Are you sure you want to {this.state.switchAction == true ? "Active" : "InActive"}?</span>
                        </div>
                    </Dialog>
                </div>
            </div>

        )
    }
}


const mapStateToProps = (state) => {    
    return {
        CartonData: state.Carton?.items,
        //UserRoleData: state.role.rollddl,
        //UserRoleData: state.role.rollddl,
        // Mappseries: state.cartonMaster.Mappseries,
        //showDialogue: state.CartonMaster.showDialogue,
        productData: state.product.prdctddl,
        showDialog: state.Carton.showDialog,
        loading: state.company.loding,
        showIsActiveDialog: state.Carton.showIsActiveDialog
    }
}

const mapDispatchToProps = (dispatch) => {    
    return {
        fetchApi: (data) => {
            dispatch(CartonActions.getAll(data))
        },
        openPermissionDialog: data => dispatch(CartonActions.openDialog(data)),
        createCarton: user => dispatch(CartonActions.CreateCarton(user)),
        updateCarton: user => dispatch(CartonActions.UpdateCarton(user)),
        inActiveRow: (user, isActive) => dispatch(CartonActions.inActiveRow(user, isActive)),
        onHideShow: isbool => dispatch(CartonActions.onHideShow(isbool)),
        onHideShoww: isbool => dispatch(CartonActions.onHideShoww(isbool)),
        onHideShowIsActive: isbool => dispatch(CartonActions.onHideShowIsActive(isbool)),
        //getRoleDDL: (userId) => dispatch(roleMasterActions.getRoleDDL(userId)),
        loadingData: (isLoding) => dispatch(CartonActions.loadingData(isLoding))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(cartonMaster)