export const RedemptionApprovalConstants = {
    APPROVE_REQUEST: 'REDEMPTIONAPPROVAL_APPROVE_REQUEST',
    APPROVE_SUCCESS: 'REDEMPTIONAPPROVAL_APPROVE_SUCCESS',
    APPROVE_FAILURE: 'REDEMPTIONAPPROVAL_APPROVE_FAILURE',

    CANCEL_REQUEST: 'REDEMPTIONAPPROVAL_CANCEL_REQUEST',
    CANCEL_SUCCESS: 'REDEMPTIONAPPROVAL_CANCEL_SUCCESS',
    CANCEL_FAILURE: 'REDEMPTIONAPPROVAL_CANCEL_FAILURE',

    CREATE_REQUEST: 'REDEMPTIONAPPROVAL_CREATE_REQUEST',
    CREATE_SUCCESS: 'REDEMPTIONAPPROVAL_CREATE_SUCCESS',
    CREATE_FAILURE: 'REDEMPTIONAPPROVAL_CREATE_FAILURE',

    GETALL_REQUEST: 'REDEMPTIONAPPROVAL_GETALL_REQUEST',
    GETALL_SUCCESS: 'REDEMPTIONAPPROVAL_GETALL_SUCCESS',
    GETALL_FAILURE: 'REDEMPTIONAPPROVAL_GETALL_FAILURE',

    GETALL_USERPT_REQUEST: 'REDEMPTIONAPPROVAL_GETALL_USERPT_REQUEST',
    GETALL_USERPT_SUCCESS: 'REDEMPTIONAPPROVAL_GETALL_USERPT_SUCCESS',
    GETALL_USERPT_FAILURE: 'REDEMPTIONAPPROVAL_GETALL_USERPT_FAILURE',

    GETALL_USER_REQUEST: 'REDEMPTIONAPPROVAL_GETALL_USER_REQUEST',
    GETALL_USER_SUCCESS: 'REDEMPTIONAPPROVAL_GETALL_USER_SUCCESS',
    GETALL_USER_FAILURE: 'REDEMPTIONAPPROVAL_GETALL_USER_FAILURE',


    DELETE_REQUEST: 'REDEMPTIONAPPROVAL_DELETE_REQUEST',
    DELETE_SUCCESS: 'REDEMPTIONAPPROVAL_DELETE_SUCCESS',
    DELETE_FAILURE: 'REDEMPTIONAPPROVAL_DELETE_FAILURE',
    
    ISACTIVE_REQUEST: 'REDEMPTIONAPPROVAL_ISACTIVE_REQUEST',
    ISACTIVE_SUCCESS: 'REDEMPTIONAPPROVAL_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE: 'REDEMPTIONAPPROVAL_ISACTIVE_FAILURE',
    
    DIALOG_REQUEST:'DIALOG_REQUEST',

    DIALOGUE_REQUEST:'DIALOGUE_REQUEST',
    
    LOADING_REQUEST:'LOADING_REQUEST',

    DIALOG_ISACTIVE_REQUEST:'DIALOG_ISACTIVE_REQUEST',

    REJECT_DIALOGUE_REQUEST : 'REJECT_DIALOGUE_REQUEST'
};