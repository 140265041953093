import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import { FileUpload } from "primereact/fileupload";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Dropdown } from "primereact/dropdown";
import { ToastContainer, toast } from "react-toastify";
import {
  roleMasterActions,
  dealerMasterActions,
  userMasterActions,
} from "../../actions";
import tableRequest from "../../models/tableRequest";
import Switch from "@material-ui/core/Switch";
import { Calendar } from "primereact/calendar";
import { CSVLink } from "react-csv";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

class dealerMaster extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.exportCSV = this.exportCSV.bind(this);
    this.FilterData = this.FilterData.bind(this);
    this.tblResponse = new tableRequest();
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
    this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.getStateList = this.getStateList.bind(this);
    this.getFirmList = this.getFirmList.bind(this);
    this.getCityList = this.getCityList.bind(this);
    this.setImagePath = this.setImagePath.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);

    this.state = {
      FromDate: new Date(),
      ToDate: new Date(),
      IsAdd: true,
      IsEdit: true,
      first: 0,
      isImage: false,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
      buttonLabel: "Submit",
      switchAction: false,
      formHeading: "Add Dealer Master",
      GstCertificate: "",
      DealerId: 0,
      pdf: "",
      RoleId: 0,
      NatureFirmId: 0,
      Nature_Firm: 0,
      PanNo: "",
      BillingSameAsShipping: false,
      CityId: 0,
      compId: 0,
      UserTypeId: "",
      TransportationModeId: 0,
      CountryData: [{ value: 1, label: "India" }],
      UserFirmData: [
        { value: 1, label: "Private Ltd" },
        { value: 2, label: "Ltd" },
        { value: 3, label: "Proprietorship" },
        { value: 4, label: "Partnership" },
      ],
      DealerName: "",
      CompanyName: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      Nature_Firm: "",
      GstNo: "",
      // GstCertificate: '',
      Bill_Address: "",
      Bill_Pincode: "",
      Bill_Country: "",
      Bill_State: "",
      Bill_City: "",
      CityShip: [],
      CityBill: [],
      Ship_Address: "",
      Ship_Pincode: "",
      Ship_Country: "",
      Ship_State: "",
      Ship_City: "",
      IsActive: true,
      CreatedDate: null,
      UpdatedDate: null,
      formError: {
        PanNo: "",
        DealerName: "",
        CompanyName: "",
        UserTypeId: "",
        Bill_Address: "",
        Bill_Country: "",
        Bill_State: "",
        Bill_City: "",
        Bill_Pincode: "",
        Ship_Address: "",
        Ship_Country: "",
        Ship_State: "",
        Ship_City: "",
        Ship_Pincode: "",
        GstCertificate: "",
        GstNo: "",
        Email: "",
        MobileNo: "",
        Password: "",
        ConfirmPassword: "",
        RoleId: "",
        CityId: "",
        NatureFirmId: "",
        TransportationModeId: "",
      },
    };
  }
  componentDidMount() {
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() - 30);
    this.tblResponse.FromDate = date.toLocaleDateString();
    this.tblResponse.ToDate = new Date().toLocaleDateString();
    this.setState({
      FromDate: date,
      ToDate: new Date(),
    });
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    this.props.fetchApi(this.tblResponse);
    this.props.getRoleDDL(2);
    this.props.getTransportationModeDDL();
    // const userId = JSON.parse(sessionStorage.getItem("UserData"));
    // this.props.getRoleDDL(userId);
  }

  //
  // set Image Path
  setImagePath(event) {
    let errors = this.state.formError;
    errors.GstCertificate = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              GstCertificate: response.Source,
              pdf: "",
              isImage: true,
            });
            if (response.Source.toString().includes(".pdf")) {
              this.setState({
                pdf: event.files[0].name,
                isImage: false,
              });
            }
          } else if (response.ResponseCode === "400") {
            errors.GstCertificate = "Only image format is accepted.";
            this.setState({
              GstCertificate: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  ///end image
  componentDidUpdate(prevProps) {
    if (this.props.UserData) {
      if (prevProps.UserData !== this.props.UserData) {
        // this.props.myProp has a different value
        if (this.props.UserData) {
          var e = this.props.UserData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  switchToggleConfirm = (e) => {
    this.props.inActiveRow(this.state.DealerId, this.state.switchAction);
  };

  FilterData() {
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
    this.props.fetchApi(this.tblResponse);
  }

  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "IsActive":
        value = event.target.checked;
        break;
      case "BillingSameAsShipping":
        value = event.target.checked;
        this.SameAsBilling(event);
        break;
      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  handleChangeDate(event) {
    const { name, value } = event.target;
    switch (name) {
      case "FromDate":
        if (value === null || !(value instanceof Date)) {
          this.formIsValid = false;
          // this.refs.Messchild.showError("Please select from date.");
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please select from date.",
            life: 3000,
          });

          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please enter only date.",
            life: 3000,
          });
          // this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (value instanceof Date && this.state.ToDate instanceof Date) {
          const startDate = value;
          const endDate = this.state.ToDate;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.toast.show({
              severity: "error",
              summary: "Error Message",
              detail: "From date must be less than to date.",
              life: 3000,
            });
            // this.refs.Messchild.showError("From date must be less than to date.");
            return;
          }
        }

        break;
      case "ToDate":
        if (value === null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please select to date.",
            life: 3000,
          });
          // this.refs.Messchild.showError("Please select to date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please enter only date.",
            life: 3000,
          });
          // this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (
          value instanceof Date &&
          this.state.FromDate instanceof Date
        ) {
          const startDate = this.state.FromDate;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.toast.show({
              severity: "error",
              summary: "Error Message",
              detail: "To date must be greater than from date.",
              life: 3000,
            });
            // this.refs.Messchild.showError("To date must be greater than from date.");
            return;
          }
        }
        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  }

  getStateList(event, type) {
    //this.handleChange(event)
    if (type == "B") {
      this.setState({ Bill_Country: event.target.value });
      this.props.getStateDDL(event.target.value);
    } else if (type == "S") {
      this.setState({ Ship_Country: event.target.value });
      this.props.getStateDDL(event.target.value);
    }
  }
  getCityList(event, type, flag) {
    if (flag == "i") {
      if (type == "B") {
        this.setState({ Bill_State: event.target.value });
        this.props.getBCityDDL(event.target.value);
      } else if (type == "S") {
        this.setState({ Ship_State: event.target.value });
        this.props.getSCityDDL(event.target.value);
      }
    } else {
      if (type == "B") {
        this.setState({ Bill_State: event });
        this.props.getBCityDDL(event);
      } else if (type == "S") {
        this.setState({ Ship_State: event });
        this.props.getSCityDDL(event);
      }
    }

    //this.handleChange(event)
    // if (flag == "i") {
    //   if (type == "B") {
    //     this.setState({ Bill_State: event.target.value });
    //     this.props.getBCityDDL(event.target.value);
    //     setTimeout(() => {

    //       this.setState({ CityBill: this.props.UserBCityData, Bill_State: event.target.value });
    //     }, 1500);
    //   } else if (type == "S") {
    //     this.setState({ Ship_State: event.target.value });
    //     this.props.getSCityDDL(event.target.value);
    //     setTimeout(() => {

    //       this.setState({ CityShip: this.props.UserSCityData, Ship_State: event.target.value });
    //     }, 1500);
    //   }
    // } else {
    //   if (type == "B") {
    //     this.setState({ Bill_State: event });
    //     this.props.getBCityDDL(event);
    //     setTimeout(() => {

    //       this.setState({
    //         CityBill: this.props.UserBCityData,
    //         Bill_City: event,
    //       });
    //     }, 1500);
    //   } else if (type == "S") {
    //     this.setState({ Ship_State: event });
    //     this.props.getSCityDDL(event);
    //     setTimeout(() => {

    //       this.setState({
    //         CityShip: this.props.UserSCityData,
    //         Ship_City: event,
    //       });
    //     }, 1500);
    //   }
    // }
  }

  getFirmList(event) {
    this.setState({ NatureFirmId: event.target.value });
    this.props.getFirmDDL(event.target.value);
  }

  UpdatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>;
    }
  }

  CreatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>;
    }
  }
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "DealerName":
        if (value.length < 1) {
          IsValid = false;
          errors.DealerName = "Please enter Dealer Name.";
        } else errors.DealerName = "";
        break;
      case "DealerName":
        if (value.length < 1) {
          IsValid = false;
          errors.DealerName = "Please enter Dealer Name.";
        } else errors.DealerName = "";
        break;
      case "GstCertificate":
        if (!value) {
          IsValid = false;
          errors.GstCertificate = "Please select GST Certificate.";
        } else errors.GstCertificate = "";
        break;
      case "TransportationModeId":
        if (!value) {
          IsValid = false;
          errors.TransportationModeId = "Please select Transportation Mode.";
        } else errors.TransportationModeId = "";
        break;

      case "NatureFirmId":
        if (!value) {
          IsValid = false;
          errors.NatureFirmId = "Please select Nature Firm .";
        } else errors.NatureFirmId = "";
        break;
      case "GstNo":
        var gstinformat = new RegExp(
          "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
        );
        if (value.length < 1) {
          IsValid = false;
          errors.GstNo = "Please enter GST Number.";
        } else if (value.length >= 1 && !gstinformat.test(value)) {
          IsValid = false;
          errors.GstNo =
            "GST Identification Number is not valid. It should be in this 11AAAAA1111Z1A1 format.";
        } else errors.GstNo = "";
        break;
      case "Email":
        if (value.length < 1) {
          IsValid = false;
          errors.Email = "Please enter email-id.";
        } else if (value.length >= 1) {
          /* eslint-disable */
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.Email = "Please enter valid email-id.";
          } else errors.Email = "";
          /* eslint-enable */
        } else errors.Email = "";
        break;
      case "MobileNo":
        if (value.length < 1) {
          IsValid = false;
          errors.MobileNo = "Please enter mobile no.";
        } else if (value.length >= 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = true;
            errors.MobileNo = "Please enter valid mobile no.";
          } else errors.MobileNo = "";
        } else errors.MobileNo = "";
        break;
      case "Password":
        if (!value && value.length < 1) {
          IsValid = false;
          errors.Password = "Please enter password.";
        } else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{4,20}$/)) {
          IsValid = false;
          errors.Password =
            "Password should be minimum 4 characters long, 1 uppercase & 1 lowercase character with 1 number.";
        } else if (
          !value &&
          value.length >= 1 &&
          this.state.ConfirmPassword.length >= 1
        ) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (!(value === this.state.ConfirmPassword)) {
            IsValid = false;
            errors.Password = "password does not match";
          } else errors.Password = "";
        } else errors.Password = "";
        break;

      case "ConfirmPassword":
        if (!value && value.length < 1) {
          IsValid = false;
          errors.ConfirmPassword = "Please enter confirm password.";
        } else if (value.length >= 1 && this.state.Password.length >= 1) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (value !== this.state.Password) {
            IsValid = false;
            errors.ConfirmPassword =
              "New password and Confirm password should be same.";
          }
        } else if (
          !value &&
          value.length >= 1 &&
          this.state.Password.length >= 1
        ) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (!(value === this.state.Password)) {
            IsValid = false;
            errors.ConfirmPassword = "Confirm password does not match";
          } else errors.ConfirmPassword = "";
        } else errors.ConfirmPassword = "";
        break;
      case "Ship_City":
        if (value == undefined || value == "") {
          IsValid = false;
          errors.Ship_City = "Please select city";
        } else errors.Ship_City = "";
        break;
      case "Bill_City":
        if (value == undefined || value == "") {
          IsValid = false;
          errors.Bill_City = "Please select city";
        } else errors.Bill_City = "";
        break;
      case "Bill_State":
        if (value == undefined || value == "") {
          IsValid = false;
          errors.Bill_State = "Please select state";
        } else {
          errors.Bill_State = "";
        }
        break;
      case "Ship_State":
        if (value == undefined || value == "") {
          IsValid = false;
          errors.Ship_State = "Please select state";
        } else {
          errors.Ship_State = "";
        }
        break;
      case "Bill_Address":
        if (value.length < 1) {
          IsValid = false;
          errors.Bill_Address = "Please enter Address";
        } else errors.Bill_Address = "";
        break;
      case "Bill_Pincode":
        if (value.length < 1) {
          IsValid = false;
          errors.Bill_Pincode = "Please enter Pincode";
        } else errors.Bill_Pincode = "";
        break;
      case "Ship_Address":
        if (value.length < 1) {
          IsValid = false;
          errors.Ship_Address = "Please enter Address";
        } else errors.Ship_Address = "";
        break;
      case "Bill_Country":
        if (value == undefined || value == "") {
          IsValid = false;
          errors.Bill_Country = "Please select Country";
        } else errors.Bill_Country = "";
        break;
      case "Ship_Country":
        if (value == undefined || value == "") {
          IsValid = false;
          errors.Ship_Country = "Please select Country";
        } else errors.Ship_Country = "";
        break;
      case "Ship_Pincode":
        if (value.length < 1) {
          IsValid = false;
          errors.Ship_Pincode = "Please enter Pincode";
        } else errors.Ship_Pincode = "";
        break;
      case "BillingSameAsShipping":
        if (value) {
          errors.Ship_Country = "";
          errors.Ship_Address = "";
          errors.Ship_State = "";
          errors.Ship_City = "";
          errors.Ship_Pincode = "";
        }
        break;
      default:
        break;
    }

    return IsValid;
  }

  getDropValueOfRole() {
    let roleId = "";
    this.props.UserRoleData.filter((x) => {
      if (x.label === "Dealer") {
        roleId = x.value;
      }
    });

    return roleId;
  }
  getDropValueOfCity() {
    let city = "";
    this.props.UserCityData.filter((x) => {
      if (x.value === this.state.CityId.toString()) {
        city = x.label;
      }
    });

    return city;
  }

  getDropValueOfFirm() {
    let firmName = "";
    this.props.UserFirmData.filter((x) => {
      if (x.value === this.state.NatureFirmId.toString()) {
        firmName = x.label;
      }
    });

    return firmName;
  }
  getDropValueOfUserType() {
    let usertype = "";
    this.state.CountryData.filter((x) => {
      if (x.value === this.state.Ship_Country) {
        usertype = x.label;
      }
    });

    return usertype;
  }

  submitForm(event) {
    debugger;
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });
    if (isfrmvalid) {
      let roleId = this.getDropValueOfRole();
      const formData = {
        UserId: this.state.DealerId,
        UserType: "2",
        RoleId: roleId,
        UserName: this.state.DealerName,
        Email: this.state.Email,
        MobileNo: this.state.MobileNo,
        Password: this.state.Password,
        IsActive: this.state.IsActive,
        CreatedDate:
          this.state.DealerId > 0
            ? this.state.CreatedDate
            : this.getCurrentDateMMDDYYY(),
        UpdatedDate:
          this.state.DealerId > 0 ? this.getCurrentDateMMDDYYY() : null,
        DealerId: this.state.DealerId,
        NatureFirmId: this.state.NatureFirmId,
        PanNo: this.state.PanNo,
        GstNo: this.state.GstNo,
        GstCertificate: this.state.GstCertificate,
        Bill_Address: this.state.Bill_Address,
        Bill_Pincode: this.state.Bill_Pincode,
        Bill_Country: this.state.Bill_Country,
        Bill_State: this.state.Bill_State,
        Bill_City: this.state.Bill_City,

        Ship_Address: this.state.Ship_Address,
        Ship_Pincode: this.state.Ship_Pincode,
        Ship_Country: this.state.Ship_Country,
        Ship_State: this.state.Ship_State,
        Ship_City: this.state.Ship_City,
        TransportationModeId: this.state.TransportationModeId,
      };
      if (formData.DealerId > 0) {
        this.props.updateDealer(formData);
      } else {
        //console.log(formData);
        this.props.createDealer(formData);
      }
      if (!this.props.showDialog) {
        this.resetForm();
      }
      setTimeout(() => {
        this.props.fetchApi(this.tblResponse);
      }, 1000);
    }
  }

  // getRoleDDL(event) {
  //     this.props.getRoleDDL();
  // }
  resetForm() {
    this.setState({
      UserId: 0,
      DealerId: 0,
      NatureFirmId: "",
      PanNo: "",
      GstNo: "",
      GstCertificate: "",
      Bill_Address: "",
      Bill_Pincode: "",
      Bill_Country: "",
      Bill_State: "",
      Bill_City: "",
      Ship_Address: "",
      Ship_Pincode: "",
      Ship_Country: "",
      Ship_State: "",
      Ship_City: "",
      CreatedDate: "",
      UpdatedDate: "",
      compId: "",
      UserTypeId: "",
      DealerName: "",
      CompanyName: "",
      RoleId: "",
      CityId: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      IsActive: true,
      formError: {
        DealerName: "",
        CompanyName: "",
        UserTypeId: "",
        Email: "",
        MobileNo: "",
        Password: "",
        ConfirmPassword: "",
        PanNo: "",
        Bill_Address: "",
        Bill_Pincode: "",
        Bill_Country: "",
        Bill_State: "",
        Bill_City: "",
        Ship_Address: "",
        Ship_Pincode: "",
        Ship_Country: "",
        Ship_State: "",
        Ship_City: "",
        GstCertificate: "",
        GstNo: "",
        RoleId: "",
        CityId: "",
        NatureFirmId: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }

  //to lazy load
  getLazyLoadingValues(event) {
    debugger;
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  SameAsBilling(event) {
    if (event.target.checked) {
      this.setState({
        Ship_Country: this.state.Bill_Country,
        Ship_State: this.state.Bill_State,
        Ship_City: this.state.Bill_City,
        Ship_Pincode: this.state.Bill_Pincode,
        Ship_Address: this.state.Bill_Address,
      });
      this.props.getSCityDDL(this.state.Bill_State);
    } else {
      this.setState({
        Ship_Country: "",
        Ship_State: "",
        Ship_City: "",
        Ship_Address: "",
        Ship_Pincode: "",
      });
    }
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }
  photoBodyTemplate(e) {
    return <img src={e.GstCertificate} width="50px" />;
  }

  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }
  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add Dealer" });
  }
  statusBodyTemplate(rowData) {
    if (rowData) {
      return (
        <Checkbox
          disabled
          checked={rowData.IsActive}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      );
    }
  }
  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }
  actionBodyTemplate(rowData) {
    debugger;
    const editMode = (e) => {
      this.props.onHideShow(true);
      this.props.getStateDDL(rowData.Bill_Country);
      this.getCityList(rowData.Bill_State, "B", "");
      this.getCityList(rowData.Ship_State, "S", "");
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Dealer",
        NatureFirmId: rowData.NatureFirmId,
        TransportationModeId: rowData.TransportationModeId,
        IsActive: rowData.IsActive,
        Password: rowData.Password,
        ConfirmPassword: rowData.Password,
        CreatedDate: rowData.CreatedDate,
        DealerId: rowData.DealerId,
        DealerName: rowData.DealerName,
        PanNo: rowData.PanNo,
        Nature_Firm: rowData.Nature_Firm,
        GstNo: rowData.GstNo,
        GstCertificate: rowData.GstCertificate,
        Email: rowData.Email,
        MobileNo: rowData.MobileNo,
        Bill_Address: rowData.Bill_Address,
        Bill_Pincode: rowData.Bill_Pincode,
        Bill_Country: rowData.Bill_Country,
        Bill_State: rowData.Bill_State,
        Bill_City: rowData.Bill_City,
        Ship_Address: rowData.Ship_Address,
        Ship_Pincode: rowData.Ship_Pincode,
        Ship_Country: rowData.Ship_Country,
        Ship_State: rowData.Ship_State,
        Ship_City: rowData.Ship_City,
      });
    };
    const switchHandleChange = (e) => {
      this.setState({
        switchAction: !rowData.IsActive,
        DealerId: rowData.DealerId,
      });
      this.props.onHideShowIsActive(true);
    };

    if (rowData) {
      return (
        <>
          {this.state.IsEdit === true ? (
            <div>
              <Switch
                checked={rowData.IsActive}
                color="primary"
                name={rowData.DealerId}
                onChange={switchHandleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Button icon="pi pi-pencil" onClick={editMode} />
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  exportCSV() {
    debugger
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
    this.tblResponse.First = -1;
    this.tblResponse.PageSize = -1;
    this.props.exportData(this.tblResponse);
    setTimeout(() => {
      this.csvLink.current.element.click();
    }, 4000);
  }

  render() {
    const renderFooter = (name) => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={this.switchToggleConfirm}
            autoFocus
          />
        </div>
      );
    };

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />
        <div className="table-header row mb-3">
          <div className="col-md-2 pl-4">
            <h4>Dealer Master</h4>
          </div>
          <div className="col-md-10">
            <div className="row text-right">
              <div style={{ width: "25%" }}>
                <Calendar
                  placeholder="From Date"
                  name="FromDate"
                  showIcon="true"
                  value={this.state.FromDate}
                  onChange={this.handleChange}
                ></Calendar>
              </div>
              <div style={{ width: "25%" }}>
                <Calendar
                  placeholder="To Date"
                  name="ToDate"
                  showIcon="true"
                  value={this.state.ToDate}
                  onChange={this.handleChange}
                ></Calendar>
              </div>
              <div style={{ width: "18%" }} className="text-center">
                <button
                  type="button"
                  onClick={this.FilterData}
                  className="btn btn-primary p-2 pl-3 pr-3 mr-2"
                >
                  Submit
                </button>
                &nbsp;
                <button
                  onClick={this.exportCSV}
                  className="btn btn-success p-2 pl-3 pr-3 ml-2 buttone1"
                >
                  Export
                </button>
                <ExcelFile
                  filename={"UserMasterReport"}
                  element={<Button hidden ref={this.csvLink} />}
                >
                  <ExcelSheet
                    data={this.props.List}
                    name={"Delar_Master_Report"}
                  >
                   
                    {/* <ExcelColumn label="Role Name" value="RoleName" />
                    <ExcelColumn label="Dealer" value="isDealer" />
                    <ExcelColumn label="Dealer Id" value="DealerId" />
                    <ExcelColumn label="Country " value="CountryId" />*/}
                    <ExcelColumn label="UserName" value="DealerName" /> 
                    <ExcelColumn label="Role Name" value="RoleName" />
                     {/* <ExcelColumn label="Dealer Name" value="DealerName" /> */}
                    {/* <ExcelColumn label="Company Name" value="CompanyName" />  */}
                    {/* <ExcelColumn label="Transportation Mode" value="TransportationModeId" /> */}
                    <ExcelColumn label="Email" value="Email" />
                    <ExcelColumn label="Mobile No" value="MobileNo" />
                    
                    {/* <ExcelColumn label="Pan No" value="PanNo" /> */}
                    <ExcelColumn label="Nature Firm" value="NatureFirmId" />
                    <ExcelColumn label="Gst No." value="GstNo" />
                    {/* //<ExcelColumn label="GstCertificate." value="GstCertificate" formula /> */}
                    <ExcelColumn label="Ship Country" value="Ship_Country" />
                    <ExcelColumn label="Ship State" value="Ship_State" />
                    <ExcelColumn label="Ship City" value="Ship_City" />
                    <ExcelColumn label="Shiping Address" value="Ship_Address" />
                    <ExcelColumn label="Ship_Pincode" value="Ship_Pincode" />
                    <ExcelColumn label="Bill_Country" value="Bill_Country" />
                    <ExcelColumn label="Bill_State" value="Bill_State" />
                    <ExcelColumn label="Bill_Pincode" value="Bill_Pincode" />
                    <ExcelColumn label="Bill_City" value="Bill_City" />
                    <ExcelColumn label="Bill_Address" value="Bill_Address" />
                    <ExcelColumn label="Created Date" value="CreatedDate" />
                    {/* <ExcelColumn label="Updated Date" value="UpdatedDate" /> */}
                  </ExcelSheet>
                </ExcelFile>
                {/* <CSVLink
                  hidden
                  data={this.props.List}
                  headers={this.state.headers}
                  filename={"Dealer Report.csv"}
                  target="_blank"
                >
                  <Button hidden ref={this.csvLink} />
                </CSVLink> */}
              </div>
              <div style={{ width: "15%" }}>
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    type="search"
                    onInput={(e) => this.searchFilter(e)}
                    placeholder="Global Search"
                  />
                </span>
              </div>

              <div style={{ width: "15%" }}>
                {this.state.IsAdd === true ? (
                  <Button
                    className="mr-3"
                    label="Add Dealer"
                    icon="pi pi-plus-circle"
                    onClick={this.openDialog}
                  />
                ) : (
                  <Button
                    className="mr-3"
                    label="Add Dealer"
                    icon="pi pi-plus-circle"
                    style={{ display: "none" }}
                    onClick={this.openDialog}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <DataTable
            value={this.props.UserData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column field="DealerName" header="Name" sortable></Column>
            <Column field="Email" header="Email" sortable></Column>
            <Column field="MobileNo" header="Mobile Number" sortable></Column>
            <Column field="GstNo" header="GST Number" sortable></Column>
            
            <Column field="StrBill_State" header="State" sortable></Column>
            <Column field="StrBill_City" header="City" sortable></Column>
            <Column field="Bill_Pincode" header="Pincode" sortable></Column>


            {/* <Column field="GstCertificate" header="GstCertificate" style={{ width: '200px' }} body={this.photoBodyTemplate}></Column> */}
            {this.state.IsEdit === true ? (
              <Column
                field="Action"
                header="Action"
                style={{ width: "120px" }}
                body={this.actionBodyTemplate}
              ></Column>
            ) : (
              <Column
                field="Action"
                header="Action"
                style={{ width: "120px" }}
                style2={{ display: "none" }}
              ></Column>
            )}
          </DataTable>
        </div>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          <form autoComplete="off">
            <div className="row">
              <div className="col-md-6 pt-3">
                <TextField
                  onChange={this.handleChange}
                  name="DealerName"
                  autoComplete="false"
                  value={this.state.DealerName}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Dealer Name*"
                />
                {this.state.formError.DealerName !== "" ? (
                  <div className="error">{this.state.formError.DealerName}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  onChange={this.handleChange}
                  name="Email"
                  value={this.state.Email}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Email*"
                />
                {this.state.formError.Email !== "" ? (
                  <div className="error">{this.state.formError.Email}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  id="standard-basic"
                  size="small"
                  onChange={this.handleChange}
                  variant="outlined"
                  name="MobileNo"
                  value={this.state.MobileNo}
                  className="fullwidth"
                  label="Mobile Number*"
                />
                {this.state.formError.MobileNo !== "" ? (
                  <div className="error">{this.state.formError.MobileNo}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  input
                  type="password"
                  autoComplete="new-password"
                  onChange={this.handleChange}
                  name="Password"
                  value={this.state.Password}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Password*"
                />
                {this.state.formError.Password !== "" ? (
                  <div className="error">{this.state.formError.Password}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  input
                  type="password"
                  onChange={this.handleChange}
                  name="ConfirmPassword"
                  value={this.state.ConfirmPassword}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Confirm Password*"
                />
                {this.state.formError.ConfirmPassword !== "" ? (
                  <div className="error">
                    {this.state.formError.ConfirmPassword}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  id="standard-basic"
                  size="small"
                  variant="outlined"
                  name="PanNo"
                  onChange={this.handleChange}
                  value={this.state.PanNo}
                  className="fullwidth"
                  label="Pan No"
                />
                {this.state.formError.PanNo !== "" ? (
                  <div className="error">{this.state.formError.PanNo}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6 pt-3">
                <FormControl
                  variant="outlined"
                  size="small"
                  className="formControl col-md-12"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Firm Type<span>*</span>
                  </InputLabel>
                  <Select
                    onChange={this.getFirmList}
                    name="NatureFirmId"
                    value={this.state.NatureFirmId}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Select Firm Type*"
                  >
                    <MenuItem value={""}>
                      <em>--- Select Firm Type --- </em>
                    </MenuItem>
                    {this.state.UserFirmData.map((key) => (
                      <MenuItem value={key.value}>{key.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {this.state.formError.NatureFirmId !== "" ? (
                  <div className="error">
                    {this.state.formError.NatureFirmId}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6 pt-3">
                <FormControl
                  variant="outlined"
                  size="small"
                  className="formControl col-md-12"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Transportation Mode<span>*</span>
                  </InputLabel>
                  <Select
                    onChange={this.handleChange}
                    name="TransportationModeId"
                    value={this.state.TransportationModeId}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Select Transportation Mode"
                  >
                    <MenuItem value={""}>
                      <em>--- Select Transportation Mode ---</em>
                      <span>*</span>
                    </MenuItem>
                    {this.props.TransportationModeData.map((key) => (
                      <MenuItem value={key.value}>{key.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {this.state.formError.TransportationModeId !== "" ? (
                  <div className="error">
                    {this.state.formError.TransportationModeId}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  id="standard-basic"
                  size="small"
                  variant="outlined"
                  name="GstNo"
                  onChange={this.handleChange}
                  value={this.state.GstNo}
                  className="fullwidth"
                  label="GST No*"
                />
                {this.state.formError.GstNo !== "" ? (
                  <div className="error">{this.state.formError.GstNo}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <label>
                  Upload GST certificate<span>*</span>:
                </label>
                <FileUpload
                  name="GstCertificate"
                  mode="basic"
                  url={process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"}
                  auto={true}
                  onUpload={this.setImagePath}
                  onError={this.onErrorImage}
                  accept="image/*,application/pdf"
                />
                {this.state.pdf != "" ? <b>{this.state.pdf}</b> : null}
                <div className="col-md-12 mb-3">
                  {this.state.GstCertificate && this.state.isImage ? (
                    <div
                      className="p-fileupload-content mt-3 p-1"
                      style={{ display: "table", border: "1px solid #ccc" }}
                    >
                      <div>
                        <img
                          alt="pro.jfif"
                          role="presentation"
                          src={this.state.GstCertificate}
                          width="100"
                        />
                      </div>
                    </div>
                  ) : null}

                  {this.state.formError.GstCertificate !== null ? (
                    <div className="error">
                      {this.state.formError.GstCertificate}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 pt-3">
                <Checkbox
                  name="IsActive"
                  checked={this.state.IsActive}
                  onClick={this.handleChange}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />{" "}
                <label>Is Active</label>
              </div>
              <div className="col-md-12 bordercolor">
                <fieldset>
                  <div className="row">
                    <legend className="col-md-12 billing-add">
                      Billing Address*
                    </legend>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <FormControl
                            variant="outlined"
                            size="small"
                            className="formControl col-md-12"
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Select Country*
                            </InputLabel>
                            <Select
                              onChange={(e) => {
                                this.getStateList(e, "B");
                              }}
                              name="Bill_Country"
                              value={this.state.Bill_Country}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Select Country"
                            >
                              <MenuItem>
                                <em>Select Country</em>
                                <span>*</span>
                              </MenuItem>
                              {this.state.CountryData.map((key) => (
                                <MenuItem value={key.value}>
                                  {key.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.formError.Bill_Country !== "" ? (
                            <div className="error">
                              {this.state.formError.Bill_Country}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-12">
                          <label>
                            {" "}
                            <span></span>
                          </label>
                          <FormControl
                            variant="outlined"
                            size="small"
                            className="formControl col-md-12 custom-select"
                          >
                            <Dropdown
                              appendTo={document.getElementById("root")}
                              value={this.state.Bill_City}
                              //value={this.props.ProductData}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                              options={this.props.UserBCityData}
                              optionValue="value"
                              optionLabel="label"
                              className="form-control custom-select"
                              placeholder={"Select City*"}
                              name="Bill_City"
                              filter
                            />
                          </FormControl>

                          {this.state.formError.Bill_City !== "" ? (
                            <div className="error">
                              {this.state.formError.Bill_City}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-12">
                          <label>
                            <span></span>
                          </label>
                          <TextField
                            id="standard-basic"
                            name="Bill_Pincode"
                            value={this.state.Bill_Pincode}
                            type="text"
                            variant="outlined"
                            size="small"
                            className="fullwidth"
                            onChange={this.handleChange}
                            label="Bill Pin Code*"
                          />
                          {this.state.formError.Bill_Pincode !== null ? (
                            <div className="error">
                              {this.state.formError.Bill_Pincode}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <FormControl
                            variant="outlined"
                            size="small"
                            className="formControl col-md-12 custom-select"
                          >
                            <Dropdown
                              appendTo={document.getElementById("root")}
                              value={this.state.Bill_State}
                              //value={this.props.ProductData}
                              options={this.props.StateData}
                              optionValue="value"
                              optionLabel="label"
                              className="form-control custom-select"
                              placeholder={"Select State*"}
                              name="Bill_State"
                              onChange={(e) => {
                                this.getCityList(e, "B", "i");
                              }}
                              filter
                            />
                          </FormControl>

                          {this.state.formError.Bill_State !== "" ? (
                            <div className="error">
                              {this.state.formError.Bill_State}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-12">
                          <label>
                            <span></span>
                          </label>
                          <TextField
                            id="standard-basic"
                            name="Bill_Address"
                            value={this.state.Bill_Address}
                            type="text"
                            variant="outlined"
                            size="small"
                            className="fullwidth"
                            onChange={this.handleChange}
                            label="Billing Address*"
                          />
                          {this.state.formError.Bill_Address !== null ? (
                            <div className="error">
                              {this.state.formError.Bill_Address}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div className="col-md-12 pt-3">
                <Checkbox
                  name="BillingSameAsShipping"
                  checked={this.state.BillingSameAsShipping}
                  onClick={this.handleChange}
                  color="primary"
                />{" "}
                <label>
                  Shipping address is the same as my billing address{" "}
                </label>
              </div>
              <div className="col-md-12 bordercolor">
                <fieldset>
                  <div className="row">
                    <legend className="col-md-12 billing-add">
                      Shipping Address*
                    </legend>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <FormControl
                            variant="outlined"
                            size="small"
                            className="formControl col-md-12"
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Select Country*
                            </InputLabel>
                            <Select
                              disabled={this.state.BillingSameAsShipping}
                              onChange={(e) => {
                                this.getStateList(e, "S");
                              }}
                              name="Ship_Country"
                              value={this.state.Ship_Country}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Select Country"
                            >
                              <MenuItem>
                                <em>None</em>
                              </MenuItem>
                              {this.state.CountryData.map((key) => (
                                <MenuItem value={key.value}>
                                  {key.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.formError.Ship_Country !== "" ? (
                            <div className="error">
                              {this.state.formError.Ship_Country}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-12">
                          <label>
                            {" "}
                            <span></span>
                          </label>
                          <label>
                            {" "}
                            <span></span>
                          </label>
                          <FormControl
                            variant="outlined"
                            size="small"
                            className="formControl col-md-12"
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Select City*
                            </InputLabel>
                            <Select
                              disabled={this.state.BillingSameAsShipping}
                              onChange={this.handleChange}
                              name="Ship_City"
                              value={this.state.Ship_City}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Select City*"
                            >
                              <MenuItem>
                                <em>None</em>
                              </MenuItem>

                              {this.props.UserSCityData == (undefined || null)
                                ? null
                                : this.props.UserSCityData.map((key) => (
                                  <MenuItem value={key.value}>
                                    {key.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>

                          {this.state.formError.Ship_City !== "" ? (
                            <div className="error">
                              {this.state.formError.Ship_City}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-12">
                          <label>
                            <span></span>
                          </label>
                          <TextField
                            disabled={this.state.BillingSameAsShipping}
                            label="Ship Pin Code*"
                            name="Ship_Pincode*"
                            value={this.state.Ship_Pincode}
                            type="text"
                            variant="outlined"
                            size="small"
                            className="fullwidth"
                            id="demo-simple-select-outlined"
                            onChange={this.handleChange}
                          />
                          {this.state.formError.Ship_Pincode !== null ? (
                            <div className="error">
                              {this.state.formError.Ship_Pincode}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <FormControl
                            variant="outlined"
                            size="small"
                            className="formControl col-md-12"
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Select State*
                            </InputLabel>
                            <Select
                              onChange={(e) => {
                                this.getCityList(e, "S", "i");
                              }}
                              disabled={this.state.BillingSameAsShipping}
                              name="Ship_State"
                              value={this.state.Ship_State}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Select State*"
                            >
                              {/* <Select onChange={this.getCityList} name="RoleId" value={this.state.RoleId} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select State"> */}
                              <MenuItem>
                                <em>None</em>
                              </MenuItem>

                              {this.props.StateData == (undefined || null)
                                ? null
                                : this.props.StateData.map((key) => (
                                  <MenuItem value={key.value}>
                                    {key.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>

                          {this.state.formError.Ship_State !== "" ? (
                            <div className="error">
                              {this.state.formError.Ship_State}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-12">
                          <label>
                            <span></span>
                          </label>
                          <TextField
                            disabled={this.state.BillingSameAsShipping}
                            label="Shipping Address*"
                            name="Ship_Address"
                            value={this.state.Ship_Address}
                            type="text"
                            variant="outlined"
                            size="small"
                            className="fullwidth"
                            onChange={this.handleChange}
                          />
                          {this.state.formError.Ship_Address !== null ? (
                            <div className="error">
                              {this.state.formError.Ship_Address}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div className="col-md-12 mt-3 mb-3 text-center">
                {this.state.IsEdit === true ? (
                  <Button
                    onClick={(e) => this.submitForm(e)}
                    label={this.state.buttonLabel}
                    icon="pi pi-check"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12">
                Note: Fields marked as (*) are required
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          header="Confirmation"
          visible={this.props.showIsActiveDialog}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserRoleData: state.user.rollddl,
    UserData: state.dealer.items,
    StateData: state.dealer.stateddl,
    UserBCityData: state.dealer.bcityddl,
    UserSCityData: state.dealer.scityddl,
    UserFirmData: state.role.firmddl,
    TransportationModeData:
      state.dealer.transportationModeddl == undefined
        ? []
        : state.dealer.transportationModeddl,
    showDialog: state.role.showDialog,
    loading: state.company.loding,
    showIsActiveDialog: state.user.showIsActiveDialog,
    List:
      state.dealer.exportitems == (null || undefined)
        ? []
        : state.dealer.exportitems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(dealerMasterActions.getAll(obj));
    },
    createDealer: (user) => dispatch(dealerMasterActions.createDealer(user)),
    updateDealer: (user) => dispatch(dealerMasterActions.updateDealer(user)),
    inActiveRow: (user, isActive) =>
      dispatch(dealerMasterActions.inActiveRow(user, isActive)),
    onHideShow: (isbool) => dispatch(dealerMasterActions.onHideShow(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(dealerMasterActions.onHideShowIsActive(isbool)),
    getStateDDL: (data) => dispatch(dealerMasterActions.getStateDDL(data)),
    getFirmDDL: (data) => dispatch(dealerMasterActions.getFirmDDL(data)),
    getBCityDDL: (data) => dispatch(dealerMasterActions.getBCityDDL(data)),
    getSCityDDL: (data) => dispatch(dealerMasterActions.getSCityDDL(data)),
    getTransportationModeDDL: () =>
      dispatch(dealerMasterActions.getTransportationModeDDL()),
    loadingData: (isLoding) =>
      dispatch(dealerMasterActions.loadingData(isLoding)),
    getRoleDDL: (data) => dispatch(userMasterActions.getRoleDDL(data)),
    exportData: (obj) => {
      dispatch(dealerMasterActions.exportData(obj));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(dealerMaster);
