import { menuService } from "../services";
import { menuConstants } from "../constants";
import { alertActions } from ".";

export const menuActions = {
  getMenu,
  getAccessRight,
};

function getMenu(roleId) {
  return (dispatch) => {
    dispatch(request());

    menuService.fetchMenuData(roleId).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: menuConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: menuConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: menuConstants.GETALL_FAILURE, error };
  }
}

function getAccessRight(roleId) {
  // menuService.getAccessRight(roleId).then(response => {
  //     if (response && response.ResponseCode) {
  //         if (response.ResponseCode == "200") {
  //             //sessionStorage.setItem("assignedPermissionsForUser", JSON.stringify({ assigned: response.ResponseData[0].assignedPermissionsForUser }));
  //         }
  //     }
  // },
  //     error => {
  //     }
  // );

  return (dispatch) => {
    //dispatch(request());

    menuService.getAccessRight(roleId).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode == "200") {
            sessionStorage.removeItem("assignedPermissionsForUser");
            sessionStorage.setItem(
              "assignedPermissionsForUser",
              JSON.stringify({ assigned: response.ResponseData })
            );
          }
        }
      },
      (error) => {
        // dispatch(failure(error.toString()));
        //dispatch(alertActions.error(error.toString()));
      }
    );
  };
}
