export const LoyaltySchemeRptConstants ={
    GETALL_REQUEST: 'LOYALTYSCHEME_GETALL_REQUEST',
    GETALL_SUCCESS: 'LOYALTYSCHEME_GETALL_SUCCESS',
    GETALL_FAILURE: 'LOYALTYSCHEME_GETALL_FAILURE',

    GETEXPORT_REQUEST: 'LOYALTYSCHEME_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'LOYALTYSCHEME_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'LOYALTYSCHEME_GETEXPORT_FAILURE',

    LOADING_REQUEST: 'LOADING_REQUEST'
}