import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TextField from "@material-ui/core/TextField";
import { Calendar } from "primereact/calendar";
import {
  alertActions,
  mappingRptActions,
  QRCodeTrackingRptActions,
} from "../../actions";
import { connect } from "react-redux";
import tableRequest from "../../models/tableRequest.js";
import { Toast } from "primereact/toast";
import { CSVLink } from "react-csv";

class QRCodeTrackingRpt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDetail: false,
      journeyList: [],
      scanTxt: "",
      did: false,
      tid: false,
      qrs: true,
      sid: false,
      TIDproducts: [],
      iddata: [],
      expandedRows: "",
      iddata2: [],
      expandedRows2: "",
      activeIndex: 0,
    };
    this.items = [];
    this.ScanItem = this.ScanItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.rowExpansionTemplate2 = this.rowExpansionTemplate2.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
  }

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    // const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  toggle(e) {
    if (e.length == 0 || this.state.expandedRows.length > 0) {
      this.setState({ expandedRows: "" });
      this.setState({
        iddata: "",
      });
    } else {
      let uid = e.data[0].Id;
      if (uid.includes("SID")) {
        this.props.toggle2(uid);
      } else this.props.toggle(uid);
      // this.DispatchService.GetScanInfo(uid).then((res) => {
      //     const resp = res.data
      //     let qr = []
      //     let val
      //     (resp.ResponseData).forEach(element => {
      //         if (val == element.Id) { }
      //         else {
      //             val = element.Id;
      //             qr.push(element)
      //         }
      //     });
      //     this.setState({
      //         iddata: qr
      //     })
      // })
      this.setState({ expandedRows: e.data });
    }
  }

  toggle2(e) {
    //console.log(this.state.expandedRows);
    if (e.length == 0 || this.state.expandedRows2.length > 0) {
      this.setState({ expandedRows2: "" });
      this.setState({
        iddata2: "",
      });
    } else {
      let uid = e.data[0].Id;
      this.props.toggle2(uid);
      // this.DispatchService.GetScanInfo(uid).then((res) => {
      //     const resp = res.data
      //     let qr = []
      //     let val
      //     (resp.ResponseData).forEach(element => {
      //         if (val == element.SeqNo) { }
      //         else {
      //             val = element.Id;
      //             qr.push(element)
      //         }
      //     });
      //     this.setState({
      //         iddata2: qr
      //     })
      // })
      this.setState({ expandedRows2: e.data });
    }
  }

  // scan qr code
  ScanItem(e) {
    if (e.key === "Enter") {
      let qr = e.target.value;

      // if (qr.includes("iil")||qr.includes("104")|| (qr.includes("SID")||qr.includes("TID")))
      //  {
      if (qr.includes("DID")) {
        this.props.GetScanInfo(qr);
        // this.DispatchService.GetScanInfo(qr).then((data) => {
        //     const res = data.data
        //     if (res.ResponseCode === "200") {
        //         this.setState({
        //             qrs: false,
        //             TIDproducts: res.ResponseData,
        //             did: true,
        //             tid: false,
        //             sid: false
        //         })
        //     }
        // })
      } else {
        qr = qr.includes("=") ? qr.split("=")[1] : qr;
        this.props.GetJourney(qr);
        // this.DispatchService.GetJourney(qr).then((data) => {
        //     ;
        //     const resdata = data.data;
        //     //if response data not null
        //     if (resdata !== null && resdata.ResponseCode === "200") {

        //         //  this.items = [] = [];
        //         this.setState({
        //             journeyList: resdata.ResponseData,
        //             qrs: true,
        //             did: false,
        //             tid: false,
        //             sid: false
        //         });

        //     } else this.refs.Messchild.showWarn(resdata.ResponseMessage);
        // });
      }
      this.setState({
        scanTxt: "",
      });
    }
  }

  rowExpansionTemplate(data) {
    if (data.Id.includes("SID")) {
      return (
        <div className="orders-subtable">
          <h4>Items In {data.Id}</h4>
          <DataTable value={this.props.iddata2}>
            <Column field="ProductName" header="Product Name" sortable></Column>
            <Column field="Qty" header="Total Qty" sortable></Column>
            <Column field="date" header="Date of Packing" sortable></Column>
          </DataTable>
        </div>
      );
    } else if (data.Id.includes("TID")) {
      return (
        <div className="orders-subtable">
          <h4>Items In {data.Id}</h4>
          <DataTable
            value={this.props.iddata}
            expandedRows={this.state.expandedRows2}
            onRowToggle={(e) => {
              this.toggle2(e);
            }}
            rowExpansionTemplate={this.rowExpansionTemplate2}
          >
            <Column expander style={{ width: "3em" }} />
            <Column field="Id" header="Id" sortable></Column>
            <Column field="Qty" header="Total Product Qty"></Column>
            <Column field="date" header="Date of Packing" sortable></Column>
          </DataTable>
        </div>
      );
    }
  }
  rowExpansionTemplate2(data) {
    return (
      <div className="orders-subtable">
        <h4>Items In {data.Id}</h4>
        <DataTable value={this.props.iddata2}>
          <Column field="ProductName" header="Product Name" sortable></Column>
          <Column field="Qty" header="Total Qty" sortable></Column>
          <Column field="date" header="Date of Packing" sortable></Column>
        </DataTable>
      </div>
    );
  }

  render() {
    const item = [];
    for (const [index, value] of this.props.journeyList.entries()) {
      item.push(
        <li key={index}>
          <div dangerouslySetInnerHTML={{ __html: value.activity }}></div>{" "}
          {value.date ? (
            <span>
              <i className="fa fa-clock-o"></i> {value.date}
            </span>
          ) : null}{" "}
        </li>
      );
    }
    const itemLength = 60 * item.length + "px";
    itemLength.toString();
    const mystyle = {
      height: itemLength,
    };

    return (
      <>
        {/* <MessageComponent ref="Messchild" /> */}

        <div className="heading m-4">
          <h1>QR Code Tracking Report</h1>
        </div>
        <div className="col-md-9">
          <input
            style={{ width: "400px" }}
            onChange={(e) => {
              this.handleChange(e);
            }}
            name="scanTxt"
            value={this.state.scanTxt}
            type="search"
            autoComplete="off"
            className="form-control ml-2"
            id="scan"
            onKeyDown={(e) => {
              this.ScanItem(e);
            }}
            autoFocus
          />
        </div>
        <div className="col-md-12 mt-5 " style={{ marginBottom: "10vh" }}>
          {this.props.qrs && (
            <ul className="timeline" style={mystyle}>
              {item}
            </ul>
          )}
        </div>
        <div>
          {this.props.did && (
            <DataTable
              value={this.props.TIDproducts}
              expandedRows={this.state.expandedRows}
              onRowToggle={(e) => {
                this.toggle(e);
              }}
              rowExpansionTemplate={this.rowExpansionTemplate}
            >
              <Column expander style={{ width: "3em" }} />
              <Column field="Id" header="Name" sortable />
              <Column field="Qty" header="Total Qty" />
              <Column field="date" header="Date" sortable />
            </DataTable>
          )}
          {this.props.tid && (
            <DataTable
              value={this.props.iddata}
              expandedRows={this.state.expandedRows2}
              onRowToggle={(e) => {
                this.toggle2(e);
              }}
              rowExpansionTemplate={this.rowExpansionTemplate2}
            >
              <Column expander style={{ width: "3em" }} />
              <Column field="Id" header="Name" sortable />
              <Column field="Qty" header="Total Product Qty"></Column>
              <Column field="date" header="Date" sortable />
            </DataTable>
          )}
          {this.props.sid && (
            <DataTable value={this.props.iddata2}>
              <Column
                field="ProductName"
                header="Product Name"
                sortable
              ></Column>
              <Column field="SeqNo" header="Id" sortable></Column>
              <Column field="date" header="Date of Packing" sortable></Column>
            </DataTable>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("state.QRCodeTrackingRpt", state.QRCodeTrackingRpt);
  return {
    TIDproducts: state.QRCodeTrackingRpt.TIDproducts,
    iddata2: state.QRCodeTrackingRpt.iddata2,
    iddata: state.QRCodeTrackingRpt.iddata,
    loading: state.verification.loding,
    journeyList:
      state.QRCodeTrackingRpt.journeyList == undefined
        ? []
        : state.QRCodeTrackingRpt.journeyList,
    did: state.QRCodeTrackingRpt.did,
    tid: state.QRCodeTrackingRpt.tid,
    sid: state.QRCodeTrackingRpt.sid,
    qrs: state.QRCodeTrackingRpt.qrs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle: (obj) => {
      dispatch(QRCodeTrackingRptActions.toggle(obj));
    },
    toggle2: (obj) => {
      dispatch(QRCodeTrackingRptActions.toggle2(obj));
    },

    GetScanInfo: (data) => dispatch(QRCodeTrackingRptActions.GetScanInfo(data)),
    GetJourney: (data) => dispatch(QRCodeTrackingRptActions.GetJourney(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeTrackingRpt);
