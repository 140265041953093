export const CartonConstants = {
  CREATE_REQUEST: 'CARTON_CREATE_REQUEST',
  CREATE_SUCCESS: 'CARTON_CREATE_SUCCESS',
  CREATE_FAILURE: 'CARTON_CREATE_FAILURE',

  UPDATE_REQUEST: 'CARTON_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CARTON_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CARTON_UPDATE_FAILURE',

  GETALL_REQUEST: 'CARTON_GETALL_REQUEST',
  GETALL_SUCCESS: 'CARTON_GETALL_SUCCESS',
  GETALL_FAILURE: 'CARTON_GETALL_FAILURE',

  GETCARTONDDL_REQUEST: 'CARTON_DDL_REQUEST',
  GETCARTONDDL_SUCCESS: 'CARTON_DDL_SUCCESS',
  GETCARTONDDL_FAILURE: 'CARTON_DDL_FAILURE',

  GETALL_AVAILABLE_REQUEST: 'CARTON_GETALL_AVAILABLE_REQUEST',
  GETALL_AVAILABLE_SUCCESS: 'CARTON_GETALL_AVAILABLE_SUCCESS',
  GETALL_AVAILABLE_FAILURE: 'CARTON_GETALL_AVAILABLE_FAILURE',

  DELETE_REQUEST: 'CARTON_DELETE_REQUEST',
  DELETE_SUCCESS: 'CARTON_DELETE_SUCCESS',
  DELETE_FAILURE: 'CARTON_DELETE_FAILURE',

  ISACTIVE_REQUEST: 'CARTON_ISACTIVE_REQUEST',
  ISACTIVE_SUCCESS: 'CARTON_ISACTIVE_SUCCESS',
  ISACTIVE_FAILURE: 'CARTON_ISACTIVE_FAILURE',

  DIALOG_REQUEST: 'DIALOG_REQUEST',

  //DIALOGUE_REQUEST:'DIALOGUE_REQUEST',

  LOADING_REQUEST: 'LOADING_REQUEST',

  DIALOG_ISACTIVE_REQUEST: 'DIALOG_ISACTIVE_REQUEST'
};