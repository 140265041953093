import React, { Component } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
// import UserService from '../../services/UserService';
import { BreadCrumb } from "primereact/breadcrumb";
// import CartonMasterService from '../../services/CartonMasterService';
// import SecondaryPackingService from '../../services/SecondaryPackingService';
// import PrintSecondaryPackagingService from '../../services/PrintSecondaryPackagingService';
// import MappingService from '../../services/MappingService';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import tableRequest from "../../models/tableRequest.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import { ConfirmationModal } from '../../template/ConfirmationModal';
// import MessageComponent from '../../template/Message';
// import $ from 'jquery';
// import Loaders from '../../templates/Loaders';
import { connect } from "react-redux";
import {
  alertActions,
  CartonActions,
  secondaryPackingActions,
} from "../../actions";
import alreadyPacked from "../../assets/audio/AlreadyScanned.mp3";
import invalidScan from "../../assets/audio/InvalidScan.mp3";

class SecondaryPacking extends React.Component {
  constructor(props) {
    super(props);
    this.autoref = React.createRef();
    // this.UserService = new UserService();
    // this.CartonMasterService = new CartonMasterService();
    // this.MappingService = new MappingService();
    // this.SecondaryPackingService = new SecondaryPackingService();
    // this.PrintSecondaryPackagingService = new PrintSecondaryPackagingService();
    this.tblResponse = new tableRequest();
    this.alreadyPacked = new Audio(alreadyPacked);
    this.invalidScan = new Audio(invalidScan);
    this.state = {
      reason: "",
      CartonName: "",
      Tvisible: false,
      rvisible: false,
      globalFilter: null,
      visible: false,
      sortIcon: false,
      productLoading: true,
      cartoonLoading: false,
      sortOrder: "1",
      formError: {},
      CartonDDL: [],
      CartonId: [],
      buttonLabel: "Submit",
      sales: [],
      scanItems: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      IsDisabledCartonDDL: false,
      scanTxt: "",
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitFormFinal = this.submitFormFinal.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.onSort = this.onSort.bind(this);

    this.ScanItem = this.ScanItem.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    this.tblResponse.SortColumn = "CartonId";
    setTimeout(() => {
      this.props.GetSecondaryPacking(this.tblResponse);
    }, 100);

    // $('.p-column-title').on('click', function () {
    //     $(this).next().removeClass('pi-sort').toggleClass('pi-sort-up').addClass('pi-sort-down');
    // });

    this.props.fetchCartonList();
  }

  componentDidUpdate() {
    if (this.props.alreadyPackedAudio) {
      this.alreadyPacked.play();
      this.props.alreadyPacked(false);
    } else if (this.props.warningAudio) {
      this.invalidScan.play();
      this.props.warning(false);
    }
  }

  // PAGE EVENT CALL
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.GetSecondaryPacking(this.tblResponse);
    }, 250);
  }

  // set lazy loading values
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // get users data list from api
  // GetSecondaryPacking() {
  //     this.tblResponse.SortColumn = 'CartonId';
  //     this.SecondaryPackingService.GetSecondaryPacking(this.tblResponse).then(data => {

  //         //if ResponseData is not null
  //         const resdata = data.data;
  //         this.setState({ cartoonLoading: true });
  //         if (resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
  //             this.datasource = resdata.ResponseData;
  //             this.setState({
  //                 totalRecords: resdata.ResponseData[0].TotalRows,
  //                 first: (this.tblResponse.First - 1),
  //                 sales: this.datasource.slice(0, ((this.tblResponse.First - 1) + this.tblResponse.PageSize)),
  //                 loading: false
  //             });
  //         }
  //         //if ResponseData is null
  //         else {
  //             this.datasource = null;
  //             this.setState({
  //                 totalRecords: 0,
  //                 first: 1,
  //                 sales: null,
  //                 loading: false
  //             });
  //         }
  //     });
  // }

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    //const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "UserName":
        if (value.length < 1) {
          IsValid = false;
          errors.UserName = "Please enter your username.";
        } else errors.UserName = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  // reset form text value
  formReset() {
    this.setState({
      IsDisabledCartonDDL: false,
      Tvisible: false,
      ReferenceID: "",
      CartonName: "",
      reason: "",
      rvisible: false,
      reason: "",
      UserName: "",
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      IsActive: true,
      CartonId: [],
      reason: "",
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // open Dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }

  submitForm(event) {
    if (this.props.scanItems == undefined) {
      this.props.showWarn("Scan First");
      return;
    }
    // else if (this.state.CartonId.CartonSize != this.props.scanItems.length && this.state.reason.trim().length < 4) {

    //     ; // let reason = prompt("Carton is not fully Packed. \n Please Specify the Reason in min 5 chars Max 500 Chars", "");
    //     // reason = reason != null ? reason.trim() : "";
    //     this.setState({ rvisible: true })
    // }
    else {
      this.setState({ Tvisible: true });
    }
  }

  // submit packing
  submitFormFinal(event) {
    event.preventDefault();
    if (this.props.scanItems == undefined) {
      this.props.showWarn("Scan First");
      return;
    }
    // ;
    // if (this.state.CartonId.CartonSize != this.props.scanItems.length && this.state.reason.trim().length < 4) {

    //     ; // let reason = prompt("Carton is not fully Packed. \n Please Specify the Reason in min 5 chars Max 500 Chars", "");
    //     // reason = reason != null ? reason.trim() : "";
    // }
    else {
      this.setState({ productLoading: false });
      const formData = {
        //createdBy
        // cartonMasterId: this.state.CartonId.CartonId,
        secondaryPackingDetails: this.props.scanItems,
        reason: this.state.reason,
        CartonId: this.state.CartonName,
      };

      this.setState({ Tvisible: false });
      setTimeout(() => {
        // this.SecondaryPackingService.Save(formData).then(data => {
        //     const resdata = data.data;
        //     this.setState({ productLoading: true });
        //     //if ResponseCode is 200 success else error or warning message
        //     if (resdata !== null && resdata.ResponseCode === '200') {

        //         this.refs.Messchild.showSuccess(resdata.ResponseMessage);
        //         // this.PrintSecondaryPackagingService.formPreview1([resdata.ResponseData]);
        //         this.formReset();
        //         // this.setState({
        //         //     loading: true,
        //         //     visible: false
        //         // });

        //         // this.state.scanItems = [];
        //         // this.setState({
        //         //     scanItems: []
        //         // });
        //         this.GetSecondaryPacking();
        //         this.autoref.current.focus();
        //     } else if (resdata.ResponseCode === '400') {
        //         this.refs.Messchild.showWarn(resdata.ResponseMessage);
        //     } else {
        //         this.setState({ CartonName: '' })
        //         this.refs.Messchild.showError(resdata.ResponseMessage);
        //     }
        // });
        this.setState({ productLoading: true });
        this.props.saveSecondaryPacking(formData);
        this.setState({ reason: "" });
        this.formReset();
        this.autoref.current.focus();
      }, 100);
    }
  }

  // get active carton drop down list
  GetActiveCartonDDL() {
    this.CartonMasterService.GetActiveCartonDDL().then((data) => {
      const resdata = data.data;
      //if ResponseCode is 200
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          CartonDDL: resdata.ResponseData,
        });
      }
    });
  }

  // scan qr code
  ScanItem(e) {
    if (e.key === "Enter") {
      let qr = e.target.value;

      if (
        qr.includes("hytech") ||
        qr.includes("localhost") ||
        qr.includes("104") ||
        qr.includes("194")
      ) {
        /////////for multiline qr code on KREPL//
        const splitQr = qr.split("=");
        ////console.log(splitQr[1]);
        /////////for multiline qr code on KREPL//

        /////////for multiline qr code on KREPL//  splitQr[splitQr.length-1].trim()
        qr = splitQr[1] === undefined ? qr : splitQr[1];
        /////////for multiline qr code on KREPL//
      }

      // if (this.state.CartonId.length == 0) {
      //     this.props.showWarn("Select Carton Size");
      //     this.setState({ scanTxt: '' })
      //     return;
      // }

      // else if (this.state.CartonId.CartonSize == this.props.scanItems?.length) {
      //     this.setState({
      //         scanTxt: ""
      //     });
      //     this.props.showWarn("Carton Full");
      //     return;
      // }

      const fObj = { QrData: qr };
      this.props.GetProductByQrScan(fObj, this.props.scanItems);
      // this.MappingService.GetProductByQrScan(fObj).then(data => {

      //     const resdata = data.data;
      //     //if ResponseCode is 200 success else error or warning message
      //     if (resdata !== null && resdata.ResponseCode === 200) {

      //         if (this.state.scanItems.length > 0) {

      //             let checkseqNo = this.state.scanItems.filter(y => y.SeqNo == resdata.SeqNo)
      //             if (checkseqNo.length > 0) {
      //                 this.refs.Messchild.showWarn("already Scanned");
      //                 return;
      //             }
      //             let IscheckSameProduct = this.state.scanItems.filter(y => y.ProductID == resdata.ProductID)
      //             if (IscheckSameProduct.length == 0) {
      //                 this.refs.Messchild.showWarn("You can pack only same product");
      //                 return;
      //             }
      //         }

      //         // const newArray = this.state.scanItems.slice(); // Create a copy
      //         // newArray.push(resdata); // Push the object
      //         // this.setState({ scanItems: newArray });

      //         let lists = this.state.scanItems;
      //         lists.push(resdata);

      //         this.setState({
      //             scanItems: lists
      //         });

      //         //  ////console.log(this.state.scanItems);
      //         this.setState({
      //             IsDisabledCartonDDL: true,
      //             scanTxt: "" //KREP-55 defect
      //         })
      //         if (this.state.CartonId.CartonSize == this.state.scanItems.length) {
      //             this.submitForm(e);
      //         }
      //     }
      //     else
      //         this.refs.Messchild.showWarn(resdata.ResponseMessage);
      // });

      this.setState({
        scanTxt: "",
      });

      // this.setState({
      //     IsDisabledCartonDDL: true,
      //     scanTxt: "" //KREP-55 defect
      // })

      e.target.value = "";
    }
  }

  // delete user data
  deleteScanned(SeqNo) {
    ////console.log(this.props.scanItems);
    let arrayfilter = this.props.scanItems.filter((x) => x.SeqNo != SeqNo);
    // this.setState({
    //     scanItems: arrayfilter
    // });
    ////console.log(this.props.scanItems);
  }

  // seach table
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.props.GetSecondaryPacking(this.tblResponse);
  }

  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

  // disabled checkbox
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // Sort table
  onSort(event) {
    this.setState({
      sortOrder: this.state.sortOrder === 1 ? 2 : 1,
      sortIcon: !this.state.sortIcon,
    });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.props.GetSecondaryPacking(this.tblResponse);
    const clickData = document.getElementsByClassName(
      "p-sortable-column-icon pi pi-fw pi-sort"
    );
    ////console.log(event);
  }

  // edit form
  actionTemplate(rowData, column) {
    const deleteFunc = () => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.props.removeItem(rowData.SeqNo),
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    };

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-trash"
          onClick={deleteFunc}
          className="p-button-danger"
        ></Button>
      </span>
    );
  }

  // create html part
  render() {
    const items = [{ label: "Transaction" }, { label: "Secondary Packing" }];
    const TabView = (
      <Dialog
        className="popup-modal py-3 text-center"
        style={{ width: "30%" }}
        header="Scan the Carton Slip For Secodary Packing"
        visible={this.state.Tvisible}
        modal={true}
        onHide={() => {
          this.setState({ Tvisible: false });
        }}
      >
        <div className="py-3 text-center">
          {" "}
          <input
            type="text"
            value={this.state.CartonName}
            name="CartonName"
            onChange={(e) => {
              this.setState({ CartonName: e.target.value.toString().trim() });
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                //if (e.target.value.includes(sessionStorage.getItem('Prefix')) && e.target.value.includes('SID')) {
                if (e.target.value.includes("SID")) {
                  this.submitFormFinal(e);
                } else {
                  this.props.showWarn("Invalid Cartons");
                  this.setState({ CartonName: "" });
                }
              }
            }}
            autoFocus
          />
        </div>
      </Dialog>
    );
    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div className="row">
        <div style={{ marginTop: "7px" }} className="col-md-2">
          <label>
            <h4>Secondary Packing</h4>
          </label>
          {/* <h4 className="leftspace">Secondary Packing</h4> */}
        </div>
        <div className="col-md-2 mapping text-right">
          <label>Scan items:</label>
        </div>
        <div className="col-md-3 text-left">
          <div className="form-group pl-3" style={{ margin: "0rem" }}>
            <input
              name="scanTxt"
              ref={this.autoref}
              value={this.state.scanTxt}
              type="search"
              className="form-control ml-2"
              onChange={(e) => {
                this.handleChange(e);
              }}
              id="scanTxt"
              onKeyDown={(e) => {
                this.ScanItem(e);
              }}
            />
          </div>
        </div>
        <div className="col-md-5 text-right">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );

    let header2 = (
      <div className="row">
        <div className="col-md-4 text-left">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <div className="card">
          <div>
            <audio hidden src={this.alreadyPacked} type="audio/mpeg" controls />
            <audio hidden src={this.invalidScan} type="audio/mpeg" controls />
            {/* <MessageComponent ref="Messchild" /> */}
            {/* <ConfirmationModal parentCallback={this.callbackFunction} ref="child" /> */}
            {/* <div className="row head_data">
                            <div className="col-md-6 heading">
                                <h4 className="leftspace">Secondary Packing</h4>
                            </div>
                            <div className="col-md-6 custom-breadcrumb">
                                <BreadCrumb model={items} home={home} />
                            </div>
                        </div> */}
            {/* <Loaders loader={this.state.productLoading} classType="small" /> */}
            <DataTable
              responsive={true}
              header={header}
              emptyMessage={this.state.emptyMessage}
              value={this.props.scanItems}
              paginator={true}
              currentPageReportTemplate="Total Items Added: {totalRecords}, Showing {first} to {last}"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              sortMode="multiple"
              totalRecords={this.state.scanItems.length}
              rows={this.state.rows}
              className="tblAuto"
              globalFilter={this.state.globalFilter}
            >
              <Column field="SeqNo" header="Visible No" sortable={true} />
              <Column field="BatchNo" header="Batch No" sortable={true} />
              <Column
                field="ProductName"
                header="Product Name"
                sortable="custom"
              />
              {/* <Column field="MFGDate" header="MFG Date" /> */}
              <Column body={this.actionTemplate} header="Action" />
            </DataTable>
            <div className="col-md-12 text-center mt-4 mb-4">
              <button className="btn btn-primary" onClick={this.submitForm}>
                Submit
              </button>
            </div>
            <Dialog
              visible={this.state.rvisible}
              className="popup-modal"
              style={{ width: "30%" }}
              modal={true}
              header="Fractional Carton Remark"
              onHide={() => {
                this.setState({ rvisible: false });
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <h5>Define in min 5 char</h5>
                  <label>
                    Submit Remark<span>*</span>:
                  </label>
                  <input
                    type="text"
                    style={{ width: "95%", height: "30px" }}
                    name="reason"
                    value={this.state.reason}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-12 text-center mt-4">
                <button
                  type="button"
                  onClick={(e) => {
                    this.setState({ rvisible: false });
                    this.submitForm(e);
                  }}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </Dialog>
            <div className="col-md-12 p-0">
              {/* <Loaders loader={this.state.cartoonLoading} classType="small" /> */}
              <DataTable
                responsive={true}
                header={header2}
                globalFilter={this.state.globalFilter}
                emptyMessage={this.state.emptyMessage}
                value={this.props.secondaryItems}
                paginator={true}
                rows={this.state.rows}
                rowsPerPageOptions={[10, 50, 200, 500]}
                totalRecords={parseInt(this.state.totalRecords)}
                lazy={true}
                first={this.state.first}
                onPage={this.onPage}
                onSort={this.onSort}
                className="tblAuto"
              >
                {/* <Column field="Id" headerStyle={{ width: '140px' }} header="Id" sortable={true} /> */}
                <Column
                  field="CartonId"
                  headerStyle={{ width: "250px" }}
                  header="CartonId"
                  sortable={true}
                />
                <Column
                  field="TotalQty"
                  headerStyle={{ width: "250px" }}
                  header="Total Qty"
                />
                <Column
                  field="CreatedDt"
                  headerStyle={{ width: "250px" }}
                  header="Created Date"
                  sortable={true}
                />
                {/* <Column field="CartonName" headerStyle={{ width: '250px' }} header="Carton Size" style={{ width: '200px' }} /> */}
                {/* <Column field="reason" headerStyle={{ width: '250px' }} header="Reason of Fractional" style={{ width: '200px' }} /> */}
              </DataTable>

              <br />
              <br />
            </div>
            {TabView}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    CartonDDL: state.Carton?.cartonddl,
    scanItems: state.secondaryPacking?.scanneditems,
    secondaryItems: state.secondaryPacking?.secondaryItems,
    warningAudio: state.secondaryPacking?.warning,
    alreadyPackedAudio: state.secondaryPacking?.alreadyPacked,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCartonList: () => {
      dispatch(CartonActions.GetActiveCartonDDL());
    },
    GetProductByQrScan: (QrData, scanneditems) =>
      dispatch(
        secondaryPackingActions.getProductByQrScan(QrData, scanneditems)
      ),
    removeItem: (data) => dispatch(secondaryPackingActions.removeItem(data)),
    saveSecondaryPacking: (formData) =>
      dispatch(secondaryPackingActions.saveSecondaryPacking(formData)),
    showWarn: (message) => dispatch(alertActions.warning(message)),
    GetSecondaryPacking: (tblObj) =>
      dispatch(secondaryPackingActions.getSecondaryPacking(tblObj)),
    warning: (bool) => dispatch(secondaryPackingActions.warning(bool)),
    alreadyPacked: (bool) =>
      dispatch(secondaryPackingActions.alreadyPacked(bool)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SecondaryPacking);
