import { OrderBookingConstants } from "../constants";

export function OrderBooking(state = {}, action) {
  switch (action.type) {
    case OrderBookingConstants.LOADING_REQUEST:
      return {
        ...state,
        loding: action.isbool,
      };
    case OrderBookingConstants.DIALOG_REQUEST:
      return {
        ...state,
        showDialog: action.isbool,
      };
    case OrderBookingConstants.DIALOG_ISACTIVE_REQUEST:
      return {
        ...state,
        showIsActiveDialog: action.isbool,
      };
    case OrderBookingConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case OrderBookingConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };
    case OrderBookingConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case OrderBookingConstants.CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case OrderBookingConstants.CREATE_SUCCESS:
      return {
        ...state,
        items:
          state.items == null ? [action.data] : [action.data, ...state.items],
      };
    case OrderBookingConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case OrderBookingConstants.UPDATE_REQUEST:
      return {
        ...state,
        items: state.items.map((item) =>
          item.OrderBookingNo == action.data.OrderBookingNo
            ? { ...item, updating: true }
            : item
        ),
      };
    case OrderBookingConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.ProductId === action.data.ProductId ? action.data : item
        ),
      };
    case OrderBookingConstants.GETDEALERDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case OrderBookingConstants.GETDEALERDDL_SUCCESS:
      return {
        ...state,
        dealerddl: action.datas,
      };
    case OrderBookingConstants.GETDEALERDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case OrderBookingConstants.GETSEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case OrderBookingConstants.GETSEARCH_SUCCESS:
      return {
        ...state,
        searchData: action.datas,
      };
    case OrderBookingConstants.GETSEARCH_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case OrderBookingConstants.UPLOAD_SO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case OrderBookingConstants.UPLOAD_SO_SUCCESS:
      return {
        ...state,
        uploadRes: action.datas,
      };
    case OrderBookingConstants.UPLOAD_SO_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case OrderBookingConstants.GET_SO_LOG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case OrderBookingConstants.GET_SO_LOG_SUCCESS:
      return {
        ...state,
        logData: action.datas,
      };
    case OrderBookingConstants.GET_SO_LOG_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case OrderBookingConstants.GETPRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case OrderBookingConstants.GETPRODUCTS_SUCCESS:
      return {
        ...state,
        orderProducts: action.datas,
      };
    case OrderBookingConstants.GETCMPNYDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case OrderBookingConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case OrderBookingConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case OrderBookingConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.OrderBookingNo === action.data
            ? {
                OrderBookingNo: item.OrderBookingNo,
                ProductId: item.ProductId,
                Qty: item.Qty,
                Remark: item.Remark,

                //CompanyName: item.CompanyName,
                //CompanyDisplayName: item.CompanyDisplayName,
                //Prefix: item.Prefix,
                //Email: item.Email,
                //Mobile: item.Mobile,
                //WebsiteURL: item.WebsiteURL,
                //Address: item.Address,
                //CompanyLogo: item.CompanyLogo,
                //FakeMessage: item.FakeMessage,
                //FakeAudio: item.FakeAudio,
                IsActive: false,
                CreatedDate: item.CreatedDate,
                UpdatedDate: item.UpdatedDate,
              }
            : item
        ),
      };
    case OrderBookingConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case OrderBookingConstants.GETCMPNYDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    // case OrderBookingConstants.GETCMPNYDDL_SUCCESS:
    //     return {
    //         ...state,
    //         cmpnyddl: action.datas
    //     };
    case OrderBookingConstants.GETCMPNYDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case OrderBookingConstants.ISACTIVE_REQUEST:
      return {
        ...state,
        activating: true,
      };
    case OrderBookingConstants.ISACTIVE_SUCCESS:
      return {
        ...state,
        success: state.items,
      };
    case OrderBookingConstants.ISACTIVE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case OrderBookingConstants.GETORDERDTL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case OrderBookingConstants.GETORDERDTL_SUCCESS:
      ;
      return {
        ...state,
        OrderDetail: action.data,
      };
    case OrderBookingConstants.GETORDERDTL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
