export const companyConstants = {
    CREATE_REQUEST: 'COMPANY_CREATE_REQUEST',
    CREATE_SUCCESS: 'COMPANY_CREATE_SUCCESS',
    CREATE_FAILURE: 'COMPANY_CREATE_FAILURE',

    UPDATE_REQUEST: 'COMPANY_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'COMPANY_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'COMPANY_UPDATE_FAILURE',

    GETALL_REQUEST: 'COMPANY_GETALL_REQUEST',
    GETALL_SUCCESS: 'COMPANY_GETALL_SUCCESS',
    GETALL_FAILURE: 'COMPANY_GETALL_FAILURE',

    DELETE_REQUEST: 'COMPANY_DELETE_REQUEST',
    DELETE_SUCCESS: 'COMPANY_DELETE_SUCCESS',
    DELETE_FAILURE: 'COMPANY_DELETE_FAILURE',
    
    ISACTIVE_REQUEST: 'COMPANY_REQUEST',
    ISACTIVE_SUCCESS: 'COMPANY_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE: 'COMPANY_ISACTIVE_FAILURE',
    
    GETCMPNYDDL_REQUEST: 'COMPANY_GETCMPNYDDL_REQUEST',
    GETCMPNYDDL_SUCCESS: 'COMPANY_GETCMPNYDDL_SUCCESS',
    GETCMPNYDDL_FAILURE: 'COMPANY_GETCMPNYDDL_FAILURE',

    DIALOG_REQUEST:'DIALOG_REQUEST',

    LOADING_REQUEST:'LOADING_REQUEST',

    DIALOG_ISACTIVE_REQUEST:'DIALOG_ISACTIVE_REQUEST'
};