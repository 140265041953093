import { DispatchService } from "../services/DispatchService";
import PrintDispatchService from "../services/PrintDispatchService";
import { DispatchConstants } from "../constants/DispatchConstants";
import { alertActions } from ".";

export const DispatchActions = {
  getAll,
  GetActiveDispatchDDL,
  CreateDispatch,
  openDialog,
  inActiveRow,
  onHideShow,
  onHideShoww,
  loadingData,
  onHideShowIsActive,
  fetchSODetails,
  warning,
  alreadyPacked,
};

function getAll(data) {
  return (dispatch) => {
    dispatch(request());
    DispatchService.GetDispatch(data).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: DispatchConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: DispatchConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: DispatchConstants.GETALL_FAILURE, error };
  }
}

function GetActiveDispatchDDL() {
  return (dispatch) => {
    dispatch(request());

    DispatchService.GetActiveDispatchDDL().then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: DispatchConstants.GETDispatchDDL_REQUEST };
  }
  function success(datas) {
    return { type: DispatchConstants.GETDispatchDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: DispatchConstants.GETDispatchDDL_FAILURE, error };
  }
}

function CreateDispatch(data) {
  return (dispatch) => {
    dispatch(request(data));
    DispatchService.CreateDispatch(data).then(
      (response) => {
        debugger;
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            debugger;
            dispatch(success(response));
            dispatch(onHideShow(false));
            this.PrintDispatchService = new PrintDispatchService();
            this.PrintDispatchService.Printpdf(response.ResponseData);
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: DispatchConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: DispatchConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: DispatchConstants.CREATE_FAILURE, error };
  }
}

function inActiveRow(user, isActive) {
  var data = {
    UserId: user,
    IsActive: isActive,
  };
  return (dispatch) => {
    dispatch(request());

    DispatchService.inActiveRow(user, isActive).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: DispatchConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: DispatchConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: DispatchConstants.ISACTIVE_FAILURE, error };
  }
}

function fetchSODetails(SONo) {
  return (dispatch) => {
    dispatch(request(SONo));

    DispatchService.fetchSODetails(SONo).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(response));
            dispatch(onHideShowIsActive(false));
            //dispatch(alertActions.success(response.ResponseMessage))
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: DispatchConstants.SO_DETAILS_REQUEST };
  }
  function success(data) {
    return { type: DispatchConstants.SO_DETAILS_SUCCESS, data };
  }
  function failure(error) {
    return { type: DispatchConstants.SO_DETAILS_FAILURE, error };
  }
}

function onHideShow(isbool) {
  return {
    type: DispatchConstants.DIALOG_REQUEST,
    isbool,
  };
}
function onHideShoww(isbool) {
  return {
    type: DispatchConstants.DIALOGUE_REQUEST,
    isbool,
  };
}

function loadingData(isbool) {
  return {
    type: DispatchConstants.LOADING_REQUEST,
    isbool,
  };
}

function onHideShowIsActive(isbool) {
  return {
    type: DispatchConstants.DIALOG_ISACTIVE_REQUEST,
    isbool,
  };
}

function alreadyPacked(isbool) {
  return {
    type: DispatchConstants.Alreadypacked,
    isbool,
  };
}

function warning(isbool) {
  return {
    type: DispatchConstants.Warning,
    isbool,
  };
}

function openDialog(data) {
  return {
    type: DispatchConstants.OPEN_DIALOG_REQUEST,
    data,
  };
}
