import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Dialog } from "primereact/dialog";
import { ToastContainer, toast } from "react-toastify";
import { mappingActions, productMasterActions } from "../../actions";
import tableRequest from "../../models/tableRequest.js";

class Mapping extends React.Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitseries = this.submitseries.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
    this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onHidee = this.onHidee.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.openDialogue = this.openDialogue.bind(this);
    this.resetFormHide = this.resetFormHide.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.state = {
      IsAdd: true,
      IsClick: true,
      IsEdit: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
      confirm: false,
      MFGDate: new Date(),
      EXPDate: new Date(),
      buttonLabel: "Submit",
      buttonLabel1: "Upload Certificate",
      formHeading: "Add Mapping",
      displayModal: false,
      switchAction: false,
      showSnackbar: false,
      displayConfirmation: false,
      FileName: "",
      ProductNameDLL: [],
      MappingId: 0,
      CreatedDate: null,
      UpdatedDate: null,
      ProductId: "",
      ProductName: "",
      FromNo: 1,
      ToNo: "",
      Qty: "",
      BatchNo: "",
      IsActive: true,
      Issueseriesdata: [],
      selectedProduct: null,
      formError: {
        ProductId: "",
        FromNo: "",
        ToNo: "",
        Qty: "",
      },
      serieserror: {
        FromNo: "",
        ToNo: "",
        Qty: "",
      },
    };
  }
  //to trigger api on page call
  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);
      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });
      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        }
      }
    }
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 7);
    this.tblResponse.FromDate = date.toLocaleDateString();
    this.tblResponse.ToDate = new Date().toLocaleDateString();
    this.setState({
      MFGDate: new Date(),
      EXPDate: date,
    });
    this.setState({
      Issueseriesdata: this.props.Issueseries,
    });
    this.props.fetchApi(this.tblResponse);
    this.props.getPrdctDDL();
    this.props.getToNumber();
  }

  componentDidUpdate(prevProps) {
    if (this.props.MappingData) {
      if (prevProps.MappingData !== this.props.MappingData) {
        // this.props.myProp has a different value
        if (this.props.MappingData) {
          var e = this.props.MappingData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
    if (prevProps.MaxFromNo !== this.props.MaxFromNo) {
      this.setState({
        FromNo: this.props.MaxFromNo,
      });
    }
  }

  // singleCheck() {
  //     let obj = {};
  //     const checkboxes = document.getElementsByName('childCheck');
  //     for (var checkbox of checkboxes) {
  //         if (checkbox.checked) {
  //             obj[checkbox.value] = true;
  //         } else {
  //             obj[checkbox.value] = false;
  //         }
  //     }
  //     return JSON.stringify(obj);
  // }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    this.getLazyLoadingValues(event);
    this.props.fetchApi(this.tblResponse);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  getDropValue() {
    let productId = "";
    this.props.ProductData.filter((x) => {
      if (x.ProductId === this.state.ProductId) {
        productId = x.ProductId;
      }
    });
    return productId;
  }
  //to manage switch toggle confirm box
  switchToggleConfirm = (e) => {
    this.setState({ confirm: true, displayConfirmation: false });
    const formData = {
      MachineId: this.state.MachineId,
    };
    this.props.inActiveRow(this.state.MachineId);
  };

  // Availableseries(event) {
  //     //this.setState({ ProductId : event.target.value});
  //     this.props.GetAvailableseries(event.target.value);

  // }

  //to handle form
  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    // switch (name) {
    //     case 'IsActive':
    //         value = event.target.checked;
    //         break;
    //     default:
    //         break;
    // }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  //to validate form data
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;

    switch (name) {
      case "ProductId":
        if (value == "" || value === 0) {
          IsValid = false;
          errors.ProductId = "Please select Product";
        } else errors.ProductId = null;
        break;
      case "Qty":
        if (!value === value) {
          IsValid = false;
        } else {
          if (value < 1) {
            IsValid = false;
            errors.Qty = "Please enter your quantity.";
          } else {
            if (!value.toString().match(/^[0-9]+$/.exec(value))) {
              IsValid = false;
              errors.Qty = "Please enter only number.";
              this.setState({ Qty: 0 });
            } else {
              errors.ToNo = null;
              errors.Qty = null;
              errors.FromNo = null;
              const qty =
                value.toString().trim() === ""
                  ? 0
                  : parseInt(value.toString().trim());
              const fromNumber =
                this.state.FromNo.toString().trim() === ""
                  ? 0
                  : parseInt(this.state.FromNo.toString().trim());
              if (fromNumber >= 1 && qty >= 1) {
                const toNumber = qty + fromNumber - 1;
                if (toNumber >= 0) {
                  this.setState({ ToNo: toNumber });
                } else {
                  IsValid = false;
                  errors.Qty =
                    "To number should be greater than or equal to  from number.";
                  this.setState({ ToNo: 0 });
                }
              } else {
                // IsValid = false;
                // errors.ToNo = "To number should be greater than from number.";
                this.setState({ ToNo: 0 });
              }
            }
          }
        }
      default:
        break;
    }

    return IsValid;
  }
  //to get current date
  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  //to submit series data
  submitseries(event) {
    event.preventDefault();
    this.props.onHideShoww(false);
  }

  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      const ddlValu = this.getDropValue();

      const formData = {
        MappingId: this.state.MappingId,
        ProductId: this.state.ProductId,
        FromNo: this.state.FromNo,
        ToNo: this.state.ToNo,
        Qty: this.state.Qty,
        BatchNo: this.state.BatchNo,
        MFGDate: this.state.MFGDate.toLocaleDateString(),
        EXPDate: this.state.EXPDate.toLocaleDateString(),
        CreatedDate:
          this.state.MappingId > 0
            ? this.state.CreatedDatee
            : this.getCurrentDateMMDDYYY(),
        UpdatedDate:
          this.state.MappingId > 0 ? this.getCurrentDateMMDDYYY() : null,
        ProductName: ddlValu != null ? ddlValu : "",
        TotalRows: this.state.totalRecords,
      };

      if (this.state.MappingId == 0) {
        this.props.createMapping(formData);
      } else {
        this.props.updateMapping(formData);
      }
    }
  }

  //to reset form
  resetForm() {
    this.setState({
      ProductId: "",
      MappingId: 0,
      MFGDate: "",
      EXPDate: "",
      FromNo: "",
      ToNo: "",
      Qty: "",
      BatchNo: "",
      // Location: '',
      // DocURL: '',
      formError: {
        FromNo: "",
        ToNo: "",
        Qty: "",
        // BatchNo: '',
        // Location: '',
        // DocURL: '',
        ProductId: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });

    //     const checkboxes = document.getElementsByName('childCheck');
    //     for (var checkbox of checkboxes) {
    //         checkbox.checked = false;
    //     }
  }

  //to reset form on hide
  resetFormHide() {
    this.setState({
      ProductId: "",
      MappingId: 0,
      ToNo: "",
      Qty: "",
      BatchNo: "",
      // Location: '',
      // DocURL: '',
      formError: {
        FromNo: "",
        ToNo: "",
        Qty: "",
        // BatchNo: '',
        // Location: '',
        // DocURL: '',
        ProductId: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }
  //to hide function
  onHide() {
    this.props.onHideShow(false);
    this.resetFormHide();
  }

  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add Mapping" });

    this.props.getToNumber();
  }

  onHidee() {
    this.props.onHideShoww(false);
  }

  openDialogue() {
    this.props.onHideShoww(true);
    this.setState({ buttonLabel: "Submit", formHeadingg: "Available Series" });
    this.props.GetAvailableseries(this.tblResponse);
  }

  onRowSelect(event) {
    //this.toast.show({ severity: 'info', summary: 'Series Selected', detail: `FromNo: ${event.data.FromNo}`, detail: `ToNo: ${event.data.ToNo}`, life: 3000 });
    this.setState({
      FromNo: event.data.FromNo,
      ToNo: event.data.ToNo,
      Qty: event.data.ToNo - event.data.FromNo + 1,
    });
    this.onHidee();
  }

  // onRowUnselect(event) {
  //     this.toast.show({ severity: 'warn', summary: 'Series Unselected', detail: `FromNo: ${event.data.FromNo}`, detail: `ToNo: ${event.data.ToNo}`, life: 3000 });
  // }

  // toCapitalize(str) {
  //     return str.charAt(0).toUpperCase() + str.slice(1);
  // }

  //to get format of date
  UpdatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>;
    }
  }
  //to get format of date
  CreatedDateTemplate(rowData) {
    if (rowData.CreatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>;
    }
  }

  // changevalue(event, field) {
  //     var ischeck = event.currentTarget.checked;
  //     var id = event.currentTarget.id;
  //     setTimeout(() => {
  //         this.checkCheckBox(ischeck, id);
  //     }, 200);

  //     this.ischeckAllCheck(field);
  // }

  // checkCheckBox(ischeck, id) {
  //     document.getElementById(id).checked = ischeck;
  // }

  //to edit rowdata
  actionBodyTemplate(rowData, i) {
    const editMode = (e) => {
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Mapping",
        displayModal: true,
        ProductId: rowData.ProductId,
        MappingId: rowData.MappingId,
        FromNo: rowData.FromNo,
        ToNo: rowData.ToNo,
        Qty: rowData.Qty,
        BatchNo: rowData.BatchNo,
        MFGDate: new Date(rowData.MFGDate),
        EXPDate: new Date(rowData.EXPDate),
        // Location: rowData.Location,
        // DocURL: rowData.DocURL,
        CreatedDate: rowData.CreatedDate,
      });
    };
    return <Button icon="pi pi-pencil" onClick={editMode} />;
  }
  //to export data
  exportCSV() {
    this.dt.exportCSV();
  }

  //to render html
  render() {
    const renderFooter = (name) => {
      return (
        <div>
          {/* <Button label="No" icon="pi pi-times" onClick={() => this.setState({ displayConfirmation: false })} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus /> */}
        </div>
      );
    };

    const header = (
      <div className="table-header row">
        <div className="col-md-6">
          <h4>Mapping</h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>
          {this.state.IsAdd === true ? (
            <Button
              className="mr-2"
              icon="pi pi-plus"
              label="Add"
              onClick={this.openDialog}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />
        <div className="card">
          <DataTable
            value={this.props.MappingData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            {/* <Column field="MappingId" header="Mapping Id" sortable></Column> */}
            <Column field="ProductName" header="Product Name" sortable></Column>
            <Column field="FromNo" header="From No" sortable></Column>
            <Column field="ToNo" header="To No" sortable></Column>
            <Column field="Qty" header="Quantity" sortable></Column>
            <Column field="BatchNo" header="Batch No" sortable></Column>
          </DataTable>
        </div>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={false}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          {/* <form onSubmit={this.submitForm}> */}
          <div className="row">
            <div className="col-md-6 pt-2">
              <FormControl
                variant="outlined"
                size="small"
                className="formControl col-md-12"
              >
                {/* <InputLabel id="demo-simple-select-outlined-label">
                  Select Product*
                </InputLabel>
                <Select
                  onChange={this.handleChange}
                  name="ProductId"
                  value={this.state.ProductId}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Select Product"
                >
                  <MenuItem>
                    <em>--- Select Product --- </em>
                  </MenuItem>
                  {(typeof this.props.ProductData == "undefined" ||
                    this.props.ProductData == null) === true
                    ? null
                    : this.props.ProductData?.map((key) => (
                        <MenuItem value={key.value}>{key.label}</MenuItem>
                      ))}
                </Select> */}

                <Dropdown
                  appendTo={document.getElementById("root")}
                  value={this.state.ProductId}
                  //value={this.props.ProductData}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  options={this.props.ProductData}
                  optionValue="value"
                  optionLabel="label"
                  className="form-control custom-select"
                  placeholder={"Select Product "}
                  name="ProductId"
                  filter
                />
              </FormControl>
              {this.state.formError.ProductId !== "" ? (
                <div className="error">{this.state.formError.ProductId}</div>
              ) : (
                ""
              )}
            </div>

            {/* <div className="col-md-6 pt-3">
                                <TextField onChange={this.handleChange} value={this.state.FromNo} name="FromNo" size="small" variant="outlined" className="fullwidth" label="From No*" />
                                {this.state.formError.FromNo !== '' ?
                                    <div className='error'>{this.state.formError.FromNo}</div> : ''}
                            </div> */}
            <div className="col-md-6 pt-2">
              <TextField
                onChange={this.handleChange}
                value={this.state.FromNo}
                name="FromNo"
                size="small"
                variant="outlined"
                className="fullwidth"
                label="From No*"
              />
              {/* {this.state.formError.FrmNo !== '' ?
                                    <div className='error'>{this.state.formError.FrmNo}</div> : ''} */}
            </div>
            {/* <div className="col-md-6 pt-3">
                                <TextField onChange={this.handleChange} value={this.state.ToNo} name="ToNo" size="small" variant="outlined" className="fullwidth" label="To No*" />
                                {this.state.formError.ToNo !== '' ?
                                    <div className='error'>{this.state.formError.ToNo}</div> : ''}
                            </div> */}
            <div className="col-md-6 pt-2 mt-2">
              <TextField
                onChange={this.handleChange}
                disabled
                value={this.state.ToNo}
                name="ToNo"
                size="small"
                variant="outlined"
                className="fullwidth"
                label="To No*"
              />
              {/* {this.state.formError.ToNo !== '' ?
                                    <div className='error'>{this.state.formError.ToNo}</div> : ''} */}
            </div>
            <div className="col-md-6 pt-3">
              <TextField
                onChange={this.handleChange}
                value={this.state.Qty}
                name="Qty"
                size="small"
                variant="outlined"
                className="fullwidth"
                label="Qty*"
              />
              {this.state.formError.Qty !== "" ? (
                <div className="error">{this.state.formError.Qty}</div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-6 pt-3">
              <TextField
                onChange={this.handleChange}
                value={this.state.BatchNo}
                name="BatchNo"
                size="small"
                variant="outlined"
                className="fullwidth"
                label="Batch No"
              />
              {/* {this.state.formError.BatchNo !== '' ?
                                    <div className='error'>{this.state.formError.BatchNo}</div> : ''} */}
            </div>

            <div className="col-md-6 pt-3">
              {this.state.IsAdd === true ? (
                <Button
                  className="mr-2"
                  icon="pi pi-plus"
                  label="Available mapping series"
                  onClick={this.openDialogue}
                />
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 pt-3">
              <span className="ml-2">MFG Date: </span>
              <Calendar
                disabled={this.state.isEditing}
                placeholder="Manufacture Date"
                showIcon={true}
                name="MFGDate"
                value={this.state.MFGDate}
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
              ></Calendar>
            </div>
            <div className="col-md-6 pt-3">
              <span className="ml-2">EXP Date: </span>
              <Calendar
                placeholder="EXP Date"
                showIcon={true}
                name="EXPDate"
                value={this.state.EXPDate}
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
              ></Calendar>
            </div>
            <Dialog
              header={"Available Series"}
              modal
              dismissableMask={false}
              visible={this.props.showDialogue}
              style={{ width: "50vw" }}
              onHide={this.onHidee}
            >
              <div className="datatable-selection-demo">
                <div className="card">
                  <Toast ref={(el) => (this.toast = el)} />

                  <DataTable
                    value={this.props.Issueseries}
                    scrollable
                    responsive={true}
                    selectionMode="checkbox"
                    selection={this.props.selectedProduct}
                    onSelectionChange={(e) =>
                      this.setState({ selectedProduct: e.value })
                    }
                    dataKey="id"
                    responsiveLayout="scroll"
                    onRowSelect={this.onRowSelect}
                  >
                    <Column
                      selectionMode="multiple"
                      headerStyle={{ width: "6em" }}
                    ></Column>
                    <Column field="FromNo" header="From No" sortable></Column>
                    <Column field="ToNo" header="To No" sortable></Column>
                    <Column field="Qty" header="Quantity" sortable></Column>
                  </DataTable>

                  <div className="col-md-12 mt-4 text-center">
                    <Button
                      label={this.state.buttonLabel}
                      onClick={this.submitseries}
                      icon="pi pi-check"
                    />
                  </div>
                </div>
              </div>
            </Dialog>

            <div className="col-md-12 mt-4 text-center">
              <div className="mb-2">Please Click Below Button To Submit*</div>

              <Button
                label={this.state.buttonLabel}
                onClick={this.submitForm}
                icon="pi pi-check"
              />
            </div>

            <div className="col-md-12 ">
              Note: Fields marked as (*) are required
            </div>
          </div>
          {/* </form> */}
        </Dialog>

        <Dialog
          header="Confirmation"
          visible={this.state.displayConfirmation}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.setState({ displayConfirmation: false })}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>Are you sure you want to InActive?</span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  ////console.log('state.mapping.Issueseries', state.mapping.Issueseries);
  ////console.log('MaxFromNo', state.mapping?.issueData?.ToNo);
  return {
    MappingData: state.mapping.items,
    ProductData:
      (typeof state.product.prdctddl !== "undefined" &&
        state.product.prdctddl !== null) === true
        ? state.product?.prdctddl
        : null,
    Issueseries: state.mapping.Issueseries,
    MaxFromNo:
      state.mapping?.issueData?.ToNo == (null || undefined)
        ? 1
        : state.mapping?.issueData?.ToNo + 1,
    showDialog: state.mapping.showDialog,
    showDialogue: state.mapping.showDialogue,
    loading: state.company.loding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(mappingActions.getAll(obj));
    },
    fetchProductApi: (obj) => {
      dispatch(productMasterActions.getProductName(obj));
    },
    getToNumber: () => {
      dispatch(mappingActions.GetToNo());
    },
    GetAvailableseries: (data) => {
      dispatch(mappingActions.GetAvailableseries(data));
    },
    createMapping: (machine) => dispatch(mappingActions.createMapping(machine)),
    updateMapping: (machine) => dispatch(mappingActions.updateMapping(machine)),
    onHideShow: (isbool) => dispatch(mappingActions.onHideShow(isbool)),
    onHideShoww: (isbool) => dispatch(mappingActions.onHideShoww(isbool)),
    getPrdctDDL: () => dispatch(productMasterActions.getPrdctDDL()),
    loadingData: (isLoding) => dispatch(mappingActions.loadingData(isLoding)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Mapping);
