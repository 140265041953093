export const changePasswordConstants = {
    CREATE_REQUEST: 'CHANGEPASSWORD_CREATE_REQUEST',
    CREATE_SUCCESS: 'CHANGEPASSWORD_CREATE_SUCCESS',
    CREATE_FAILURE: 'CHANGEPASSWORD_CREATE_FAILURE', 
    
    GETALL_REQUEST: 'CHANGEPASSWORD_GETALL_REQUEST',
    GETALL_SUCCESS: 'CHANGEPASSWORD_GETALL_SUCCESS',
    GETALL_FAILURE: 'CHANGEPASSWORD_GETALL_FAILURE',

    CREATE_FORGOT_REQUEST: 'CHANGEPASSWORD_CREATE_FORGOT_REQUEST',
    CREATE_FORGOT_SUCCESS: 'CHANGEPASSWORD_CREATE_FORGET_SUCCESS',
    CREATE_FORGOT_FAILURE: 'CHANGEPASSWORD_CREATE_FORGET_FAILURE', 

};