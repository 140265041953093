import { changePasswordConstants } from '../constants';

export function changePassword(state = {}, action) {
    switch (action.type) {
        
        case changePasswordConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case changePasswordConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: [action.data]
            };
        case changePasswordConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case changePasswordConstants.CREATE_FORGOT_REQUEST:
            return {
                ...state,
                creating: true
                };
        case changePasswordConstants.CREATE_FORGOT_SUCCESS:
            return {
                ...state,
                items: [action.data, ...state.items]
                };
        case changePasswordConstants.CREATE_FORGOT_FAILURE:
            return {
                ...state,
                error: action.error
                };       
        case changePasswordConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case changePasswordConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case changePasswordConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
       
        default:
            return state
    }
}
