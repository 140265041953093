import { authHeader, handleResponse } from "../helpers";

export const SliderProMsgService = {
  UploadSliderImage,
  GetSliderHome,
  GetAllSlider,
  DeleteSlider,
};

function UploadSliderImage(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  //console.log(requestOptions);
  return fetch(
    `${process.env.REACT_APP_API_URL}SliderMaster/UploadSlider`,
    requestOptions
  ).then(handleResponse);
}

function GetSliderHome(data) {
  const requestOptions = {
    method: "Get",
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}SliderMaster/getSliderWeb`,
    requestOptions
  ).then(handleResponse);
}

function GetAllSlider(data) {
  const requestOptions = {
    method: "Get",
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}SliderMaster/getllAllSlider`,
    requestOptions
  ).then(handleResponse);
}

function DeleteSlider(SId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}SliderMaster/sliderDelete/${SId}`,
    requestOptions
  ).then(handleResponse);
}
