export const categoryConstants = {
    CREATE_REQUEST: 'CATEGORY_CREATE_REQUEST',
    CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
    CREATE_FAILURE: 'CATEGORY_CREATE_FAILURE',
  
    UPDATE_REQUEST: 'CATEGORY_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CATEGORY_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CATEGORY_UPDATE_FAILURE',
  
    GETALL_REQUEST: 'CATEGORY_GETALL_REQUEST',
    GETALL_SUCCESS: 'CATEGORY_GETALL_SUCCESS',
    GETALL_FAILURE: 'CATEGORY_GETALL_FAILURE',
  
    ISACTIVE_REQUEST: 'CATEGORY_ISACTIVE_REQUEST',
    ISACTIVE_SUCCESS: 'CATEGORY_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE: 'CATEGORY_ISACTIVE_FAILURE',
  
    DIALOG_REQUEST: 'DIALOG_REQUEST',  
    LOADING_REQUEST: 'LOADING_REQUEST',  
    DIALOG_ISACTIVE_REQUEST: 'DIALOG_ISACTIVE_REQUEST',

    GETCATEGORYDDL_REQUEST:'GETCATEGORYDDL_REQUEST',
    GETCATEGORYDDL_SUCCESS:'GETCATEGORYDDL_SUCCESS',
    GETCATEGOYRDDL_FAILURE:'GETCATEGOYRDDL_FAILURE'
  };