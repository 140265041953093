import { authHeader, handleResponse } from '../helpers';
export const QRCodeTrackingRptService = {
    GetScanInfo,
    GetJourney
};

function GetScanInfo(d) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}Dispatch/GetScanInfo?qr=` + d, requestOptions)
        .then(handleResponse);
}

function GetJourney(d) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}Dispatch/GetJourney?qr=` + d, requestOptions)
        .then(handleResponse);
}



