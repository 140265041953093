import { RedemptionApprovalService } from '../services/RedemptionApprovalService';
import { RedemptionApprovalConstants } from '../constants/RedemptionApprovalConstants';
import { alertActions } from '.';
import { history } from '../helpers';

export const RedemptionApprovalActions = {
    getAll,
    ApproveRedeem,
    CancelRedeem,
    createRedemption,
    UserData,
    GetUserPoints,
    inActiveRow,
    onHideShow,
    onHideShoww,
    onHideclose,
    loadingData,
    onHideShowIsActive
};

function getAll(data) {
    return dispatch => {
        dispatch(request());
        RedemptionApprovalService.fetchSchemeData(data).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: RedemptionApprovalConstants.GETALL_REQUEST } }
    function success(datas) { return { type: RedemptionApprovalConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: RedemptionApprovalConstants.GETALL_FAILURE, error } }
}

function ApproveRedeem(data) {
    return dispatch => {
        dispatch(request(data));

        RedemptionApprovalService.ApproveRedeem(data).then(
            response => {
                //history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(onHideShowIsActive(false));
                        dispatch(onHideShoww(false));
                        dispatch(onHideclose(false));
                        
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: RedemptionApprovalConstants.APPROVE_REQUEST, data } }
    function success(data) { return { type: RedemptionApprovalConstants.APPROVE_SUCCESS, data } }
    function failure(error) { return { type: RedemptionApprovalConstants.APPROVE_FAILURE, error } }
};


function CancelRedeem(data) {
    return dispatch => {
        dispatch(request(data));

        RedemptionApprovalService.CancelRedeem(data).then(
            response => {
                //history.push('/');                
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(data));
                        dispatch(onHideShowIsActive(false));
                        dispatch(onHideShoww(false));
                        dispatch(onHideclose(false));
                      
                        dispatch(alertActions.success(response.ResponseMessage))
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: RedemptionApprovalConstants.CANCEL_REQUEST, data } }
    function success(data) { return { type: RedemptionApprovalConstants.CANCEL_SUCCESS, data } }
    function failure(error) { return { type: RedemptionApprovalConstants.CANCEL_FAILURE, error } }
};

function createRedemption(data) {
    return dispatch => {
        dispatch(request(data));

        RedemptionApprovalService.createRedemption(data).then(
            response => {
                //history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(onHideShow(false));
                        dispatch(onHideShoww(false));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: RedemptionApprovalConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: RedemptionApprovalConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: RedemptionApprovalConstants.CREATE_FAILURE, error } }
};

function GetUserPoints(data) {
    return dispatch => {
        dispatch(request(data));

        RedemptionApprovalService.GetUserPoints(data).then(
            response => {
                //history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(loadingData(false));
                        
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: RedemptionApprovalConstants.GETALL_USERPT_REQUEST, data } }
    function success(data) { return { type: RedemptionApprovalConstants.GETALL_USERPT_SUCCESS, data } }
    function failure(error) { return { type: RedemptionApprovalConstants.GETALL_USERPT_FAILURE, error } }
};

// function GetUserPoints(data) {
//     return dispatch => {
//         dispatch(request(data));

//         RedemptionApprovalService.GetUserPoints(data).then(response => {
//             dispatch(loadingData(false));
//             dispatch(success(response.ResponseData))
//         },
//             error => {
//                 dispatch(loadingData(false));
//                 dispatch(failure(error.toString()));
//                 dispatch(alertActions.error(error.toString()));
//             }
//         );
//     };

//     function request(data) { return { type: RedemptionApprovalConstants.GETALL_USERPT_REQUEST,data } }
//     function success(datas) { return { type: RedemptionApprovalConstants.GETALL_USERPT_SUCCESS, datas } }
//     function failure(error) { return { type: RedemptionApprovalConstants.GETALL_USERPT_FAILURE, error } }
// }

function UserData(obj) {
    return dispatch => {
        dispatch(request());

        RedemptionApprovalService.fetchUserData(obj).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: RedemptionApprovalConstants.GETALL_USER_REQUEST } }
    function success(datas) { return { type: RedemptionApprovalConstants.GETALL_USER_SUCCESS, datas } }
    function failure(error) { return { type: RedemptionApprovalConstants.GETALL_USER_FAILURE, error } }
}



function inActiveRow(data) {
    return dispatch => {
        dispatch(request());

        RedemptionApprovalService.inActiveRow(data).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(data));
                        dispatch(onHideShowIsActive(false));
                        dispatch(alertActions.success(response.ResponseMessage))
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: RedemptionApprovalConstants.ISACTIVE_REQUEST } }
    function success(data) { return { type: RedemptionApprovalConstants.ISACTIVE_SUCCESS, data } }
    function failure(error) { return { type: RedemptionApprovalConstants.ISACTIVE_FAILURE, error } }
};

function onHideShow(isbool) {
    return {
        type: RedemptionApprovalConstants.DIALOG_REQUEST, isbool
    }
}
function onHideShoww(isbool) {
    return {
        type: RedemptionApprovalConstants.DIALOGUE_REQUEST, isbool
    }
}

function onHideclose(isbool) {
    return {
        type: RedemptionApprovalConstants.REJECT_DIALOGUE_REQUEST, isbool
    }
}

function loadingData(isbool) {
    return {
        type: RedemptionApprovalConstants.LOADING_REQUEST, isbool
    }
}

function onHideShowIsActive(isbool) {
    return {
        type: RedemptionApprovalConstants.DIALOG_ISACTIVE_REQUEST, isbool
    }
}



