import React, { Component } from "react";
import { PanelMenu } from "primereact/panelmenu";
import { menuActions } from "../../src/actions";
import { Menubar } from "primereact/menubar";
import { connect } from "react-redux";
import logo from "./../assets/images/Header_logo1.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: false,
    };
    this.items = [
      {
        label: "Dashboard",
        icon: "pi pi-dashboard",
        url: "/",
      },
      {
        label: "Masters",
        icon: "pi pi-user",
        items: [
          {
            icon: "pi pi-users",
            label: "User Master",
            url: "/masters/user-master",
            //icon: 'pi pi-angle-right'
          },

          {
            icon: "pi pi-shield",
            label: "Product Master",
            url: "/masters/product-master",
            // icon: 'pi pi-angle-right'
          },
          {
            icon: "pi pi-chart-bar",
            label: 'company master"',
            url: '/masters/company-master"',
            // icon: 'pi pi-angle-right'
          },

          {
            label: "Role Master",
            url: "/masters/role-master",
            icon: "pi pi-slack",
          },
          {
            label: "Carton Master",
            url: "/masters/carton-master",
            icon: "pi pi-angle-right",
          },
          {
            label: "Order Booking",
            url: "/masters/order-booking",
            icon: "pi pi-angle-right",
          },
          {
            label: "Loyality Scheme Master",
            url: "/masters/loyalityscheme-master",
            icon: "pi pi-angle-right",
          },
          {
            label: "Product Configuration",
            url: "/masters/product-configuration",
            icon: "pi pi-cog",
          },
          {
            label: "Sql Editor",
            url: "/masters/sql-editor",
            icon: "pi pi-check-square",
          },
        ],
      },
      {
        label: "Transactions",
        icon: "pi pi-fw pi-pencil",
        items: [
          {
            label: "Mapping",
            url: "/transactions/mapping",
            icon: "pi pi-angle-right",
          },
          {
            label: "Damage",
            url: "/transactions/damage",
            icon: "pi pi-angle-right",
          },
          {
            label: "De mapping",
            url: "/transactions/de-mapping",
            icon: "pi pi-angle-right",
          },
          {
            label: "Redemption Approval",
            url: "/transactions/RedemptionApproval",
            icon: "pi pi-angle-right",
          },
        ],
      },
      {
        label: "Reports",
        icon: "pi pi-chart-bar",
        items: [
          {
            label: "Verification Report",
            url: "/reports/verification-log",
            icon: "pi pi-angle-right",
          },
          {
            label: "Mapping-Report",
            url: "/reports/mapping-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Demapping Report",
            url: "/reports/Demapping-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Damage Report",
            url: "/reports/Damage-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Loyalty Scheme Report",
            url: "/reports/Loyalty-Scheme-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Redemption Log Report",
            url: "/reports/Redemption-Log-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Order Booking Report",
            url: "/reports/orderbooking-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Secondary Packing Report",
            url: "/reports/secondarypacking-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Tertiary Packing Report",
            url: "/reports/TertiaryPacking-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "Feedback Report",
            url: "/reports/feedback-report",
            icon: "pi pi-angle-right",
          },
          {
            label: "User Loyality Report",
            url: "/reports/Userloyality-report",
            icon: "pi pi-angle-right",
          },
        ],
      },
    ];
  }

  render() {
    return (
      <div className={`${this.state.nav && "Nav__black"}`} id="myHeader">
        <Menubar model={this.props.MenuData} dismissableMask={false} />
        {/* <Menubar model={this.items} dismissableMask={false}/> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    MenuData: state.menu.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (roleId) => {
      dispatch(menuActions.getMenu(roleId));
    },
    getAccessRight: (roleId) => {
      dispatch(menuActions.getAccessRight(roleId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
