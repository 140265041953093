
export const QRCodeTrackingRptConstants = {
    TRACK_SCAN_INFO_REQUEST: 'TRACK_SCAN_INFO_REQUEST',
    TRACK_SCAN_INFO_SUCCESS: 'TRACK_SCAN_INFO_SUCCESS',
    TRACK_SCAN_INFO_FAILURE: 'TRACK_SCAN_INFO_FAILURE',

    TRACK_TOGGLE_REQUEST: 'TRACK_TOGGLE_REQUEST',
    TRACK_TOGGLE_SUCCESS: 'TRACK_TOGGLE_SUCCESS',
    TRACK_TOGGLE_FAILURE: 'TRACK_TOGGLE_FAILURE',

    TRACK_TOGGLE_2_REQUEST: 'TRACK_TOGGLE_2_REQUEST',
    TRACK_TOGGLE_2_SUCCESS: 'TRACK_TOGGLE_2_SUCCESS',
    TRACK_TOGGLE_2_FAILURE: 'TRACK_TOGGLE_2_FAILURE',

    TRACK_JOURNEY_INFO_REQUEST: 'TRACK_JOURNEY_INFO_REQUEST',
    TRACK_JOURNEY_INFO_SUCCESS: 'TRACK_JOURNEY_INFO_SUCCESS',
    TRACK_JOURNEY_INFO_FAILURE: 'TRACK_JOURNEY_INFO_FAILURE',
    LOADING_REQUEST:'LOADING_REQUEST',  
}