import { loyalityschemeService } from '../services/loyalityschemeService';
import { loyalityschemeConstants } from '../constants/loyalityschemeConstants';
import { userService } from '../services';
import { userConstants } from '../constants';
import { alertActions } from '.';
import { history } from '../helpers';

export const loyalityschemeActions = {
    getAll,
    getRoleDDL,
    GetAvailableseries,
    createScheme,
    updateScheme,
    inActiveRow,
    onHideShow,
    onHideShoww,
    loadingData,
    onHideShowIsActive
};

function getAll(data) {
    return dispatch => {
        dispatch(request());
        loyalityschemeService.fetchSchemeData(data).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: loyalityschemeConstants.GETALL_REQUEST } }
    function success(datas) { return { type: loyalityschemeConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: loyalityschemeConstants.GETALL_FAILURE, error } }
}

function getRoleDDL(data) {
    return dispatch => {
        dispatch(request());

        userService.fetchRoleDLL(data).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };
    function request() { return { type: userConstants.GETROLEDDL_REQUEST } }
    function success(datas) { return { type: userConstants.GETROLEDDL_SUCCESS, datas } }
    function failure(error) { return { type: userConstants.GETROLEDDL_FAILURE, error } }
};

function GetAvailableseries(data) {
    return dispatch => {
        dispatch(request(data));

        loyalityschemeService.GetAvailableseries(data).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: userConstants.GETROLEDDL_REQUEST } }
    function request() { return { type: loyalityschemeConstants.GETALL_AVAILABLE_REQUEST } }
    function success(datas) { return { type: loyalityschemeConstants.GETALL_AVAILABLE_SUCCESS, datas } }
    function failure(error) { return { type: loyalityschemeConstants.GETALL_AVAILABLE_FAILURE, error } }
}

function createScheme(data) {
    return dispatch => {
        dispatch(request(data));

        loyalityschemeService.createScheme(data).then(
            response => {
                //history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(onHideShow(false));
                        dispatch(onHideShoww(false));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: loyalityschemeConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: loyalityschemeConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: loyalityschemeConstants.CREATE_FAILURE, error } }
};


function updateScheme(data) {
    return dispatch => {
        dispatch(request(data));

        loyalityschemeService.UpdateScheme(data).then(
            response => {
                //history.push('/');                
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(<div>{new Date(data).toString()}</div>));
                        dispatch(onHideShow(false));
                        dispatch(onHideShow(false));
                        dispatch(alertActions.success(response.ResponseMessage))
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: loyalityschemeConstants.UPDATE_REQUEST, data } }
    function success(data) { return { type: loyalityschemeConstants.UPDATE_SUCCESS, data } }
    function failure(error) { return { type: loyalityschemeConstants.UPDATE_FAILURE, error } }
};


function inActiveRow(SchemeId, isActive) {
    var data = {
        SchemeId: SchemeId,
        IsActive: isActive
    }
    return dispatch => {
        dispatch(request());

        loyalityschemeService.inActiveRow(SchemeId, isActive).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(data));
                        dispatch(onHideShowIsActive(false));
                        dispatch(alertActions.success(response.ResponseMessage))
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: loyalityschemeConstants.ISACTIVE_REQUEST } }
    function success(data) { return { type: loyalityschemeConstants.ISACTIVE_SUCCESS, data } }
    function failure(error) { return { type: loyalityschemeConstants.ISACTIVE_FAILURE, error } }
};

function onHideShow(isbool) {
    return {
        type: loyalityschemeConstants.DIALOG_REQUEST, isbool
    }
}
function onHideShoww(isbool) {
    return {
        type: loyalityschemeConstants.DIALOGUE_REQUEST, isbool
    }
}

function loadingData(isbool) {
    return {
        type: loyalityschemeConstants.LOADING_REQUEST, isbool
    }
}

function onHideShowIsActive(isbool) {
    return {
        type: loyalityschemeConstants.DIALOG_ISACTIVE_REQUEST, isbool
    }
}



