import { ProductPurchaseRptConstants } from '../constants/ProductPurchaseRptConstants';
import { ProductPurchaseRptService } from '../services/ProductPurchaseRptService';
import {alertActions} from '.';

export const ProductPurchaseRptActions= {
    getAll,
    loadingData,
    exportData
};

function getAll(obj)
{
    return dispatch =>{
        dispatch(request());

        ProductPurchaseRptService.fetchProductPurchaseData(obj).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
        error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        }
        
        );
    };
    function request() { return { type : ProductPurchaseRptConstants.GETALL_REQUEST } }       //Changes
    function success(datas) { return { type: ProductPurchaseRptConstants.GETALL_SUCCESS, datas } }
    function successExport(datas) { return { type: ProductPurchaseRptConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: ProductPurchaseRptConstants.GETALL_FAILURE, error } }
}

function exportData(obj) {
    return dispatch => {
        dispatch(request());
        ProductPurchaseRptService.fetchProductPurchaseData(obj).then(response => {
            dispatch(loadingData(false));
            var data = response.ResponseData;
            if (data != null) {
                data.forEach(function (v) { delete v.TotalRows; delete v.CreatedBy; 
                    delete v.DocURL; delete v.ProductPurchaseId; delete v.ProductID
                });
            }
            dispatch(success(data))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };
    function request() { return { type: ProductPurchaseRptConstants.GETEXPORT_REQUEST } }
    function success(datas) { return { type: ProductPurchaseRptConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: ProductPurchaseRptConstants.GETEXPORT_FAILURE, error } }
}

function loadingData(isbool) {
    return {
        type: ProductPurchaseRptConstants.LOADING_REQUEST, isbool
    }
}

