import { authHeader, handleResponse } from '../helpers';
export const loyalityschemeService = {
    createScheme,
    UpdateScheme,
    fetchSchemeData,
    inActiveRow,
    GetAvailableseries
};

function createScheme(data) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Loyalityscheme/CreateScheme`, requestOptions)
        .then(handleResponse);
}

function UpdateScheme(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Loyalityscheme/CreateScheme`, requestOptions)
        .then(handleResponse);
}

function fetchSchemeData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}Loyalityscheme/GetSchemes`, requestOptions)
        .then(handleResponse);
}

function inActiveRow(userId, isActive) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}Loyalityscheme/InActiveRow/${userId}/${isActive}`, requestOptions)
        .then(handleResponse);
}
function GetAvailableseries(proddata) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(proddata)
    };

    return fetch(`${process.env.REACT_APP_API_URL}Loyalityscheme/GetAvailableseries`,requestOptions)
    .then(handleResponse);
}





