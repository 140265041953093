import { authHeader, handleResponse } from '../helpers';
export const companyService = {
    createCompany,
    updateCompany,
    fetchCompanyData,
    inActiveRow,
    FetchCompanyDDL
};

function createCompany(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Company/CreateCompany`, requestOptions)
        .then(handleResponse);
}

function updateCompany(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Company/UpdateCompany`, requestOptions)
        .then(handleResponse);
}

function fetchCompanyData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Company/GetCompanys`, requestOptions)
        .then(handleResponse);
}

function inActiveRow(companyId, isActive) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}Company/InActiveRow/${companyId}/${isActive}`, requestOptions)
        .then(handleResponse);
}

function FetchCompanyDDL() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}Company/GetCompanyDLL`, requestOptions)
        .then(handleResponse);
}


