import { authHeader, handleResponse } from '../helpers';
export const RedemptionLogRptService = {
    fetchRedemptionLogData   
};

function fetchRedemptionLogData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}RedemptionLogRpt/GetRedemptionLogReport`, requestOptions)
        .then(handleResponse);
}