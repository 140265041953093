import { authHeader, handleResponse } from '../helpers';
export const LoyaltySchemeRptService = {
    fetchLoyaltySchemeData   
};

function fetchLoyaltySchemeData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}LoyaltySchemeRpt/GetLoyaltySchemeReport`, requestOptions)
        .then(handleResponse);
}