import { dealerConstants, userConstants } from "../constants";

export function dealer(state = {}, action) {
  switch (action.type) {
    case userConstants.LOADING_REQUEST:
      return {
        ...state,
        loding: action.isbool,
      };
    case userConstants.DIALOG_REQUEST:
      return {
        ...state,
        showDialog: action.isbool,
      };
    case userConstants.DIALOG_ISACTIVE_REQUEST:
      return {
        ...state,
        showIsActiveDialog: action.isbool,
      };
    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };
    case userConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case dealerConstants.CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case dealerConstants.CREATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.UserId === action.data.UserId ? action.data : item
        ),
      };
    case dealerConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case dealerConstants.UPDATE_REQUEST:
      return {
        ...state,
        items: state.items.map((item) =>
          item.UserId == action.data.UserId ? { ...item, updating: true } : item
        ),
      };
    case dealerConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.UserId === action.data.UserId ? action.data : item
        ),
      };
    case dealerConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case userConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.UserId === action.data
            ? {
                UserId: item.userId,
                UserName: item.UserName,
                Email: item.Email,
                MobileNo: item.MobileNo,
                CompanyId: item.CompanyId,
                CompanyName: item.CompanyName,
                RoleId: item.RoleId,
                RoleName: item.RoleName,
                IsActive: item.isActive,
                Password: item.Password,
                ConfirmPassword: item.Password,
                CreatedDate: item.CreatedDate,
              }
            : item
        ),
      };

    case dealerConstants.GETSTATEDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dealerConstants.GETSTATEDDL_SUCCESS:
      return {
        ...state,
        stateddl: action.datas,
      };
    case dealerConstants.GETSTATEDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case dealerConstants.GET_TRANSPORTATION_REQUEST:
      return {
        ...state,
        transportdllloading: true,
      };
    case dealerConstants.GET_TRANSPORTATION_SUCCESS:
      return {
        ...state,
        transportationModeddl: action.datas,
      };
    case dealerConstants.GET_TRANSPORTATION_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    //start Firm
    case dealerConstants.GETFIRMDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dealerConstants.GETFIRMDDL_SUCCESS:
      return {
        ...state,
        firmddl: action.datas,
      };
    case dealerConstants.GETFIRMDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    //end firm

    //start Ship City
    case dealerConstants.GETSCITYDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dealerConstants.GETSCITYDDL_SUCCESS:
      return {
        ...state,
        scityddl: action.datas,
      };
    case dealerConstants.GETSCITYDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    //end Ship City

    //start Bill City
    case dealerConstants.GETBCITYDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dealerConstants.GETBCITYDDL_SUCCESS:
      return {
        ...state,
        bcityddl: action.datas,
      };
    case dealerConstants.GETBCITYDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    //end Bill City
    case userConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.ISACTIVE_REQUEST:
      return {
        ...state,
        activating: true,
      };
    case dealerConstants.ISACTIVE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.UserId === action.data.UserId
            ? {
                UserId: item.UserId,
                UserName: item.UserName,
                Email: item.Email,
                MobileNo: item.MobileNo,
                CompanyId: item.CompanyId,
                CompanyName: item.CompanyName,
                RoleId: item.RoleId,
                RoleName: item.RoleName,
                CityId: item.CityId,
                City: item.City,

                NatureFirmId: item.NatureFirmId,
                FirmName: item.FirmName,

                IsActive: action.data.IsActive,
                TotalRows: item.TotalRows,
                Password: item.Password,
                ConfirmPassword: item.Password,
                CreatedDate: item.CreatedDate,
              }
            : item
        ),
      };
    case userConstants.ISACTIVE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case dealerConstants.GETEXPORT_REQUEST:
      return {
        ...state,
        exporting: true,
      };
    case dealerConstants.GETEXPORT_SUCCESS:
      return {
        ...state,
        exportitems: action.datas,
      };
    case dealerConstants.GETEXPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
