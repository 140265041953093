import { mappingConstants } from '../constants';

export function mapping(state = {}, action) {
    switch (action.type) {
        case mappingConstants.LOADING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };
        case mappingConstants.DIALOG_REQUEST:
            return {
                ...state,
                showDialog: action.isbool
            };

        case mappingConstants.DIALOGUE_REQUEST:
            return {
                ...state,
                showDialogue: action.isbool
            };
        case mappingConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case mappingConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case mappingConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case mappingConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case mappingConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: state.items == null ? [action.data] : [action.data, ...state.items]
            };
        case mappingConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case mappingConstants.UPDATE_REQUEST:
            return {
                ...state,
                items: state.items.map(item =>
                    item.MappingId == action.data.MappingId ? { ...item, updating: true } : item
                )
            };
        case mappingConstants.UPDATE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.MappingId === action.data.MappingId ? action.data : item
                )
            };
        case mappingConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case mappingConstants.DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            };
        case mappingConstants.DELETE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.mappingId === action.data ? {
                        CreatedBy: item.CreatedBy,
                        CreatedDt: item.CreatedDt,
                        mappingId: item.mappingId,
                        productName: item.productName,
                        UpdatedBy: item.UpdatedBy,
                        UpdatedDt: item.UpdatedDt,
                        FromNo: item.FromNo,
                        ToNo: item.ToNo,
                        Qty: item.Qty
                    } : item
                )
            };
        case mappingConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case mappingConstants.GETALL_TONO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case mappingConstants.GETALL_TONO_SUCCESS:
            return {
                ...state,
                issueData: action.datas
            };
        case mappingConstants.GETALL_TONO_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case mappingConstants.GETALL_AVAILABLE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case mappingConstants.GETALL_AVAILABLE_SUCCESS:
            return {
                ...state,
                Issueseries: action.datas
            };
        case mappingConstants.GETALL_AVAILABLE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }
}
