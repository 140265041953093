import { dealerService } from "../services";

import { dealerConstants, userConstants } from "../constants";
import { alertActions } from ".";
import { history } from "../helpers";

export const dealerMasterActions = {
  getAll,
  getStateDDL,
  getFirmDDL,
  getSCityDDL,
  getBCityDDL,
  createDealer,
  updateDealer,
  inActiveRow,
  onHideShow,
  loadingData,
  onHideShowIsActive,
  getTransportationModeDDL,
  exportData,
};

function getAll(obj) {
  return (dispatch) => {
    dispatch(request());
    dealerService.fetchDealerData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dealerConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: dealerConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: dealerConstants.GETALL_FAILURE, error };
  }
}

function getStateDDL(data) {
  return (dispatch) => {
    dispatch(request());

    dealerService.fetchStateDLL(data).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dealerConstants.GETSTATEDDL_REQUEST };
  }
  function success(datas) {
    return { type: dealerConstants.GETSTATEDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: dealerConstants.GETSTATEDDL_FAILURE, error };
  }
}

function exportData(obj) {
  return (dispatch) => {
    dispatch(request());
    dealerService.fetchDealerDataforExport(obj).then(
      (response) => {
        dispatch(loadingData(false));
        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.UserId;
            delete v.FirstName;
            delete v.LastName;
            delete v.RoleId;
            delete v.UserType;
            delete v.IsActive;
            delete v.IsResetPassword;
            delete v.Password;
            delete v.Token;
            delete v.RefreshToken;
            delete v.Salt;
            delete v.CreatedBy;
            delete v.UpdatedBy;
            delete v.isDeleted;
            delete v.isApproved;
            delete v.isDealer;
            delete v.DealerId;
            delete v.CountryId;
            delete v.UserName;
            delete v.CompanyName;
          });
        }
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: dealerConstants.GETEXPORT_REQUEST };
  }
  function success(datas) {
    return { type: dealerConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: dealerConstants.GETEXPORT_FAILURE, error };
  }
}

function getFirmDDL(data) {
  return (dispatch) => {
    dispatch(request());

    dealerService.fetchFirmDLL(data).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dealerConstants.GETFIRMDDL_REQUEST };
  }
  function success(datas) {
    return { type: dealerConstants.GETFIRMDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: dealerConstants.GETFIRMDDL_FAILURE, error };
  }
}

function getTransportationModeDDL() {
  return (dispatch) => {
    dispatch(request());

    dealerService.fetchTransportationModeDDL().then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dealerConstants.GET_TRANSPORTATION_REQUEST };
  }
  function success(datas) {
    return { type: dealerConstants.GET_TRANSPORTATION_SUCCESS, datas };
  }
  function failure(error) {
    return { type: dealerConstants.GET_TRANSPORTATION_FAILURE, error };
  }
}

function getSCityDDL(data) {
  return (dispatch) => {
    dispatch(request());

    dealerService.fetchCityDLL(data).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dealerConstants.GETSCITYDDL_REQUEST };
  }
  function success(datas) {
    return { type: dealerConstants.GETSCITYDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: dealerConstants.GETSCITYDDL_FAILURE, error };
  }
}

function getBCityDDL(data) {
  return (dispatch) => {
    dispatch(request());

    dealerService.fetchCityDLL(data).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dealerConstants.GETBCITYDDL_REQUEST };
  }
  function success(datas) {
    return { type: dealerConstants.GETBCITYDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: dealerConstants.GETBCITYDDL_FAILURE, error };
  }
}

function createDealer(data) {
  return (dispatch) => {
    dispatch(request(data));

    dealerService.createDealer(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(response.ResponseData));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: userConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: userConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.CREATE_FAILURE, error };
  }
}

function updateDealer(data) {
  return (dispatch) => {
    dispatch(request(data));

    dealerService.updateDealer(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: dealerConstants.UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: dealerConstants.UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: dealerConstants.UPDATE_FAILURE, error };
  }
}

function inActiveRow(user, isActive) {
  var data = {
    DealerId: user,
    IsActive: isActive,
  };
  return (dispatch) => {
    dispatch(request());

    dealerService.inActiveRow(user, isActive).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dealerConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: dealerConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: dealerConstants.ISACTIVE_FAILURE, error };
  }
}

function onHideShow(isbool) {
  return {
    type: dealerConstants.DIALOG_REQUEST,
    isbool,
  };
}

function loadingData(isbool) {
  return {
    type: dealerConstants.LOADING_REQUEST,
    isbool,
  };
}

function onHideShowIsActive(isbool) {
  return {
    type: dealerConstants.DIALOG_ISACTIVE_REQUEST,
    isbool,
  };
}
