import { loyalityschemeConstants } from "../constants/loyalityschemeConstants";

export function loyalityscheme(state = {}, action) {
    switch (action.type) {
        case loyalityschemeConstants.LOADING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };
        case loyalityschemeConstants.DIALOG_REQUEST:
            return {
                ...state,
                showDialog: action.isbool
            };
        case loyalityschemeConstants.DIALOGUE_REQUEST:
            return {
                ...state,
                showDialogue: action.isbool
            };
        case loyalityschemeConstants.DIALOG_ISACTIVE_REQUEST:
            return {
                ...state,
                showIsActiveDialog: action.isbool
            };
        case loyalityschemeConstants.UPDATE_PAGE_STORE:
            return {
                ...state,
                pages: action.pageList
            };
        case loyalityschemeConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case loyalityschemeConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case loyalityschemeConstants.GETALL_AVAILABLE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case loyalityschemeConstants.GETALL_AVAILABLE_SUCCESS:
            return {
                ...state,
                Mappseries: action.datas,
               
            };
        case loyalityschemeConstants.GETALL_AVAILABLE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case loyalityschemeConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case loyalityschemeConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case loyalityschemeConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: state.items == null ? [action.data] : [action.data, ...state.items]
            };
        case loyalityschemeConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case loyalityschemeConstants.UPDATE_REQUEST:
            return {
                ...state,
                items: state.items.map(item =>
                    item.RoleId === action.data.RoleId ? { ...item, updating: true } : item
                )
            };
        case loyalityschemeConstants.UPDATE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.RoleId === action.data.RoleId ? action.data : item
                )
            };
        case loyalityschemeConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case loyalityschemeConstants.DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            };
        case loyalityschemeConstants.DELETE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.RoleId === action.data ? {
                        CompanyId: item.CompanyId,
                        CompanyName: item.CompanyName,
                        roleId: item.roleId,
                        RoleName: item.roleName,
                        RoleDesc: item.RoleDesc,
                        isActive: item.isActive,
                        CreatedDate: item.CreatedDate
                    } : item
                )
            };
        case loyalityschemeConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case loyalityschemeConstants.GETROLEDDL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case loyalityschemeConstants.GETROLEDDL_SUCCESS:
            return {
                ...state,
                rollddl: action.datas
            };
        case loyalityschemeConstants.GETROLEDDL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case loyalityschemeConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };

            case loyalityschemeConstants.GETPRDCTDDL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case loyalityschemeConstants.GETPRDCTDDL_SUCCESS:
            return {
                ...state,
                prdctddl: action.datas
            };
        case loyalityschemeConstants.GETPRDCTDDL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case loyalityschemeConstants.GET_PAGES_REQUEST:
            return {
                ...state,
                getpages: true
            };
        case loyalityschemeConstants.GET_PAGES_SUCCESS:
            return {
                ...state,
                pages: action.pages
            };
        case loyalityschemeConstants.GET_PAGES_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case loyalityschemeConstants.ISACTIVE_REQUEST:
            return {
                ...state,
                activating: true
            };
        case loyalityschemeConstants.ISACTIVE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.RoleId === action.data.RoleId ? {
                        CompanyId: item.CompanyId,
                        CompanyName: item.CompanyName,
                        RoleId: item.RoleId,
                        RoleName: item.RoleName,
                        RoleDesc: item.RoleDesc,
                        TotalRows: item.TotalRows,
                        IsActive: action.data.IsActive,
                        CreatedDate: item.CreatedDate
                    } : item
                )
            };
        case loyalityschemeConstants.ISACTIVE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }
}