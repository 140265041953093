import { authHeader, handleResponse } from '../helpers';
export const DispatchRptService = {
    fetchDispatchData,
    GetDispatchToPrint
};

function fetchDispatchData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}DispatchRpt/GetDispatchReport`, requestOptions)
        .then(handleResponse);
}

function GetDispatchToPrint(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}DispatchRpt/GetDispatchToPrint`, requestOptions)
        .then(handleResponse);
}