import { authHeader, handleResponse } from '../helpers';
export const OrderBookingRptService = {
    fetchOrderBookingData   
};

function fetchOrderBookingData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}OrderBookingRpt/GetOrderBookingReport`, requestOptions)
        .then(handleResponse);
}