import { SliderProMsgConstants } from "../constants";
import { SliderProMsgService } from "../services/SliderProMsgService";
import { alertActions } from "./alertActions";

export const SliderProMsgActions = {
  onHideShow,
  UploadSliderImage,
  GetSliderImage,
  GetSliderList,
  DeleteSliderImage,
};

function onHideShow(isbool) {
  return {
    type: SliderProMsgConstants.DIALOG_REQUEST,
    isbool,
  };
}

// Slider Image
function UploadSliderImage(data) {
  return (dispatch) => {
    dispatch(request());

    SliderProMsgService.UploadSliderImage(data).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(response.ResponseData));
            dispatch(GetSliderList());
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request(data) {
    return { type: SliderProMsgConstants.UPLOAD_REQUEST, data };
  }
  function success(data) {
    return { type: SliderProMsgConstants.UPLOAD_SUCCESS, data };
  }
  function failure(error) {
    return { type: SliderProMsgConstants.UPLOAD_FAILURE, error };
  }
}

// Slider Image
function GetSliderImage() {
  return (dispatch) => {
    dispatch(request());
    SliderProMsgService.GetSliderHome().then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(response.ResponseData));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request(data) {
    return { type: SliderProMsgConstants.GETALL_REQUEST, data };
  }
  function success(data) {
    return { type: SliderProMsgConstants.GETALL_SUCCESS, data };
  }
  function failure(error) {
    return { type: SliderProMsgConstants.GETALL_FAILURE, error };
  }
}

function GetSliderList() {
  return (dispatch) => {
    dispatch(request());
    SliderProMsgService.GetAllSlider().then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(response.ResponseData));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request(data) {
    return { type: SliderProMsgConstants.GETALLS_REQUEST, data };
  }
  function success(data) {
    return { type: SliderProMsgConstants.GETALLS_SUCCESS, data };
  }
  function failure(error) {
    return { type: SliderProMsgConstants.GETALL_FAILURE, error };
  }
}

// Slider Delete
function DeleteSliderImage(sid) {
  ;
  return (dispatch) => {
    dispatch(request());

    SliderProMsgService.DeleteSlider(sid).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(response.ResponseData));
            dispatch(GetSliderList());
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request(data) {
    return { type: SliderProMsgConstants.DELETE_REQUEST, data };
  }
  function success(data) {
    return { type: SliderProMsgConstants.DELETE_SUCCESS, data };
  }
  function failure(error) {
    return { type: SliderProMsgConstants.DELETE_FAILURE, error };
  }
}
