import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import $ from 'jquery';

import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import App from './App';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
//import productReducer from './reducer/productReducer';
//import Warehouses from './reducer/WarehouseReducer';
// import SupplierReducer from './reducer/SupplierReducer';
// import MachineReducer from './reducer/MachineReducer';
// import ShiftReducer from './reducer/ShiftReducer';
// import UserReducer from "./reducer/UserReducer";
// import  UserTypeReducer  from "./reducer/UserTypeReducer";

// import thunk from 'redux-thunk';
// import { reducer as formReducer } from 'redux-form';

import { store } from './helpers';
// const rootReducer = combineReducers({
//   productDatas: productReducer,
//   //WarehouseDatas: Warehouses,
//   SupplierDatas:SupplierReducer,
//   MachineDatas:MachineReducer,
//   ShiftDatas:ShiftReducer,
//   UserDatas: UserReducer,
//   UserTypeDatas: UserTypeReducer,
//   form: formReducer
// })

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
$(document).ready(function () {
  $('.value').each(function () {
      $(this).prop('Counter', 0).animate({
          Counter: $(this).text()
      }, {
              duration: 3500,
              easing: 'swing',
              step: function (now) {
                  $(this).text(Math.ceil(now));
              }
          });
  });
});




ReactDOM.render(
  <Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(////console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
