import { authHeader, handleResponse, authHeaderFormData } from "../helpers";
export const OrderBookingService = {
  CreateOrder,
  updateOrderBooking,
  fetchOrderBookingData,
  inActiveRow,
  FetchOrderDDL,
  getDealerDDL,
  GetSearchData,
  GetProductsData,
  fetchCategoryDLL,
  ReadRow,
  Download,
  UploadSODetails,
  GetSOInsertLog,
};

function CreateOrder(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}OrderBooking/CreateOrder`,
    requestOptions
  ).then(handleResponse);
}

function updateOrderBooking(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}OrderBooking/CreateOrder`,
    requestOptions
  ).then(handleResponse);
}

function fetchOrderBookingData(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}OrderBooking/GetOrder`,
    requestOptions
  ).then(handleResponse);
}

function inActiveRow(orderBookingno, isActive) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}OrderBooking/InActiveRow/${orderBookingno}/${isActive}`,
    requestOptions
  ).then(handleResponse);
}

function ReadRow(orderBookingno, IsShow) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}OrderBooking/ReadRow?orderbookingno=${orderBookingno}&IsShow=${IsShow}`,
    requestOptions
  ).then(handleResponse);
}

function Download(Id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}OrderBooking/GetOrderForExport?OrderId=${Id}`,
    requestOptions
  ).then(handleResponse);
}

function FetchOrderDDL() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}Company/GetOrderDDL`,
    requestOptions
  ).then(handleResponse);
}

function getDealerDDL() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}OrderBooking/GetDealerDDL`,
    requestOptions
  ).then(handleResponse);
}

function GetSearchData(searchval, cateId, pageSize) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}OrderBooking/GetSearchData_Web?searchval=${searchval}&cateId=${cateId}&pageSize=${pageSize}`,
    requestOptions
  ).then(handleResponse);
}

function GetProductsData(ids) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(ids),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}OrderBooking/GetProductsData`,
    requestOptions
  ).then(handleResponse);
}

function fetchCategoryDLL() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}Category/GetCategoryDLL`,
    requestOptions
  ).then(handleResponse);
}

function UploadSODetails(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeaderFormData(),
    body: formData,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}OrderBooking/UploadSODetails`,
    requestOptions
  ).then(handleResponse);
}

function GetSOInsertLog(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(formData),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}OrderBooking/GetSOInsertLog`,
    requestOptions
  ).then(handleResponse);
}
