import { authHeader, handleResponse } from '../helpers';
export const mappingService = {
    createMapping,
    fetchMappingData,
    fetchToNo,
    GetAvailableseries
};

function createMapping(data) {  

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Mapping/CreateMapping`, requestOptions)
    .then(handleResponse);
}


function fetchMappingData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    // return fetch('data/products.json').then(res => res.json()).then(d => d.data);
    return fetch(`${process.env.REACT_APP_API_URL}Mapping/GetMappings`, requestOptions)
    .then(handleResponse);
}
function fetchToNo() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}Mapping/GetToNumbers`,requestOptions)
    .then(handleResponse);
}

function GetAvailableseries(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}Mapping/GetAvailableseries`,requestOptions)
    .then(handleResponse);
}








