import { QRCodeTrackingRptService } from "../services/QRCodeTrackingRptService";
import { QRCodeTrackingRptConstants } from "../constants/QRCodeTrackingRptConstants";
import { alertActions } from ".";

export const QRCodeTrackingRptActions = {
  toggle,
  toggle2,
  GetScanInfo,
  GetJourney,
};

function GetScanInfo(data) {
  return (dispatch) => {
    dispatch(request());
    QRCodeTrackingRptService.GetScanInfo(data).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: QRCodeTrackingRptConstants.TRACK_SCAN_INFO_REQUEST };
  }
  function success(datas) {
    return { type: QRCodeTrackingRptConstants.TRACK_SCAN_INFO_SUCCESS, datas };
  }
  function failure(error) {
    return { type: QRCodeTrackingRptConstants.TRACK_SCAN_INFO_FAILURE, error };
  }
}

function toggle(data) {
  return (dispatch) => {
    dispatch(request());
    QRCodeTrackingRptService.GetScanInfo(data).then(
      (response) => {
        dispatch(loadingData(false));

        const resp = response.ResponseData;
        let qr = [];
        let val;
        resp.forEach((element) => {
          if (val == element.Id) {
          } else {
            val = element.Id;
            qr.push(element);
          }
        });

        dispatch(success(qr));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: QRCodeTrackingRptConstants.TRACK_TOGGLE_REQUEST };
  }
  function success(datas) {
    return { type: QRCodeTrackingRptConstants.TRACK_TOGGLE_SUCCESS, datas };
  }
  function failure(error) {
    return { type: QRCodeTrackingRptConstants.TRACK_TOGGLE_FAILURE, error };
  }
}

function toggle2(data) {
  return (dispatch) => {
    dispatch(request());
    QRCodeTrackingRptService.GetScanInfo(data).then(
      (response) => {
        dispatch(loadingData(false));

        const resp = response.ResponseData;
        let qr = [];
        let val;
        resp.forEach((element) => {
          if (val == element.Id) {
          } else {
            val = element.Id;
            qr.push(element);
          }
        });

        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: QRCodeTrackingRptConstants.TRACK_TOGGLE_2_REQUEST };
  }
  function success(datas) {
    return { type: QRCodeTrackingRptConstants.TRACK_TOGGLE_2_SUCCESS, datas };
  }
  function failure(error) {
    return { type: QRCodeTrackingRptConstants.TRACK_TOGGLE_2_FAILURE, error };
  }
}

function GetJourney(data) {
  return (dispatch) => {
    dispatch(request());
    QRCodeTrackingRptService.GetJourney(data).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: QRCodeTrackingRptConstants.TRACK_JOURNEY_INFO_REQUEST };
  }
  function success(datas) {
    return {
      type: QRCodeTrackingRptConstants.TRACK_JOURNEY_INFO_SUCCESS,
      datas,
    };
  }
  function failure(error) {
    return {
      type: QRCodeTrackingRptConstants.TRACK_JOURNEY_INFO_FAILURE,
      error,
    };
  }
}

function loadingData(isbool) {
  return {
    type: QRCodeTrackingRptConstants.LOADING_REQUEST,
    isbool,
  };
}
