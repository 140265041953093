import { OrderBookingRptConstants } from "../constants";

export function OrderBookingRpt(state = {}, action) {
    switch (action.type) {
        case OrderBookingRptConstants.LOADING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };
        case OrderBookingRptConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case OrderBookingRptConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case OrderBookingRptConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case OrderBookingRptConstants.GETEXPORT_REQUEST:
            return {
                ...state,
                exporting: true
            };
        case OrderBookingRptConstants.GETEXPORT_SUCCESS:
            return {
                ...state,
                exportitems: action.datas,
                isDownload: state.isDownload == null ? 1 : state.isDownload + 1
            };
        case OrderBookingRptConstants.GETEXPORT_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }

}