import { authHeader, handleResponse } from '../helpers';
export const categoryService = {
    getCategory,
    createCategory,
    updateCategory,
    inActiveRow,
    fetchCategoryDLL
};

function getCategory(data) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Category/GetCategory`, requestOptions)
        .then(handleResponse);
}

function createCategory(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Category/CreateCategory`, requestOptions)
        .then(handleResponse);
}

function updateCategory(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Category/UpdateCategory`, requestOptions)
        .then(handleResponse);
}

function inActiveRow(categoryId, isActive) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}Category/InActiveRow/${categoryId}/${isActive}`, requestOptions)
        .then(handleResponse);
}

function fetchCategoryDLL() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}Category/GetCategoryDLL`, requestOptions)
        .then(handleResponse);
}