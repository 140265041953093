import { authHeader, handleResponse } from "../helpers";
export const DispatchService = {
  GetDispatch,
  CreateDispatch,
  GetActiveDispatchDDL,
  fetchSODetails,
  GetScanInfo,
};

function GetDispatch(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}Dispatch/GetDispatch`,
    requestOptions
  ).then(handleResponse);
}

function CreateDispatch(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}Dispatch/Save`,
    requestOptions
  ).then(handleResponse);
}

function GetActiveDispatchDDL() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}Dispatch/GetActiveDispatchDDL`,
    requestOptions
  ).then(handleResponse);
}

function fetchSODetails(SONo) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}Dispatch/GetSODetails?SONo=${SONo}`,
    requestOptions
  ).then(handleResponse);
}

function GetScanInfo(d) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}Dispatch/GetScanInfo?qr=` + d,
    requestOptions
  ).then(handleResponse);
}
