import { SqlEditorConstants } from "../constants";

export function SqlEditor(state = {}, action) {
    switch (action.type) {
        case SqlEditorConstants.RUN_SCRIPT_REQUEST:
            return {
                ...state,
                creating: true
            };
        case SqlEditorConstants.RUN_SCRIPT_SUCCESS:
            return {
                ...state,
                items: action.data
            };
        case SqlEditorConstants.RUN_SCRIPT_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }
}
