import { secondaryPackingConstants } from "../constants";

export function secondaryPacking(state = {}, action) {
  switch (action.type) {
    case secondaryPackingConstants.ProductByQrScan_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case secondaryPackingConstants.ProductByQrScan_SUCCESS:
      return {
        ...state,
        scanneditems:
          state.scanneditems == null
            ? [action.data]
            : [action.data, ...state.scanneditems],
      };
    case secondaryPackingConstants.ProductByQrScan_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case secondaryPackingConstants.ProductByQrScan_Warning:
      return {
        ...state,
        warning: action.data == "no data found" ? true : false,
        alreadyPacked: action.data != "no data found" ? true : false,
      };
    case secondaryPackingConstants.UPDATE_SCANNED_REQUEST:
      return {
        ...state,
        scanneditems: state.updatedList,
      };
    case secondaryPackingConstants.SAVE_SECONDARY_PACKING_RESUEST:
      return {
        ...state,
        saveing: true,
      };
    case secondaryPackingConstants.SAVE_SECONDARY_PACKING_SUCCESS:
      return {
        ...state,
        secondaryItems:
          state.secondaryItems == null
            ? [action.data]
            : [action.data, ...state.secondaryItems],
        scanneditems: undefined,
      };
    case secondaryPackingConstants.SAVE_SECONDARY_PACKING_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case secondaryPackingConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case secondaryPackingConstants.GETALL_SUCCESS:
      return {
        ...state,
        secondaryItems: action.data,
      };
    case secondaryPackingConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case secondaryPackingConstants.REMOVE_ITEM:
      return {
        ...state,
        scanneditems:
          state.scanneditems.filter((x) => x.SeqNo != action.data).length == 0
            ? undefined
            : state.scanneditems.filter((x) => x.SeqNo != action.data),
      };
    case secondaryPackingConstants.Warning:
      return {
        ...state,
        warning: action.isbool,
      };
    case secondaryPackingConstants.Alreadypacked:
      return {
        ...state,
        alreadyPacked: action.isbool,
      };
    default:
      return state;
  }
}
