import { UserloyalityLogRptService } from '../services/UserloyalityLogRptService';
import { UserloyalityLogRptConstants } from '../constants/UserloyalityLogRptConstants';
import {alertActions} from '.';

export const UserloyalityLogRptActions= {
    getAll,
    loadingData,
    exportData
};

function getAll(obj)
{
    return dispatch =>{
        dispatch(request());

        UserloyalityLogRptService.fetchUserloyalityLogData(obj).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
        error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        }
        
        );
    };
    function request() { return { type : UserloyalityLogRptConstants.GETALL_REQUEST } }       //Changes
    function success(datas) { return { type: UserloyalityLogRptConstants.GETALL_SUCCESS, datas } }
    function successExport(datas) { return { type: UserloyalityLogRptConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: UserloyalityLogRptConstants.GETALL_FAILURE, error } }
}

function exportData(obj) {
    return dispatch => {
        dispatch(request());
        UserloyalityLogRptService.fetchUserloyalityLogData(obj).then(response => {
            dispatch(loadingData(false));
            var data = response.ResponseData;
            if (data != null) {
                data.forEach(function (v) { delete v.TotalRows; delete v.CreatedBy; 
                    delete v.DocURL; delete v.UserloyalityLogId; delete v.ProductID
                });
            }
            dispatch(success(data))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };
    function request() { return { type: UserloyalityLogRptConstants.GETEXPORT_REQUEST } }
    function success(datas) { return { type: UserloyalityLogRptConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: UserloyalityLogRptConstants.GETEXPORT_FAILURE, error } }
}

function loadingData(isbool) {
    return {
        type: UserloyalityLogRptConstants.LOADING_REQUEST, isbool
    }
}

