export const issueNoConstants = {
    CREATE_REQUEST: 'ISSUE_CREATE_REQUEST',
    CREATE_SUCCESS: 'ISSUE_CREATE_SUCCESS',
    CREATE_FAILURE: 'ISSUE_CREATE_FAILURE',

    UPDATE_REQUEST: 'ISSUE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ISSUE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ISSUE_UPDATE_FAILURE',

    GETALL_REQUEST: 'ISSUE_GETALL_REQUEST',
    GETALL_SUCCESS: 'ISSUE_GETALL_SUCCESS',
    GETALL_FAILURE: 'ISSUE_GETALL_FAILURE',

    GETALL_FROMNO_REQUEST: 'ISSUE_GETALL_FROMNO_REQUEST',
    GETALL_FROMNO_SUCCESS: 'ISSUE_GETALL_FROMNO_SUCCESS',
    GETALL_FROMNO_FAILURE: 'ISSUE_GETALL_FROMNO_FAILURE',

    DELETE_REQUEST: 'ISSUE_DELETE_REQUEST',
    DELETE_SUCCESS: 'ISSUE_DELETE_SUCCESS',
    DELETE_FAILURE: 'ISSUE_DELETE_FAILURE',
    
    LOADING_REQUEST:'LOADING_REQUEST',
    DIALOG_REQUEST:'DIALOG_REQUEST'
};