import { authHeader, handleResponse } from '../helpers';
export const ProductPurchaseRptService = {
    fetchProductPurchaseData   
};

function fetchProductPurchaseData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}ProductPurchaseRpt/GetProductPurchaseReport`, requestOptions)
        .then(handleResponse);
}