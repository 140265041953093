import React, { Component } from "react";
import MessageComponent from "../../templates/Message";
import "primereact/resources/themes/saga-blue/theme.css"; 
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import tableRequest from "../../models/tableRequest.js";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
import { connect } from "react-redux";
import PrintSecondaryPackagingService from "../../services/PrintSecondaryPackagingService";
import { productMasterActions } from "../../actions";

class CartonSlip extends Component {
  constructor(props) {
    super(props);
    this.PrintSecondaryPackagingService = new PrintSecondaryPackagingService();
    this.tblResponse = new tableRequest();
    this.state = {
      FromNo: null,
      Qty: null,
      type: "",
      ToNo: null,
      Prefix: "",
      Sgen: "",
      Tgen: "",
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  //to trigger api function o page call
  componentDidMount() {
    this.GetSTIds();
  }

  GetSTIds() {
    this.props.getIds();
    setTimeout(() => {
      this.setState({
        Sgen: parseInt(this.props.Sids) + 1,
        Tgen: parseInt(this.props.Tids) + 1,
      });
    }, 800);
  }

  submitForm() {
    if (
      this.state.FromNo == null ||
      this.state.ToNo == null ||
      this.state.Qty == null ||
      this.state.FromNo == "" ||
      this.state.ToNo == "" ||
      this.state.Qty == "" ||
      this.state.FromNo == undefined ||
      this.state.ToNo == undefined ||
      this.state.Qty == undefined ||
      this.state.type == undefined ||
      this.state.type == null ||
      this.state.type == ""
    ) {
      this.refs.Messchild.showWarn("Invalid Arguments");
    } else {
      this.setState({
        FromNo: this.state.ToNo,
        Qty: "",
        ToNo: "",
      });
      this.PrintSecondaryPackagingService.BulkData(
        this.state.type,
        this.state.FromNo,
        this.state.ToNo,
        this.state.Prefix
      );
      if (
        parseInt(this.state.Sgen) < parseInt(this.state.ToNo) ||
        this.state.Tgen < parseInt(this.state.ToNo)
      ) {
        this.props.updateIds(this.state.type, this.state.ToNo);
        setTimeout(() => {
          this.GetSTIds();
        }, 800);
      } else {
        this.refs.Messchild.showSuccess("Download Succcess");
      }
    }
  }
  handleChange(e) {
    //const { name, value } = e.target;
    // this.setState({name: value });
    //      this.setState({FromNo:e})
  }
  onValueChange(e) {
    if (e.target.value == "TID") {
      this.setState({
        type: e.target.value,
        FromNo: this.state.Tgen,
        Qty: "",
        ToNo: "",
        Prefix: sessionStorage.getItem("Prefix"),
      });
    } else if (e.target.value == "SID") {
      this.setState({
        type: e.target.value,
        FromNo: this.state.Sgen,
        Qty: "",
        ToNo: "",
        Prefix: sessionStorage.getItem("Prefix"),
      });
    }
  }
  //sessionStorage.getItem("Prefix")
  render() {
    const items = [{ label: "Transactions" }, { label: ">Carton Slip" }];
    const home = { icon: "pi pi-home", url: "/dashboard" };

    return (
      <>
        <div className="datatable-filter-demo">
          <div className="card">
            <MessageComponent ref="Messchild" />
            {/* {<Loaders loader={this.state.updateLoader} /> } */}
            <Card>
              <div className="row  head_data">
                <div className="col-md-6 heading">
                  <h4 className="leftspace">Carton Slip</h4>
                </div>
                <div className="col-md-6 custom-breadcrumb">
                  <BreadCrumb model={items} home={home} />
                </div>
              </div>
              <div className="row boxspace">
                <div className="col-md-6">
                  <div className="row bottunspace">
                    <div className="col-md-3">
                      <label>
                        From Number<span>*</span>:
                      </label>
                    </div>
                    <div className="col-md-9">
                      <input
                        type="number"
                        value={this.state.FromNo}
                        onChange={(e) =>
                          this.setState({
                            FromNo: e.target.value,
                            Qty: "",
                            ToNo: "",
                          })
                        }
                        className="form-control"
                        name="FromNo"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <label>
                        Quantity<span>*</span>:
                      </label>
                    </div>
                    <div className="col-md-9">
                      <input
                        type="number"
                        value={this.state.Qty}
                        onChange={(e) =>
                          this.setState({
                            ToNo:
                              parseInt(e.target.value) +
                              parseInt(this.state.FromNo) -
                              1,
                            Qty: parseInt(e.target.value),
                          })
                        }
                        className="form-control"
                        name="Qty"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row bottunspace">
                    <div className="col-md-4">
                      <label>
                        To Number. (Included)<span>*</span>:
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="number"
                        value={this.state.ToNo}
                        className="form-control"
                        name="ToNo"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <input
                        type="radio"
                        value="SID"
                        className="mr-3 ml-3"
                        name="type"
                        onChange={this.onValueChange}
                      />
                      SID{" "}
                      <input
                        type="radio"
                        value="TID"
                        className="ml-3"
                        name="type"
                        onChange={this.onValueChange}
                      />{" "}
                      TID
                    </div>
                  </div>
                </div>
              </div>

              <div className="usearea">
                <div className="text-center mb-5">
                  <button
                    className="btn btn-primary mt-3"
                    onClick={this.submitForm}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let tmp = state.product?.Ids?.split("VAL");
  return {
    Sids: state.product?.Ids != null && tmp[0],
    Tids: state.product?.Ids != null && tmp[1],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIds: () => {
      dispatch(productMasterActions.GetIds());
    },
    updateIds: (type, value) => {
      dispatch(productMasterActions.UpdateIds(type, value));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartonSlip);
