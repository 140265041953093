export const TertiaryPackingConstants = {
  ProductByQrScan_REQUEST: "ProductByQrScan_REQUEST",
  ProductByQrScan_SUCCESS: "ProductByQrScan_SUCCESS",
  ProductByQrScan_Warning: "ProductByQrScan_Warning",
  ProductByQrScan_FAILURE: "ProductByQrScan_FAILURE",

  UPDATE_SCANNED_REQUEST: "UPDATE_SCANNED_REQUEST",

  SAVE_TERTIARY_PACKING_RESUEST: "SAVE_TERTIARY_PACKING_RESUEST",
  SAVE_TERTIARY_PACKING_FAILURE: "SAVE_TERTIARY_PACKING_FAILURE",

  GETALL_REQUEST: "TERTIARY_PACKING_GETALL_REQUEST",
  GETALL_SUCCESS: "TERTIARY_PACKING_GETALL_SUCCESS",
  GETALL_FAILURE: "TERTIARY_PACKING_GETALL_FAILURE",

  GETALL_SCAN_REQUEST: "TERTIARY_PACKING_GETALL_SCAN_REQUEST",
  GETALL_SCAN_SUCCESS: "TERTIARY_PACKING_GETALL_SCAN_SUCCESS",
  GETALL_SCAN_FAILURE: "TERTIARY_PACKING_GETALL_SCAN_FAILURE",

  GETALL_CARTON_REQUEST: "TERTIARY_PACKING_GETALL_CARTON_REQUEST",
  GETALL_CARTON_SUCCESS: "TERTIARY_PACKING_GETALL_CARTON_SUCCESS",
  GETALL_CARTON_FAILURE: "TERTIARY_PACKING_GETALL_CARTON_FAILURE",

  LOADING_REQUEST: "TERTIARY_PACKING_LOADING_REQUEST",

  Warning: "Warning",
  Alreadypacked: "Alreadypacked",
};
