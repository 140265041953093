export const OrderBookingRptConstants ={
    GETALL_REQUEST: 'ORDERBOOKING_GETALL_REQUEST',
    GETALL_SUCCESS: 'ORDERBOOKING_GETALL_SUCCESS',
    GETALL_FAILURE: 'ORDERBOOKING_GETALL_FAILURE',

    GETEXPORT_REQUEST: 'ORDERBOOKING_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'ORDERBOOKING_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'ORDERBOOKING_GETEXPORT_FAILURE',

    LOADING_REQUEST: 'LOADING_REQU  EST'
}