import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./../views/Dashboard";
import CompanyMaster from "./../components/masters/companyMaster";
import OrderBooking from "./../components/masters/OrderBooking";
import userApproval from "./../components/masters/userApproval";

import ProductMaster from "./../components/masters/productMaster";
import roleMaster from "./../components/masters/roleMaster";
import CartonMaster from "../components/masters/cartonMaster";
import loyalityscheme from "./../components/masters/loyalityScheme";
import SqlEditor from "../components/masters/SqlEditor";
import Profile from "./../views/Profile";
import IssueNumber from "../components/mapping/IssueNumber";
import Mapping from "./../components/transactions/Mapping";
import DeMapping from "./../components/transactions/DeMapping";
import Damage from "./../components/transactions/Damage";
import UserMaster from "./../components/masters/userMaster";
import VerificationLog from "./../components/reports/VerificationLog";
import MappingRpt from "./../components/reports/MappingRpt";
import DemappingRpt from "./../components/reports/DemappingRpt";
import DamageRpt from "../components/reports/DamageRpt";
import LoyaltySchemeRpt from "./../components/reports/LoyaltySchemeRpt";
import FeedbackRpt from "./../components/reports/FeedbackRpt";
import ProductConfiguration from "../components/masters/ProductConfiguration";
import RedemptionApproval from "../components/transactions/RedemptionApproval";
import RedemptionLogRpt from "./../components/reports/RedemptionLogRpt";
import UserloyalityLogRpt from "../components/reports/UserloyalityLogRpt";
import SecondaryPacking from "../components/transactions/SecondaryPacking";
import Dispatch from "../components/transactions/Dispatch";
import CartonSlip from "../components/transactions/CartonSlip";
import dealerMaster from "./../components/masters/dealerMaster";
import SecondaryPackingReport from "../components/reports/SecondaryPackingReport";
import DispatchReport from "../components/reports/DispatchReport";
import OrderBookingRpt from "../components/reports/OrderBookingRpt";
import TertiaryPacking from "../components/transactions/TertiaryPacking";
import TertiaryPackingRpt from "../components/reports/TertiaryPackingRpt";
import ProductPurchaseRpt from "../components/reports/ProductPurchaseRpt";
import MRPMaster from "../components/masters/MRPMaster";
import categoryMaster from "../components/masters/categoryMaster";
import QRCodeTrackingRpt from "../components/reports/QRCodeTrackingRpt";
import SliderProMsg from "../components/masters/SliderProMsg";
import SODetails from "../components/masters/SODetails";

export default class Template extends Component {
  render() {
    return (
      <div className="container-fluid ">
        <Header />
        <div className="row">
          <div className="col-md-2 sidebar-bg">
            <Sidebar />
          </div>
          <div className="col-md-10 body-part ">
            <Router>
              <Switch>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/profile" component={Profile} />
                <Route
                  exact
                  path="/masters/company-master"
                  component={CompanyMaster}
                />
                <Route
                  exact
                  path="/masters/product-master"
                  component={ProductMaster}
                />
                <Route exact path="/masters/mrp-master" component={MRPMaster} />
                <Route
                  exact
                  path="/masters/role-master"
                  component={roleMaster}
                />
                <Route
                  exact
                  path="/masters/carton-master"
                  component={CartonMaster}
                />
                <Route
                  exact
                  path="/masters/loyalityscheme-master"
                  component={loyalityscheme}
                />
                <Route
                  exact
                  path="/masters/user-master"
                  component={UserMaster}
                />
                <Route
                  exact
                  path="/masters/product-configuration"
                  component={ProductConfiguration}
                />
                <Route exact path="/masters/sql-editor" component={SqlEditor} />
                <Route
                  exact
                  path="/activation/issue-number"
                  component={IssueNumber}
                />
                <Route exact path="/transactions/damage" component={Damage} />
                <Route exact path="/transactions/mapping" component={Mapping} />
                <Route
                  exact
                  path="/transactions/de-mapping"
                  component={DeMapping}
                />
                <Route
                  exact
                  path="/transactions/RedemptionApproval"
                  component={RedemptionApproval}
                />
                <Route
                  exact
                  path="/reports/verification-log"
                  component={VerificationLog}
                />
                <Route
                  exact
                  path="/reports/mapping-report"
                  component={MappingRpt}
                />
                <Route
                  exact
                  path="/reports/Demapping-report"
                  component={DemappingRpt}
                />
                <Route
                  exact
                  path="/reports/Damage-report"
                  component={DamageRpt}
                />
                <Route
                  exact
                  path="/reports/TertiaryPacking-report"
                  component={TertiaryPackingRpt}
                />
                <Route
                  exact
                  path="/reports/Loyalty-Scheme-report"
                  component={LoyaltySchemeRpt}
                />
                <Route
                  exact
                  path="/reports/Redemption-Log-report"
                  component={RedemptionLogRpt}
                />
                <Route
                  exact
                  path="/reports/feedback-report"
                  component={FeedbackRpt}
                />
                <Route
                  exact
                  path="/reports/Userloyality-report"
                  component={UserloyalityLogRpt}
                />
                <Route
                  exact
                  path="/transactions/secondary-packing"
                  component={SecondaryPacking}
                />
                <Route
                  exact
                  path="/transactions/Tertiary-Packing"
                  component={TertiaryPacking}
                />
                <Route
                  exact
                  path="/transactions/dispatch"
                  component={Dispatch}
                />
                <Route
                  exact
                  path="/transactions/carton-slip"
                  component={CartonSlip}
                />
                <Route
                  exact
                  path="/masters/dealer-master"
                  component={dealerMaster}
                />
                <Route
                  exact
                  path="/masters/upload-so-details"
                  component={SODetails}
                />
                <Route
                  exact
                  path="/masters/order-booking"
                  component={OrderBooking}
                />
                <Route
                  exact
                  path="/masters/user-approval"
                  component={userApproval}
                />
                <Route
                  exact
                  path="/reports/secondarypacking-report"
                  component={SecondaryPackingReport}
                />
                <Route
                  exact
                  path="/reports/dispatch-report"
                  component={DispatchReport}
                />
                <Route
                  exact
                  path="/reports/orderbooking-report"
                  component={OrderBookingRpt}
                />
                <Route
                  exact
                  path="/reports/ProductPurchaseRpt-report"
                  component={ProductPurchaseRpt}
                />
                <Route
                  exact
                  path="/masters/category-master"
                  component={categoryMaster}
                />
                <Route
                  exact
                  path="/reports/qr-code-tracking-report"
                  component={QRCodeTrackingRpt}
                />
                <Route
                  exact
                  path="/masters/SliderProMsg"
                  component={SliderProMsg}
                />
                <Route path="*" component={Dashboard} />
              </Switch>
            </Router>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
