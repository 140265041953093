import { companyConstants } from '../constants';

export function company(state = {}, action) {
    switch (action.type) {
        case companyConstants.LOADING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };
        case companyConstants.DIALOG_REQUEST:
            return {
                ...state,
                showDialog: action.isbool
            };
            case companyConstants.DIALOG_ISACTIVE_REQUEST:
            return {
                ...state,
                showIsActiveDialog: action.isbool
            };
        case companyConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case companyConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case companyConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case companyConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case companyConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: [action.data, ...state.items]
            };
        case companyConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case companyConstants.UPDATE_REQUEST:
            return {
                ...state,
                items: state.items.map(item =>
                    item.CompanyId == action.data.CompanyId ? { ...item, updating: true } : item
                )
            };
        case companyConstants.UPDATE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.CompanyId === action.data.CompanyId ? action.data : item
                )
            };
        case companyConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case companyConstants.DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            };
        case companyConstants.DELETE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.CompanyId === action.data ? {
                        CompanyId: item.CompanyId,
                        CompanyName: item.CompanyName,
                        CompanyDisplayName: item.CompanyDisplayName,
                        Prefix: item.Prefix,
                        Email: item.Email,
                        Mobile: item.Mobile,
                        WebsiteURL: item.WebsiteURL,
                        Address: item.Address,
                        CompanyLogo: item.CompanyLogo,
                        FakeMessage: item.FakeMessage,
                        FakeAudio: item.FakeAudio,
                        IsActive: false,
                        CreatedDate: item.CreatedDate,
                        UpdatedDate: item.UpdatedDate

                    } : item
                )
            };
        case companyConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case companyConstants.GETCMPNYDDL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case companyConstants.GETCMPNYDDL_SUCCESS:
            return {
                ...state,
                cmpnyddl: action.datas
            };
        case companyConstants.GETCMPNYDDL_FAILURE:
            return {
                ...state,
                error: action.error
            };

            case companyConstants.ISACTIVE_REQUEST:
            return {
                ...state,
                activating: true
            };
        case companyConstants.ISACTIVE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.CompanyId === action.data.CompanyId ? {
                        CompanyId: item.CompanyId,
                        CompanyName: item.CompanyName,
                        CompanyDisplayName: item.CompanyDisplayName,
                        Prefix: item.Prefix,
                        Email: item.Email,
                        Mobile: item.Mobile,
                        WebsiteURL: item.WebsiteURL,
                        Address: item.Address,
                        CompanyLogo: item.CompanyLogo,
                        FakeMessage: item.FakeMessage,
                        FakeAudio: item.FakeAudio,
                        IsActive: action.data.IsActive,
                        TotalRows: item.TotalRows,
                        CreatedDate: item.CreatedDate,
                        UpdatedDate: item.UpdatedDate

                    } : item
                )
            };
        case companyConstants.ISACTIVE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        default:
            return state
    }
}
