import { DispatchRptService } from '../services';
import { DispatchRptConstants } from '../constants';
import {alertActions} from '.';

export const DispatchRptActions = {
    getAll,
    loadingData,
    exportData,
    GetDispatchToPrint
};

function getAll(obj)
{
    return dispatch =>{
        dispatch(request());
        DispatchRptService.fetchDispatchData(obj).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))

            var data = response.ResponseData;
            if (data != null) {
                data.forEach(function (v) { delete v.TotalRows });
            }
            dispatch(successExport(data))
        },
        error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
        }
        
        );
    };
    function request() { return { type : DispatchRptConstants.GETALL_REQUEST } }
    function success(datas) { return { type: DispatchRptConstants.GETALL_SUCCESS, datas } }
    function successExport(datas) { return { type: DispatchRptConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: DispatchRptConstants.GETALL_FAILURE, error } }
}

function exportData(obj) {
    return dispatch => {
        dispatch(request());
        DispatchRptService.fetchDispatchData(obj).then(response => {
            dispatch(loadingData(false));
            var data = response.ResponseData;
            if (data != null) {
                data.forEach(function (v) { delete v.TotalRows });
            }
            dispatch(success(data))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };
    function request() { return { type: DispatchRptConstants.GETEXPORT_REQUEST } }
    function success(datas) { return { type: DispatchRptConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: DispatchRptConstants.GETEXPORT_FAILURE, error } }
}

function loadingData(isbool) {
    return {
        type: DispatchRptConstants.LOADING_REQUEST, isbool
    }
}

function GetDispatchToPrint(obj) {
    return dispatch => {
        dispatch(request());
        DispatchRptService.GetDispatchToPrint(obj).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }

        );
    };
    function request() { return { type: DispatchRptConstants.GETPRINT_REQUEST } }
    function success(datas) { return { type: DispatchRptConstants.GETPRINT_SUCCESS, datas } }
    function failure(error) { return { type: DispatchRptConstants.GETPRINT_FAILURE, error } }
}
