import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { SliderProMsgActions } from "../../actions";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";

class SliderProMsg extends Component {
  constructor(props) {
    super(props);
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.photoBodyTemplate = this.photoBodyTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.setImagePath = this.setImagePath.bind(this);

    this.submitForm = this.submitForm.bind(this);

    this.state = {
      SliderOptionSelectItems: [
        { label: " Home Page Silder", value: "WebSlider" },
        { label: " App Home Page Slider ", value: "ZAppSlider" },
        { label: "Promotional Msg", value: "ProMsg" },
      ],
      SliderUpload: [
        { label: "Video File", value: "video/*" },
        { label: "Image File", value: "image/*" },
        { label: "Audio File", value: "audio/*" },
        // { label: 'Text', value: 'Txt' },
      ],
      editorContent: "",
      Promo: true,
      promotionalStartDate: "",
      promotionalEndDate: "",
      editorState: "",
      storetext: "",
      setEditorState: "",
      SliderOption: "",
      SliderUploadType: "",
      sliderImage: "",
      file: [null],
      IsAdd: true,
      SId: 0,
      ProMsg: "",
      manulText: "",
      formError: {
        sliderImage: "",
        promotionalStartDate: "",
        promotionalEndDate: "",
        SliderOption: "",
        SliderUploadType: "",
      },
    };
  }

  componentDidMount() {
    //this.props.fetchApi();
    this.props.GetSliderListfetchApi();
  }

  // set Slider Image Path
  setImagePath(event) {
    let errors = this.state.formError;
    errors.sliderImage = "";
    //errors.promotionalImage="";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              sliderImage: response.Source,
              //promotionalImage: response.Source
            });
          } else if (response.ResponseCode === "400") {
            errors.sliderImage = "Only image format is accepted.";
            this.setState({
              sliderImage: [],
              //promotionalImage:[],
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  photoBodyTemplate(e) {
    if (e.SliderImage) {
      return <img src={e.SliderImage} width="50px" />;
    }
  }

  onHide() {
    this.resetForm();
    this.props.onHideShow(false);
  }

  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "isActive":
        value = event.target.checked;
        break;
      case "SliderOption":
        if (value.includes("ProMsg"))
          this.setState({
            Promo: false,
          });
        else {
          this.setState({
            Promo: true,
          });
        }
      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  resetForm() {
    this.setState({
      Promo: true,
      sliderImage: "",
      SliderOption: "",
      SliderUploadType: "",
      storetext: "",
      promotionalStartDate: "",
      promotionalEndDate: "",
      isActive: true,
      formError: {
        sliderImage: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }
  // Upload Slider Submit Form
  submitForm(event) {
    event.preventDefault();
    //console.log(this.state.sliderImage);
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      const formData = {
        FilePath: this.state.sliderImage,
        FileType: this.state.SliderUploadType,
        UploadFor: this.state.SliderOption,
        ProMsg: this.state.ProMsg,
        startDate: this.state.promotionalStartDate,
        endDate: this.state.promotionalEndDate,
      };
      //console.log(formData);
      this.resetForm();
      this.props.uploadSliderImage(formData);
    }
  }

  //Slider Validate Form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      // case 'promotionalMessage':
      //     if (!value || value.length < 1) {
      //         IsValid = false;
      //         errors.promotionalMessage = "Please enter your promotional message.";
      //     } else errors.promotionalMessage = ''
      //     break;
      case "sliderImage":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.sliderImage = "Please upload slider image.";
        } else errors.sliderImage = "";
        break;
      case "SliderOption":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.SliderOption = "Please Select Slider Option.";
        } else errors.SliderOption = "";
        break;
      case "SliderUploadType":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.SliderUploadType = "Please Select File Type.";
        } else errors.SliderUploadType = "";
        break;
      case "promotionalStartDate":
        if (!this.state.Promo && (!value || value.toString().length < 1)) {
          IsValid = false;
          errors.promotionalStartDate = "Please Select Start Date.";
        } else errors.promotionalStartDate = "";
        break;
      case "promotionalEndDate":
        if (!this.state.Promo && (!value || value.toString().length < 1)) {
          IsValid = false;
          errors.promotionalEndDate = "Please Select End Date.";
        } else errors.promotionalEndDate = "";
        break;
      default:
        break;
    }

    return IsValid;
  }

  uploadMultipleFiles(e) {
    this.fileObj.push(e.target.files);
    for (let i = 0; i < this.fileObj[0].length; i++) {
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
    }
    this.setState({ file: this.fileArray });
  }

  uploadFiles(e) {
    e.preventDefault();
    //console.log(this.state.file);
  }

  render() {
    return (
      <>
        <div className="m-5">
          <div style={{ height: 10 }}></div>
          <div className="row">
            <div class="fileslider col-md-4 col-xl-4">
              <Dropdown
                appendTo={document.getElementById("root")}
                value={this.state.SliderOption}
                //value={this.props.ProductData}
                onChange={(e) => {
                  this.handleChange(e);
                }}
                options={this.state.SliderOptionSelectItems}
                optionValue="value"
                optionLabel="label"
                className="form-control custom-select"
                placeholder={"Select File Upload For "}
                name="SliderOption"
              />
              {this.state.formError.SliderOption !== null ? (
                <div className="error">{this.state.formError.SliderOption}</div>
              ) : null}
              <br />
              <br />
              <Dropdown
                appendTo={document.getElementById("root")}
                value={this.state.SliderUploadType}
                //value={this.props.ProductData}
                onChange={(e) => {
                  this.handleChange(e);
                }}
                options={this.state.SliderUpload}
                optionValue="value"
                optionLabel="label"
                className="form-control custom-select"
                placeholder={"Select File Type "}
                name="SliderUploadType"
              />
              {this.state.formError.SliderUploadType !== null ? (
                <div className="error">
                  {this.state.formError.SliderUploadType}
                </div>
              ) : null}
            </div>
            <div class="fileslider col-md-4 col-xl-4">
              <FileUpload
                name="sliderImage"
                mode="basic"
                url={process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"}
                auto={true}
                onUpload={this.setImagePath}
                onChange={this.fileSelectedHandler}
                onError={this.onErrorImage}
                multiple
                accept={this.state.SliderUploadType}
              />
              <br />
              <br />
              <button
                type="button"
                className="btn btn-warning btn-block"
                onClick={this.submitForm}
              >
                Upload
              </button>
            </div>

            <div class="fileslider col-md-4 col-xl-4">
              <TextField
                id="standard-basic"
                size="small"
                onChange={this.handleChange}
                value={this.state.sliderImage}
                name="shareurl"
                variant="outlined"
                className="width"
                label="File Url : "
              />
              <div class="blockimgprive">
                {this.state.sliderImage ? (
                  <div
                    className="p-fileupload-content mt-3 p-1"
                    style={{ display: "table" }}
                  >
                    <div>
                      <br />
                      <img
                        alt=" "
                        role="presentation"
                        src={this.state.sliderImage}
                        width="300"
                        height="200"
                      />
                    </div>
                  </div>
                ) : null}

                {this.state.formError.sliderImage !== null ? (
                  <div className="error">
                    {this.state.formError.sliderImage}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <hr hidden={this.state.Promo}></hr>
          <div className="row" hidden={this.state.Promo}>
            <div className="col-md-4 pt-3">
              <TextField
                id="date"
                label="Promotion Start Date"
                type="date"
                onChange={this.handleChange}
                name="promotionalStartDate"
                value={this.state.promotionalStartDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {this.state.formError.promotionalStartDate !== null ? (
                <div className="error">
                  {this.state.formError.promotionalStartDate}
                </div>
              ) : null}
            </div>
            <div className="col-md-4 pt-3">
              <TextField
                id="date"
                label="Promotion End Date"
                type="date"
                onChange={this.handleChange}
                name="promotionalEndDate"
                value={this.state.promotionalEndDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {this.state.formError.promotionalEndDate !== null ? (
                <div className="error">
                  {this.state.formError.promotionalEndDate}
                </div>
              ) : null}
            </div>
            {/* <div className="col-md-4 pt-3">
              <TextField
                id="standard-basic"
                size="small"
                onChange={this.handleChange}
                value={this.state.ProMsg}
                name="ProMsg"
                variant="outlined"
                className="width"
                label="Promotional Message: "
              />
            </div> */}
          </div>
          <br></br>
          <div>
            <table
              style={{ marginBottom: "6rem" }}
              className="table table border shadow table-striped table-md table-hover responsive"
            >
              <thead className="table-light">
                <tr
                  style={{
                    color: "#fdbe33",
                    fontSize: "18px",
                    backgroundColor: "#2d4050",
                  }}
                >
                  <th scope="col">File Preview</th>
                  <th scope="col">Used For</th>
                  <th scope="col">File Type</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.data.map((item, i) => (
                  <tr>
                    <td>
                      {item.FileType.includes("image") ? (
                        <img height="100" width="200px" src={item.FilePath} />
                      ) : null}
                      {item.FileType.includes("video") ? (
                        <video
                          controls
                          autoplay
                          height="100"
                          width="200px"
                          src={item.FilePath}
                        />
                      ) : null}
                    </td>
                    <td>
                      {" "}
                      <h6 className="textaline">{item.UploadFor}</h6>
                    </td>
                    <td>
                      <h6 className="textaline">{item.FileType}</h6>
                    </td>
                    <td>
                      {item.endDate ? (
                        <h6 className="textaline">{item.endDate}</h6>
                      ) : (
                        <h6 className="textaline">Not Applicable</h6>
                      )}
                    </td>
                    <td>
                      <p className="textaline">
                        <button
                          type="submit"
                          onClick={() => {
                            this.props.DeleteSliderImage(item.SId);
                          }}
                          className=" btn btn-outline-danger mx-1"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data:
      state.SliderProMsg.items == (null || undefined)
        ? []
        : state.SliderProMsg.items,
    //showDialog: state.role.showDialog,
    //UploadData: state.upload.items
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHideShow: (isbool) => {
      dispatch(SliderProMsgActions.onHideShow(isbool));
    },
    uploadSliderImage: (image) => {
      dispatch(SliderProMsgActions.UploadSliderImage(image));
    },
    fetchApi: () => {
      dispatch(SliderProMsgActions.GetSliderImage());
    },
    GetSliderListfetchApi: () => {
      dispatch(SliderProMsgActions.GetSliderList());
    },
    DeleteSliderImage: (id) => {
      dispatch(SliderProMsgActions.DeleteSliderImage(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SliderProMsg);
