import { authHeader, handleResponse } from '../helpers';
export const SqlEditorServices = {
    executeScript
};

function executeScript(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}SqlEditor/ExecuteScript`, requestOptions)
        .then(handleResponse);
}



