export const OrderBookingConstants = {
  CREATE_REQUEST: "ORDERBOOKING_CREATE_REQUEST",
  CREATE_SUCCESS: "ORDERBOOKING_CREATE_SUCCESS",
  CREATE_FAILURE: "ORDERBOOKING_CREATE_FAILURE",

  UPDATE_REQUEST: "ORDERBOOKING_UPDATE_REQUEST",
  UPDATE_SUCCESS: "ORDERBOOKING_UPDATE_SUCCESS",
  UPDATE_FAILURE: "ORDERBOOKING_UPDATE_FAILURE",

  GETALL_REQUEST: "ORDERBOOKING_GETALL_REQUEST",
  GETALL_SUCCESS: "ORDERBOOKING_GETALL_SUCCESS",
  GETALL_FAILURE: "ORDERBOOKING_GETALL_FAILURE",

  DELETE_REQUEST: "ORDERBOOKING_DELETE_REQUEST",
  DELETE_SUCCESS: "ORDERBOOKING_DELETE_SUCCESS",
  DELETE_FAILURE: "ORDERBOOKING_DELETE_FAILURE",

  ISACTIVE_REQUEST: "ORDERBOOKING_ISACTIVE_REQUEST",
  ISACTIVE_SUCCESS: "ORDERBOOKING_ISACTIVE_SUCCESS",
  ISACTIVE_FAILURE: "ORDERBOOKING_ISACTIVE_FAILURE",

  GETORDERDDL_REQUEST: "ORDER_DDL_REQUEST",
  GETORDERDDL_SUCCESS: "ORDER_DDL_SUCCESS",
  GETORDERDDL_FAILURE: "ORDER_DDL_FAILURE",

  GETORDERDTL_REQUEST: "ORDER_DTL_REQUEST",
  GETORDERDTL_SUCCESS: "ORDER_DTL_SUCCESS",
  GETORDERDTL_FAILURE: "ORDER_DTL_FAILURE",

  GETDEALERDDL_REQUEST: "GETDEALERDDL_REQUEST",
  GETDEALERDDL_SUCCESS: "GETDEALERDDL_SUCCESS",
  GETDEALERDDL_FAILURE: "GETDEALERDDL_FAILURE",

  GETSEARCH_REQUEST: "GETSEARCH_REQUEST",
  GETSEARCH_SUCCESS: "GETSEARCH_SUCCESS",
  GETSEARCH_FAILURE: "GETSEARCH_FAILURE",

  UPLOAD_SO_REQUEST: "UPLOAD_SO_REQUEST",
  UPLOAD_SO_SUCCESS: "UPLOAD_SO_SUCCESS",
  UPLOAD_SO_FAILURE: "UPLOAD_SO_FAILURE",

  GET_SO_LOG_REQUEST: "GET_SO_LOG_REQUEST",
  GET_SO_LOG_SUCCESS: "GET_SO_LOG_SUCCESS",
  GET_SO_LOG_FAILURE: "GET_SO_LOG_FAILURE",

  GETPRODUCTS_REQUEST: "GETPRODUCTS_REQUEST",
  GETPRODUCTS_SUCCESS: "GETPRODUCTS_SUCCESS",
  GETPRODUCTS_FAILURE: "GETPRODUCTS_FAILURE",

  DIALOG_REQUEST: "DIALOG_REQUEST",

  LOADING_REQUEST: "LOADING_REQUEST",

  DIALOG_ISACTIVE_REQUEST: "DIALOG_ISACTIVE_REQUEST",
};
