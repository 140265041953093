import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import { FileUpload } from "primereact/fileupload";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import { roleMasterActions, userMasterActions } from "../../actions";
import tableRequest from "../../models/tableRequest";
// import { userTypeMasterActions } from '../../actions';
import Switch from "@material-ui/core/Switch";
import { Calendar } from "primereact/calendar";
import { CSVLink } from "react-csv";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

class userMaster extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.tblResponse = new tableRequest();
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportCSVAll = this.exportCSVAll.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.FilterData = this.FilterData.bind(this);
    this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
    this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.getRoleList = this.getRoleList.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    const UserTypeData = [
      { value: 1, label: "Web" },
      // { value: 2, label: "Mobile" },
    ];

    this.state = {
      FromDate: new Date(),
      ToDate: new Date(),
      IsAdd: true,
      //headers: {},
      IsEdit: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
      buttonLabel: "Submit",
      switchAction: false,
      formHeading: "Add User Master",
      UserId: 0,
      RoleId: 0,
      UserTypeId: "",
      UserTypeData: [
        { value: 1, label: "Web" },
        // { value: 2, label: "Mobile" },
      ],
      UserName: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      IsActive: true,
      CreatedDate: null,
      UpdatedDate: null,
      formError: {
        UserName: "",
        UserTypeId: "",
        Email: "",
        MobileNo: "",
        Password: "",
        ConfirmPassword: "",
        RoleId: "",
      },
    };
  }
  componentDidMount() {
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() - 30);
    this.tblResponse.FromDate = date.toLocaleDateString();
    this.tblResponse.ToDate = new Date().toLocaleDateString();
    this.setState({
      FromDate: date,
      ToDate: new Date(),
    });
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    this.props.fetchApi(this.tblResponse);

    // const userId = JSON.parse(sessionStorage.getItem("UserData"));
    // this.props.getRoleDDL(userId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.UserData) {
      if (prevProps.UserData !== this.props.UserData) {
        // this.props.myProp has a different value
        if (this.props.UserData) {
          var e = this.props.UserData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  switchToggleConfirm = (e) => {
    this.props.inActiveRow(this.state.UserId, this.state.switchAction);
  };

  FilterData() {
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
    this.props.fetchApi(this.tblResponse);
  }

  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "IsActive":
        value = event.target.checked;
        break;
      default:
        break;
    }
    this.validateForm(value, name);

    if (name == "RoleId") {
      this.props.UserRoleData.filter((x) => {
        if (x.value === value.toString()) {
          this.state.RoleName = x.label;
        }
      });
    }
    this.setState({ errors, [name]: value });
  }

  handleChangeDate(event) {
    const { name, value } = event.target;
    switch (name) {
      case "FromDate":
        if (value === null || !(value instanceof Date)) {
          this.formIsValid = false;
          // this.refs.Messchild.showError("Please select from date.");
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please select from date.",
            life: 3000,
          });

          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please enter only date.",
            life: 3000,
          });
          // this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (value instanceof Date && this.state.ToDate instanceof Date) {
          const startDate = value;
          const endDate = this.state.ToDate;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.toast.show({
              severity: "error",
              summary: "Error Message",
              detail: "From date must be less than to date.",
              life: 3000,
            });
            // this.refs.Messchild.showError("From date must be less than to date.");
            return;
          }
        }

        break;
      case "ToDate":
        if (value === null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please select to date.",
            life: 3000,
          });
          // this.refs.Messchild.showError("Please select to date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please enter only date.",
            life: 3000,
          });
          // this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (
          value instanceof Date &&
          this.state.FromDate instanceof Date
        ) {
          const startDate = this.state.FromDate;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.toast.show({
              severity: "error",
              summary: "Error Message",
              detail: "To date must be greater than from date.",
              life: 3000,
            });
            // this.refs.Messchild.showError("To date must be greater than from date.");
            return;
          }
        }
        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  }

  getRoleList(event) {
    this.setState({ UserTypeId: event.target.value });
    this.props.getRoleDDL(event.target.value);
  }

  UpdatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>;
    }
  }

  CreatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>;
    }
  }
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "UserName":
        if (value.length < 1) {
          IsValid = false;
          errors.UserName = "Please enter user name.";
        } else errors.UserName = "";
        break;
      case "UserTypeId":
        if (!value) {
          IsValid = false;
          errors.UserTypeId = "Please select user type.";
        } else errors.UserTypeId = "";
        break;
      case "RoleId":
        if (!value) {
          IsValid = false;
          errors.RoleId = "Please select user role.";
        } else errors.RoleId = "";
        break;
      // case 'FirstName':
      //     if (value.length < 1) {
      //         IsValid = false;
      //         errors.FirstName = "Please enter your first name.";
      //     }
      //     else if (value.length >= 1) {
      //         if (!value.match(/^[A-Za-z]+$/)) {
      //             IsValid = false;
      //             errors.FirstName = "FirstName should be alpha only."
      //         } else errors.FirstName = ''
      //     }
      //     else errors.FirstName = ''
      //     break;
      // case 'LastName':
      //     if (value.length < 1) {
      //         IsValid = false;
      //         errors.LastName = "Please enter your last name.";
      //     }
      //     else if (value.length >= 1) {
      //         if (!value.match(/^[A-Za-z]+$/)) {
      //             IsValid = false;
      //             errors.LastName = "LastName should be alpha only."
      //         } else errors.LastName = ''
      //     }
      //     else errors.LastName = ''
      //     break;
      case "Email":
        if (value.length < 1) {
          IsValid = false;
          errors.Email = "Please enter email-id.";
        } else if (value.length >= 1) {
          /* eslint-disable */
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.Email = "Please enter valid email-id.";
          } else errors.Email = "";
          /* eslint-enable */
        } else errors.Email = "";
        break;
      case "MobileNo":
        if (value.length < 1) {
          IsValid = false;
          errors.MobileNo = "Please enter mobile no.";
        } else if (value.length >= 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = true;
            errors.MobileNo = "Please enter valid mobile no.";
          } else errors.MobileNo = "";
        } else errors.MobileNo = "";
        break;
      case "Password":
        if (!value && value.length < 1) {
          IsValid = false;
          errors.Password = "Please enter password.";
        } else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{4,20}$/)) {
          IsValid = false;
          errors.Password =
            "Password should be minimum 4 characters long, 1 uppercase & 1 lowercase character with 1 number.";
        } else if (
          !value &&
          value.length >= 1 &&
          this.state.ConfirmPassword.length >= 1
        ) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (!(value === this.state.ConfirmPassword)) {
            IsValid = false;
            errors.Password = "password does not match";
          } else errors.Password = "";
        } else errors.Password = "";
        break;

      case "ConfirmPassword":
        if (!value && value.length < 1) {
          IsValid = false;
          errors.ConfirmPassword = "Please enter confirm password.";
        } else if (value.length >= 1 && this.state.Password.length >= 1) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (value !== this.state.Password) {
            IsValid = false;
            errors.ConfirmPassword =
              "New password and Confirm password should be same.";
          }
        } else if (
          !value &&
          value.length >= 1 &&
          this.state.Password.length >= 1
        ) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (!(value === this.state.Password)) {
            IsValid = false;
            errors.ConfirmPassword = "Confirm password does not match";
          } else errors.ConfirmPassword = "";
        } else errors.ConfirmPassword = "";
        break;
      default:
        break;
    }
    return IsValid;
  }

  getDropValueOfRole() {
    let roleName = "";
    this.props.UserRoleData.filter((x) => {
      if (x.value === this.state.RoleId.toString()) {
        roleName = x.label;
      }
    });

    return roleName;
  }

  getDropValueOfUserType() {
    let usertype = "";
    this.state.UserTypeData.filter((x) => {
      if (x.value === this.state.UserTypeId) {
        usertype = x.label;
      }
    });

    return usertype;
  }

  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      const ddlValuRole = this.getDropValueOfRole();
      const ddlValuUserType = this.getDropValueOfUserType();
      const formData = {
        UserId: this.state.UserId,
        UserType: this.state.UserTypeId,
        RoleId: this.state.RoleId,
        UserName: this.state.UserName,
        Email: this.state.Email,
        MobileNo: this.state.MobileNo,
        Password: this.state.Password,
        IsActive: this.state.IsActive,
        CreatedDate:
          this.state.UserId > 0
            ? this.state.CreatedDate
            : this.getCurrentDateMMDDYYY(),
        UpdatedDate:
          this.state.UserId > 0 ? this.getCurrentDateMMDDYYY() : null,
        RoleName: ddlValuRole != null ? ddlValuRole : "",
        TotalRows: this.state.totalRecords,
        isApproved: true,
        isDeleted: false,
      };
      if (formData.UserId > 0) {
        this.props.updateUser(formData);
        if (
          formData.UserId === JSON.parse(sessionStorage.getItem("UserData"))
        ) {
          setTimeout(() => {
            sessionStorage.removeItem("LoggedInUser");
            window.location = "/login";
          }, 2000);
        }
      } else {
        this.props.createUser(formData);
      }

      if (!this.props.showDialog) {
        this.resetForm();
      }
    }
  }

  // getRoleDDL(event) {
  //     this.props.getRoleDDL();
  // }
  resetForm() {
    this.setState({
      UserId: 0,
      UserTypeId: "",
      UserName: "",
      RoleId: "",
      UserTypeId: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      IsActive: true,
      UserTypeData: [
        { value: 1, label: "Web" },
        // { value: 2, label: "Mobile" },
      ],
      formError: {
        UserName: "",
        UserTypeId: "",
        Email: "",
        MobileNo: "",
        Password: "",
        ConfirmPassword: "",

      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }
  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add User" });
  }
  statusBodyTemplate(rowData) {
    if (rowData) {
      return (
        <Checkbox
          disabled
          checked={rowData.IsActive}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      );
    }
  }
  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }
  actionBodyTemplate(rowData) {
    const editMode = (e) => {
      this.props.getRoleDDL(rowData.UserType);
      this.props.onHideShow(true);
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update User",
        UserId: rowData.UserId == null ? 0 : rowData.UserId,
        UserName: rowData.UserName,
        Email: rowData.Email,
        MobileNo: rowData.MobileNo,
        RoleId: rowData.RoleId,
        UserTypeId: rowData.UserType,
        IsActive: rowData.IsActive,
        Password: rowData.Password,
        ConfirmPassword: rowData.Password,
        CreatedDate: rowData.CreatedDate,
        UserTypeData: [
          { value: 1, label: "Web" },
          { value: 2, label: "Mobile" },
        ],
      });
    };
    const switchHandleChange = (e) => {
      this.setState({
        switchAction: !rowData.IsActive,
        UserId: rowData.UserId,
      });
      this.props.onHideShowIsActive(true);
    };

    if (rowData) {
      return (
        <>
          {this.state.IsEdit === true ? (
            <div>
              <Switch
                checked={rowData.IsActive}
                color="primary"
                name={rowData.UserId}
                onChange={switchHandleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Button hidden={rowData.UserType == 2 ? true : false} icon="pi pi-pencil" onClick={editMode} />
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  exportCSV() {
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
    this.tblResponse.First = -1;
    this.tblResponse.PageSize = -1;
    this.props.exportData(this.tblResponse);
    setTimeout(() => {
      this.csvLink.current.element.click();
    }, 3000);
  }
  exportCSVAll() {
    this.tblResponse.FromDate = "2022-01-01";
    this.tblResponse.ToDate = new Date().toLocaleDateString();
    this.tblResponse.First = -1;
    this.tblResponse.PageSize = -1;
    this.props.exportData(this.tblResponse);
    setTimeout(() => {
      this.csvLink.current.element.click();
    }, 3000);
  }


  render() {
    const renderFooter = (name) => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={this.switchToggleConfirm}
            autoFocus
          />
        </div>
      );
    };
    // const header = (

    // );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />
        <div className="table-header row mb-3">
          <div className="pl-4" style={{ width: "9%" }}>
            <h5>User Master</h5>
          </div>
          <div style={{ width: "91%" }}>
            <div className="row text-right mr-2">
              <div style={{ width: "22%" }}>
                <Calendar
                  placeholder="From Date"
                  name="FromDate"
                  showIcon="true"
                  value={this.state.FromDate}
                  onChange={this.handleChange}
                ></Calendar>
              </div>
              <div style={{ width: "22%" }}>
                <Calendar
                  placeholder="To Date"
                  name="ToDate"
                  showIcon="true"
                  value={this.state.ToDate}
                  onChange={this.handleChange}
                ></Calendar>
              </div>
              <div style={{ width: "26%" }} className="text-center">
                <button
                  type="button"
                  onClick={this.FilterData}
                  className="btn btn-primary p-2 pl-3 pr-3 mr-2"
                >
                  Submit
                </button>
                &nbsp;
                <button
                  onClick={this.exportCSV}
                  className="btn btn-success p-2 pl-3 pr-3 ml-2 buttone1"
                >
                  Export
                </button>
                <button
                  onClick={this.exportCSVAll}
                  className="btn btn-success p-2 pl-3 pr-3 ml-2 buttone1"
                >
                  Export All
                </button>

                <ExcelFile
                  filename={"UserMasterReport"}
                  element={<Button hidden ref={this.csvLink} />}
                >
                  <ExcelSheet
                    data={this.props.List}
                    name={"UserMasterReport"}
                  >

                    {/* <ExcelColumn label="Role Name" value="RoleName" />
                    <ExcelColumn label="Dealer" value="isDealer" />
                    <ExcelColumn label="Dealer Id" value="DealerId" />
                    <ExcelColumn label="Country " value="CountryId" />*/}
                    <ExcelColumn label="UserName" value="UserName" />
                    <ExcelColumn label="Role Name" value="RoleName" />
                    {/* <ExcelColumn label="Dealer Name" value="DealerName" /> */}
                    {/* <ExcelColumn label="Company Name" value="CompanyName" />  */}
                    {/* <ExcelColumn label="Transportation Mode" value="TransportationModeId" /> */}
                    <ExcelColumn label="Email" value="Email" />
                    <ExcelColumn label="Mobile No" value="MobileNo" />

                    {/* <ExcelColumn label="Pan No" value="PanNo" /> */}
                    <ExcelColumn label="Nature Firm" value="NatureFirmId" />
                    <ExcelColumn label="Gst No." value="GstNo" />
                    {/* //<ExcelColumn label="GstCertificate." value="GstCertificate" formula /> */}
                    <ExcelColumn label="Ship Country" value="Ship_Country" />
                    <ExcelColumn label="Ship State" value="Ship_State" />
                    <ExcelColumn label="Ship City" value="Ship_City" />
                    <ExcelColumn label="Shiping Address" value="Ship_Address" />
                    <ExcelColumn label="Ship_Pincode" value="Ship_Pincode" />
                    <ExcelColumn label="Bill_Country" value="Bill_Country" />
                    <ExcelColumn label="Bill_State" value="Bill_State" />
                    <ExcelColumn label="Bill_Pincode" value="Bill_Pincode" />
                    <ExcelColumn label="Bill_City" value="Bill_City" />
                    <ExcelColumn label="Bill_Address" value="Bill_Address" />
                    <ExcelColumn label="Created Date" value="CreatedDate" />
                    {/* <ExcelColumn label="Updated Date" value="UpdatedDate" /> */}
                  </ExcelSheet>
                </ExcelFile>
                {/* <CSVLink
                  hidden
                  data={this.props.List}
                  headers={this.state.headers}
                  filename={"User Report.csv"}
                  target="_blank"
                >
                  <Button hidden ref={this.csvLink} /> 
                </CSVLink> */}

              </div>



              <div style={{ width: "15%" }}>
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    type="search"
                    onInput={(e) => this.searchFilter(e)}
                    placeholder="Global Search"
                  />
                </span>
              </div>

              <div style={{ width: "15%" }}>


                {this.state.IsAdd === true ? (
                  <Button
                    className="mr-4"
                    label="Add User"
                    icon="pi pi-plus-circle"
                    onClick={this.openDialog}
                  />
                ) : (
                  <Button
                    className="mr-4"
                    label="Add User"
                    icon="pi pi-plus-circle"
                    style={{ display: "none" }}
                    onClick={this.openDialog}
                  />
                )}


              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <DataTable
            value={this.props.UserData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            //header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column field="UserName" header="User Name" sortable></Column>
            <Column field="RoleName" header="Role Name" sortable></Column>
            <Column field="Email" header="Email" sortable></Column>
            <Column field="MobileNo" header="Mobile Number" sortable></Column>
            <Column field="bill_City" header="City" sortable></Column>
            
            {/* <Column field="Ship_City_Name" header="City" sortable></Column> */}
            {this.state.IsEdit === true ? (
              <Column
                field="Action"
                header="Action"
                style={{ width: "120px" }}
                body={this.actionBodyTemplate}
                className="text-center"
              ></Column>
            ) : (
              <Column
                field="Action"
                header="Action"
                style={{ width: "120px", display: "none" }}
                className="text-center"
              ></Column>
            )}
          </DataTable>
        </div>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          <form onSubmit={this.submitForm} autocomplete="off">
            <div className="row">
              <div className="col-md-6 pt-3">
                <TextField
                  onChange={this.handleChange}
                  name="UserName"
                  autoComplete="false"
                  value={this.state.UserName}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="User Name*"
                />
                {this.state.formError.UserName !== "" ? (
                  <div className="error">{this.state.formError.UserName}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <FormControl
                  variant="outlined"
                  size="small"
                  className="formControl col-md-12"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select User Type*
                  </InputLabel>
                  <Select
                    onChange={this.getRoleList}
                    name="UserTypeId"
                    value={this.state.UserTypeId}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Select User Type"
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>
                    {this.state.UserTypeData.map((key) => (
                      <MenuItem value={key.value}>{key.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {this.state.formError.UserTypeId !== "" ? (
                  <div className="error">{this.state.formError.UserTypeId}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <FormControl
                  variant="outlined"
                  size="small"
                  className="formControl col-md-12"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select User Role*
                  </InputLabel>

                  <Select
                    onChange={this.handleChange}
                    name="RoleId"
                    value={this.state.RoleId}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Select User Role"
                  >
                    <MenuItem>
                      <em>None</em>
                    </MenuItem>

                    {this.props.UserRoleData == (undefined || null)
                      ? null
                      : this.props.UserRoleData.map((key) => (
                        <MenuItem value={key.value}>{key.label}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {this.state.formError.RoleId !== "" ? (
                  <div className="error">{this.state.formError.RoleId}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  onChange={this.handleChange}
                  name="Email"
                  value={this.state.Email}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Email*"
                />
                {this.state.formError.Email !== "" ? (
                  <div className="error">{this.state.formError.Email}</div>
                ) : (
                  ""
                )}
              </div>
              {/* <div className="col-md-6 pt-3">
                                <TextField onChange={this.handleChange} name="MobileNo" value={this.state.MobileNo} size="small" variant="outlined" className="fullwidth" label="Mobile Number*" />
                                {this.state.formError.MobileNo !== '' ?
                                    <div className='error'>{this.state.formError.MobileNo}</div> : ''}
                            </div> */}
              <div className="col-md-6 pt-3">
                <TextField
                  id="standard-basic"
                  size="small"
                  onChange={this.handleChange}
                  variant="outlined"
                  name="MobileNo"
                  value={this.state.MobileNo}
                  className="fullwidth"
                  label="Mobile Number*"
                />
                {this.state.formError.MobileNo !== "" ? (
                  <div className="error">{this.state.formError.MobileNo}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  input
                  type="password"
                  autocomplete="new-password"
                  onChange={this.handleChange}
                  name="Password"
                  value={this.state.Password}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Password*"
                />
                {this.state.formError.Password !== "" ? (
                  <div className="error">{this.state.formError.Password}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  input
                  type="password"
                  onChange={this.handleChange}
                  name="ConfirmPassword"
                  value={this.state.ConfirmPassword}
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Confirm Password*"
                />
                {this.state.formError.ConfirmPassword !== "" ? (
                  <div className="error">
                    {this.state.formError.ConfirmPassword}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <Checkbox
                  name="IsActive"
                  checked={this.state.IsActive}
                  onClick={this.handleChange}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />{" "}
                <label>Is Active</label>
              </div>

              <div className="col-md-12 mt-3 mb-3 text-center">
                {this.state.IsEdit === true ? (
                  <Button label={this.state.buttonLabel} icon="pi pi-check" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12">
                Note: Fields marked as (*) are required
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
        
          header="Confirmation"
          visible={this.props.showIsActiveDialog}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserData: state.user.items,
    UserRoleData: state.role.rollddl,
    showDialog: state.role.showDialog,
    loading: state.company.loding,
    showIsActiveDialog: state.user.showIsActiveDialog,
    List:
      state.user.exportitems == (null || undefined)
        ? []
        : state.user.exportitems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(userMasterActions.getAll(obj));
    },
    createUser: (user) => dispatch(userMasterActions.createUser(user)),
    updateUser: (user) => dispatch(userMasterActions.updateUser(user)),
    inActiveRow: (user, isActive) =>
      dispatch(userMasterActions.inActiveRow(user, isActive)),
    onHideShow: (isbool) => dispatch(userMasterActions.onHideShow(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(userMasterActions.onHideShowIsActive(isbool)),
    getRoleDDL: (data) => dispatch(userMasterActions.getRoleDDL(data)),
    loadingData: (isLoding) =>
      dispatch(userMasterActions.loadingData(isLoding)),
    exportData: (obj) => {
      dispatch(userMasterActions.exportData(obj));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(userMaster);
