import QRCode from 'qrcode'
import * as jsPDF from 'jspdf';


/// TO print cartonslip on secondary packing service
export default class PrintSecondaryPackagingService {

    genQR(text) {
        let qr = "";
        QRCode.toDataURL(text, function (err, url) {
            qr = url;
        });
        return qr;
    }
    formPreview1(resp) {
        let doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [90, 70],
            compressPdf: true
        });

        doc = this.printLblPrntContent1(doc, resp);
        doc.save(resp[0].CartonId + '.pdf');
        // window.open(doc.output('bloburl'), '_blank');
    }
    printLblPrntContent1(doc, resp) {

        let i = 1;
        let j = 0;

        for (; i <= resp.length; i++) {
            // doc.setFontSize(7)
            doc.setFontType('bold');
            doc.setFont('Times');
            var loc = 0,
                inc = 3,
                docW = 2;
            //loc += inc;
            //doc.text(50, loc, resp.rateCd);
            //loc += inc + 4;
            //doc.text(docW, loc, 'Item  ');
            //doc.text(9 + docW, loc, ': ' + resp[j].itemNm);
            //loc += inc + 2;
            //doc.text(docW, loc, 'M.R.P.  ');
            //doc.text(9 + docW, loc, ': INR ' + resp.mrp);
            //loc += inc + 1;
            //doc.text(docW, loc, 'Lot No ');
            //doc.text(9 + docW, loc, ': ' + resp.lotNo);
            //loc += inc + 1;
            //doc.text(docW, loc, 'Pack ');
            //doc.text(9 + docW, loc, ': ' + resp.mfgDt.MyDtFrmt('M yyyy', '-'));
            //loc += inc + 1;
            //
            //doc.text(9 + docW, loc, ': ' + resp.pktWt + ' ' + resp.unitId.unitNm);
            doc.setFontSize(10);

            let qrdata = this.genQR(resp[j].CartonId);
            ;
            doc.addImage(qrdata, 'JPEG', 0, 4, 15, 15, resp[j].CartonId, false);
            doc.text(resp[j].ProductName, 1, 4);

            // if (resp[j].Brand.length > 0)
            // doc.text("Date : " + resp[j].CreatedDt, 20, 10);
            doc.text(resp[j].CreatedDt, 15, 10);
            // if (resp[j].ModelNumber.length > 0)
            //     doc.text("Model : " + resp[j].ModelNumber, 20, 14);

            //doc.text("Qty : " + resp[j].Qty, 20, 18);
            doc.text(resp[j].Qty, 15, 16);
            doc.setFontSize(10);
            doc.text(resp[j].CartonId, 1, 22);


            // qrdata = '';
            j++;
            if (resp.length > i)
                doc.addPage();
        }
        return doc;
    }
    BulkData(type,from,to,pre) {
        
        let doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [100, 70],
            compressPdf: true
        });
        doc = this.bulkPrint(doc, type,from,to,"HP");
      doc.save(`${type}-${from} - ${to}`+'.pdf')

    }
    bulkPrint(doc, type,from,to,pre) {
        
        
        let j = 0;
        let year= new Date().getFullYear()

        for (let i=from; i <= to; i++) {
            doc.setFontType('bold');
            doc.setFont('Times');
            doc.setFontSize(10);
            let val= i.toString().padStart(5, "0")
            let CartonID=`${type}-${year}-${pre}-${val}`;
            let qrdata = this.genQR(CartonID);
            ;
            doc.addImage(qrdata, 'JPEG', 8, 4, 17, 17, CartonID, false);
           // doc.text(resp[j].ProductName, 1, 4);
             doc.text( new Date().toDateString(),3,5);
            //doc.text(resp[j].Qty, 15, 16);
            doc.setFontSize(10);
            doc.text(CartonID, 3, 23);


            // qrdata = '';
                doc.addPage();
        }
        return doc;
    }

}