import { loginConstants } from "../constants";

export function login(state = {}, action) {
  switch (action.type) {
    case loginConstants.CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case loginConstants.CREATE_SUCCESS:
      return {
        ...state,
        items: [action.data],
      };
    case loginConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case loginConstants.TIMEOUT_SUCCESS:
      return {
        ...state,
        enable: action.data,
      };
    default:
      return state;
  }
}
