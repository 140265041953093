import { roleService } from '../services';
import { roleMasterConstants } from '../constants';
import { alertActions } from '.';

export const roleMasterActions = {
    getAll,
    createRole,
    updateRole,
    openDialog,
    inActiveRow,
    onHideShow,
    getRoleDDL,
    getPages,
    updatePageStore,
    updateRolePermission,
    loadingData,
    onHideShowIsActive
};

function getAll(data) {
    return dispatch => {
        dispatch(request());
        roleService.fetchRoleData(data).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: roleMasterConstants.GETALL_REQUEST } }
    function success(datas) { return { type: roleMasterConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: roleMasterConstants.GETALL_FAILURE, error } }
}

function updateRolePermission(data) {
    return dispatch => {
        dispatch(request(data));
        roleService.updateRolePermission(data).then(
            response => {
                sessionStorage.removeItem('assignedPermissionsForUser');
                dispatch(success(response.ResponseData));
                sessionStorage.setItem("assignedPermissionsForUser", JSON.stringify({ assigned: response.ResponseData }));
                //history.push('/');                
                if (response && response.ResponseCode) {
                    response.ResponseCode == "200" ? dispatch(alertActions.success(response.ResponseMessage))
                        : response.ResponseCode == "400" ? dispatch(alertActions.warning(response.ResponseMessage))
                            : dispatch(alertActions.error(response.ResponseMessage))
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: roleMasterConstants.UPDATE_REQUEST, data } }
    function success(data) { return { type: roleMasterConstants.UPDATE_SUCCESS, data } }
    function failure(error) { return { type: roleMasterConstants.UPDATE_FAILURE, error } }
};

function getPages(roleId) {
    return dispatch => {
        dispatch(request());
        roleService.fetchPageData(roleId).then(response => {
            dispatch(success(response.ResponseData))

            if (response && response.ResponseCode) {
                if (response.ResponseCode === "200") {
                } else if (response.ResponseCode === "400") {
                    dispatch(alertActions.warning(response.ResponseMessage))
                }
                else {
                    dispatch(alertActions.error(response.ResponseMessage))
                }
            }
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: roleMasterConstants.GET_PAGES_REQUEST } }
    function success(pages) { return { type: roleMasterConstants.GET_PAGES_SUCCESS, pages } }
    function failure(error) { return { type: roleMasterConstants.GET_PAGES_FAILURE, error } }
}

function createRole(data) {
    return dispatch => {
        dispatch(request(data));

        roleService.createRole(data).then(
            response => {
                //history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(onHideShow(false));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    }
                    else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: roleMasterConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: roleMasterConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: roleMasterConstants.CREATE_FAILURE, error } }
};

function updateRole(data) {
    return dispatch => {
        dispatch(request(data));

        roleService.updateRole(data).then(
            response => {
                //history.push('/');                
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(data));
                        dispatch(onHideShow(false));
                        dispatch(alertActions.success(response.ResponseMessage));
                    }
                    else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    }
                    else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: roleMasterConstants.UPDATE_REQUEST, data } }
    function success(data) { return { type: roleMasterConstants.UPDATE_SUCCESS, data } }
    function failure(error) { return { type: roleMasterConstants.UPDATE_FAILURE, error } }
};

function openDialog(data) {
    return {
        type: roleMasterConstants.OPEN_DIALOG_REQUEST, data
    }
}

function inActiveRow(roleId, isActive) {
    var data = {
        RoleId: roleId,
        IsActive: isActive
    }
    return dispatch => {
        dispatch(request());

        roleService.inActiveRow(roleId, isActive).then(
            response => {
                //history.push('/');                
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(data));
                        dispatch(onHideShowIsActive(false));
                        dispatch(alertActions.success(response.ResponseMessage))
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: roleMasterConstants.ISACTIVE_REQUEST } }
    function success(data) { return { type: roleMasterConstants.ISACTIVE_SUCCESS, data } }
    function failure(error) { return { type: roleMasterConstants.ISACTIVE_FAILURE, error } }
};

function getRoleDDL(userId) {
    return dispatch => {
        dispatch(request());

        roleService.fetchRoleDLL(userId).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: roleMasterConstants.GETROLEDDL_REQUEST } }
    function success(datas) { return { type: roleMasterConstants.GETROLEDDL_SUCCESS, datas } }
    function failure(error) { return { type: roleMasterConstants.GETROLEDDL_FAILURE, error } }
};

function onHideShow(isbool) {
    return {
        type: roleMasterConstants.DIALOG_REQUEST, isbool
    }
}

function updatePageStore(pageList) {
    return dispatch => {
        dispatch(success(pageList))
    };
    function success(datas) { return { type: roleMasterConstants.UPDATE_PAGE_STORE, pageList } }
}

function loadingData(isbool) {
    return {
        type: roleMasterConstants.LOADING_REQUEST, isbool
    }
}
function onHideShowIsActive(isbool) {
    return {
        type: roleMasterConstants.DIALOG_ISACTIVE_REQUEST, isbool
    }
}