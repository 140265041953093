import { LoyaltySchemeRptConstants } from "../constants";

export function LoyaltySchemeRpt(state = {}, action) {
    switch (action.type) {
        case LoyaltySchemeRptConstants.LOADING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };
        case LoyaltySchemeRptConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case LoyaltySchemeRptConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case LoyaltySchemeRptConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case LoyaltySchemeRptConstants.GETEXPORT_REQUEST:
            return {
                ...state,
                exporting: true
            };
        case LoyaltySchemeRptConstants.GETEXPORT_SUCCESS:
            return {
                ...state,
                exportitems: action.datas
            };
        case LoyaltySchemeRptConstants.GETEXPORT_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }

}