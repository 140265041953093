export const RedemptionLogRptConstants ={
    GETALL_REQUEST: 'REDEMPTIONLOG_GETALL_REQUEST',
    GETALL_SUCCESS: 'REDEMPTIONLOG_GETALL_SUCCESS',
    GETALL_FAILURE: 'REDEMPTIONLOG_GETALL_FAILURE',

    GETEXPORT_REQUEST: 'REDEMPTIONLOG_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'REDEMPTIONLOG_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'REDEMPTIONLOG_GETEXPORT_FAILURE',

    LOADING_REQUEST: 'LOADING_REQUEST'
}