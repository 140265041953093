import { menuConstants } from '../constants';
 
export function menu(state = {}, action) {   
    switch (action.type) {
        case menuConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case menuConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case menuConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }
}