import { TertiaryPackingConstants } from "../constants/TertiaryPackingConstants";

export function TertiaryPacking(state = {}, action) {
  switch (action.type) {
    case TertiaryPackingConstants.ProductByQrScan_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TertiaryPackingConstants.ProductByQrScan_SUCCESS:
      return {
        ...state,
        scanneditems:
          state.scanneditems == null
            ? [action.data]
            : [action.data, ...state.scanneditems],
      };
    case TertiaryPackingConstants.ProductByQrScan_Warning:
      return {
        ...state,
        warning: action.data == "Invalid Item" ? true : false,
        alreadyPacked: action.data != "Invalid Item" ? true : false,
      };
    case TertiaryPackingConstants.ProductByQrScan_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case TertiaryPackingConstants.UPDATE_SCANNED_REQUEST:
      return {
        ...state,
        scanneditems: state.updatedList,
      };
    case TertiaryPackingConstants.SAVE_SECONDARY_PACKING_RESUEST:
      return {
        ...state,
        saveing: true,
      };
    case TertiaryPackingConstants.SAVE_SECONDARY_PACKING_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case TertiaryPackingConstants.GETALL_CARTON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TertiaryPackingConstants.GETALL_CARTON_SUCCESS:
      return {
        ...state,
        Cartondata: action.datas,
      };
    case TertiaryPackingConstants.GETALL_CARTON_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case TertiaryPackingConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TertiaryPackingConstants.GETALL_SUCCESS:
      return {
        ...state,
        secondaryItems: action.data,
      };
    case TertiaryPackingConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case TertiaryPackingConstants.GETALL_SCAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TertiaryPackingConstants.GETALL_SCAN_SUCCESS:
      return {
        ...state,
        TertiaryscanItems: action.data,
      };
    // return {
    //     ...state,
    //     items: state.secondaryItems.map(item =>
    //         item.qr === action.data.qr ? {
    //             scanlist: item.scanlist,
    //             flag: item.flag,
    //         } : item
    //     )
    // };
    case TertiaryPackingConstants.GETALL_SCAN_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case TertiaryPackingConstants.Warning:
      return {
        ...state,
        warning: action.isbool,
      };
    case TertiaryPackingConstants.Alreadypacked:
      return {
        ...state,
        alreadyPacked: action.isbool,
      };
    default:
      return state;
  }
}
