export const DemappingRptConstants ={
    GETALL_REQUEST: 'DEMAPPING_GETALL_REQUEST',
    GETALL_SUCCESS: 'DEMAPPING_GETALL_SUCCESS',
    GETALL_FAILURE: 'DEMAPPING_GETALL_FAILURE',

    GETEXPORT_REQUEST: 'DEMAPPING_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'DEMAPPING_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'DEMAPPING_GETEXPORT_FAILURE',

    LOADING_REQUEST: 'LOADING_REQUEST'
}