import { authHeader, handleResponse } from '../helpers';
export const changePasswordService = {
    changePassword,
    forgotPassword
};

function changePassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/ChangePassword`, requestOptions)
        .then(handleResponse);
}

function forgotPassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Login/ForgotPassword`, requestOptions)
        .then(handleResponse);
}