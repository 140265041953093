import { QRCodeTrackingRptConstants } from "../constants";

export function QRCodeTrackingRpt(state = {}, action) {
  switch (action.type) {
    case QRCodeTrackingRptConstants.TRACK_SCAN_INFO_REQUEST:
      return {
        ...state,
        toggleLoading: true,
      };
    case QRCodeTrackingRptConstants.TRACK_SCAN_INFO_SUCCESS:
      ;
      return {
        ...state,
        TIDproducts: action.datas,
        qrs: false,
        did: true,
        tid: false,
        sid: false,
      };
    case QRCodeTrackingRptConstants.TRACK_SCAN_INFO_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case QRCodeTrackingRptConstants.TRACK_TOGGLE_REQUEST:
      return {
        ...state,
        toggleLoading: true,
      };
    case QRCodeTrackingRptConstants.TRACK_TOGGLE_SUCCESS:
      ;
      return {
        ...state,
        iddata: action.datas,
      };
    case QRCodeTrackingRptConstants.TRACK_TOGGLE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case QRCodeTrackingRptConstants.TRACK_TOGGLE_2_REQUEST:
      return {
        ...state,
        toggle2Loading: true,
      };
    case QRCodeTrackingRptConstants.TRACK_TOGGLE_2_SUCCESS:
      
      return {
        ...state,
        iddata2: action.datas,
      };
    case QRCodeTrackingRptConstants.TRACK_TOGGLE_2_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case QRCodeTrackingRptConstants.TRACK_JOURNEY_INFO_REQUEST:
      return {
        ...state,
        toggleLoading: true,
      };
    case QRCodeTrackingRptConstants.TRACK_JOURNEY_INFO_SUCCESS:
      return {
        ...state,
        journeyList: action.datas,
        qrs: true,
        did: false,
        tid: false,
        sid: false,
      };
    case QRCodeTrackingRptConstants.TRACK_JOURNEY_INFO_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
